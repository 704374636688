import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAuth from "../../context/AuthProvider"; 
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { Box } from '@mui/material';
import { FMTextField } from '../Elements/input';
import { PolicyModel, PolicyModel_VS } from '../../models/PolicyModel';

interface Iprops {
  PolicyModelCl: PolicyModel,
   
  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void,
  initValues: PolicyModel
 
}


export const AddEditRejectionNotePolicy: React.FC<Iprops> = ({ handleClose, Open, PolicyModelCl, handleSubmit, initValues }) => {
  const initialValues: PolicyModel = initValues ? PolicyModelCl : initValues;

  const { user } = useAuth();
  const token = user?.token;


  return (

    <Dialog open={Open} onClose={handleClose}>
            <DialogTitle>Please Add Rejection Notes</DialogTitle>
            <Formik initialValues={initialValues}
                validateOnChange={true}
                validationSchema ={PolicyModel_VS}
                onSubmit={(data, { setSubmitting }) => {
                  fetch(
                    config.url.API_URL + '/Policy/Reject',
                    {
                        method: 'POST',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        body: JSON.stringify(data)  // <-- Post parameters
            
                    })
                    .then((response) => {
                                    if (response.ok) {
                                      return response.json();
                                    }
                                    throw new Error('Something went wrong');
                                  })
                                  .then((res) => {
                                    // Do something with the response
                                    handleSubmit()
                                    setSubmitting(true)
                                    
                                  }).catch((error) => {
                                    console.log(error)
                                  });
                   
                }} >
                {({ values, isSubmitting }) => (
                    <Form>

                        <DialogContent>
                            <Box sx={{ display: 'flex', m:2 }}>
                                <FMTextField name="StatusNotes" autoFocus margin="dense" placeholder="Rejection Notes" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                            </Box>
                           

                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="secondary" onClick={handleClose} type="button"  >Cancel</Button>
                            <Button variant="outlined" type="submit" disabled={isSubmitting} >Submit</Button>
                        </DialogActions>

                    </Form>

                )}


            </Formik>
        </Dialog>

  );
}

