import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl,  Grid,  } from '@mui/material';
import {  Form, Formik } from 'formik';
import { config } from '../../Constants';
import { FMSelectCustomerPartnerStatus, FMSelectState } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';

interface Iprops {
    InsuredCL: InsuredModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: InsuredModel
}

export const AddEditInsured: React.FC<Iprops> = ({ handleClose, Open, InsuredCL, handleSubmit, initValues }) => {
    const initialValues: InsuredModel = initValues ? InsuredCL : initValues;
    const { user } = useAuth();
    const token = user?.token;

    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle>Insured</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={PartnersModelVS}
                onSubmit={(data, { setSubmitting }) => {

                    fetch(
                        config.url.API_URL + '/Partners/AddEdit',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="insuredName" autoFocus margin="dense" label="Name" placeholder="Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="insuredAddressLine1" placeholder='Address' label="Address" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                <FormControl sx={{ width: '100%' }}>
                                  <FMTextField name="insuredCity" InputLabelProps={{shrink: true}}   label="City"  autoFocus margin="dense" type="text" variant="outlined" sx={{ m: 2 }} />

                                  </FormControl >
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="insuredZipCode" placeholder='Zip' label="Zip" type="number" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                      <FMSelectState name="stateId" placeholder='State' label="State"/>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="secondary" onClick={handleClose} type="button" >Cancel</Button>
                            <Button variant="outlined" type='submit' disabled={isSubmitting} >Submit</Button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
