import { Box, IconButton, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";
import { useState } from "react";
import { ButlerResponseModel } from "../../models/ButlerResponseModel";
import DownloadIcon from '@mui/icons-material/Download';
import { useParams } from "react-router-dom";
import { Crop32Outlined } from "@mui/icons-material";
import FlagIcon from '@mui/icons-material/Flag';
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { ErrorMessage } from "formik";
import { minBy } from "lodash";
import { minHeight } from "@mui/system";

interface iprops {
    ResponseData: ButlerResponseModel[]
    loading: boolean
    setloading: (state: boolean) => void
    PolicyCheckData: { documentId: string, fieldName: string, policyChecklistResultId: number, isMatching: boolean, errorMessage: string }[]
    fetchDataDocument: () => void
    fetchPolicyChecksWithDocumentId: () => void
    customerPolicyDetails: any
}


export const CustomerPolicyDocumentDataList: React.FC<iprops> = ({ customerPolicyDetails, ResponseData, loading, setloading, PolicyCheckData, fetchDataDocument, fetchPolicyChecksWithDocumentId }) => {

    const { user } = useAuth();
    const token = user?.token;
    const [pageload, setpageloading] = useState(true);
    // const params = window.location.href.split('#')[1].split('@')[0];
    const url = useParams();
    const policyId = url.policyid;


    const UrlParams = useParams();

    // if (!loading) {
    //     const timer = setTimeout(() => {
    //         const section = document.getElementById('#' + params);
    //         console.log(section);
    //         section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }, 2000);
    // }

    const handleFlag = (flagId: number | undefined | null) => {
        // console.log(flagId);
        fetch(config.url.API_URL + `/ProcessPolicyChecks/UpdateFlag?PolicyCheckListResultId=${flagId}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {

                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                fetchDataDocument();
                fetchPolicyChecksWithDocumentId();

            }).catch((error) => {

                console.log("Errors", error)

            });

    };

    const handleSendEmail = (policyChecklistResultId: number | undefined) => {
        var polId = window.location.href.split('@')[1];

        fetch(config.url.API_URL + `/ProcessPolicyChecks/SendCheckSingleEmail?PolicyId=${polId}&&policyChecklistResultId=${policyChecklistResultId}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {

                    return response;
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                alert("Email Sent");



            }).catch((error) => {

                console.log(error)

            });

    };


    // if (document.readyState === 'complete') 
    // {
    //     const section = document.getElementById('#' + params);
    //     console.log(section);
    //     section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }

    function convertStringToDecimal(str: string): string {
        let total: number = 0;
        const sb: string[] = [];

        if (!str) {
            return total.toString();
        }

        if (str.includes('%')) {
            const splitString = str.split('%')[1];
            for (const c of splitString) {
                if (c === '.' || !isNaN(Number(c))) {
                    sb.push(c);
                }
            }
            total = parseFloat(sb.join(''));
        } else if (str.length >= 10) {
            const splitString = str.split('$')[1];
            for (const c of splitString) {
                if (c === '.' || !isNaN(Number(c))) {
                    sb.push(c);
                }
            }
            total = parseFloat(sb.join('').replace('$', ''));
        } else {
            total = parseFloat(str.replace('$', ''));
        }

        return total.toString();
    }

    // Example usage:
    const result = convertStringToDecimal("$123.45");
    // console.log(result);



    return (

        <React.Fragment>

            {/* <div className=" row " key={"1"} style={{ justifyContent: "center" }} > */}
            <div className=" row " key={"1"} style={{ justifyContent: "center" }} id="newdivPolicy">
                {ResponseData?.length > 0 ?
                    (

                        ResponseData?.map((index, i) =>
                        (


                            <div className={"col-lg-" + (ResponseData.length < 2 ? '12' : i > 1 ? '12' : '6') + "  "} key={`col1-${i}`}>
                                <div className="  ">


                                    {
                                        <React.Fragment>
                                            {index?.items?.map((item, i) => (

                                                <div key={`row1-${i}`} id={'#' + item.documentId}>
                                                    <div className="card dashboard-table scroller-none-1" style={{ height: "81vh", marginBottom: "8px", marginTop: "8px" }}>
                                                        <span className="card-title" >
                                                            <div style={{ minHeight: "40px" }}>
                                                                <Link color="black" download={item.fileName.split('_')[1]?.toString()} href={item.blob_Url} underline="none">{item.fileName.substring(item.fileName.indexOf('_') + 1, item.fileName.length)?.toString()}</Link>

                                                                <Link color="black" download={item.fileName.split('_')[1]?.toString()} href={item.blob_Url} underline="none">      <svg style={{ margin: "9px 11px 9px" }} width="22" height="22" viewBox="0 0 22 22" fill="none" className="ml-2" xmlns="http://www.w3.org/2000/svg" >
                                                                    <path d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75" stroke="#02A4E3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg></Link>
                                                            </div>
                                                        </span>
                                                        <div className="table-responsive p-4 " >
                                                            <table className="table">

                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={2} >

                                                                        </td>
                                                                    </tr>

                                                                    {item.documentStatus === "Completed" ?
                                                                        <React.Fragment>

                                                                            {
                                                                                item.formFields.map((cel, t) =>
                                                                                (
                                                                                    <React.Fragment key={`item1-${t}`}>
                                                                                        {
                                                                                            PolicyCheckData?.some((x) => x.fieldName === cel.fieldName)
                                                                                                ?
                                                                                                <React.Fragment>

                                                                                                    <tr key={t +1}

                                                                                                        className={
                                                                                                            customerPolicyDetails?.policyStatus === "Flagged" || customerPolicyDetails?.policyStatus === "State Flagged" ? (
                                                                                                                PolicyCheckData.find((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase() && x?.documentId === item?.documentId)?.isMatching === true ? "table-success"
                                                                                                                    : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase() && x?.documentId === item?.documentId)?.isMatching === false ? "table-danger"
                                                                                                                        : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase() && x?.documentId === "00")?.isMatching === false ? "table-danger"
                                                                                                                            : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase().trim() === cel.fieldName?.toLowerCase().trim() && x?.documentId === "00")?.isMatching === true ? "table-success"
                                                                                                                                : ""
                                                                                                            ) : "table-success"}
                                                                                                        title={PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel?.fieldName?.toLowerCase()
                                                                                                            && x.documentId === item.documentId)?.isMatching ? "" : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel?.fieldName?.toLowerCase()
                                                                                                                && x.documentId === item.documentId)?.errorMessage}>


                                                                                                        <td >{cel?.fieldName}</td>
                                                                                                        <td >
                                                                                                            {
                                                                                                                cel?.fieldName === "SL Agency License" || cel?.fieldName === "SL Broker License Number" || cel?.fieldName === "Broker License Number" ?

                                                                                                                    // (cel.value.startsWith('O') ? cel.value.replace('O', '0') : cel.value)
                                                                                                                    (cel?.value?.startsWith('O') ? cel?.value?.replace('O', '0') : cel?.value || cel?.value?.length <= 15 ? cel?.value?.substring(0, 15) : "")

                                                                                                                    :
                                                                                                                    cel?.fieldName === "State Tax" ?
                                                                                                                        (cel?.value.startsWith('Tax3.00%') ? cel?.value.split('$')[1] : cel?.value)

                                                                                                                        : cel?.fieldName === "Stamp Fee" ?
                                                                                                                            (cel?.value.startsWith('Fee0.18%') ? cel?.value.split('$')[1] : cel?.value)
                                                                                                                            : cel?.value

                                                                                                            }
                                                                                                        </td>

                                                                                                        {PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel.fieldName.toLowerCase() && x?.documentId === item?.documentId || x.documentId === "00") ?

                                                                                                            <td style={{}} className="getRows  d-flex">
                                                                                                                <IconButton title="Flag/Un-Flag"
                                                                                                                    color="primary"
                                                                                                                    onClick={() => {
                                                                                                                        handleFlag(PolicyCheckData.find
                                                                                                                            ((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase()
                                                                                                                            )?.policyChecklistResultId)
                                                                                                                    }}
                                                                                                                >

                                                                                                                    <FlagIcon />
                                                                                                                </IconButton >

                                                                                                                {!PolicyCheckData.find((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase() && x.documentId === item.documentId)?.isMatching ?
                                                                                                                    <IconButton title="Send Flag Communication"
                                                                                                                        color="primary"
                                                                                                                        onClick={() => { handleSendEmail(PolicyCheckData.find((x) => x.fieldName.toLowerCase() === cel.fieldName.toLowerCase() && x.documentId === item.documentId)?.policyChecklistResultId) }}
                                                                                                                    >
                                                                                                                        < ContactMailIcon />
                                                                                                                    </IconButton >
                                                                                                                    : ""}


                                                                                                                {
                                                                                                                    cel.fieldName === "SL Agency License" || cel.fieldName === "Agency License Number" || cel.fieldName === "SL Broker License Number" || cel.fieldName === "Broker License Number" ?
                                                                                                                        <Link title="Check License" href={'/CorrectedDocumentResubmission/' + UrlParams.custPolicyId + '/' + PolicyCheckData.find((x) => x.fieldName.toLowerCase() === cel.fieldName.toLowerCase() && x.documentId === item.documentId)?.documentId + "/" + PolicyCheckData.find((x) => x.fieldName.toLowerCase() === cel.fieldName.toLowerCase() && x.documentId === item.documentId)?.fieldName.replaceAll(' ', '@') + "/" + cel.value} color="#1976D2">
                                                                                                                            {
                                                                                                                                <IconButton
                                                                                                                                    color="primary"

                                                                                                                                >
                                                                                                                                    <FactCheckIcon />
                                                                                                                                </IconButton>
                                                                                                                            }
                                                                                                                        </Link>
                                                                                                                        : ""}
                                                                                                                <Tooltip title="Flag Information">
                                                                                                                    <IconButton
                                                                                                                        color="primary"
                                                                                                                        onClick={() => {
                                                                                                                            let x = document.getElementById('#' + item.documentId + t)
                                                                                                                            if (x != null) {
                                                                                                                                if (x?.style.display === "none") {
                                                                                                                                    x.style.display = "";
                                                                                                                                } else {
                                                                                                                                    x.style.display = "none";
                                                                                                                                }
                                                                                                                            }

                                                                                                                        }}

                                                                                                                    >

                                                                                                                        <InfoIcon />
                                                                                                                    </IconButton>
                                                                                                                </Tooltip>


                                                                                                            </td>
                                                                                                            : ""}

                                                                                                    </tr>

                                                                                                    <tr className="table" style={{ display: "none", background: "yellow" }} id={'#' + item.documentId + t} >
                                                                                                        <td>
                                                                                                            Flag Informaion
                                                                                                        </td>

                                                                                                        <td colSpan={2}>
                                                                                                            {
                                                                                                                PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel?.fieldName?.toLowerCase()
                                                                                                                    && x.documentId === item.documentId)?.isMatching ? ""
                                                                                                                    : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel?.fieldName?.toLowerCase()
                                                                                                                        && x.documentId === item.documentId)?.errorMessage == null ?
                                                                                                                        PolicyCheckData.find((x) => x.fieldName?.toLowerCase() === cel?.fieldName?.toLowerCase()
                                                                                                                            && x?.documentId === "00")?.errorMessage : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase() === cel?.fieldName?.toLowerCase()
                                                                                                                                && x?.documentId === item?.documentId)?.errorMessage
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </React.Fragment>


                                                                                                :
                                                                                                <tr key={`sl-${t}`}>
                                                                                                    <td>{cel.fieldName}</td>
                                                                                                    <td >  {
                                                                                                        cel.fieldName === "SL Agency License" || cel.fieldName === "SL Broker License Number" || cel.fieldName === "Broker License Number" ?

                                                                                                            // (cel.value.startsWith('O') ? cel.value.replace('O', '0') : cel.value)
                                                                                                            (cel.value.startsWith('O') ? cel.value.replace('O', '0') : cel.value || cel.value.length >= 15 ? cel.value.substring(0, 15) : "")

                                                                                                            : cel.value

                                                                                                    }</td>
                                                                                                    <td >
                                                                                                        {
                                                                                                            cel.fieldName === "SL Agency License" || cel.fieldName === "Agency License Number" || cel.fieldName === "SL Broker License Number" || cel.fieldName === "Broker License Number" ?
                                                                                                                <Link title="Check License" href={'/CorrectedDocumentResubmission/' + UrlParams.custPolicyId + '/' + PolicyCheckData.find((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase() && x?.documentId === item?.documentId)?.documentId + "/" + PolicyCheckData.find((x) => x.fieldName?.toLowerCase() === cel.fieldName?.toLowerCase() && x.documentId === item.documentId)?.fieldName?.replaceAll(' ', '@') + "/" + cel.value} color="#1976D2">
                                                                                                                    {
                                                                                                                        <IconButton
                                                                                                                            color="primary"

                                                                                                                        >
                                                                                                                            <FactCheckIcon />
                                                                                                                        </IconButton>
                                                                                                                    }
                                                                                                                </Link>
                                                                                                                : ""}
                                                                                                    </td>

                                                                                                </tr>


                                                                                        }

                                                                                    </React.Fragment>
                                                                                ))
                                                                            }


                                                                        </React.Fragment>

                                                                        : ""}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div>
                                                       
                                                    </div>

                                                </div>

                                            ))}


                                        </React.Fragment>

                                    }

                                </div>

                            </div>





                        )
                        )
                    )


                    : ""
                }
            </div>
            {PolicyCheckData?.some((x) => x.fieldName === "Signature" && x.documentId === "00") ? (

                <div className="table-responsive p-6 " >
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title" style={{ marginLeft: '20px' }}>Auto Generated SL-1</h5>

                            <table className="table">

                                <tbody>
                                    <tr>
                                        <td colSpan={2} >

                                        </td>
                                    </tr>

                                    <tr className={
                                        customerPolicyDetails?.policyStatus === "Flagged" || customerPolicyDetails?.policyStatus === "State Flagged" ? (
                                            PolicyCheckData.find((x) => x.fieldName?.toLowerCase() === "signature" && x.documentId === "00")?.isMatching === true ? "table-success"
                                                : PolicyCheckData?.find((x) => x.fieldName?.toLowerCase().trim() === "signature" && x.documentId === "00")?.isMatching === false ? "table-danger"
                                                    : ""
                                        ) : "table-success"}
                                    >
                                        <td >
                                            {
                                                PolicyCheckData.find((x) => x.fieldName === "Signature" && x.documentId === "00" && x.isMatching === false)?.errorMessage
                                            }
                                        </td>
                                        <td>
                                            <IconButton title="Flag/Un-Flag"
                                                color="primary"
                                                onClick={() => {
                                                    handleFlag(PolicyCheckData.find(
                                                        (x) => x.fieldName?.toLowerCase() === "Signature".toLowerCase()
                                                    )?.policyChecklistResultId)
                                                }}
                                            >
                                                <FlagIcon />
                                            </IconButton>
                                        </td>
                                        {/* <div className="" style={{ textAlign: 'left', marginLeft: '30px' }}>
                                                    {
                                                        PolicyCheckData.find((x) => x.fieldName === "Signature" && x.documentId === "00" && x.isMatching === false)?.errorMessage
                                                    }
                                                    <IconButton title="Flag/Un-Flag"
                                                        color="primary"
                                                        onClick={() => {
                                                            handleFlag(PolicyCheckData.find(
                                                                (x) => x.fieldName?.toLowerCase() === "Signature".toLowerCase()
                                                            )?.policyChecklistResultId)
                                                        }}
                                                    >
                                                        <FlagIcon />
                                                    </IconButton>


                                                </div> */}

                                    </tr>


                                </tbody>
                            </table>
                        </div >
                    </div>
                </div>
            ) : ""
            }



            <div className=" row  " key={"2"} style={{ justifyContent: "center" }} >
                {
                    ResponseData?.length > 0 ?
                        (

                            ResponseData?.map((index, i) =>
                            (


                                // <div className={"col-lg-" + (i > 1 ? '12' : '6') + "  "} key={i}>
                                //     <div className="m-3 card " style={{ height: "100vh", overflow: "scroll" }}>

                                <React.Fragment key={`row2-${i}`}>
                                    {
                                        index.items.map((item, i) => (





                                            item?.tables.map((table, t) => (
                                                <div className={"col-lg-" + (i > 1 ? '12' : '12') + "  "} key={i}>
                                                    <div className=" card p-5" style={{ marginBottom: "16px", marginTop: "16px" }}>
                                                        <React.Fragment>
                                                            <span className="card-title" >
                                                                {table?.tableName}
                                                            </span>

                                                            <table className="table table-boder-colapse table-hover  " >
                                                                <thead className="table-header custom-tr-1">
                                                                    <tr >


                                                                        {table.rows[0]?.cells.map((hname, idx) =>
                                                                            <React.Fragment key={`tbl1-${idx}`}>

                                                                                <th>
                                                                                    {hname.columnName}
                                                                                </th>
                                                                                <th>

                                                                                </th>
                                                                            </React.Fragment>

                                                                        )}


                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-group-divider" >
                                                                    {table?.rows?.map((row, index) =>
                                                                        <React.Fragment key={`tbl2-${index}`}>


                                                                            <tr >
                                                                                {row?.cells?.map((cell, i) =>
                                                                                    <React.Fragment key={`item2-${i}`}>
                                                                                        <td style={{ textAlign: "center" }} title={PolicyCheckData?.find(x => x.fieldName?.toLowerCase() === cell?.columnName?.toLowerCase() + (index + 1))?.errorMessage}

                                                                                            className={
                                                                                                customerPolicyDetails === "Flagged" || customerPolicyDetails === "State Flagged" ? (
                                                                                                    PolicyCheckData?.find(x => x.fieldName?.toLowerCase() === cell.columnName?.toLowerCase() + (index + 1))?.isMatching == false ? "table-danger" : PolicyCheckData.find(x => x.fieldName?.toLowerCase() === cell.columnName?.toLowerCase() + (index + 1))?.isMatching == true ? "table-success" : ""

                                                                                                ) : ""
                                                                                            }>
                                                                                            {cell.value}

                                                                                        </td>
                                                                                        {PolicyCheckData.find(x => x.fieldName?.toLowerCase() === cell.columnName?.toLowerCase() + (index + 1)) ?
                                                                                            <td style={{ verticalAlign: "middle" }} className="getRows">
                                                                                                <IconButton title="Flag/Un-Flag"
                                                                                                    color="primary"
                                                                                                    onClick={() => {
                                                                                                        handleFlag(PolicyCheckData.find
                                                                                                            ((x) => x.fieldName?.toLowerCase() === cell.columnName?.toLowerCase() + (index + 1)
                                                                                                            )?.policyChecklistResultId)
                                                                                                    }}
                                                                                                >

                                                                                                    <FlagIcon />
                                                                                                </IconButton >
                                                                                                <Tooltip title={PolicyCheckData.find(x => x.fieldName?.toLowerCase() === cell.columnName?.toLowerCase() + (index + 1))?.errorMessage ?? ""}>
                                                                                                    <IconButton
                                                                                                        color="primary"
                                                                                                    // onClick={() => {
                                                                                                    //     let x = document.getElementById('#' + item.documentId + t)
                                                                                                    //     if (x != null) {
                                                                                                    //         if (x?.style.display === "none") {
                                                                                                    //             x.style.display = "";
                                                                                                    //         } else {
                                                                                                    //             x.style.display = "none";
                                                                                                    //         }
                                                                                                    //     }

                                                                                                    // }}

                                                                                                    >

                                                                                                        <InfoIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </td>
                                                                                            : <td></td>}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </tr>


                                                                        </React.Fragment>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            ))





                                        ))}
                                </React.Fragment>


                                //     </div>


                                // </div>



                            )
                            )
                        )
                        : ""}
            </div >
        </React.Fragment >
    )
}
