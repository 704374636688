import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import Moment from 'moment';
import { Field, FieldAttributes, Form, Formik, useField } from 'formik';
import { config } from '../../Constants';
import { FMSelectState, FMSelectTaxTypeField, FMSelectTrueFalse } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { StatesZeroFilingRuleModel, StatesZeroFilingRuleModel_VS } from '../../models/StatesZeroFilingRuleModel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Iprops {
    StateZeroRuleMatrixCL: StatesZeroFilingRuleModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: StatesZeroFilingRuleModel
}

export const EditStateZeroFilingRuleMatrix: React.FC<Iprops> = ({ handleClose, Open, StateZeroRuleMatrixCL, handleSubmit, initValues }) => {
    const initialValues: StatesZeroFilingRuleModel = initValues ? StateZeroRuleMatrixCL : initValues;

    const { user } = useAuth();
    const token = user?.token;

    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { stateId: 0, stateName: "" };
        const [States, setStates] = useState([initialdata]);
        const fetchData = () => {
            fetch(config.url.API_URL + "/States/getStates", {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error("Something went wrong");
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {
                        setStates(responseJson.data);
                    }
                    console.log("statesDAta", responseJson.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        useEffect(() => {
            fetchData();
        }, []);
        return (
            <FormControl error={!!errorText} fullWidth>
                {/* <FormHelperText>Select State</FormHelperText> */}
                <InputLabel id="State-Select-Label">State</InputLabel>
                <Select
                    {...field}
                    labelId="State-Select-Label"
                    label="State"
                    IconComponent={ExpandMoreIcon}
                    // label={null}
                    className="border-round main-form-2 "
                >
                    {/* <MenuItem key={0} value={0}>
                        {" "}
                        All State{" "}
                    </MenuItem> */}
                    {States.map((state, key) => (
                        <MenuItem key={key} value={state.stateId}>
                            {" "}
                            {state.stateName}{" "}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };


    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle  className='sub-card-title' >State Zero Filings Rules</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={StatesZeroFilingRuleModel_VS}
                onSubmit={(data, { setSubmitting }) => {
                    if (data.stateId <= "") {
                        alert("Please Select state");
                        return;
                    }



                    fetch(
                        config.url.API_URL + '/StatesZeroFilingRules/Edit',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            alert("Data successfully saved");

                            handleSubmit()
                            setSubmitting(true)
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    {/* <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="name" autoFocus margin="dense" label="State Name" placeholder="State Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl> */}
                                    {/* <FMSelectState autoWidth={true} name="stateId" /> */}
                                    <FMSelectStateCustom autoWidth={true} name="stateId"/>

                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="postalAbbv" autoFocus margin="dense" label="Postal Abbv" placeholder="Postal Abbv"  type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="standardAbbv" autoFocus margin="dense" label="Standard Abbv" placeholder="Standard Abbv" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FMSelectTrueFalse autoWidth={true} name="isElectronicSignature" label="Is Electronic Signature" />

                                </Grid>
                                <Grid item xs={6}>
                                    <FMSelectTrueFalse autoWidth={true} name="isZeroFilingState" label="is ZeroFiling State" />

                                </Grid>



                            </Grid>





                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button"  >Cancel</button>
                            <button  className="btn  btn-primary active-btn-blue  search-btn " disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
