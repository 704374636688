import { useEffect, useRef, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import React from "react";
import { ReconciliationTransactionReportModel_Resp, ReconciliationTransactionReportModel } from "../models/TaxTransactionReportModel";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { TaxTransactionReportList } from "../components/Lists/TaxTransactionReportList";
import { writeXlsxFile } from "../helper/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import formatPrice from "../helper/currencyFormatter";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import { FMTextField } from "../components/Elements/input";
import moment from "moment";


export const TaxTransactionReportModule = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: ReconciliationTransactionReportModel_Resp = {
        getAllTaxTransactionReportWithExport: [],
        totalCount: 0
    }
    const [ReportModule, setReportModule] = useState<ReconciliationTransactionReportModel_Resp>(initValue)
    const [stateId, setstateId] = useState(0);
    const [tax, setTax] = useState("");
    const PloicyNum = React.useRef("");
    const insName = React.useRef("");
    const effectiveDateEd = React.useRef("");
    const effectiveDateSt = React.useRef("");
    const stateData = React.useRef(0);
    const customerData = React.useRef(0);
    const taxData = React.useRef("");
    const statusData = React.useRef("");
    const [selectedButton, setSelectedButton] = useState<number>(1);
    const [customerId, setCustomerId] = useState(0);
    const [statuses, setstatus] = useState("");
    const [selectedRows, setSelectedRows] = useState<ReconciliationTransactionReportModel[] | null>([]);
    const [_currentPage, setCurrentPage] = React.useState(1);
    const [_pageSize, setPageSize] = React.useState(50);

    // const keyNames: (keyof ReconciliationTransactionReportModel)[] = [
    //     // 'dueDate',
    //     'client', 'invoiceNumber', 'insuredName', 'policyNumber',
    //     'homeState', 'transactionType', 'invDate', 'taxType',
    //     'policyEffDate', 'policyExpDate', 'grossPremium', 'coverage',
    //     'carrier', 'naic', 'nonAdmittedPremium', 'taxableFees', 'nonTaxableFees',
    //     'taxBalance', 'clientDiv', 'dateInvoiceAdded', 'clientNotes', 'suspended',
    //     'dateFiled', 'filingType', 'filerName', 'paymentPeriod', 'paymentNoticeDate',
    //     'feeOnly', 'bor', 'status', 'filerNotes', 'firstRequest', 'secondRequest',
    //     'thirdRequest', 'taxAmt'
    // ];


    // const keysNamesFromXlsx: string[] = [
    //     // 'Filing Due Date', 
    //     'Client', 'Invoice Number', 'Insured Name', 'Policy Number',
    //     'Home State', 'Transaction Type', 'Invoice Date', 'Tax Type',
    //     'Policy Eff Date', 'Policy Exp Date', 'Gross Premium', 'Coverage',
    //     'Carrier', 'NAIC', 'Non Admitted Premium', 'Taxable Fees', 'Non Taxable Fees',
    //     'Tax Balance', 'Client Div', 'Date Invoice Added', 'Client Notes', 'Suspended',
    //     'Date Filed', 'Filing Type', 'Filer Name', 'Payment Period', 'Payment Notice Date',
    //     'Fee Only', 'BOR', 'Policy Status', 'Filer Notes', 'First Request', 'Second Request',
    //     'Third Request', 'Tax Amount'
    // ];

    const fetchMainData = (action: string, pageSize: number, pageNumber: number) => {
        // 
        setloading(true);
        console.log(action);
        console.log("refreshdata.");

        const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        var customerIds = selectedValuesArray.join(',');
        const url = `${config.url.API_URL}/TaxTransactionReport/${action}?userId=${user?.userId}&policyNumber=${PloicyNum.current}
        &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
        &taxName=${encodeURIComponent(taxData.current)}
        &status=${encodeURIComponent(statusData.current)}
        &insuredName=${insName.current}&customerId=${customerData.current}&customerIds=${customerIds}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

        fetch(

            url,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                console.log("reportlistdata", res.data);
                // Do something with the response
                if (res.data.totalCount > 0) {
                    setReportModule(res.data)
                }
                else {
                    setReportModule(initValue)
                }
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });
    }

    const fetchDataSorting = (action: string, pageSize: number, pageNumber: number, field: string, sort?: string) => {
        setloading(true);
        console.log(action);
        console.log("refreshdata.");
        action = 'getAllTaxTransactionFilingsData';

        const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        var customerIds = selectedValuesArray.join(',');
        const url = `${config.url.API_URL}/TaxTransactionReport/${action}?userId=${user?.userId}&policyNumber=${PloicyNum.current}
        &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
        &taxName=${encodeURIComponent(taxData.current)}
        &status=${encodeURIComponent(statusData.current)}
        &insuredName=${insName.current}
        &customerId=${customerData.current}
        &customerIds=${customerIds}
        &pageSize=${pageSize}
        &pageNumber=${pageNumber}
        &field=${field}&sort=${sort}`


        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false);
                console.log("reportlistdata", res.data);

                if (res.data.totalCount > 0) {
                    setReportModule(res.data);
                } else {
                  
                    setReportModule({
                        ...initValue,
                        getAllTaxTransactionReportWithExport: [], // Assuming `initValue` has a `data` property that needs to be an empty array
                        totalCount: 0,
                    });
                }
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };
    function updateStatus(status: string, selectedRow: any) {
        setloading(true);

        fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRow)
        }).then((response) => {
            setloading(false);
            if (response.ok) {
                return response.json();
            }
            throw new Error("Something went wrong");
        }).then((res) => {
            setloading(false);
            if (res !== null && res !== undefined) {
                Swal.fire({
                    title: res.data,
                    text: res.message,
                    iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    confirmButtonColor: '#02A4E3',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn-radius',
                        cancelButton: 'btn-radius',
                        popup: 'card-radius'
                    }
                }).then((result) => {
                    window.location.reload();
                })
            }
        }).catch((error) => {
            console.log(error);
            setloading(false);
        });
    }
    const handlePaid = () => {
        console.log({ selectedRows })
        const status: string = 'Paid'
        Swal.fire({
            title: 'Confirmation!',
            text: "Do you want to update customer policy's status as " + status + "?",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setloading(true);
                if (status !== undefined && status !== '' && status !== null) {
                    updateStatus('Paid', selectedRows);
                }
            }
        });

        setloading(false);
    }

    const onExportXlsx = (values: ReconciliationTransactionReportModel[]) => {
        setloading(true);
        if (values?.length === 0) {
            setloading(false);
            return;
        }
        const fieldsToExport: (keyof ReconciliationTransactionReportModel)[] = [
            'invoiceNumber', 'insuredName', 'policyNumber',
            'homeState', 'taxType', 'transactionType', 'invDate',
            'policyEffDate', 'policyExpDate', 'grossPremium', 'taxAmt','client','clientDiv'
        ];
        const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

        const formattedValues = values.map(item => {
            item.taxAmt = formatPrice(item.taxAmt ?? "");
            item.grossPremium = formatPrice(item.grossPremium ?? "");
            item.invDate = (item.invDate !== null && item.invDate !== '') ? moment(item.invDate).format("MM/DD/YYYY") : "";
            console.log('before:', item.policyExpDate);
            item.policyEffDate = (item.policyEffDate !== null && item.policyEffDate !== '') ? moment(item.policyEffDate).format("MM/DD/YYYY") : "";
            item.policyExpDate = (item.policyExpDate !== null && item.policyExpDate !== '') ? moment(item.policyExpDate).format("MM/DD/YYYY") : "";
            const update: (string | number | Date | undefined)[] = fieldsToExport.map((key: keyof ReconciliationTransactionReportModel) => {
                return item[key];
            });
            console.log(item.policyExpDate);

            return update;
        });

        const headers = fieldsToExport.map(field => capitalizeFirstLetter(field));

        const updates = [
            headers,
            ...formattedValues
        ];

        writeXlsxFile(updates);
        setloading(false);
    };

    const handleExportXlsxNew = async (val: ReconciliationTransactionReportModel[]) => {
        try {
            setloading(true);

            console.log("selecteddata", selectedRows);
            const action = "getAllTaxTransactionFilingsData";
            const response = await fetchExportData(action, selectedOptions, ReportModule.totalCount, 1, selectedRows);
            let values = response
            if (val && val?.length > 0) {
                values = val
            }
            if (values?.length <= 0) {
                alert('Please upload a xlsx file')
                return
            }
            onExportXlsx(values);
            setSelectedRows([]);
            fetchMainData('getAllTaxTransactionFilingsData', _pageSize, _currentPage);
            setloading(false);
        } catch (error) {
            setloading(false);
            console.error("Error occurred:", error);
        }
    }

    async function fetchExportData(action: string, customers: string | string[], pageSize: number, pageNumber: number, selectedRows: any) {

        setloading(true);
        var MultiplePolicyIds: string = selectedRows?.map((x: { customerpolicyid: string }) => x.customerpolicyid).join(",") ?? "";

        const response = await fetch(
            config.url.API_URL +
            `/TaxTransactionReport/${action}?userId=${user?.userId}&&policyNumber=${PloicyNum.current}
            &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
            &taxName=${encodeURIComponent(taxData.current)}&status=${encodeURIComponent(statusData.current)}
            &insuredName=${insName.current}&customerId=${customerData.current}&&customerIds=${MultiplePolicyIds}&&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                if (res.data.totalCount > 0) {

                    if (pageSize <= 100) {
                        setReportModule(res.data)
                    }


                    return res.data.getAllTaxTransactionReportWithExport;
                }
                
            }).catch((error) => {
                setloading(false)
            });
        return response;
    }

    const [TaxandFees, setTaxandFees] = useState<string[]>([""]);

    const fetchTaxFeeName = () => {
        fetch(config.url.API_URL + `/PolicyFilingType/GetAllTaxandFeeName`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                if (responseJson.data != null) {
                    setTaxandFees(responseJson.data);
                }

                console.log("TaxandFees", responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchTaxFeeName();
    }, []);

    const FMSelectTaxAndFeesCustom: React.FC<SelectProps> = ({
        variant,
        ...props
    }) => {
        const handleTaxChange = (event: SelectChangeEvent<string>) => {
            const selectedTax = event.target.value;
            setloading(true); // Correctly use setLoading
            setTax(selectedTax);
            taxData.current = selectedTax;
            console.log('taxcurrentdata', taxData.current);
            setloading(false);
        };

        return (
            <div>
                <FormControl fullWidth>
                    <Select
                        onChange={handleTaxChange}
                        value={tax}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                    >
                        <MenuItem key={""} value={""} className="custom-menu-item">
                            Tax Type
                        </MenuItem>
                        {TaxandFees?.map((taxdata, key) => (
                            <MenuItem
                                key={key}
                                className="custom-menu-item"
                                value={taxdata}
                            >
                                {taxdata}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    const statusList = [
        'Not Started', 'Waiting on Client', 'Ready to File', 'Filed', 'Pending Autofile', 'Closed', 'Filed - Binder'
    ];
    const isRowSelected = selectedRows !== null && selectedRows?.length > 0

    const FMSelectStatus: React.FC<SelectProps> = ({
        variant,
        ...props
    }) => {

        const handleStatusChange = (event: SelectChangeEvent<string>) => {
            const selectedStatus = event.target.value;
            setloading(true);
            setstatus(selectedStatus);
            statusData.current = selectedStatus; // Update the ref
            console.log('Current Status Data:', statusData.current);
            setloading(false);
        };

        return (
            <div>
                <FormControl fullWidth>
                    <Select
                        onChange={handleStatusChange}
                        value={statuses} // Ensure this matches the state
                        id="status-select"
                        autoWidth
                        size="small"
                        label={null}
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                    >
                        <MenuItem key={""} value={""} className="custom-menu-item">
                            Status
                        </MenuItem>
                        {statusList.map((item, key) => (
                            <MenuItem
                                key={key}
                                className="custom-menu-item"
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    const initialUserdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialUserdata]);
    const fetchCustomer = () => {
        fetch(config.url.API_URL + "/Partners/getPartners", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                setCustomer(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [selectedOptions, setSelectedOption] = React.useState<string[] | string>([]);

    const FMSelectCustomCustomer: React.FC<SelectProps> = ({
        variant,
        ...props
    }) => {
        const handleChange = (event: SelectChangeEvent) => {
            setloading(true);
            setCustomerId(Number(event.target.value));
            customerData.current = Number(event.target.value);
            setloading(false);
        };

        return (
            <div>
                <FormControl fullWidth>
                    {/* <InputLabel id="test-select-label"></InputLabel> */}
                    <Select
                        onChange={handleChange}
                        value={customerId.toString()}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                    >
                        <MenuItem key={0} value={0} className="custom-menu-item">
                            Client
                        </MenuItem>
                        {Customer?.map((item, key) => (
                            <MenuItem
                                key={key}
                                className="custom-menu-item"
                                value={item.partnersId}
                            >
                                {item.customerName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    function RunSearch() {

        fetchMainData('getAllTaxTransactionFilingsData', _pageSize, _currentPage);

    }

    const initialStatedata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialStatedata]);

    const [open, setOpen] = useState(false);
    const fetchStateData = () => {
        fetch(config.url.API_URL + "/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response

                setStates(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const FMSelectStateCustom: React.FC<SelectProps> = ({ variant, ...props }) => {
        const handleChangestate = (event: SelectChangeEvent) => {
            setloading(true);

            setstateId(Number(event.target.value));
            stateData.current = Number(event.target.value);
            setloading(false);
        };
        return (

            <FormControl fullWidth>
                <Select
                    onChange={handleChangestate}
                    value={stateId.toString()}
                    size="small"
                    id="test-select-label"
                    // autoWidth
                    label={null}
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                >
                    <MenuItem value={"0"} className="custom-menu-item">Home State</MenuItem>
                    {States?.map((state, key) => (
                        <MenuItem
                            key={key}
                            value={state.stateId}
                            className="custom-menu-item"
                        >
                            {state.stateName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    function ClearSearch() {
        setloading(true);

        setstateId(0);
        setCustomerId(0);
        setstatus("");
        setTax("");

        stateData.current = 0;
        customerData.current = 0;
        taxData.current = "";
        statusData.current = "";
        setloading(false);

        fetchMainData('getAllTaxTransactionFilingsData', _pageSize, _currentPage);
    }

    const handleCloseNotesDialog = () => {
        setOpen(false);

    };
    interface newProp {
        handleClose: () => void;
        open: boolean;

    }

    interface NewProp {
        paymentPeriod?: string;
        selectedRows: ReconciliationTransactionReportModel[] | null;

    }

    const ShowPaymenPeriod = ({ open, handleClose }: newProp) => {

        const initValuePaid: NewProp = {
            selectedRows: selectedRows,
            paymentPeriod: '',
        }

        return (
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <DialogTitle className='card-title'>Payment Period </DialogTitle>
                <React.Fragment>
                    <Formik
                        initialValues={initValuePaid}
                        onSubmit={(data, setError) => {

                            fetch(
                                config.url.API_URL + `/TaxTransactionReport/updateTaxTransactionPaidStatus`,
                                {
                                    method: 'POST',
                                    headers: new Headers({
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    }),
                                    body: JSON.stringify(data)
                                }
                            )
                                .then((response) => {
                                    if (response.ok) {

                                        return response.json();

                                    }
                                    throw new Error('Something went wrong');
                                })
                                .then((res) => {
                                    if (res.resp === 1) {
                                        Swal.fire({
                                            title: res.message,
                                            text: "",
                                            icon: "warning",
                                            confirmButtonText: "OK",
                                        });
                                        console.log("response", res);
                                    } else if (res.resp === 0) {

                                        Swal.fire({
                                            title: "Data Successfully saved",
                                            text: "",
                                            icon: "success",
                                            confirmButtonText: "OK",
                                        });
                                        setSelectedRows([]);
                                        handleClose();
                                        fetchMainData('getAllTaxTransactionFilingsData', _pageSize, _currentPage);

                                    } else {
                                        Swal.fire({
                                            title: "Unexpected response",
                                            text: "The response code was not recognized.",
                                            icon: "warning",
                                            confirmButtonText: "OK",
                                        });

                                        console.log("response", res);
                                    }
                                })
                                .catch((error) => {
                                    Swal.fire({
                                        title: "Error",
                                        text: error.message,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                    });
                                    console.error('Error:', error);
                                });

                        }
                        }
                    >
                        {({ values, isSubmitting, setErrors }) => (
                            <Form className='main-form'>

                                <DialogContent>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%', minWidth: '500px' }}>
                                            <FMTextField
                                                name="paymentPeriod"
                                                label={null}
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ marginTop: 2 }}
                                                multiline
                                                // value={null}
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <button className='btn-2 btn d-flex btn-primary search-btn' style={{ minWidth: '125px' }} type='button' onClick={handleClose}>Cancel</button>
                                    <button className='btn btn-primary active-btn-blue' type='submit' disabled={isSubmitting}>Submit</button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>

            </Dialog>
        );
    };


    const handleExport = (option: string) => {
        handleExportXlsxNew([])

    };
    useEffect(() => {
        fetchMainData('getAllTaxTransactionFilingsData', _pageSize,_currentPage);
        fetchCustomer();
        fetchStateData();
    }, [])



    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="card-title">
                    <span className="d-flex">Reconciliation Queue</span>
                </div>

            </div>
            <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
            </div>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ width: "100%" }}>

                    <Box>
                        <Grid
                            container
                            spacing={2}
                            className="filter-grid"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC State Reporter")) ? (
                                    <FMSelectCustomCustomer name="customer" />
                                ) : ""}
                            </Grid>
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                <FMSelectStateCustom name="state" />
                            </Grid>
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                <FMSelectTaxAndFeesCustom name="taxName" />
                            </Grid>
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                <FMSelectStatus />
                            </Grid>
                            <Grid item xl={0.7} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                RunSearch();
                                            }}
                                            className="active-btn1-blue-tabs"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
                                <FormControl fullWidth>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                ClearSearch();
                                            }}
                                            className="btn-clear-refresh btn"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
                                <FormControl fullWidth>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                            className="btn-clear-refresh btn"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Box>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="card-title">

                </div>
                <div className="d-flex align-items-center mt-2" >

                    {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC State Reporter")) && (selectedRows !== null && selectedRows?.length > 0) ?
                        <button
                            className=" ms-2 active-btn-red "
                            onClick={() => { setOpen(true) }}

                            title="Paid" >
                            Paid
                        </button>
                        : ""
                    }

                    <button
                        className=" ms-2  active-btn-blue-export-12  "
                        onClick={() => { handleExport('') }}

                        title="Export" >
                        Export
                    </button>

                </div>
            </div>

            <TaxTransactionReportList
                ReportModuleCL={ReportModule}
                fetchDataMain={fetchDataSorting}
                selectedButton={selectedButton}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setCurrentPage={setCurrentPage}
                _currentPage={_currentPage}
                setPageSize={setPageSize}
                _pageSize={_pageSize}
            />
            <ShowPaymenPeriod handleClose={handleCloseNotesDialog} open={open} />
        </LocalizationProvider>
    );

};



