import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { LoggedInUserInfo } from "../../components/Elements/loggedInUserInfo";
import { RightNav } from "../../components/Nav/RightNav";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import BasicTabs, { FMTabs } from "../Tabs";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import {
    DataGrid,
    getGridStringOperators,
    GridColDef,
    GridColumnMenuContainer,
    GridColumnMenuProps,
    GridColumnsMenuItem,
    GridFilterMenuItem,
    GridFilterModel,
    GridFilterOperator,
    GridRowId,
    GridSelectionModel,
    GridSortModel,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    HideGridColMenuItem,
    SortGridMenuItems,
    // GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import { useParams } from "react-router-dom";
import { PolicyDocumentsPopup } from "./PolicyDocumentsPopup";
import { PolicyErrorPopup } from "./PolicyErrorsPopup";
import { RiExpandUpDownLine } from "react-icons/ri";
import { ArrowForward, ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import { FaRegEdit } from "react-icons/fa";
import { size } from "lodash";
import { margin, Stack } from "@mui/system";
import React from "react";
import { FMFilters, NewCompletedFilingsModel, getCompletedFilings__Resp, getPolicyFilingAndReport } from "../../models/NewCompletedFilingsModel";
import { MenuDropdown } from "../../components/Elements/MenuDropdown";
import Swal from "sweetalert2";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import FilerNotes from "../../components/Elements/filerNotes";
import formatPrice from "../../helper/currencyFormatter";
import { boolean } from "yup";
import { getRowsPerPageOptions } from "../../components/AddEdit/CustomFunctions";

interface Iprops {
    NewCompletedFilingsCL: getCompletedFilings__Resp;
    fetchDataMain: (
        action: string,
        newValue: number,
        pageSize: number,
        currentPage: number
    ) => void;
    closePolicy: (
        custPolicyId: number,
        action: string,
        value: number,
        message: string,
        isClosed: boolean
    ) => void;

    fetchPendingDataSorting: (
        action: string,
        newValue: number,
        pageSize: number,
        currentPage: number,
        field: string,
        sort?: string
    ) => void;
    getCustomerPolicyListbyTaxType: (
        action: string,
        customerPolicyIds: number[],
        option: string
    ) => void;
    getCustomerPolicyDetails: (customerPolicyIds: number) => Promise<string>;
    // NewCompletedFilingsCL: NewCompletedFilingsModel[];
    // RefreshListData: (action: string, newValue: number) => void;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    _currentPage: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    _pageSize: number
    debouncedOnFilterChange: ((filterModel: GridFilterModel, tabValue: number) => void)
    fetchDataForFilter: (action: string, newValue: number, pageSize: number, pageNumber: number, newFilters?: FMFilters) => void;
    queryOptions: FMFilters | undefined
    sortModelRef: React.MutableRefObject<GridSortModel>
}

export const NewCompletedFilings: React.FC<Iprops> = ({
    NewCompletedFilingsCL,
    fetchDataMain,
    closePolicy,
    fetchPendingDataSorting,
    getCustomerPolicyListbyTaxType,
    getCustomerPolicyDetails,
    setCurrentPage,
    _currentPage,
    setPageSize,
    _pageSize,
    debouncedOnFilterChange,
    fetchDataForFilter,
    queryOptions,
    sortModelRef
    //RefreshListData,
}) => {
    //const [_currentPage, setCurrentPage] = React.useState(1);
    // const [_pageSize, setPageSize] = React.useState(50);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const [Formdata, setFormData] = useState([]);
    const [Open, setOpen] = useState(false);
    const params = useParams();
    const [OpenError, setOpenError] = useState(false);
    const [selectedRows, setSelectedRows] = useState<NewCompletedFilingsModel[]>();
    const [ErrorFormdata, setErrorFormData] = useState([]);
    const [custPolId, setcustPolId] = React.useState("0");
    const [OpenDocResubmission, setDocResubmission] = React.useState(false);
    const [selectedStatus, setselectedStatus] = useState("");
    const [openNotesDialog, setOpenNotesDialog] = useState(false);
    const [CurrentStatus, setCurrentStatus] = useState('');
    const [currentPolicySubStatus, setCurrentPolicySubStatus] = useState<string>('');

    //  const [notes, setNotes] = useState<string>("");
    const handleDocumentReubmission = (custPolId: number) => {
        setcustPolId(String(custPolId));

        setDocResubmission(true);
    };

    const handleOpenNotesDialog = (status: string) => {
        setCurrentStatus(status)
        setOpenNotesDialog(true);
    };
    //const handleSubmitNotes = (data: { notes: string }) => {
    const handleSubmitNotes = (data: any) => {
        const { noteDetail } = data;
        console.log("Notes submitted: ", noteDetail); // Debugging log
        setOpenNotesDialog(false);
        setCurrentStatus('');

        updateStatus(data.status, selectedRows, noteDetail, data.policySubStatus, data.dateDeclarationFiled);
    };

    const handleCloseNotesDialog = () => {
        setCurrentStatus('')
        setOpenNotesDialog(false);
    };


    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        debouncedOnFilterChange(filterModel, FMTabs.CompletedFiling);
    }, []);


    const onRowsSelectionHandler = (ids: GridSelectionModel) => {
        debugger
        const selectedIDs = new Set(ids);
        const uniqueRowsIds = new Set();
        const selectedRowData = NewCompletedFilingsCL.getCompletedFilings_Respdata.filter((row) => {
            if (selectedIDs.has(row?.rowNum ?? "") && !uniqueRowsIds.has(row?.rowNum ?? "")) {
                uniqueRowsIds.add(row?.rowNum ?? "");
                return true;
            }
            return false;
        });

        if (ids[0] != null || undefined) {
            setselectedStatus(
                NewCompletedFilingsCL.getCompletedFilings_Respdata.find((x) => x?.rowNum === Number(ids[0]))?.status ?? ""
            );
        } else {
            setselectedStatus("");
        }
        if (ids[0] != null || undefined) {
            setselectedState(
                NewCompletedFilingsCL.getCompletedFilings_Respdata.find((x) => x?.rowNum === Number(ids[0]))?.homeState ?? ""
            );
        } else {
            setselectedState("");
        }

        setSelectedRows(selectedRowData);
    };

    const handleExport = (option: string) => {

        var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];

        getCustomerPolicyListbyTaxType("getCustomerPolicyListbyTaxType", customerPolicyIds, option);
    };
    const exportOptions = [
        'Short', 'Detailed'
    ]
    const exportOptionsAdmin = [
        'Short', 'Detailed', 'All'
    ]
    const SumitToSLA = () => {

        const MultiplePolicyIds: string =
            selectedRows?.map((x) => x.customerpolicyid).join(",") ?? "";

        if (MultiplePolicyIds === "") {
            alert("Please Select at least one Filing");
            return;
        }

        fetch(
            config.url.API_URL +
            `/SLIPSubmission/SubmitPolicyToState?custPolicyIdList=${MultiplePolicyIds}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {

                alert(responseJson.data);
                if (MultiplePolicyIds === "") {
                    alert("Please Select at least one Filing");
                    return;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleNextPreviousPage = (newPage: number) => {
        setCurrentPage(newPage);
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        // fetchPendingDataSorting('getFlagsPendingReadyReviewFilings',6, _pageSize, newPage,field, sort);
        fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, newPage, queryOptions)
    };

    const handlePageSize = (newPageSize: number) => {
        setPageSize(newPageSize);

        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        // fetchPendingDataSorting('getFlagsPendingReadyReviewFilings', 6, newPageSize,_currentPage, field, sort);
        fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.CompletedFiling, newPageSize, _currentPage, queryOptions)

    };

    const handleSendEmail = () => {
        if (!selectedRows || selectedRows.length === 0) {
            // If no rows are selected, show a message to the user
            alert("Please select row before sending an email");
            return;
        }

        const MultiplePolicyIds: string = selectedRows?.map(x => x.customerpolicyid).join(",") ?? ""
        fetch(config.url.API_URL + `/ProcessPolicyChecks/SendChecksEmailMultiple?customerPolicyIdList=${MultiplePolicyIds}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {

                    return response;
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                alert("Email sent");
            }).catch((error) => {

                console.log(error)

            });

    };

    const handleMultipleDownload = () => {
        var MultiplePolicyIds: string =
            selectedRows?.map((x) => x.policyId).join(",") ?? "";

        const PendingstateRows = selectedRows?.filter(row => row.status === "Pending state review" || row.status === "Ready to File");
        MultiplePolicyIds = PendingstateRows?.map(x => x.policyId).join(",") ?? "";

        // if (hasNonPendingStateReview) {
        Swal.fire({
            title: 'Confirmation!',
            text: 'You can download document only for "Pending state review" or "Ready to File" Filings. Proceed anyway?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (MultiplePolicyIds === "") {
                    alert("Please Select at least one Filing or policyid does not exist.");
                    return;
                }
                setloading(true);
                //call api to save data
                fetch(
                    config.url.API_URL +
                    `/PolicyFilings/getMultiplePolicyBatchSubmissionData?MultiplePolicyIds=${MultiplePolicyIds}`,
                    {
                        method: "POST",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        }),

                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            return response.json();

                        }
                        throw new Error("Something went wrong");
                    })
                    .then((res) => {
                        setloading(false);
                        if (res.resp === 1) {
                            alert(res.message);
                            setloading(false);
                            return;
                        }
                        console.log(res.data, "readyTofiledata");
                        if (res.data != "") {
                            window.open(res.data, "_blank");
                            fetchDataMain("getFlagsPendingReadyReviewFilings", 6, _pageSize, _currentPage);

                        } else {
                            alert("Something went wrong");
                        }

                    })
                    .catch((error) => {
                        setloading(false);
                        console.log(error);
                    });
            }
            else {
                setloading(false);
                return;
            }
        });


    };
    const handleEdit = (custPolId: number) => {
        fetch(
            config.url.API_URL +
            `/PolicyDashBoard/getCustomerPolicyDocuments?CustPolicyId=${custPolId}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response
                console.log(responseJson);
                setFormData(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setOpenError(false);
        setOpenPolicyDueDateNullDialog(false);
        seteditableSelectedRows(undefined);
    };

    const [selectedState, setselectedState] = useState("");

    const [selectedSLIPPartnerId, setSelectedSLIPPartnerId] = useState(0);
    const initialPartnerData = { partnersId: 0, customerName: "" };
    const [SLIPCustomer, setSLIPCustomer] = useState([initialPartnerData]);
    const fetchSLIPCustomer = () => {
        fetch(config.url.API_URL + "/Partners/getCASLAPartners", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response

                setSLIPCustomer(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handlehandleApprove = (custPolId: number) => {
        setloading(true);
        fetch(config.url.API_URL + `/PolicyDashBoard/Approve?custPolId=${custPolId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
                //fetch data
            }).then(() => {
                // fetchDataMain("getFlagsPendingReadyReviewFilings", 6, _pageSize, _currentPage);
                fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions)
                setloading(false);

            })
            .catch((error) => {
                console.log(error)
                setloading(false);
            });
    };

    useEffect(() => {
        fetchSLIPCustomer();
    }, []);

    const initialdata: NewCompletedFilingsModel = {
        customerpolicyid: 0,
        homeState: "",
        policyId: 0,
        dueDate: ""
    }

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
        setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,);
    },
        [],
    );
    const filterOperators = getGridStringOperators().filter(({ value }) => ['contains'].includes(value));
    const equalOperators: GridFilterOperator<any, string | number | null, any>[] =  getGridStringOperators().filter(({ value }) => ['equals'].includes(value));

    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({ row }) => <DetailPanelContent row={row} />, []);
    const getDetailPanelHeight = React.useCallback(() => 200, []);
    function DetailPanelContent({ row }: { row: NewCompletedFilingsModel }) {
        const [TaxNamAndData, setTaxNamAndData] = useState<NewCompletedFilingsModel[]>([initialdata]);
        const TaxNameAndDate = () => {

            setTaxNamAndData([])
            fetch(config.url.API_URL + `/PolicyDashBoard/getaxTypeandDateByCustpolicyid?customerpolicyId=${row.customerpolicyid}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {
                        setTaxNamAndData(responseJson.data)
                    }

                }).catch((error) => {
                    console.log(error)
                });
        }
        useEffect(() => {
            TaxNameAndDate();
        }, [row])
        return (

            <Stack direction="column" spacing={1} sx={{ height: 1 }} className="hide-border-12" >

                <DataGridPro
                    columns={[
                        {
                            field: 'taxType',
                            type: 'string',
                            headerName: 'Tax Type',
                            flex: 1,
                            headerAlign: "center",
                            hideSortIcons: true,
                            renderHeader: (params) => (
                                <div>
                                    <span className="table-card-title" >{params.colDef.headerName}</span>
                                    <RiExpandUpDownLine />
                                </div>
                            ),
                            align: "center",
                        },

                        {
                            field: 'taxAmt', hideSortIcons: true,
                            renderHeader: (params) => (
                                <div>
                                    <span className="table-card-title" >{params.colDef.headerName}</span>
                                    <RiExpandUpDownLine />
                                </div>
                            ), type: 'string', headerName: 'Tax Amount', flex: 0.5, align: 'center', headerAlign: 'center',
                            renderCell: (params) => {
                                const value = params.value || '0'; // Default to '0' if value is null or undefined
                                return (
                                    <Box display="flex" alignItems="left" justifyContent="left">

                                        {formatPrice(value)}

                                    </Box>
                                )
                            }

                        },

                    ]}
                    rows={TaxNamAndData}
                    getRowId={(r) => r?.rowNum ?? 0}
                    sx={{ flex: 1 }}
                    hideFooter
                />
            </Stack>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'dueDate', hideSortIcons: true,
            valueGetter: (params) =>
            (
                new Date(params.row.dueDate)
            ),
            renderCell: (params) =>
            (
                <div>
                    {(params.row.dueDate != null && params.row.dueDate != '') ? moment(params.row.dueDate).format("MM/DD/YYYY") : ""}
                </div>
            ),
            renderHeader: (params) => (
                <div className="d-flex align-items-center" >
                    <span className="table-card-title word-wrap-1 p-0 " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            type: 'string', headerName: 'Filing Due Date', flex: 0.5, align: 'center', headerAlign: 'center',
        },

        

        {
            field: 'agency', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Client', flex: 0.35, align: 'center', headerAlign: 'center' , filterOperators
        },
        {
            field: 'invoiceNumber', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Invoice Number', flex: 0.68, align: 'center', headerAlign: 'center' , filterOperators
        },

        {
            field: 'insuredName',
            type: 'string',
            headerName: 'Insured Name',
            flex: .6,
            headerAlign: "center",
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center", filterOperators
        },
        {
            field: 'policyNumber',
            type: 'string',
            headerName: 'Policy Number',
            flex: 0.65,
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: "center",
            align: "center",
            filterOperators,
        },

        {
            field: "homeState",
            type: "string",
            headerName: "Home State",
            flex: 0.55,
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center",
            headerAlign: "center",
            filterOperators,
        },

        {
            field: "transactionTypeName",
            type: "string",
            headerName: "Transaction Type",
            flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center",
            headerAlign: "center",
            filterOperators,
        },
        // {
        //     field: 'invoiceDate', hideSortIcons: true,
        //     renderHeader: (params) => (
        //         <div>
        //             <span className="table-card-title " >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), type: 'date', headerName: 'Invoice Date', flex: 0.6, align: 'center', headerAlign: 'center',
        //     valueGetter: (params) =>
        //     (
        //         new Date(params.row.invoiceDate)
        //     ),
        //     renderCell: (params) =>
        //     (
        //         <div>
        //             {(params.row.invoiceDate != null && params.row.invoiceDate != '') ? moment(params.row.invoiceDate).format("MM/DD/YYYY") : ""}
        //         </div>
        //     )
        // },
        // {
        //     field: 'effectiveDate', hideSortIcons: true,
        //     renderHeader: (params) => (
        //         <div className="d-flex align-items-center" >
        //             <span className="table-card-title word-wrap-1 p-0 " >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), type: 'date', headerName: 'Policy Effective Date', flex: 0.6, align: 'center', headerAlign: 'center',
        //     valueGetter: (params) =>
        //     (
        //         new Date(params.row.effectiveDate)
        //     ),
        //     renderCell: (params) =>
        //     (
        //         <div>
        //             {(params.row.effectiveDate != null && params.row.effectiveDate != '') ? moment(params.row.effectiveDate).format("MM/DD/YYYY") : ""}
        //         </div>
        //     )
        // },
        // {
        //     field: 'policyExpDate', hideSortIcons: true,
        //     renderHeader: (params) => (
        //         <div className="d-flex align-items-center" >
        //             <span className="table-card-title word-wrap-1 p-0 " >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), type: 'date', headerName: 'Policy Expiration Date', flex: 0.7, align: 'center', headerAlign: 'center',
        //     valueGetter: (params) =>
        //     (
        //         new Date(params.row.policyExpDate)
        //     ),
        //     renderCell: (params) =>
        //     (
        //         <div>
        //             {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
        //         </div>
        //     )
        // },

        {
            field: 'invoiceDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Invoice Date', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators: equalOperators, 
            renderCell: (params) =>
            (
                <div>
                    {(params.row.invoiceDate != null && params.row.invoiceDate != '') ? moment(params.row.invoiceDate).format("MM/DD/YYYY") : ""}
                </div>
            )
        },
        {
            field: 'effectiveDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Policy Effective Date', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators: equalOperators,  
            renderCell: (params) =>
            (
                <div>
                    {(params.row.effectiveDate != null && params.row.effectiveDate != '') ? moment(params.row.effectiveDate).format("MM/DD/YYYY") : ""}
                </div>
            )
        },

        {
            field: 'policyExpDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Policy Expiration Date', flex: 0.7, align: 'center',  headerAlign: 'center', filterOperators: equalOperators, 
             renderCell: (params) =>
            (
                <div>
                    {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
                </div>
            ),

        },

        {
            field: 'grossPremium',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            type: 'string',
            headerName: 'Gross Premium',
            flex: 0.5,
            align: 'left',
            headerAlign: 'center',
            filterOperators,
            renderCell: (params) => {
                const value = params.value || '0'; // Default to '0' if value is null or undefined
                return (
                    <Box display="flex" alignItems="left" justifyContent="center">
                        {formatPrice(value)}
                    </Box>
                )
            }

        },
        {
            field: 'status', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Status', flex: 0.4, align: 'center', headerAlign: 'center' , filterOperators,
        },
        {
            field: 'Action', flex: 0.6, headerName: "Action", filterable: false, headerAlign: 'center', align: 'center', renderCell: (params) =>
            (
                <div style={{ display: 'flex', justifyItems: 'center', alignItems: "center" }}>
                    {params.row?.policyId === null ?

                        <Link href={'/Admin/AddEditCustomerPolicyDetails/' + params.row.customerpolicyid} color="#1976D2">
                            <IconButton
                                title="Update Policy"
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </IconButton>
                        </Link> :
                        <Link href={'/Transaction/' + params.row?.policyId + '/' + params.row?.customerpolicyid} color="#1976D2">
                            {
                                (<IconButton
                                    title="Check Policy"
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </IconButton>)
                            }
                        </Link>
                    }
                    {/* {user?.userRoles.split(",").includes("Super Admin")
                        ? (
                            <IconButton
                                className="btn2 btn-primary  m-1 "
                                style={{ float: 'right', color: "white", margin: '41px' }}
                                onClick={() => { closePolicy(params.row.customerpolicyid, 'getFlagsPendingReadyReviewFilings', 6, 'You are going to close this policy!!', true) }}
                                title="Close policy"
                            >

                                <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 8L8 24M8 8L24 24" stroke="#162E3C" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </IconButton >
                        ) : ""} */}

                    {params.row?.status === "Pending state review" ?
                        <IconButton
                            title="Approve"
                            onClick={() => handlehandleApprove(params.row.customerpolicyid)}>
                            <VerifiedUserIcon />
                        </IconButton>
                        : ""}
                </div>
            )

        },
    ];
    function CustomToolbar() {
        return (
            <GridToolbarContainer  >
                <GridToolbarFilterButton className="me-3 sky-blue" />
                <GridToolbarColumnsButton className="me-3 sky-blue" />
                {/* <GridToolbarExport className="me-3 sky-blue" /> */}
            </GridToolbarContainer>

        );
    }
    function EditToolbar() {
        return (

            <div className=" p-2 d-flex justify-content-end datagrid-butons">
                <div className="ml-2"><CustomToolbar /></div>

                <div style={{ display: "flex" }}>

                    {(selectedStatus === "Pending state review" || selectedStatus === "Ready to File") ?

                        <button
                            className=" me-2 active-btn-blue "
                            onClick={handleMultipleDownload}
                            title="Download"
                        >
                            Download
                        </button>
                        : null}
                    {selectedStatus === "FileMule Flags" ?

                        <button
                            className="btn  btn-primary active-btn-blue "
                            style={{ color: "white", margin: '4px' }}
                            onClick={handleSendEmail}
                        >
                            Send Email
                        </button>
                        : null}
                    {selectedStatus === "Ready to File" ?
                        <button
                            className="  active-btn-blue "
                            onClick={SumitToSLA}
                            title="Submit to SLA"
                        >
                            Submit SLA
                        </button>
                        : null}
                    {selectedRows?.length !== 0 && selectedRows?.length !== undefined ?
                        <div className="dropdowm-class ms-2" style={{ zIndex: "9" }} >
                            <MenuDropdown
                                menuTitle={'Status'}
                                items={status}
                                // items={status}
                                onItemClick={handleStatus} />
                        </div>
                        : ''}
                    <div className="dropdowm-class ms-2" style={{ zIndex: "9" }} >
                        <MenuDropdown
                            menuTitle={'Export'}
                            items={user?.userRoles.split(",").includes("Super Admin") ? exportOptionsAdmin : exportOptions}
                            onItemClick={handleExport} />
                    </div>
                </div>
            </div>

        );
    }

    function checkSelectedRowsForNY(): boolean {
        if (selectedRows?.find(x => x.homeState === 'NY')) {
            if (selectedRows.find(x => x.homeState !== 'NY')) {
                Swal.fire({
                    title: 'Warning!',
                    text: "Please select filings either specifically for New York or those that exclude New York, as there is a special rule for changing the status in New York.",
                    iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    // showCancelButton: true,
                    confirmButtonColor: '#02A4E3',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Proceed',
                    customClass: {
                        confirmButton: 'btn-radius',
                        // cancelButton: 'btn-radius',
                        popup: 'card-radius'
                    }
                })
                    .then((result) => {

                    });
                return false;
            }


        }
        return true;
    }
    function handleStatus(status: string) {
        if (!checkSelectedRowsForNY()) {
            return;
        }

        setCurrentPolicySubStatus('');
        const isNewYorkFiling = selectedRows && selectedRows?.length === 1 && selectedRows[0].homeState === 'NY' && (selectedRows[0].transactionTypeName === 'New Business' || selectedRows[0].transactionTypeName === 'Renewal Business')


        if (status === 'Waiting on Client' || (status === 'Filed' && isNewYorkFiling)) {
            (async () => {
                var response = await getCustomerPolicyDetails(selectedRows !== null && selectedRows !== undefined ? selectedRows[0].customerpolicyid : 0)
                setCurrentPolicySubStatus(response);
                handleOpenNotesDialog(status);

            })();
            //handleOpenNotesDialog(status);
        }
        else if (selectedRows?.find(m => m.homeState === "NY") && selectedRows.length !== 1 && status === "Filed") {
            Swal.fire({
                title: "",
                text: "Please select one filing for New York state",
                icon: 'warning',
                confirmButtonText: "OK",
            });
            return;
        }
        else {
            Swal.fire({
                title: 'Confirmation!',
                text: "Do you want to update customer policy's status as " + status + "?",
                iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                showCancelButton: true,
                confirmButtonColor: '#02A4E3',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Proceed',
                customClass: {
                    confirmButton: 'btn-radius',
                    cancelButton: 'btn-radius',
                    popup: 'card-radius'
                }
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        setloading(true);
                        if (status !== undefined && status !== '' && status !== null) {
                            count = 0;
                            SetCustPolicyNullDueDateData(null);
                            updateStatus(status, selectedRows);
                        }
                    }
                });

            setloading(false);
        }
    }
    const parseDueDate = (dueDateStr: string): Date => {
        const currentDate = new Date();
        const [month, day] = dueDateStr.split('-').map(Number);
        const year = currentDate.getFullYear();
        return new Date(year, month - 1, day);
    };
    const isDueDateWithinRange = (dueDateStr: string): boolean => {
        const currentDate = new Date();
        const dueDate = parseDueDate(dueDateStr);
        const dateDifference = dueDate.getTime() - currentDate.getTime();
        const dayDifference = dateDifference / (1000 * 3600 * 24);
        return dayDifference >= 0 && dayDifference <= 9;
    };
    var count = 0;
    function updateStatus(status: string, selectedRow: any, notes: string = '', policySubStatus: string = '', dateDeclarationFiled: string = '') {

        if (status === "Filed") {
            var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
            if (count === 0) {
                (async () => {
                    try {
                        const response = await getTaxTypeData('getIsPolicyFilingAndReportByCustPolIds', customerPolicyIds);

                        if (response != null && response.length > 0) {
                            const dueDateNotValid: NewCompletedFilingsModel[] = []; // where due date is not prior - 10 days of due date
                            response.forEach((e: getPolicyFilingAndReport) => {
                                if (e.report == true) {
                                    var newData = selectedRows?.find(m => m.customerpolicyid == e.customerPolicyId);
                                    if (!isDueDateWithinRange(newData?.dueDate) && newData !== undefined) {
                                        dueDateNotValid.push(newData);
                                    }
                                }
                            });

                            const DueDateNotValid = new Set(dueDateNotValid.map(item => item.customerpolicyid));
                            const filteredSelectedIds = selectedRows?.filter(item => !DueDateNotValid.has(item.customerpolicyid!))
                            console.log("filteredSelectedIds :", filteredSelectedIds);
                            count++;
                            if (dueDateNotValid.length > 0) {
                                seteditableSelectedRows(filteredSelectedIds)
                                SetCustPolicyNullDueDateData(dueDateNotValid);
                                setOpenPolicyDueDateNullDialog(true);
                            }
                            else {
                                SetCustPolicyNullDueDateData(null);
                                setOpenPolicyDueDateNullDialog(false);
                                seteditableSelectedRows(undefined);
                                updateStatus("Filed", filteredSelectedIds, notes, policySubStatus, dateDeclarationFiled);
                            }
                        }
                        setloading(false);
                        return;
                    } catch (error) {
                        console.error("Error fetching tax type data:", error);
                        return;
                    }
                })();
                return;
            }
        }
        setOpenPolicyDueDateNullDialog(false);

        fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}&&notes=${notes}&&policySubStatus=${policySubStatus}&&dateDeclarationFiled=${dateDeclarationFiled}`, {


            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRow)
        })
            .then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((res) => {
                setloading(false);
                if (res !== null && res !== undefined) {

                    Swal.fire({
                        title: res.data,
                        text: res.message,
                        iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                        confirmButtonColor: '#02A4E3',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'btn-radius',
                            cancelButton: 'btn-radius',
                            popup: 'card-radius'
                        }
                    })
                        .then((result) => {
                            // window.location.reload();
                            setSelectedRows([]);
                            // fetchDataMain("getFlagsPendingReadyReviewFilings", 6, _pageSize, _currentPage);
                            fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions)
                        })
                }
            })
            .catch((error) => {
                console.log(error);
                setloading(false);
            });
    }
    interface newProp {
        handleClose: () => void;
        open: boolean;

    }
    const columnsForNullDueDate: GridColDef[] = [
        { field: 'customerpolicyid', headerName: 'Customer Policy Id', width: 150, align: "center", headerAlign: "center" },
        { field: 'policyNumber', headerName: 'Policy Number', width: 150, align: "center", headerAlign: "center" },
        { field: 'insuredName', headerName: 'Insured Name', width: 150, align: "center", headerAlign: "center" },
        { field: 'dueDate', headerName: 'Due Date', width: 150, align: "center", headerAlign: "center" }
    ];
    const [openPolicyDueDateNullDialog, setOpenPolicyDueDateNullDialog] = useState(false);
    const [editableSelectedRows, seteditableSelectedRows] = useState<NewCompletedFilingsModel[]>();
    const [CustPolicyNullDueDateData, SetCustPolicyNullDueDateData] = React.useState<any>();
    function submit() {
        count++;
        updateStatus("Filed", editableSelectedRows);
    }
    const ShowNullFilings = ({ open, handleClose }: newProp) => {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <DialogTitle className='card-title'>Customer Policy </DialogTitle>
                <DialogContent>
                    <span className="text-danger">Below filings cannot be updated to status "Filed" until 10 days prior to the report due date!</span>
                </DialogContent>
                <DialogContent>
                    <div style={{ height: '50vh', width: '100%' }} >
                        <DataGrid
                            rows={CustPolicyNullDueDateData ?? null}
                            columns={columnsForNullDueDate}
                            pageSize={50}
                            getRowId={(row) => row.customerpolicyid}
                            sx={{ border: "none" }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-2 btn d-flex btn-primary search-btn' type='button' onClick={handleClose}>Cancel</button>
                    {editableSelectedRows !== undefined && editableSelectedRows !== null && editableSelectedRows?.length > 0 ?
                        <button className='btn btn-primary active-btn-blue ' type='button' onClick={submit}>Update Rest</button>
                        : ""
                    }

                </DialogActions>
            </Dialog>
        );
    };

    const getTaxTypeData = async (action: string, customerPolicyIds: number[]) => {
        try {
            //  setloading(true);

            const initValueExport = {
                customerPolicyIds: customerPolicyIds,
                option: ''
            };

            const response = await fetch(
                config.url.API_URL + `/PolicyDashBoard/${action}`,
                {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }),
                    body: JSON.stringify(initValueExport)
                }
            );
            if (!response.ok) {
                throw new Error("Something went wrong");
            }
            const res = await response.json();
            if (res.data !== null) {
                return res.data;
            } else {
                throw new Error("Response data is null");
            }
        } catch (error) {
            throw error;
        }
    };
    const status = [
        'Not Started', 'Waiting on Client', 'Ready to File', 'Filed', 'Pending Autofile'
    ]
    if (user?.userRoles.split(",").includes("Super Admin")) {
        status.push('Closed');
    }
    const handleCompletedSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        sortModelRef.current = sortModel;
        //fetchPendingDataSorting("getFlagsPendingReadyReviewFilings", 6, _pageSize, _currentPage, sortModel[0].field, sortModel[0].sort == undefined || sortModel[0].sort == null ? '' : sortModel[0].sort);
        fetchDataForFilter("getCompletedFilingsFilterData", FMTabs.CompletedFiling, _pageSize, _currentPage, queryOptions)
    }, []);


    function CustomColumnMenu(props: GridColumnMenuProps) {
        const { hideMenu, currentColumn, color, ...other } = props;

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                {...other}
            >
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
                <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
            </GridColumnMenuContainer>
        );
    }

    return (
        <div style={{ height: "53vh" }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGridPro
                rowHeight={60}
                getRowId={(r) => r.rowNum}
                rows={NewCompletedFilingsCL?.getCompletedFilings_Respdata}
                columns={columns}
                pageSize={_pageSize}
                pagination
                rowCount={NewCompletedFilingsCL?.totalCount}
                paginationMode="server"
                onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
                onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                rowsPerPageOptions={getRowsPerPageOptions(NewCompletedFilingsCL?.totalCount, _pageSize)}
                sortingMode="server"
                onSortModelChange={handleCompletedSortModelChange}
                sortModel={sortModelRef.current}
                sortingOrder={['desc', 'asc']}
                checkboxSelection={
                    user?.userRoles.split(",").includes("Super Admin") ||
                        user?.userRoles.split(",").includes("MISC Filer") ? true : false
                }
                onSelectionModelChange={user?.userRoles.split(",").includes("Super Admin") ||
                    user?.userRoles.split(",").includes("MISC Filer")
                    ? (newRowSelectionModel) => {
                        onRowsSelectionHandler(newRowSelectionModel);

                    } : undefined}
                disableSelectionOnClick
                components={{
                    Toolbar: EditToolbar,
                    ColumnMenu: CustomColumnMenu
                }}

                sx={{
                    border: "0px",
                    fontSize: "16px",
                    '& .MuiDataGrid-columnHeaderTitle': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        wordWrap: 'break-word',
                        textOverflow: 'clip',
                    },
                    '& .MuiDataGrid-cell': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        wordWrap: 'break-word',
                        textOverflow: 'clip',
                    },
                }}
            />
            <PolicyDocumentsPopup
                handleClose={handleClose}
                Open={Open}
                documentList={Formdata}
            />
            <PolicyErrorPopup
                handleClose={handleClose}
                Open={OpenError}
                documentList={ErrorFormdata}
            />
            <FilerNotes
                open={openNotesDialog}
                handleClose={handleCloseNotesDialog}
                handleSubmit={handleSubmitNotes}
                selectedRows={selectedRows}
                currentStatus={CurrentStatus}
                currentPolicySubStatus={currentPolicySubStatus}

            />
            <ShowNullFilings handleClose={handleClose} open={openPolicyDueDateNullDialog} />
        </div>
    );
};
