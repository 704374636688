import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { RightNav } from "../Nav/RightNav";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import useAuth from "../../context/AuthProvider";
import Swal from "sweetalert2";

export default function Layout() {
  const [showRightNav, setShowRightNav] = useState(true);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const [loading, setloading] = useState(false);
  const toggleRightNav = () => {
    setShowRightNav(!showRightNav);
  };
  const handleLogout = () => {
    console.log({user})
    Swal.fire({
        title: 'Log Out',
        text: "Are you sure you want to log out?",
        showCancelButton: true,
        confirmButtonColor: '#02A4E3',
        cancelButtonColor: '#d33',
        iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    }).then((result) => {
      if (result.isConfirmed) {
        logOut();
        navigate('/login'); 
        return;
      }
    })
  };
  const { user, logout } = useAuth();
  const logOut = () => {
    logout();
  };
 
  return (
    <main>
      <section className="policy-dashboard-section">
        <div className="banner-section"  >
          <div className="img-bg-sec">


            <div className="right-nav-popup">
              <div className="nav-section">


                <nav className="navbar navbar-expand-lg navbar-light ">
                  <div className="container-fluid">

                    <a className="navbar-brand main-img nav-left" href="/">
                      <img src=" /assets/brand/MISC_Full_Type_Lockup_Primary.png" alt="" />
                    </a>


                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon "></span>
                    </button>
                    <div className="collapse navbar-collapse container-fluid" id="navbarTogglerDemo01">
                      <ul className="navbar-nav  align-items-center  nav-icons-ml ">
                        <li className="nav-item ">
                          <a className="nav-link home-tag" href="/ ">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 22V12H15V22M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                                stroke="white"
                                strokeWidth="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </li>

                        <li className="nav-item tag">
                          <IconButton className="nav-link arrow-back tag" onClick={goBack}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 12H5M5 12L12 19M5 12L12 5"
                                stroke="white"
                                strokeWidth="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </IconButton>
                        </li>
                        <li className="nav-item add tag">

                          <a href="/NewSubmission/">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="white"
                                strokeWidth="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </li>
                        <li className="nav-item menu tag">
                          <div onClick={toggleRightNav}>

                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 12H21M3 6H21M3 18H21"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </li>
                        <li className="nav-item white line">

                          <svg
                            width="1"
                            height="31"
                            viewBox="0 0 1 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              x1="0.5"
                              y1="2.18557e-08"
                              x2="0.499999"
                              y2="31"
                              stroke="white"
                            />
                          </svg>

                        </li>
                        <li className="nav-item bell tag">
                          <a href="#"><svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                              stroke="white"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg></a>
                        </li>
                        <li className="nav-item user tag" onClick={handleLogout} title={user?.userFirstName ?? ''}>
                          <a >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                                stroke="white"
                                strokeWidth="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>

              </div>

            </div>
          </div>
          <RightNav Open={showRightNav} handleClose={toggleRightNav} />
        </div>

        <Box
          className=" main shadow rounded"
          sx={{
            backgroundColor: "#ffffff",
            height: "100%",
          }}
        >
          <Box
            className="layout-padding"
            sx={{
              height: "100%"
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </section>
    </main >
  );
}
