import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { InsuredList } from "../components/Lists/InsuredList";
import { PartnersList } from "../components/Lists/PartnersList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";

export const Insureds = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [Insured, setInsured] = useState([]);

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/Insured/getAllInsured',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
                setInsured(responseJson.data)
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (

        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText={''} Link={''}/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">



                                            <div className="table-responsive">
                                                <div style={{marginBottom:"10px"}}>
                                                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                </div>
                                                
                                                <InsuredList InsuredList={Insured} fetchData={fetchData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>


    )

}