import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";

import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { TaxScheduleByJurisdictionList } from "../components/Lists/TaxScheduleByJurisdictionList";



export const TaxScheduleByJurisdiction = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();

    const [TaxesAndFee, setBatches] = useState([])
    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/TaxScheduleByJurisdiction/getAll`,
            {

                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false);
                console.log("taxandfess", res.data)
                // Do something with the response
                if (res.data) {
                    // res.data.effectiveDate = res.data.effectiveDate.split("T")[0];
                    // res.data.effectiveDate = res.data?.effectiveDate ? moment(res.data?.effectiveDate).format("yyyy-mm-dd") : ""
                    setBatches(res.data)
                }

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });


    }
    useEffect(() => {
        fetchData();
        console.log(fetchData);
    }, [])

    return (

        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

        //                         <LoggedInUserInfo Link="" ButtonText="" />


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="card dashboard-table">



        //                                     <div className="table-responsive">
        //                                         {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                <TaxScheduleByJurisdictionList taxesandfessCL={TaxesAndFee} fetchData={fetchData} />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>


    )

}