import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { FormControl, Grid, LinearProgress, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
import { RightNav } from '../Nav/RightNav';
import { LoggedInUserInfo } from '../Elements/loggedInUserInfo';
import { CstomerInvoiceModel, CstomerInvoiceModel_VS } from '../../models/CustomerInvoiceModel';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const AddCustomerInvoice = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState<boolean>(false);
    const navigate1 = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const params = useParams();
    const initialValues: CstomerInvoiceModel = {
        userId: 0,
        customerInvoiceId: 0,
        description: '',
        invoiceId: 0,
        amount: 0,
        isPaid: false,
        dueDate: new Date,
        zipCode: 0,
        naic: '',
        taxId: '',
        memo: '',
        accountingContactName: '',
        accountingContactEmail: '',
        accountingContactPhone: '',
        invoiceType: '',
        customerName: ''
    }
    const [FeeArray, setFeeArray] = useState([{ Amount: "", Memo: "" }]);

    const addFormFields = () => {
        setFeeArray([...FeeArray, { Amount: "", Memo: "" }])
    }

    let removeFormFields = (i: number) => {
        let newFormValues = [...FeeArray];
        newFormValues.splice(i, 1);
        setFeeArray(newFormValues);
    }

    const handleChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {

        let newFormValues = [...FeeArray];
        if (e.target.name === "amount") {
            console.log(newFormValues[i]);
            newFormValues[i].Amount = e.target.value;
            setFeeArray(newFormValues);

        }
        if (e.target.name === "memo") {
            newFormValues[i].Memo = e.target.value;
            setFeeArray(newFormValues);

        }

    }



    return (

        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                            <LoggedInUserInfo ButtonText="" Link=""/>
                                <div className="card-body">

                                    <div className="card dashboard-table">
                                        <div className="table-responsive">
                                            
                                            <div className="row col-lg-12">
                                                <div className="col-lg-4 d-flex">
                                                    <div style={{marginRight:"2px"}}>
                                                    <Link href="/">
                                                        <Button type="button" title="Home"
                                                            className="btn action-btn" ><HomeIcon />
                                                        </Button>
                                                    </Link>
                                                    </div>
                                                    <div >
                                                        <Button

                                                            className=" btn action-btn"
                                                            onClick={goBack}
                                                            title="Back"
                                                        >
                                                            <ArrowBackIosIcon />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4" style={{textAlign:"center"}}>
                                                    <span className="card-title">Create Invoice</span>
                                                </div>

                                            </div>

                                            <div className="card-body-overflow">
                                                <React.Fragment>
                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                    <Formik initialValues={initialValues}
                                                        enableReinitialize={true}
                                                        validateOnChange={true}
                                                        validationSchema={CstomerInvoiceModel_VS}
                                                        onSubmit={(data, { setSubmitting }) => {
                                                            let IsEmptyFee = false;

                                                            FeeArray.map((index, i) =>
                                                            (
                                                                FeeArray[i].Amount = ((document.getElementsByName([i] + "Amount").item(0)) as HTMLInputElement).value,
                                                                FeeArray[i].Amount == '' ? IsEmptyFee = true : '',
                                                                FeeArray[i].Memo = ((document.getElementsByName([i] + "Memo").item(0)) as HTMLInputElement).value,
                                                                FeeArray[i].Memo == '' ? IsEmptyFee = true : ''

                                                            ));

                                                            data.FeeList = FeeArray;
                                                            // let test = (document.getElementsByName("1Memo").item(0)) as HTMLInputElement;
                                                            console.log("Final Values", data);
                                                            if (!IsEmptyFee) {
                                                                fetch(
                                                                    config.url.API_URL + '/CustomerInvoice/AddEdit',
                                                                    {
                                                                        method: 'POST',

                                                                        headers: new Headers({
                                                                            'Content-Type': 'application/json',
                                                                            'Authorization': `Bearer ${token}`,
                                                                        }),
                                                                        body: JSON.stringify(data)  // <-- Post parameters

                                                                    })
                                                                    .then((response) => {
                                                                        setloading(false);
                                                                        setSubmitting(false);
                                                                        if (response.ok) {

                                                                            return response.json();

                                                                        }
                                                                        throw new Error('Something went wrong');
                                                                    })
                                                                    .then((res) => {
                                                                        // Do something with the response
                                                                        alert(res.message);

                                                                        setloading(false);

                                                                    }).catch((error) => {
                                                                        console.log(error)
                                                                    });
                                                            } else {
                                                                alert("Fee or Memo is Empty");
                                                                setSubmitting(false);
                                                            }




                                                        }} >
                                                            
                                                        {({ values, isSubmitting, setFieldValue }) => (
                                                            
                                                            <Form>
                                                                
                                                                <Grid container spacing={2} justifyContent="center" marginBottom="30px" >
                                                                    <Grid container spacing={2} item xs={7} >

                                                                        <Grid item xs={12} display='flex' flexDirection='row'>
                                                                            <h5 className="text-center text-secondary">General Information</h5>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                <FMTextField name="customerName" autoFocus margin="dense" label="Customer Name" placeholder="Customer Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                            </FormControl>
                                                                        </Grid>

                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                <FMTextField name="description" rows={3} label="Invoice Description" multiline type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Button variant="outlined" type="button" onClick={() => addFormFields()}>Add Fee</Button>

                                                                        </Grid>
                                                                        {FeeArray.map((element, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <Grid key={index + 1} item xs={5}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <FMTextField name={index.toString() + "Memo"} rows={3} label="Memo" InputLabelProps={{ shrink: true }} value={element.Memo || ""} multiline type="text" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(index, e)} fullWidth variant="outlined" sx={{ m: 2 }} />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                <Grid item xs={5}>
                                                                                    <FormControl sx={{ width: '100%' }} >
                                                                                        <FMTextField name={index.toString() + "Amount"} label="Amount" InputLabelProps={{ shrink: true }} type="number" value={element.Amount || 0} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(index, e)} fullWidth variant="outlined" sx={{ m: 2 }} />

                                                                                        {/* <FMTextField name={index.toString()+ "amount"} label="Amount" key={index} InputLabelProps={{ shrink: true }} type="text" value={element.Amount || ""}   fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                                                    </FormControl>
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    {
                                                                                        index ?
                                                                                            <button type="button" className="btn btn-danger" onClick={() => removeFormFields(index)}>Remove</button>
                                                                                            : ""
                                                                                    }
                                                                                </Grid>

                                                                            </React.Fragment>

                                                                        ))}

                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                <FMTextField name="dueDate" label="Due Date" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} />
                                                                            </FormControl>
                                                                        </Grid>

                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }}>
                                                                                <FMTextField name="zipCode" label="Zip Code" autoFocus margin="dense" type="number" variant="outlined" sx={{ m: 2 }} />
                                                                            </FormControl >
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }}>
                                                                                <FMTextField name="naic" label="Naic#" autoFocus margin="dense" type="text" variant="outlined" sx={{ m: 2 }} />
                                                                            </FormControl >
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }}>
                                                                                <FMTextField name="taxId" label="Tax Id" autoFocus margin="dense" type="text" variant="outlined" sx={{ m: 2 }} />
                                                                            </FormControl >
                                                                        </Grid>

                                                                    </Grid>
                                                                    <Grid container spacing={2} xs={7} item marginBottom="30px">
                                                                        <Grid item xs={12} display='flex' flexDirection='row'>
                                                                            <h5 className="text-center text-secondary">Accounting Contact Information</h5>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                <FMTextField name="accountingContactName" autoFocus margin="dense" label="Accounting Contact Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                <FMTextField name="accountingContactEmail" autoFocus margin="dense" label="Accounting Contact Email" type="email" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormControl sx={{ width: '100%' }} >
                                                                                <FMTextField name="accountingContactPhone" autoFocus margin="dense" InputLabelProps={{ shrink: true }} label="Accounting Contact Phone #" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container justifyContent="center" spacing={2} item xs={12} >

                                                                        <Button variant="outlined" type='submit' disabled={isSubmitting} >Create Invoice</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Form>

                                                        )}


                                                    </Formik>
                                                </React.Fragment>
                                            </div>

                                        </div >
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>
    )
}
