import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectProps, Stack } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import { FieldAttributes, Form, Formik, useField } from 'formik';
import { config } from '../../Constants';
import { FMSelectLOBField, FMSelectRateTypeField, FMSelectStateField, FMSelectTaxAndFees, FMSelectTaxTypeField } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from 'react-router-dom';
import { error } from 'console';
import Swal from 'sweetalert2';
import CancelIcon from "@mui/icons-material/Cancel";

interface Iprops {
  taxesandfessCL: taxesandfeesModel,
  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void
  initValues: taxesandfeesModel
  fetchLOBData: (stateId: string) => void
  LineOfBusiness: { id: number; businessCode: string; }[]
  setSelectedMultiLOB: React.Dispatch<React.SetStateAction<string | string[]>>
  SelectedMultiLOB: string[] | string
}

export const AddEditTaxesAndFees: React.FC<Iprops> = ({ handleClose, Open, taxesandfessCL, handleSubmit, initValues, LineOfBusiness, fetchLOBData, setSelectedMultiLOB, SelectedMultiLOB }) => {
  const initialValues: taxesandfeesModel = initValues ? taxesandfessCL : initValues;
  const { user } = useAuth();
  const token = user?.token;

  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);
  function fetchStateData() {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }

        console.log("statesDAta", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const [TaxandFees, setTaxandFees] = useState([""]);
  const params = useParams();

  const fetchData = () => {
    // fetch(config.url.API_URL + `/TaxesAndFees/GetTaxName?type=${params.type}`, {
    fetch(config.url.API_URL + `/PolicyFilingType/GetAllTaxandFeeName`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        if (responseJson.data != null) {
          setTaxandFees(responseJson.data);
        }

        console.log("TaxandFees", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
    fetchStateData();
  }, []);


  const FMSelectTaxAndFeesustom: React.FC<FieldAttributes<SelectProps>> = ({
    variant,
    ...props
  }) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    const { user } = useAuth();
    const token = user?.token;


    return (
      <FormControl error={!!errorText} fullWidth>
        <InputLabel id="State-Select-Label">TaxandFees</InputLabel>
        <Select {...field} labelId="State-Select-Label" label="TaxandFees"
          className="border-round main-form-2"
          IconComponent={ExpandMoreIcon}
        >

          {TaxandFees.map((taxandfees, key) => (
            <MenuItem key={key} value={taxandfees}>
              {" "}
              {taxandfees}{" "}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    );
  };

  const FMSelectLineOfBusinessCustom: React.FC<FieldAttributes<SelectProps>> = ({
    variant,
    ...props
  }) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    const { user } = useAuth();

    useEffect(() => {

    }, []);

    return (

      <React.Fragment>

        <FormControl error={!!errorText} fullWidth  >
          <InputLabel id="State-Select-Label">Select Line Of Business</InputLabel>
          <Select {...field}
            labelId="State-Select-Label"
            label="Select Line Of Business"
            className="border-round main-form-2"
            IconComponent={ExpandMoreIcon}

            value={field.value || "" || 0}
          >
            <MenuItem value={"ALL"}>ALL</MenuItem>
            {LineOfBusiness.map((LineOfBusiness, key) => (

              <MenuItem key={key} value={LineOfBusiness.id}>
                {LineOfBusiness.businessCode}{" "}
              </MenuItem>
            ))}


          </Select>

          <FormHelperText>{errorText}</FormHelperText>
        </FormControl>

      </React.Fragment>






    );
  };

  const [loading, setloading] = useState(false);

  return (


    <Dialog open={Open} onClose={handleClose}>
      <DialogTitle className='card-title'   >Taxes and Fees</DialogTitle>
      {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
      <Formik initialValues={initialValues}
        validateOnChange={true}
        validationSchema={UsersModel_VS}
        onSubmit={(data, { setSubmitting, setErrors }) => {
          setloading(true);
          if (data.lobId === 0) {
            data.lob = 'ALL';
          }

          // const lobIds: number[] = Array.isArray(SelectedMultiLOB)
          // ? SelectedMultiLOB.map(id => Number(id))
          // : [Number(SelectedMultiLOB)];
          //data.lobIds = lobIds;
          // if (!data.lobIds || data.lobIds.length < 1) {
          //     setErrors({ lobIds: 'Please select transaction' });
          //     setSubmitting(false);
          //     return;
          // }

          // if (data.lobId < 1) {
          //   setErrors({ lobId: 'select lob' })
          //   setSubmitting(false);
          //   return;
          // }
          console.log("FinaData", data);
          data.commaSeparatedLOBIds = SelectedMultiLOB.toString();

          fetch(
            config.url.API_URL + '/TaxesAndFees/UpdateCopy',
            {
              method: 'POST',

              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }),
              body: JSON.stringify(data)  // <-- Post parameters

            })
            .then((response) => {
              setloading(false);
              if (response.ok) {
                return response.json();
              }
              throw new Error('Something went wrong');
            })
            .then((res) => {
              setloading(false);
              if (res.resp === 0) {
                Swal.fire({
                  title: res.message,
                  text: "",
                  icon: "success",
                  confirmButtonText: "OK",
                });
              }
              handleSubmit()
              setSubmitting(true)
            }).catch((error) => {
              setloading(false);
              console.log(error)
            });
        }} >
        {({ values, isSubmitting, errors, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl error={!!errors.stateId} sx={{ width: '100%' }}>
                    <Select
                      value={initialValues.stateId}
                      id="test-select-label"
                      label={null}
                      onChange={(e) => {
                        
                        setFieldValue("stateId", e.target.value);
                        initialValues.stateId = Number(e.target.value);
                        initialValues.lobId = 0;
                       // setSelectedMultiLOB(["ALL"]);
                        fetchLOBData(String(e.target.value));
                      }}
                      name="stateId"
                      className="border-round custom-menu-item"
                      IconComponent={ExpandMoreIcon}
                      displayEmpty
                    >
                      <MenuItem key={0} value={0}>
                        Select State{" "}
                      </MenuItem>
                      {/* <MenuItem value={"0"}>State</MenuItem> */}
                      {States.map((state, key) => (
                        <MenuItem key={key} value={state.stateId}>

                          {state.stateName}
                        </MenuItem>
                      ))}

                    </Select>
                    <FormHelperText>{errors.stateId}</FormHelperText>
                  </FormControl>


                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} >

                    {/* <FMSelectTaxAndFees autoWidth={true} name="taxName"></FMSelectTaxAndFees> */}
                    {/* <FMSelectTaxTypeField autoWidth={true} name="taxName"></FMSelectTaxTypeField> */}
                    <FMSelectTaxAndFeesustom autoWidth={true} name="taxName"></FMSelectTaxAndFeesustom>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} >
                    {/* <FormHelperText></FormHelperText> */}
                    <FMSelectRateTypeField autoWidth={true} name="ratetype"  ></FMSelectRateTypeField>

                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} className='main-form' >

                    {/* <FormHelperText></FormHelperText> */}
                    <FMTextField name="rate" autoFocus margin="dense" label="Rate" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />

                  </FormControl>
                </Grid>

                {/* <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} >

                    <FMSelectLineOfBusinessCustom autoWidth={true} name="lobId" ></FMSelectLineOfBusinessCustom>

                  </FormControl>
                </Grid> */}

               

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} className='main-form' >
                    <FMTextField name="payableto" autoFocus margin="dense" label="Payable To" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                  </FormControl>
                </Grid>



                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} className='main-form' >

                   
                    <FMTextField name="effectiveDate"  autoFocus margin="dense" label="Effective Date" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} />

                    {/* <TextField  name="effectiveDate" autoFocus  onChange={onChange}
                           value = {Moment(taxesandfessCL.effectiveDate).format("YYYY-MM-DD")}
                            defaultValue={Moment(taxesandfessCL.effectiveDate).format("YYYY-MM-DD")} type="date" fullWidth variant="outlined" /> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl error={false} fullWidth       >
                    <InputLabel id="lob_type">LOB</InputLabel>
                    <Select 
                      className=" main-form-2"
                      disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                      label="LOB"
                      name="Lob"
                      multiple
                      IconComponent={ExpandMoreIcon}
                      labelId="lob_type"
                      value={SelectedMultiLOB}
                      onChange={(e) => {
                        setSelectedMultiLOB(e.target.value);
                        // setFieldValue("LobIds", e.target.value);
                      }}
                      renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected.toString().split(',').map((value) => (
                            <Chip
                              key={value}
                              label={LineOfBusiness.find(x => x.id.toString() === value)?.businessCode == undefined ?  'ALL' : LineOfBusiness.find(x => x.id.toString() === value)?.businessCode  }
                              onDelete={() => setSelectedMultiLOB(SelectedMultiLOB?.toString().split(',').filter((item) => item !== value))}
                              disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                              deleteIcon={<CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />}
                            />
                          ))}
                        </Stack>
                      )}
                      // input={<OutlinedInput label="Multiple Select" />}
                      >
                       <MenuItem value={"ALL"}>ALL</MenuItem>
                      {LineOfBusiness.map((Role, key) =>
                        <MenuItem key={key} value={Role.id.toString()}> {Role.businessCode} </MenuItem>
                      )}

                    </Select>
                    <FormHelperText>{false}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} className='main-form' >

                    <FormHelperText></FormHelperText>
                    <FMTextField name="taxPayee" autoFocus margin="dense" label="Tax Payee" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />

                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ width: '100%' }} >

                    <FormHelperText></FormHelperText>
                    <FMTextField name="notes" autoFocus margin="dense" multiline rows={3} label="Notes" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />

                  </FormControl>
                </Grid>
              </Grid>


            </DialogContent>

            <DialogActions>
              <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
              <button className=" btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
            </DialogActions>
          </Form>

        )}


      </Formik>
    </Dialog>
  )
}
