import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { PartnerAgencyDetailsModel, PartnerAgencyDetailsModelVS } from "../../models/PartnerAgencyDetails";
import { Formik, Form, Field } from "formik";
import { LoggedInUserInfo } from "../Elements/loggedInUserInfo";
import { FMTextField } from "../Elements/input";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LinearProgress, FormHelperText, Checkbox, FormControl, Button, Link, Grid, DialogContent, Dialog, DialogTitle, Divider } from "@mui/material";
import { RightNav } from "../Nav/RightNav";
import { Tune } from "@mui/icons-material";
import { margin } from "@mui/system";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';

interface LicenseesContactInformation {
    PartnerLincenseeInformationId: number;
    isAutoNotification?: boolean
    contactName: string
    contactEmail: string
    contactPhone: string
}
interface Iprops {
    partnerAgencyDetail: PartnerAgencyDetailsModel
    setPartnerAgencyDetailList: React.Dispatch<SetStateAction<PartnerAgencyDetailsModel[]>>
    Open: boolean,
    setopen: React.Dispatch<React.SetStateAction<boolean>>
    partnerAgencyDetailList: PartnerAgencyDetailsModel[]
    addselectedStates: (stateId: string, agencyLicense?: string) => void
    removeSelectedStates: (stateId: string) => void
    stateName: string
    
}


export const AddEditPartnerAgencyDetails: React.FC<Iprops> = ({ partnerAgencyDetail, setPartnerAgencyDetailList, Open, setopen, addselectedStates, removeSelectedStates, stateName, partnerAgencyDetailList }) => {

    const initialValues: PartnerAgencyDetailsModel = {
        partnerAgencyDetailsId: 0,
        firstName: "",
        lastName: "",
        slBrokerNumber: "",
        licenseNumber: "",
        agencyLicenseNumber: "",
        latestTransactionNumber: "",
        nationalProducerNumber: "",
        previousCredit: 0,
        originalAmount: 0,
        carryForward: 0,
        individualNPN: ""
    }
    

    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();

    const [licenseesContactArry, setLicenseesContactArry] = useState<LicenseesContactInformation[]>([]);
    const [isLicenseesInfoLoading, setisLicenseesInfoLoading] = useState(true);
    const [contactName, setContactName] = useState<string>('');

    useEffect(() => {
        const fetchLicensessData = () => {
          if (params.partnersId !== "0") {
            fetch(`${config.url.API_URL}/Partners/getLicenseesContactInformationById?partnerId=${params.partnersId}`, {
              method: "GET",
              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }),
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                throw new Error('Something went wrong');
              })
              .then((responseJson) => {
                if (responseJson.data != null) {
                  // Define types for a and b
                  const sortedData = responseJson.data.sort((a: LicenseesContactInformation, b: LicenseesContactInformation) => 
                    a.PartnerLincenseeInformationId - b.PartnerLincenseeInformationId
                  );
                  const lastContact = sortedData[sortedData.length - 1];
                  const contactName = lastContact?.contactName ?? '';
                  setContactName(contactName);
                  setLicenseesContactArry(sortedData);
                }
                setisLicenseesInfoLoading(false);
              })
              .catch((error) => {
                console.log(error);
                setisLicenseesInfoLoading(false);
              });
          } else {
            setisLicenseesInfoLoading(false);
          }
        };
    
        fetchLicensessData();
      }, [params.partnersId, token]);
    

    const stateIdNumber = params.StateId; // Convert to number if StateId is a string

    return (


        <React.Fragment>
            <Dialog open={Open} maxWidth={'lg'} >
                <DialogTitle className="card-title text-center" > {stateName} Details</DialogTitle>
                <Formik
                    enableReinitialize={true}
                    initialValues={partnerAgencyDetailList.find(e => e.stateID === partnerAgencyDetail.stateID) ? (partnerAgencyDetailList.find(e => e.stateID === partnerAgencyDetail.stateID) ?? partnerAgencyDetail) : partnerAgencyDetail}
                    //initialValues={initialValues}
                    validateOnChange={true}
                    validationSchema={PartnerAgencyDetailsModelVS}

                    onSubmit={(data) => {
                        data.isDeleted = false;

                        setloading(true);

                        setPartnerAgencyDetailList(current => [...current.filter(e => e.stateID !== data.stateID), data]);
                        console.log("arrraaayydatttaa", data);
                        addselectedStates(String(data.stateID), data.licenseNumber);



                        // partnerAgencyDetailList === null || partnerAgencyDetailList === undefined || partnerAgencyDetailList.length === 0 ?
                        //     setpartnerAgencyDetail([data])
                        //     :
                        //     setpartnerAgencyDetail(current => [...current, data]);
                        setopen(false);

                    }} >
                    {({ submitForm, values, setFieldValue, isSubmitting }) =>
                    (
                        <Form>
                            <DialogContent>
                               
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label className="m-2" style={{ fontWeight: "bold" }}>Principal's First Name</label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="partnerAgencyDetailsId" InputLabelProps={{ shrink: true }} autoFocus margin="dense" label="Agency First Name" type="text" hidden fullWidth variant="outlined" sx={{}} />
                                            <FMTextField name="stateID" InputLabelProps={{ shrink: true }} autoFocus margin="dense" label="" type="text" hidden fullWidth variant="outlined" sx={{}} />
                                            <FMTextField name="firstName" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label style={{ fontWeight: "bold" }} className="m-2">Principal's Last Name</label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="lastName" InputLabelProps={{ shrink: true }} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label className="m-2" style={{ fontWeight: "bold" }}>{stateName} SL Broker Number <br /> <span className="m-3"> (if applicable)</span></label>

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="slBrokerNumber" InputLabelProps={{ shrink: true }}  type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label className="m-2" style={{ fontWeight: "bold" }}> {stateName} License Number</label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="licenseNumber" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label className="m-2" style={{ fontWeight: "bold" }}>Agency {stateName} License Number</label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="agencyLicenseNumber" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label className="m-2" style={{ fontWeight: "bold" }}>{stateName} Tax ID</label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FMTextField name="taxId"  InputLabelProps={{ shrink: true }} autoFocus margin="dense" type="text" variant="outlined" />
                                        </FormControl >
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <label className="m-2" style={{ fontWeight: "bold" }}>{contactName} {stateName} License Number</label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FMTextField name="licenseeContactName" InputLabelProps={{ shrink: true }} autoFocus margin="dense" type="text" value={contactName} variant="outlined" />
                                        </FormControl >
                                    </Grid>
                                    {
                                        partnerAgencyDetail.stateID === 30 ?
                                            <React.Fragment>
                                                <Grid item xs={3}>
                                                    <FormControl sx={{ width: '100%' }} >
                                                        <label className="m-2" style={{ fontWeight: "bold" }}> {stateName} Latest Transaction Number</label>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl sx={{ width: '100%' }} >
                                                        <FMTextField name="latestTransactionNumber" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                                    </FormControl>
                                                </Grid>
                                            </React.Fragment>
                                            : ""
                                    }

                                    {/* <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <label className="m-2" style={{ fontWeight: "bold" }}> {stateName} National Producer Number </label>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FMTextField name="nationalProducerNumber" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid> */}


                                    {partnerAgencyDetail.stateID === 14 ? (
                                        <React.Fragment>

                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <label className="m-2" style={{ fontWeight: 'bold' }}> {stateName} Previous Credit </label>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <FMTextField name="previousCredit" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <label className="m-2" style={{ fontWeight: 'bold' }}> {stateName} Original Amount </label>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <FMTextField
                                                        name="originalAmount" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </React.Fragment>
                                    ) : ""}

                                    {partnerAgencyDetail.stateID === 22 ? (
                                        <React.Fragment>

                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <label className="m-2" style={{ fontWeight: 'bold' }}> {stateName} Carry Forward </label>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <FMTextField name="carryForward" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                                </FormControl>

                                            </Grid>
                                        </React.Fragment>
                                    ) : ""}

                                    {partnerAgencyDetail.stateID === 23 && <React.Fragment>
                                        <Grid item xs={3}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <label
                                                    className="m-2"
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    Date Licensed
                                                </label>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <FMTextField
                                                    name="dateLicensed"
                                                    InputLabelProps={{ shrink: true }}
                                                    autoFocus
                                                    margin="dense"
                                                    
                                                    type="date"
                                                    fullWidth
                                                    variant="outlined"
                                                    sx={{}}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>}

                                    {partnerAgencyDetail.stateID === 48 ? (
                                        <React.Fragment>

                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <label className="m-2" style={{ fontWeight: 'bold' }}> {stateName} Individual NPN </label>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <FMTextField name="individualNPN" InputLabelProps={{ shrink: true }} autoFocus margin="dense"  type="text" fullWidth variant="outlined" sx={{}} />
                                                </FormControl>

                                            </Grid>
                                        </React.Fragment>
                                    ) : ""}

                                    <Grid item xs={12}>
                                        <div className="col-lg-12" style={{ textAlign: "center" }}>
                                            {/* <Button sx={{ minWidth: "90px", margin: 2 }} variant="outlined" type='submit' disabled={isSubmitting} color="primary">Add</Button> */}
                                            <button
                                                className="btn active-btn-blue mx-2"
                                                type='submit' disabled={isSubmitting} color="primary"
                                            >
                                                Add New
                                            </button>
                                            <button  className="active-btn-white" onClick={() => { setopen(false); removeSelectedStates(String(partnerAgencyDetail.stateID)) }}  type="button">Cancel</button>
                                        </div>

                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </React.Fragment >

    )

}