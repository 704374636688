import { useEffect, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import React from "react";
import { TaxTransactionReportModel, TaxTransactionReportModel_Resp } from "../models/TaxTransactionReportModel";
import { Box, Stack, width } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { TaxTransactionReportList } from "../components/Lists/TaxTransactionReportList";
import { writeXlsxFile } from "../helper/utils";
//import { FieldAttributes, useField, useFormikContext } from "formik";
import { Field, FieldArray, FieldAttributes, Form, Formik, useField, useFormik, useFormikContext } from 'formik';
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DiligentSearchList } from "../components/Lists/DiligentSearchList";

export const DiligentSearchModule = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: TaxTransactionReportModel_Resp = {
        getOutstandingFilingsReport_Respdata: [],
        totalCount: 0
    }

    const [ReportModule, setReportModule] = useState<TaxTransactionReportModel_Resp>(initValue)

    const PloicyNum = React.useRef("");
    const insName = React.useRef("");
    const effectiveDateEd = React.useRef("");
    const effectiveDateSt = React.useRef("");
    const stateData = React.useRef(0);
    const customerData = React.useRef(0);
    const [selectedButton, setSelectedButton] = useState<number>(1);

    const keysNamesFromXlsx = [
        'HomeState', 'InsuredName', 'PolicyNumber',  'TaxType',
      'TransactionType', 'Carrier', 'NAIC','PolicyEffDate', 'PolicyExpDate',
       'GrossPremium','Client', 'Suspended', 'NeedDiligentSearch', 'DiligentSearchNotes',
        'DiligentSearchCompleted'
    ]

    const keyNames: (keyof TaxTransactionReportModel)[] = [
        'homeState', 'insuredName', 'policyNumber',  'taxType',
       'transactionType',  'carrier', 'naic','policyEffDate', 'policyExpDate', 
       'grossPremium', 'client', 'suspended', 'needDiligentSearch', 'diligentSearchNotes',
        'diligentSearchCompleted'
    ]

    const fetchNewData = (action: string, pageSize: number, pageNumber: number) => {
        setloading(true);

        const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
        if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
            let start: Date = new Date(effectiveDateSt.current);
            effectiveDateSt.current = start.toLocaleDateString('en-US', options);
        }

        if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
            let end: Date = new Date(effectiveDateEd.current ?? '');
            effectiveDateEd.current = end.toLocaleDateString('en-US', options);

        }

        const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        var customerIds = selectedValuesArray.join(',');
        fetch(
            config.url.API_URL +
            `/DiligentSearchReport/${action}?userId=${user?.userId}&&policyNumber=${PloicyNum.current}&&stateId=${stateData.current}
            &&effectiveDateStart=${effectiveDateSt.current}&&effectiveDateEnd=${effectiveDateEd.current} 
            &&insuredName=${insName.current}&&customerId=${customerData.current}&&customerIds=${customerIds}&&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                // Do something with the response
                if (res.data?.totalCount > 0) {
                    setReportModule(res.data)
                    console.log("data",res)
                }
                else {
                    setReportModule(initValue)
                }
            }).catch((error) => {
                setloading(false)
                console.log(error)
            });
    }

    const handleChange = (
        event: React.SyntheticEvent | undefined,
        newValue: number
    ) => {
        setloading(true);
        setSelectedButton(newValue);
        const action =
            newValue === 1
                ? "getOutstandingDiligentSearchReportData"
                : newValue === 2
                    ? "getCompletedFilingsReportData"
                    : newValue === 3 ? "getClosedFilingsReportData" : "";
        fetchNewData(action, 50, 1,);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const onExportXlsx = (values: TaxTransactionReportModel[]) => {
        setloading(true);
        if (values.length === 0) {
            return
        }
        const items = values.map(item => {
            const update: (string | number | Date | undefined)[] = []
            keyNames.map((key: keyof TaxTransactionReportModel) => {
                update.push(item[key])
            })
            return update
        })
        const updates = [
            keysNamesFromXlsx,
            ...items
        ]
        writeXlsxFile(updates)
        setloading(false);
    }
    const handleExportXlsxNew = async (val: TaxTransactionReportModel[]) => {
        try {
            setloading(true);

            const action =
                selectedButton === 1
                    ? "getOutstandingDiligentSearchReportData"
                    : selectedButton === 2
                        ? "getCompletedFilingsReportData"
                        : "getClosedFilingsReportData";

            const response = await fetchExportData(action, selectedOptions, ReportModule?.totalCount, 1);
            let values = response
            if (val && val.length > 0) {
                values = val
            }
            if (values.length <= 0) {
                alert('Please upload a xlsx file')
                return
            }
            onExportXlsx(values);
            setloading(false);
        } catch (error) {
            setloading(false);
            console.error("Error occurred:", error);
        }
    }
    const handleDataChange = async (e: string) => {
        var result = selectedOptions.toLocaleString().split(',').filter((item) => item !== e);
        setSelectedOption(result);
        onChangeCustomer(result);
    }

    async function onChangeCustomer(customers: string | string[]) {
        const action =
            selectedButton === 1
                ? "getOutstandingDiligentSearchReportData"
                : selectedButton === 2
                    ? "getCompletedFilingsReportData"
                    : "getClosedFilingsReportData";
        await fetchExportData(action, customers, 50, 1);
    }
    async function fetchExportData(action: string, customers: string | string[], pageSize: number, pageNumber: number) {
        setloading(true);
        const selectedValuesArray = Array.isArray(customers) ? customers : [customers];
        var customerIds = selectedValuesArray.join(',');

        const response = await fetch(
            config.url.API_URL +
            `/DiligentSearchReport/${action}?userId=${user?.userId}&&policyNumber=&&stateId=0
            &&effectiveDateStart=&&effectiveDateEnd=
            &&insuredName=&&customerId=0&&customerIds=${customerIds}&&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                console.log("reportlistdata", res.data);
                if (res.data?.totalCount > 0) {

                    if (pageSize <= 100) {
                        setReportModule(res.data)
                    }

                    //setReportModule(res.data)
                    //setExportData(res.data.getOutstandingFilingsReport_Respdata);
                    return res.data.getOutstandingFilingsReport_Respdata;
                }
                else {
                    //setReportModule(initValue)
                    setReportModule(initValue)
                }
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });
        return response;
    }
    const initialUserdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialUserdata]);
    const fetchCustomer = () => {
        fetch(config.url.API_URL + "/Partners/getPartnersByDiligent", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                setCustomer(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [selectedOptions, setSelectedOption] = React.useState<string[] | string>([]);

    const FMSelectCustomer: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {

        return (
            <FormControl fullWidth sx={{}}  >
                <InputLabel id="State-Select-Label">Customers</InputLabel>
                <Select
                    // {...field}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    label={"Customers"}

                    className="border-round main-form-2"
                    multiple
                    labelId="State-Select-Label"
                    value={selectedOptions}
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => {
                        const selectedValues = e.target.value;
                        setSelectedOption(selectedValues);
                        onChangeCustomer(selectedValues);
                    }}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.toLocaleString().split(',').map((value) => (
                                <Chip

                                    key={value}
                                    label={Customer.find(x => x.partnersId.toString() === value)?.customerName}
                                    onDelete={() =>
                                        // setSelectedOption(
                                        //     selectedOptions.toLocaleString().split(',').filter((item) => item !== value)
                                        // )
                                        handleDataChange(value)
                                    }
                                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                />
                            ))}

                        </Stack>
                    )}
                >
                    {Customer.map((Cust, key) =>
                        <MenuItem key={key} value={Cust.partnersId}>  {Cust.customerName} </MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

    useEffect(() => {
        fetchNewData('getOutstandingDiligentSearchReportData', 50, 1);
        fetchCustomer();
    }, [])

    return (
        <><LocalizationProvider dateAdapter={AdapterDayjs}>

            <Box>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>
   
            </Box>

            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="card-title">
                    <span className="d-flex">Diligent Search Reports</span>
                </div>
                <div className="d-flex align-items-center" >
                    <Grid sx={{marginRight:"16px"}} item xs={4} sm={4} md={1.5} minWidth={130}>
                        <FormControl sx={{ width: '100%', minWidth: "300px" }} >

                            <FMSelectCustomer name="customerIds" />
                        </FormControl>
                    </Grid>



                    <Grid item xs={4} sm={4} sx={{}} md={1.5} minWidth={130} className="select-input-border d-flex">
                        <button
                            onClick={() => handleExportXlsxNew([])}
                            className="active-btn-blue"
                        >
                            Export
                        </button>
                    </Grid>
                </div>
            </div>

        </LocalizationProvider>
            <DiligentSearchList ReportModuleCL={ReportModule} fetchDataMain={fetchNewData} selectedButton={selectedButton} /></>
    );
}
