
import moment from 'moment';
import * as yup from 'yup'

export interface partnertaxesandfeesModel {
    partnerTaxesandFeeId: number,
    partnersId?: number,
    stateId ?: number,
    lobId ?: number,  
    rate: number,
    rateType?: string,
    isDeleted?: boolean,
    dateCreated?: Date,
    dateModified?: Date,
    notes?: string,
    createdBy?: number,
    modifiedBy? : number
    transactionTypeId? : number,
    businessCode?: string,
    rateTypeName?:string
}


export const PartnersModel_VS = yup.object({
    stateId: yup.number().min(1, "Please select a state").required("Please select a state").typeError("Invalid value"),
   rate: yup.number().min(1, "Please enter amount").required("Please enter amount").typeError("Invalid value"),
    transactionTypeId: yup.number().min(1, "Please select a transaction type").required("Please select a transaction type").typeError("Invalid value"),
    //rateType: yup.string().required("Please select a rate type").typeError("Invalid value"),
    lobId: yup.number().min(1, "Please select a line of business").required("Please select a line of business").typeError("Invalid value"),
    // notes: yup.string()
  }); 


  
