import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid-pro"
import { RiExpandUpDownLine } from "react-icons/ri"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import useAuth from "../../context/AuthProvider"
import moment from "moment"
import { OTRFlags, OTRModelDTO, OTRModuleModel } from "../../models/OTRModuleModel"
import { SelectControl } from "../Elements/select"
import { MenuDropdown } from "../Elements/MenuDropdown"
import Swal from "sweetalert2"
import { OffsetNotes } from "../Elements/OffsetNotes"
import { toCurrency } from "../../helper/utils"
import { config } from "../../Constants"
import { AddEditOTR } from "../AddEdit/AddEditOTR"
import formatPrice from "../../helper/currencyFormatter"
import { color } from "@mui/system";

interface Iprops {
  listData: OTRModuleModel[]
  pageSize: number
  currentPage: number
  onPageChange: (val: number) => void
  onPageSizeChange: (val: number) => void
  getOptions: (field: string) => string[]
  fetchOptions: (field: string, rowVal?: any) => Promise<string[]>
  onImportXlsx: (val: File, xlsItem: string) => void
  onExportXlsx: (val: OTRModuleModel[]) => void
  onRemoveItems: (val: OTRModuleModel[]) => void
  onOffsetItems: (val: OTRModuleModel[]) => void
  onApproveItems: (val: OTRModuleModel[]) => void
  onItemChange: (val: OTRModuleModel) => void
  selectedRows: OTRModuleModel[];
  setSelectedRows: (val: OTRModuleModel[]) => void;
  selectionModel: GridSelectionModel;
  setSelectionModel: (val: GridSelectionModel) => void;
  fetchData: () => void
  status: boolean
  setStatus: (field: boolean) => void
  // openImportStatusDialog: boolean
  // setOpenImportStatusDialog: (field: boolean) => void
  isImportCompleted: string
  // setIsImportCompleted: (field: string) => void
  // checkImportStatus : () => void
}

export const OTRModuleList: React.FC<Iprops> = ({
  listData,
  pageSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
  getOptions,
  fetchOptions,
  onImportXlsx,
  onExportXlsx,
  onRemoveItems,
  onOffsetItems,
  onApproveItems,
  onItemChange,
  selectedRows,
  setSelectedRows,
  selectionModel,
  setSelectionModel,
  fetchData,
  status,
  setStatus,
  // openImportStatusDialog,
  // setOpenImportStatusDialog,
  isImportCompleted,
  // setIsImportCompleted,
  // checkImportStatus,
}) => {
  const { user } = useAuth()
  const token = user?.token
  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const [loading, setLoading] = useState(false);
  const [offSetNotes, setOffsetNotes] = useState<string>('');
  const [options, setOptions] = useState<string[]>([])
  const [OpenOTREdit, setOpenOTREdit] = React.useState(false);
  const handleCloseOTREdit = () => { setOpenOTREdit(false); };
  // const [isImportCompleted, setIsImportCompleted] = useState('');
  // const [openImportStatusDialog, setOpenImportStatusDialog] = useState(false);
  // const [hasShownSuccessAlert, setHasShownSuccessAlert] = useState(false);


  const initialOTRModelDTO: OTRModelDTO = {
    openTaxReportId: 0,
    isDeleted: false
  };

  const [Formdata, setFormData] = React.useState(initialOTRModelDTO);
  const handleSubmit = () => {
    fetchData();
    setOpenOTREdit(false);
  };


  const onMenuItemClick = async (field: string, rowVal?: any) => {
    //
    setOptions([])
    if (rowVal.taxType !== null) {
      const optionList = await fetchOptions(field, rowVal)
      setOptions(optionList)
    }

  }

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    const selectedIDs = new Set(ids)
    const selectedRowData = listData.filter(
      (row) => selectedIDs.has(row?.rowNum ?? "")
    )
    setSelectedRows(selectedRowData)
    setSelectionModel(ids)
    console.log({ selectedRowData, ids })
  }

  const handleDeleteItems = () => {
    if (!selectedRows || selectedRows.length < 1) return
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      showCancelButton: true,
      confirmButtonColor: '#02A4E3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      customClass: {
        confirmButton: 'btn-radius',
        cancelButton: 'btn-radius',
        popup: 'card-radius'
      }
    })
      .then((result) => {
        if (result.isConfirmed) {
          onRemoveItems(selectedRows)
          setSelectedRows([])
          setSelectionModel([])
        }
      })
  }

  const handleOffsetItems = () => {
    if (!selectedRows || selectedRows.length < 1) return
    setOffsetNotes('');
    handleOpen();
  }

  const handleUpdateOffsetNotes = (newNote: string) => {
    if (!selectedRows || selectedRows.length < 1) return
    const updatedRows = selectedRows.map(item => ({
      ...item,
      offSetNotes: newNote,
    }));

    onOffsetItems(updatedRows);
    setSelectedRows([]);
    setSelectionModel([])
  };

  const handleImportXlsx = (
    _e: React.ChangeEvent<HTMLInputElement>,
    xlsItem: string
  ) => {
    if (!_e || !_e.target.files) return
    onImportXlsx(_e.target.files[0], xlsItem)
  }
  const handleExportXlsx = (val: OTRModuleModel[]) => {
    let values = listData
    if (val && val.length > 0) {
      values = val
    }
    if (values.length <= 0) {
      alert('Please upload a xlsx file')
      return
    }
    onExportXlsx(values)
    setSelectedRows([])
    setSelectionModel([])
  }


  const curExcelFormat = useRef<string>('')
  const onImport = (item: string) => {
    curExcelFormat.current = item
    document.getElementById('uploadXlsxOTR')?.click();
  }

  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);
  const fetchStateData = () => {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initialTransactionListdata = { transactionId: 0, transactionTypeName: "" };
  const [Transactions, setTransactions] = useState([initialTransactionListdata]);
  const fetchTransactionListData = () => {
    fetch(config.url.API_URL + `/TransactionType/getAllTransactionType`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        if (responseJson.data != null) {
          setTransactions(responseJson.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (IOTRData: OTRModelDTO) => {
    setLoading(true);

    let a: OTRModelDTO = {
      openTaxReportId: IOTRData.openTaxReportId ?? 0,
      invoiceNumber: IOTRData.invoiceNumber ?? '',
      insuredName: IOTRData.insuredName ?? '',
      policyNumber: IOTRData.policyNumber ?? '',
      homeState: IOTRData.homeState ?? '',
      taxType: IOTRData.taxType ?? '',
      taxPayee: IOTRData.taxPayee ?? '',
      transactionType: IOTRData.transactionType ?? '',
      coverage: IOTRData.coverage ?? '',
      carrier: IOTRData.carrier ?? '',
      nAIC: IOTRData.nAIC ?? '',
      transEffDate: IOTRData.transEffDate ?? new Date,
      invDate: IOTRData.invDate ?? new Date,
      policyEffDate: IOTRData.policyEffDate ?? new Date,
      policyExpDate: IOTRData.policyExpDate ?? new Date,
      grossPremium: IOTRData.grossPremium ?? '',
      nonAdmittedPremium: IOTRData.nonAdmittedPremium ?? '',
      taxableFees: IOTRData.taxableFees ?? '',
      nonTaxableFees: IOTRData.nonTaxableFees ?? '',
      taxAmt: IOTRData.taxAmt ?? '',
      taxBalance: IOTRData.taxBalance ?? '',
      client: IOTRData.client ?? '',
      clientDiv: IOTRData.clientDiv ?? '',
      dateInvoiceAdded: IOTRData.dateInvoiceAdded ?? '',
      clientNotes: IOTRData.clientNotes ?? '',
      filerNotes: IOTRData.filerNotes ?? '',
      status: IOTRData.status ?? '',
      whoFiles: IOTRData.whoFiles ?? '',
      isManualTax: IOTRData.isManualTax ?? false,
      fileNumber: IOTRData.fileNumber ?? '',
      endNumber: IOTRData.endNumber ?? '',
      bookDate: IOTRData.bookDate ?? new Date,
      coFee: IOTRData.coFee ?? '',
      coFeeByTodayTaxFlag: IOTRData.coFeeByTodayTaxFlag ?? '',
      coFeeDiff: IOTRData.coFeeDiff ?? '',
      brokFee: IOTRData.brokFee ?? '',
      brokFeeByTodayTax: IOTRData.brokFeeByTodayTax ?? '',
      brokFeeDiff: IOTRData.brokFeeDiff ?? '',
      inspFee: IOTRData.inspFee ?? '',
      inspFeeByTodayTaxFlag: IOTRData.inspFeeByTodayTaxFlag ?? '',
      inspFeeDiff: IOTRData.inspFeeDiff ?? '',
      isInspFeeToCo: IOTRData.isInspFeeToCo ?? false,
      taxSL: IOTRData.taxSL ?? '',
      stampFee: IOTRData.stampFee ?? '',
      otherFee: IOTRData.otherFee ?? '',
      otherTax: IOTRData.otherTax ?? '',
      otherTaxDescr: IOTRData.otherTaxDescr ?? '',
      otherFeeDescr: IOTRData.otherFeeDescr ?? '',
      acctOtherFee: IOTRData.acctOtherFee ?? '',
      statusAdm: IOTRData.statusAdm ?? '',
      isCoFeeTaxable: IOTRData.isCoFeeTaxable ?? false,
      isBrFeeTaxable: IOTRData.isBrFeeTaxable ?? false,
      isInspFeeTaxable: IOTRData.isInspFeeTaxable ?? false,
      taxExempt: IOTRData.taxExempt ?? '',
      nonTaxBrFee: IOTRData.nonTaxBrFee ?? '',
      underwriter: IOTRData.underwriter ?? '',
      filingBatch: IOTRData.filingBatch ?? '',
      dateFiledWSLA: IOTRData.dateFiledWSLA ?? new Date,
      isOroBatch: IOTRData.isOroBatch ?? false,
      tranCode: IOTRData.tranCode ?? '',
      taxNotes: IOTRData.taxNotes ?? '',
      customerPolicyId: IOTRData.customerPolicyId ?? 0,
      stateId: IOTRData.stateId ?? 0,
      transactionTypeId: IOTRData.transactionTypeId ?? 0,
      // carrierId: IOTRData.carrierId ?? 0,
      isDeleted: IOTRData.isDeleted ?? false,
      partnersId: IOTRData.partnersId ?? 0,
      isApproved: IOTRData.isApproved ?? false,
      offSetNotes: IOTRData.offSetNotes ?? '',
      offSet: IOTRData.offSet ?? false,
      isFlagged: IOTRData.isFlagged ?? false,
      insuredId: IOTRData.insuredId ?? 0,
      lOBId: IOTRData.lOBId ?? 0,
      taxesAndFeesId: IOTRData.taxesAndFeesId ?? 0,
      insuranceCompanyId: IOTRData.insuranceCompanyId ?? 0,
      createdBy: IOTRData.createdBy ?? 0,
      dateCreated: IOTRData.dateCreated ?? new Date
    };

    setFormData(a);
    fetchStateData();
    fetchTransactionListData();
    console.log("FormData", Formdata);
    setOpenOTREdit(true);
  };


  const handleCheckImportStatus = () => {
    debugger
    fetch(config.url.API_URL + `/OpenTaxReport/CheckImportStatus`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        if (responseJson.data != null) {
          debugger
          console.log(responseJson.data);

          // setIsImportCompleted(responseJson.data);
          // setOpenImportStatusDialog(true);
          if (responseJson.data === 'Completed') {
            setStatus(false)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

  };

  // const CheckImportStatus = () => {
  //   debugger
  //   fetch(config.url.API_URL + `/OpenTaxReport/CheckImportStatus`, {
  //     method: "GET",
  //     headers: new Headers({
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     }),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error("Something went wrong");
  //     })
  //     .then((responseJson) => {
  //       if (responseJson.data != null) {
  //         console.log(responseJson.data);

  //         if (responseJson.data === 'Success') {
  //           Swal.fire({
  //             title: "Data Successfully Imported",
  //             text: "",
  //             icon: "success",
  //             confirmButtonText: "OK",
  //           });
  //           // debugger
  //           setStatus(false);
  //           console.log("Status on reload:", status);
  //         } else if (responseJson.data === 'Completed') {
  //           setStatus(false);
  //         }
  //         else if (responseJson.data === 'In Progress') {
  //           setStatus(true);
  //         }
  //         else {
  //           setStatus(false);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // };

  // const handleCloseImportStatusDialog = () => {
  //   setOpenImportStatusDialog(false);
  //   fetchData();
  // };
  // const handleRefreshImportStatusDialog = () => {
  //   fetchData();
  // };

  useEffect(() => {

    // checkImportStatus();
    // const intervalId = setInterval(() => {
    //   CheckImportStatus();
    // }, 10000);
    // return () => clearInterval(intervalId);
  }, []);


  const EditToolbar = () => {
    return (
      <React.Fragment>

        <Box display='flex'>
          <Grid sx={{ zIndex: 100 }} container display="flex " margin={"10px 0px"} justifyContent={"space-between"}>
            <Grid item xs={12} md={4}>
              <GridToolbar />
            </Grid>
            <Grid item xs={12} md={8}>

              <Grid container display='flex' justifyContent={'flex-end'}>
                <Grid item xs={3} sm={3} md={1.5} minWidth={130} className=" select-input-border">
                  <input
                    id='uploadXlsxOTR'
                    className='d-none'
                    type='file'
                    accept={`.xls,.xlsx,.csv`}
                    onChange={file => handleImportXlsx(file, curExcelFormat.current)}
                  />
                  <MenuDropdown
                    menuTitle={'Import'}
                    items={getOptions('excelFormatList')}
                    onItemClick={onImport}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={1.5} minWidth={130} className=" select-input-border">
                  <button
                    onClick={() => handleExportXlsx(selectedRows ?? [])}
                    className="active-btn-blue"
                  >
                    Export
                  </button>
                </Grid>
                <Grid item xs={3} sm={3} md={1.5} minWidth={130} className=" select-input-border">
                  {user?.userRoles.split(",").includes("Super Admin") ? (
                    <MenuDropdown
                      menuTitle={'Remove'}
                      items={['Remove', 'Offset']}
                      onItemClick={item => {
                        if (item === 'Remove') {
                          handleDeleteItems();
                        } else if (item === 'Offset') {
                          handleOffsetItems();
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={3} sm={3} md={1.5} minWidth={130} className=" select-input-border">
                  {user?.userRoles.split(",").includes("Super Admin") ? (
                    <button
                      onClick={() => onApproveItems(selectedRows ?? [])}
                      className="active-btn-blue"
                    >
                      Approve
                    </button>
                  ) : ""}
                </Grid>
                {/* {status == true ? (
                  <Grid item xs={3} sm={3} md={1.5} minWidth={130} className="select-input-border">

                    <button
                      onClick={handleCheckImportStatus}
                      className="active-btn-green"
                      style={{ padding: '10px 20px' }}
                    >
                      Import Status
                    </button>

                  </Grid>
                ) : ""} */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <Dialog open={openImportStatusDialog} onClose={handleCloseImportStatusDialog}>
          <DialogTitle>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
              Import Status
            </Typography>
          </DialogTitle>
          <DialogContent>
            {/* <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
              <Typography>
                {isImportCompleted === null ? "Unable to fetch status, please try again..." : isImportCompleted}
              </Typography>
            </Box> */}
        {/* <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
              {isImportCompleted === null ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  {/* <CircularProgress /> */}
        {/* <Typography variant="body1" color="textSecondary" marginTop={2}>
                    Unable to fetch status, please try again...
                  </Typography>
                </Box> */}
        {/* ) : isImportCompleted === 'In Progress' ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CircularProgress />
                  <Typography variant="body1" color="textSecondary" marginTop={2}>
                    Import is in progress. Please wait.
                  </Typography>
                </Box>
              ) : isImportCompleted === 'Completed' ? (
                <Typography variant="body1" color="success.main">
                  <CheckCircleIcon color="success" fontSize="large" />                  
                  Import completed successfully!
                </Typography>
              ) : (
                <Typography variant="body1" color="error.main">
                  <ErrorIcon color="error" fontSize="large" />
                  Unexpected status. Please try again later.
                </Typography>
              )} */}
        {/* </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="flex-end" width="100%">
              {/* <Button onClick={handleRefreshImportStatusDialog} color="primary">
                Refresh
              </Button> */}
        {/* <Button onClick={handleCloseImportStatusDialog} color="primary" style={{ marginLeft: '8px' }}>
                Close
              </Button> */}
        {/* </Box> 
          </DialogActions>
        </Dialog>  */}

        <OffsetNotes open={open} handleClose={handleClose} setOffsetNotes={setOffsetNotes} handleUpdateOffsetNotes={handleUpdateOffsetNotes} />
      </React.Fragment>
    )
  }

  const initialdata: OTRFlags = {
    fieldName: "",
    errorMessage: "",
  }

  // const getDetailPanelContent = React.useCallback<
  //       NonNullable<DataGridProProps['getDetailPanelContent']>
  //   >(({ row }) => <DetailPanelContent row={row} />, []);
  const getDetailPanelHeight = React.useCallback(() => 200, []);

  //   function DetailPanelContent({ row }: { row: OTRFlags }) {
  //     const [OTRFlags, setOTRFlags] = useState<OTRFlags[]>([initialdata]);
  //     const [newData, setNewData] = useState<OTRFlags>(row);
  //     const Flags = () => {

  //       setOTRFlags([])

  //     }


  //     const rows = [
  //         {
  //           Flags
  //         }
  //     ];
  //     return (

  //         <Stack direction="column" spacing={1} sx={{ height: 1 }}  className="hide-border-12" >

  //             <DataGridPro
  //                 columns={[
  //                     {
  //                         field: 'taxType',
  //                         type: 'string',
  //                         headerName: 'Tax Type',
  //                         flex: 1,
  //                         headerAlign: "center",
  //                         hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ),
  //                         align: "center",
  //                     },
  //                     {
  //                         field: 'insuredName',
  //                         type: 'string',
  //                         headerName: 'Insured Name',
  //                         flex: 1,
  //                         headerAlign: "center",
  //                         hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ),
  //                         align: "center",
  //                     },
  //                     {
  //                         field: 'policyNumber',
  //                         type: 'string',
  //                         headerName: 'Policy Number',
  //                         flex: 0.6,
  //                         hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ),
  //                         headerAlign: "center",
  //                         align: "center",
  //                     },

  //                     {
  //                         field: "homeState",
  //                         type: "string",
  //                         headerName: "Home State",
  //                         flex: 0.4,
  //                         hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ),
  //                         align: "center",
  //                         headerAlign: "center",
  //                     },
  //                     {
  //                         field: 'grossPremium', hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ), type: 'string', headerName: 'Gross Premium', flex: 0.5, align: 'center', headerAlign: 'center'
  //                     },
  //                     {
  //                         field: 'taxAmt', hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ), type: 'string', headerName: 'Tax Amount', flex: 0.5, align: 'center', headerAlign: 'center'
  //                     },
  //                     {
  //                         field: 'dueDate',
  //                         type: 'string',
  //                         headerName: 'Due Date',
  //                         flex: 1,
  //                         headerAlign: "center",
  //                         hideSortIcons: true,
  //                         renderHeader: (params) => (
  //                             <div>
  //                                 <span className="table-card-title" >{params.colDef.headerName}</span>
  //                                 <RiExpandUpDownLine />
  //                             </div>
  //                         ),
  //                         align: "center",
  //                     },

  //                 ]}
  //                 rows={Flags}
  //                 getRowId={(r) => r?.rowNum??0}
  //                 sx={{ flex: 1 }}
  //                 hideFooter  
  //             />
  //         </Stack>
  //     );
  // }

  const columns: GridColDef[] = [
    {
      field: 'rowNum',
      type: 'number',
      headerName: 'No',
      hide: true,
      // filterable: false,
      // disableColumnMenu: true,
      hideable: false,
      disableExport: true,
      // disableReorder: true,
    },
    {
      field: "invoiceNumber",
      type: "string",
      headerName: "Invoice Number",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "insuredName",
      type: "string",
      headerName: "Insured Name",
      minWidth: 200,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "policyNumber",
      type: "string",
      headerName: "Policy Number",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "homeState",
      type: "string",
      headerName: "State",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "taxType",
      type: "string",
      headerName: "Tax Type",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      renderCell: (params) => {
        // if (!params.row.isFlagged) {
        //   return <div>{params.value}</div>
        // }
        return <div className={`custom-cell w-100 h-100 ${params.row.isFlagged ? 'red-row' : ''}`}>
          <MenuDropdown
            type={'none'}
            menuTitle={params.value}
            items={options}
            onItemClick={item => {
              const update = params.row
              update['taxType'] = item
              onItemChange(update)
            }}
            onMenuClick={() => onMenuItemClick('taxType', params.row)}
          />
        </div>
      },
    },
    {
      field: "taxPayee",
      type: "string",
      headerName: "Tax Payee",
      minWidth: 200,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "transactionType",
      type: "string",
      headerName: "Transaction Type",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        if (params.value) {
          var substring = params.value.match("Business(.*)")
          // if (substring) return params.value.replace(substring[0], "")
          // else if (
          //   (substring =
          //     params.value.match("Endorsement(.*)") ||
          //     (substring = params.value.match("Cancellation(.*)")) ||
          //     (substring = params.value.match("Reinstatement(.*)")) ||
          //     (substring = params.value.match("Renewal(.*)")) ||
          //     (substring = params.value.match("Audit(.*)")))
          // )
          //   return substring[0]
          // else return params.value
          return params.value
        }
      },
    },
    {
      field: "coverage",
      type: "string",
      headerName: "Coverage",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "carrier",
      type: "string",
      headerName: "Carrier",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "naic",
      type: "string",
      headerName: "NAIC",
      minWidth: 80,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "transEffDate",
      type: "string",
      headerName: "Transaction Effective Date",
      minWidth: 200,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.transEffDate).format("MM/DD/YYYY")
      },
    },
    {
      field: "invDate",
      type: "string",
      headerName: "Invoice Date",
      minWidth: 120,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.invDate).format("MM/DD/YYYY")
      },
    },
    {
      field: "policyEffDate",
      type: "string",
      headerName: "Policy Effective Date",
      minWidth: 180,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.policyEffDate).format("MM/DD/YYYY")
      },
    },
    {
      field: "policyExpDate",
      type: "string",
      headerName: "Policy Expire Date",
      minWidth: 180,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.policyExpDate).format("MM/DD/YYYY")
      },
    },
    {
      field: "grossPremium",
      type: "string",
      headerName: "Gross Premium",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: "nonAdmittedPremium",
      type: "string",
      headerName: "Non Admitted Premium",
      minWidth: 200,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: "taxableFees",
      type: "string",
      headerName: "Taxable Fees",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: "nonTaxableFees",
      type: "string",
      headerName: "Non Taxable Fees",
      minWidth: 160,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: "taxAmt",
      type: "string",
      headerName: "Tax Amount",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: "taxBalance",
      type: "string",
      headerName: "Tax Balance",
      minWidth: 150,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: "client",
      type: "string",
      headerName: "Client",
      minWidth: 120,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "clientDiv",
      type: "string",
      headerName: "ClientDiv",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "dateInvoiceAdded",
      type: "string",
      headerName: "Date Invoice Added",
      minWidth: 170,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.dateInvoiceAdded).format("MM/DD/YYYY")
      },
    },
    {
      field: "clientNotes",
      type: "string",
      headerName: "Client Notes",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "filerNotes",
      type: "string",
      headerName: "Filer Notes",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "filerName",
      type: "string",
      headerName: "Filer Name",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "dateFiled",
      type: "string",
      headerName: "Date Filed",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.dateFiled).format("MM/DD/YYYY")
      },
    },
    {
      field: "filingType",
      type: "string",
      headerName: "FilingType",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "status",
      type: "string",
      headerName: "Status",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "diligentSearchNotes",
      type: "string",
      headerName: "DiligentSearchNotes",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "paymentNoticeDate",
      type: "string",
      headerName: "PaymentNoticeDate",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.dateFiled).format("MM/DD/YYYY")
      },
    },
    {
      field: "feeOnly",
      type: "string",
      headerName: "FeeOnly",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "needDiligentSearch",
      type: "string",
      headerName: "NeedDiligentSearch",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "diligentSearchProcessDate",
      type: "string",
      headerName: "DiligentSearchProcessDate",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.dateFiled).format("MM/DD/YYYY")
      },
    },
    {
      field: "sentToVenbrook",
      type: "string",
      headerName: "SentToVenbrook",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "receivedFromVenbrook",
      type: "string",
      headerName: "ReceivedFromVenbrook",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "diligentSearchCompleted",
      type: "string",
      headerName: "DiligentSearchCompleted",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "diligentSearchToGMAC",
      type: "string",
      headerName: "DiligentSearchToGMAC",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "bor",
      type: "string",
      headerName: "BOR",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "itemType",
      type: "string",
      headerName: "ItemType",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "path",
      type: "string",
      headerName: "Path",
      minWidth: 100,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "firstRequest",
      type: "string",
      headerName: "First Request",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.firstRequest).format("MM/DD/YYYY")
      },
    },
    {
      field: "secondRequest",
      type: "string",
      headerName: "Second Request",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.secondRequest).format("MM/DD/YYYY")
      },
    },
    {
      field: "thirdRequest",
      type: "string",
      headerName: "Third Request",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.thirdRequest).format("MM/DD/YYYY")
      },
    },
    {
      field: "suspended",
      type: "string",
      headerName: "Suspended",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return !params.value ? '' : moment(params.row?.suspended).format("MM/DD/YYYY")
      },
    },
    {
      field: "removed",
      type: "string",
      headerName: "Removed",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: "paymentPeriod",
      type: "string",
      headerName: "PaymentPeriod",
      minWidth: 130,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: 'id',
      headerName: "Action",
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (params) => (<div>

        <IconButton
          color="primary"
          onClick={() => { handleEdit(params.row) }}

        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </IconButton >
      </div>)

    },
    //#region 
    // {
    //   field: "whoFiles",
    //   type: "string",
    //   headerName: "Who Files",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "isManualTax",
    //   type: "string",
    //   headerName: "Manual Tax",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "fileNumber",
    //   type: "string",
    //   headerName: "File #",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "endNumber",
    //   type: "string",
    //   headerName: "End #",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "bookDate",
    //   type: "string",
    //   headerName: "Book Date",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return !params.value ? '' : moment(params.row?.bookDate).format("MM-DD-YYYY")
    //   },
    // },
    // {
    //   field: "coFee",
    //   type: "string",
    //   headerName: "Co Fee",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "coFeeByTodayTaxFlag",
    //   type: "string",
    //   headerName: "Co Fee - by today's Tax Flags",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "coFeeDiff",
    //   type: "string",
    //   headerName: "Co Fee - Diff",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "brokFee",
    //   type: "string",
    //   headerName: "Brok Fee",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "brokFeeByTodayTax",
    //   type: "string",
    //   headerName: "Brok Fee - by today's Tax Flags",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "brokFeeDiff",
    //   type: "string",
    //   headerName: "Brok Fee - DIFF",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "inspFee",
    //   type: "string",
    //   headerName: "Insp Fee",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "inspFeeByTodayTaxFlag",
    //   type: "string",
    //   headerName: "Insp Fee - by today's Tax Flags",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "inspFeeDiff",
    //   type: "string",
    //   headerName: "Insp Fee - DIFF",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "isInspFeeToCo",
    //   type: "string",
    //   headerName: "Insp Fee to Co",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "taxSL",
    //   type: "string",
    //   headerName: "S/L Tax",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "stampFee",
    //   type: "string",
    //   headerName: "Stamp Fee",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "otherFee",
    //   type: "string",
    //   headerName: "Other Fee",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "otherTax",
    //   type: "string",
    //   headerName: "Other Tax",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "otherTaxDescr",
    //   type: "string",
    //   headerName: "Other Tax Descr",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "otherFeeDescr",
    //   type: "string",
    //   headerName: "Other Fee Descr",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "acctOtherFee",
    //   type: "string",
    //   headerName: "OthFee Acct",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "statusAdm",
    //   type: "string",
    //   headerName: "Adm Status",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "isCoFeeTaxable",
    //   type: "string",
    //   headerName: "Co Fee Taxable",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "isBrFeeTaxable",
    //   type: "string",
    //   headerName: "Br Fee Taxable",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "isInspFeeTaxable",
    //   type: "string",
    //   headerName: "Insp Fee Taxable",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "taxExempt",
    //   type: "string",
    //   headerName: "Tax Exempt",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "nonTaxBrFee",
    //   type: "string",
    //   headerName: "Non-Tax Br Fee",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "underwriter",
    //   type: "string",
    //   headerName: "Underwriter",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "filingBatch",
    //   type: "string",
    //   headerName: "Filing Batch",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "dateFiledWSLA",
    //   type: "string",
    //   headerName: "Date Filed w/ SLA",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return !params.value ? '' : moment(params.row?.dateFiledWSLA).format("MM-DD-YYYY")
    //   },
    // },
    // {
    //   field: "isOroBatch",
    //   type: "string",
    //   headerName: "ORO Batch",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "tranCode",
    //   type: "string",
    //   headerName: "Tran Code",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    // {
    //   field: "taxNotes",
    //   type: "string",
    //   headerName: "Tax Notes",
    //   minWidth:150,
    //   headerAlign: "center",
    //   align: "center", hideSortIcons: true,
    //   renderHeader: (params) => (
    //     <div>
    //       <span className="table-card-title" >{params.colDef.headerName}</span>
    //       <RiExpandUpDownLine />
    //     </div>
    //   ),
    //   valueGetter: (params) => {
    //     return params.value
    //   },
    // },
    //#endregion
  ]

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGridPro
        getRowId={(r) => r.rowNum}
        rows={listData}
        columns={columns.filter(column => !column.hide)}
        pageSize={pageSize}
        // page={currentPage}
        pagination
        // paginationMode="server"
        // rowCount={totalCount}
        rowsPerPageOptions={[25, 50, 100]}

        getDetailPanelHeight={getDetailPanelHeight}
        // getDetailPanelContent={getDetailPanelContent}

        checkboxSelection={
          user?.userRoles.split(",").includes("Super Admin") ? true : false
        }
        onPageChange={(newPage) => onPageChange(newPage)}
        onPageSizeChange={(newPageSize) => onPageSizeChange(newPageSize)}
        onSelectionModelChange={
          user?.userRoles.split(",").includes("Super Admin")
            ? (newRowSelectionModel) => {
              onRowsSelectionHandler(newRowSelectionModel)
            }
            : undefined
        }
        selectionModel={selectionModel}
        disableSelectionOnClick
        // getRowClassName={(params) => (params.row.isFlagged ? 'red-row' : '')}
        components={{
          Toolbar: EditToolbar,
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
            whiteSpace: "wrap",
          },
          ".MuiDataGrid-cellContent": {
            textAlign: 'center',
            WebkitLineClamp: 2,
            lineClamp: 2,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
          },
          "&.MuiDataGrid-root--densityCompact": {
            lineHeight: "1",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "50px !important",
          },
          fontFamily: "'Proxima Nova', sans-serif",
          border: "0px",
          fontSize: "16px",
          ".custom-cell": {
            button: {
              padding: 0,
              width: '100%',
              height: '100%',
              textAlign: 'center',
              WebkitLineClamp: 2,
              lineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
            }
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
      <AddEditOTR
        handleClose={handleCloseOTREdit}
        Open={OpenOTREdit}
        opentaxreportCL={Formdata}
        handleSubmit={handleSubmit}
        initValues={initialOTRModelDTO}
        states={States}
        Transactions={Transactions}
      ></AddEditOTR>
    </div>
  )
}
