import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, Link, IconButton } from "@mui/material";
import { config } from "../../Constants";
import { InsuranceCompanyModel } from "../../models/insuranceCompanyModel";
import { AddEditInsuranceCompany } from "../AddEdit/AddEditInsuranceCompany";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'moment';
import useAuth from "../../context/AuthProvider";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

interface Iprops {
    InsuranceComapnyCL: InsuranceCompanyModel[],
    fetchData: () => void
}


export const InsuranceComapnyList: React.FC<Iprops> = ({ InsuranceComapnyCL, fetchData }) => {
    const initialICData: InsuranceCompanyModel = {
        insuranceCompanyId: 0,
        naicNumber: "", name: "",
        stateId: 0,
        createdBy: 0,
        modifiedBy: 0,
        dateCreated: new Date(),
        dateModified: new Date(),
        isDeleted: false,
        stateName: ""
    };

    const { user } = useAuth();
    const token = user?.token;

    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialICData);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const columns: GridColDef[] = [
        { field: 'naicNumber', headerName: 'Naic Number', type: 'string', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        // { field: 'stateId', headerName: 'State',type:'singleSelect',flex:1,
        //  valueOptions:['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'], width: 130 },
        { field: 'stateName', headerName: 'State', type: 'text', flex: 1 },
        { field: 'dateCreated', headerName: 'Date Created', type: "date", flex: 1, renderCell: (params) => (<div> {params.row.dateCreated ? Moment(params.row.dateCreated).format("YYYY-MM-DD") : ""}</div>) },
        // { field: 'isDeleted', headerName: 'Is Deleted',type:'boolean',flex:1 },
        {
            field: 'id', flex: 1, headerName: "Action", renderCell: (params) => (<div>
                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}
                >
                    <EditIcon />
                </IconButton >
                {user?.userRoles.split(",").includes("Super Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.id) }}

                    >
                        {params.row.isDeleted ? <RestoreFromTrashIcon /> : <DeleteIcon />}
                    </IconButton >
                    : ""}
            </div>)
        }


    ];

    const handleClickOpen = () => {
        setFormData(initialICData);
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };

    const handleEdit = (ICClData: InsuranceCompanyModel) => {
        setFormData(ICClData);
        setOpen(true);
    };

    const handleDelete = (id: number) => {
        //call api to save data
        fetch(config.url.API_URL + "/InsuranceCompany/DeleteInsuranceCompany/?id=" + `${id}`,
            {
                method: "Delete",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                // Do something with the response
                fetchData();
                setOpen(false);
            }).catch((error) => {
                console.log(error)

            });

    };


    function EditToolbar() {
        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12">
                    <div className="col-lg-4 d-flex">
                        <div style={{ marginRight: "2px" }}>
                            <Link href="/">
                                <Button type="button" title="Home"
                                    className="btn action-btn" ><HomeIcon />
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button

                                className=" btn action-btn"
                                onClick={goBack}
                                title="Back"
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: "center" }}>
                        <span className="card-title">Carrier</span>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: "right" }}>
                        <Button
                            onClick={handleClickOpen}
                            color="primary"
                            variant="contained"
                        >
                            Add
                        </Button>
                    </div>
                    <div className="col-log-4" style={{ textAlign: "center" }}>
                        <span className="card-title" > <GridToolbarFilterButton /></span>
                    </div>

                </div>

            </Box>

        );
    }

    return (
        <div style={{ height: "80vh", width: '100%' }}>
            <DataGrid
                getRowId={(r) => r.insuranceCompanyId}
                rows={InsuranceComapnyCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[10]}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px"



                    }
                }

                experimentalFeatures={{ newEditingApi: true }}
                components={{
                    Toolbar: EditToolbar,
                }}
            />
            <AddEditInsuranceCompany handleClose={handleClose} Open={Open} InsuranceCompanyCL={Formdata} handleSubmit={handleSubmit} initValues={initialICData} ></AddEditInsuranceCompany>
        </div>
    )
}