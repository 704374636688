import React, { useEffect, useState } from "react";
import { DataGrid, GridApi, GridColDef, GridEventListener, GridEvents, GridRenderCellParams, GridRowEditStopParams, GridRowId, GridRowModel, GridRowParams, GridToolbar, GridToolbarContainer, GridToolbarFilterButton, GridValueGetterParams, MuiEvent, useGridApiMethod, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button, IconButton, Link, SelectChangeEvent } from "@mui/material";
import { taxesandfeesModel } from "../../models/TaxesAndFeesModel";
import { config } from "../../Constants";
import { AddEditTaxesAndFees } from "../AddEdit/AddEditTaxesAndFees";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from "../../context/AuthProvider";
import moment from "moment";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RiExpandUpDownLine } from "react-icons/ri";
interface Iprops {
    taxesandfessCL: taxesandfeesModel[],
    fetchData: () => void
}

export const TaxesAndFeesList: React.FC<Iprops> = ({ taxesandfessCL, fetchData }) => {

    const { user } = useAuth();
    const token = user?.token;
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const [SelectedMultiLOB, setSelectedMultiLOB] = React.useState<string[] | string>([]);

    const columns: GridColDef[] = [

        {
            field: 'usstate', headerName: 'State', type: 'text', align: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center',
            // valueOptions: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
            flex: 0.7
        },
        {
            field: 'taxName', headerName: 'Tax Name', type: 'text', flex: 1, align: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center',
            // valueOptions: ["DFS Tax", "Excess Lines Tax", "Fire Marshall Tax", "FSLSO Service Fee", "Municipal Premium Tax", "MWUA Policy Fee", "SD Fire Property tax", "SD State Tax", "SDAL Transaction Fee", "SLAS Transaction Fee", "SLSC", "Stamping Fee", "State Premium Tax", "State Tax", "Surcharge", "Surplus Lines Tax", "KS Filing Fee", "KY Premium Surcharge Fee", "MD Corporate Amendment Fee"]
        },


        {
            field: 'rate', headerName: 'Rate', type: 'number', flex: 0.3, align: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center',
        },


        {
            field: 'ratetype', type: 'text', headerName: 'Rate Type', flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.ratetype ? "Dollar" : "Percentage")
        },
        {
            field: 'taxPayee', headerName: 'Tax Payee', flex: 1, type: 'singleSelect', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: 'center', headerAlign: 'center',

        },
        {
            field: 'commaSeparatedLOBIds', headerName: 'Line of Business', width: 180, align: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center',
        },
        {
            field: 'payableto', headerName: 'Payable To', flex: 0.6, align: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center',
        },
        {
            field: 'notes', headerName: 'Notes', flex: 1, align: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center',
        },
        {
            field: 'effectiveDate', type: 'text', headerName: 'Effective Date', flex: 1, headerAlign: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: 'center',
            valueGetter: (params) => {
                if (!params.value) {
                    return params.value;
                }
                return moment(params.row?.effectiveDate).format("MM-DD-YYYY")
            }
        },




        {
            field: 'id',
            flex: 0.7,
            headerName: "Action",
            align: 'center',
            headerAlign: 'center',
            disableColumnMenu: true,
            renderCell: (params) => (<div>
                {user?.userRoles.split(",").includes("Super Admin") ||
                    user?.userRoles.split(",").includes("MISC Filer") ?
                    <IconButton
                        color="primary"
                        onClick={() => { handleEdit(params.row) }}

                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </IconButton >
                    : ""}
                {user?.userRoles.split(",").includes("Super Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.id) }}

                    >
                        {params.row.isDeleted ? <RestoreFromTrashIcon /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </IconButton >
                    : ""}
            </div>)

        },




    ];


    useEffect(() => {
        console.log({ taxesandfessCL, fetchData })
    }, [taxesandfessCL, fetchData])


    const CustomToolbar = () => {
        return (
            <GridToolbar>
                {columns.map((column) =>
                    column.field !== 'id' ? (
                        <GridToolbarFilterButton
                            key={column.field}
                            onClick={() => {
                                // Logic to handle filter button click for other columns
                            }}
                        />
                    ) : null
                )}
            </GridToolbar>
        );
    };

    // Your Grid component...




    const initialLBData: taxesandfeesModel = {
        id: 0,
        usstate: "",
        postalabbreviation: "",
        standardabbreviation: "",
        taxName: "",
        ratetype: 0,
        rate: 0,
        applicableto: "",
        lob: '',
        payableto: "",
        notes: "",
        dateCreated: new Date,
        createdBy: "",
        lastEditBy: "",
        dateModified: new Date,
        effectiveDate: "",
        isDeleted: false,
        stateId: 0,
        lobId: 0,
        commaSeparatedLOBIds: "",
        taxPayee: "",
    }
    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialLBData);


    const handleClickOpen = () => {
        setFormData(initialLBData);
        setOpen(true);
        setSelectedMultiLOB([]);
    };

    const [loading, setLoading] = useState(false);
    const initialdata = { id: 0, businessCode: "" };
    const [LineOfBusiness, setLineOfBusiness] = useState([initialdata]);

    function fetchLOBData(sateid: string) {
        fetch(config.url.API_URL + `/LineOfBusiness/getLineOBusinessByStateId?stateId=${sateid}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response
                
                if (responseJson.data != null) {
                    setLineOfBusiness(responseJson.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };


    const handleEdit = (ILobData: taxesandfeesModel) => {
        //
        fetchLOBData(ILobData.stateId?.toString() ?? "");
        const commaSeparatedLOBIds: string[] = ILobData.lob?.split(',') || [];
        // const commaSeparatedLOBIds: string[] = ILobData.commaSeparatedLOBIds?.split(',') || [];

        const LOBIds: number[] = commaSeparatedLOBIds.map(id => Number(id));
        //const LOBIds: number[] = commaSeparatedLOBIds.map(id => Number(id));

        setLoading(true);
        console.log("handleEit", ILobData);
        let a: taxesandfeesModel = {
            id: ILobData.id,
            stateId: ILobData.stateId,
            lob: ILobData.lob,
            usstate: ILobData.usstate,
            taxName: ILobData.taxName,
            ratetype: ILobData.ratetype,
            rate: ILobData.rate,
            lobId: ILobData.lobId === null ? 0 : ILobData.lobId ?? 0,
            taxPayee: ILobData.taxPayee,

            //lobId: 0,
            //lobId : ILobData.lobId,
            payableto: ILobData.payableto,
            effectiveDate: ILobData.effectiveDate ? ILobData.effectiveDate.toString().split('T')[0] : "",
            notes: ILobData.notes
        }
        let abc = ILobData.lob?.split(',') ?? [];
        //let abc = ILobData.commaSeparatedLOBIds?.split(',') ?? [];

        let f = abc.map(e => e.trim());
        setSelectedMultiLOB(f);

        setFormData(a);
        setOpen(true);
    };



    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    fetch(config.url.API_URL + "/TaxesAndFees/Delete?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((response) => {
                            if (response.ok) {
                                Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                )
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            fetchData();
                            setOpen(false);
                        }).catch((error) => {
                            console.log(error)
                        });

                }
            })
        //call api to save data
        // fetch(config.url.API_URL + "/Users/Delete?id=" + `${id}`,
        //     {
        //         headers: new Headers({
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`,
        //         }),
        //         method: "Delete",
        //     }
        // )
        //     .then((response) => {
        //         if (response.ok) {
        //             return response.json();
        //         }
        //         throw new Error('Something went wrong');
        //     })
        //     .then((res) => {
        //         alert("Data Successfully Deleted")
        //         fetchData();
        //         setOpen(false);
        //     }).catch((error) => {
        //         console.log(error)
        //     });


    };


    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12" style={{ marginBottom: "10px" }}>

                    <div className="col-lg-4 col-md-6   col-sm-6" >
                        <span className="card-title" style={{ fontSize: "32px" }}>Surplus Line Taxes/Fees</span>
                    </div>
                    <div className="col-lg-4 col-md-3 col-sm-6 col-xs-3" style={{ textAlign: "center" }}>
                        <span className="card-title  filter-blue" > <GridToolbarFilterButton /></span>
                    </div>
                    <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12 justify-content-end align-items-center" >
                        <button
                            onClick={handleClickOpen}
                            style={{ marginRight: "10px" }}
                            className=" btn-2 btn d-flex btn-primary surplus-add-btn active-btn-blue "
                        >
                            Add New
                        </button>
                    </div>


                </div>



            </Box>

        );

    }

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <DataGrid

                getRowId={(r) => r.id}
                rows={taxesandfessCL}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}

                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px"
                    }
                }

            />
            <AddEditTaxesAndFees handleClose={handleClose} Open={Open} taxesandfessCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} fetchLOBData={fetchLOBData} LineOfBusiness={LineOfBusiness} setSelectedMultiLOB={setSelectedMultiLOB} SelectedMultiLOB={SelectedMultiLOB}></AddEditTaxesAndFees>

        </div>



    )




}