import { useEffect, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { Button, FormControl, FormHelperText, InputLabel, LinearProgress, Link, MenuItem, Select, SelectChangeEvent, SelectProps, selectClasses } from "@mui/material";
import { RightNav } from "../components/Nav/RightNav";
import { ButlerModel } from "../models/ButlerModel";
import { SurplusLineFormList } from "../components/Lists/SurplusLineFormList";
import { FieldAttributes } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const SurplusLineFormMapping = () => {
    const [SurplusLineFormMappingData, setSurplusLineFormMappingData] = useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const initialdata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialdata]);
    const [SelectedState, setSelectedState] = React.useState<string>('1');

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const fetchStateSurplusForms = (stateId: string) => {
        fetch(config.url.API_URL + `/ButlerMappings/getAllSurplusForm?stateId=${stateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                if (responseJson.data != null) {

                    setSurplusLineFormMappingData(responseJson.data)
                    console.log("statedata123456", responseJson)
                }

            }).catch((error) => {

                console.log(error)

            });
    }
   
    function refreshData() {
        fetchStateSurplusForms(SelectedState);
    }

    const fetchStateData = () => {

        fetch(config.url.API_URL + `/States/getStates`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {

                if (responseJson.data != null) {

                    setStates(responseJson.data)
                    console.log("listdata", responseJson)
                }

            }).catch((error) => {

                console.log(error)

            });
    }
    useEffect(() => {
        fetchStateData();
        fetchStateSurplusForms(SelectedState)


    }, [])

   
    return (       

    <SurplusLineFormList SurplusLineMappingCL={SurplusLineFormMappingData} fetchData={refreshData} selectedState={SelectedState} selectedStatename={States.find(x => x.stateId == Number(SelectedState))?.stateName} fetchStateSurplusForms={fetchStateSurplusForms}  setSelectedState={setSelectedState}SelectedState={SelectedState} States={States} />      

    )

}