import React, { useEffect, useState } from "react";
import { PolicyList } from "../components/Lists/PolicyList";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { config } from "../Constants";
import { BatchInfo } from "../components/InfoPages/BatchInfo";
import { BatchModel } from "../models/BatchModel";
import useAuth from "../context/AuthProvider";
import { RightNav } from "../components/Nav/RightNav";

export const Policy = () => {
    const { user } = useAuth();
    const token = user?.token;
    const initialbatch: BatchModel = { batchId: 0, ipaddress: "", receivedOn: new Date(), muleResponse: "", muleRespondedOn: new Date(), muleErrorCode: 0, muleErrorMessage: "", slaresponse: "", slaerrorCode: 0, slaerrorMessage: "" }
    const [Batchcl, setBatchdetails] = useState(initialbatch);
    const [policies, setpolicies] = useState([])
    const params = useParams();
    const [loading , setloading] = useState(false);


    const fetchData = ()=> {
        setloading(true);
        fetch(config.url.API_URL + `/Policy/getPolicies?batchId=${params.batchId}`,
        {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
        .then((res) => {
            // Do something with the response
            setloading(false);
            setpolicies(res.data)
            console.log(res.data)
        }).catch((error) => {
            setloading(false);
            console.log(error)
        });

        setloading(true);
    fetch(config.url.API_URL + `/Batch/getBatchDetails?batchid=${params.batchId}`,
        {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
        .then((res) => {
            setloading(false);
            // Do something with the response
            setBatchdetails(res.data)
           
        }).catch((error) => {
            setloading(false);
            console.log(error)
        });


    }
    useEffect(() => {
 
        fetchData();

    }, [])

    return (
        <div className='dashboard'>
        {/*    {/* <div className="background-animation">
            <div className="ocean2">
                    <div className="wave2"></div>
            </div>
            <div className="ocean3">
                <div className="wave3"></div>
            </div>
        </div> */} 

        <div className="col-lg-10 col-md-12 col-sm-12 pl-space" >
            <h1 className="dashboard-form-heading text-center">Shield-Knot <span className="sidenav-icon"><i className='bx bx-menu'></i></span></h1>
            <svg className="MobileWaves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"></use>
                </g>
            </svg>
            <div className="row heading align-items-center m-0">
                <div className="col-md-7 col-12">
                    {/* <div className="dashboard-content">
                        JerryMGA <span className="wave-hand"><img src="/assets//brand/hand-icon-removebg-preview.png" className="hand-icon" /></span> Here your day at a glance.
                    </div> */}
                </div>
                <div className="col-md-5 col-12">
                    {/* <div className="search">
                        <i className="fa fa-search"></i>
                        <input type="text" className="searchbox" placeholder="Add a report" />
                        <button className="btn btn-primary"> <i className="fa fa-check"></i></button>
                    </div> */}
                </div>


            </div>
            <div className="card-body">
                <div className="col-lg-12 col col-margin">
                    <div className="card dashboard-table">

                        <span className="card-title"></span>

                        <div className="table-responsive">

                              {loading && <LinearProgress sx={{backgroundColor: "#132D3D"}} />}
                            <BatchInfo Batchcl={Batchcl} />
                            <h1>List of Policies for Batch: {params.batchId} </h1>
                            {loading && <LinearProgress sx={{backgroundColor: "#132D3D"}} />}
                            <PolicyList policyCL={policies} fetchData ={fetchData} />
                      
                            </div>
                        </div>
                    </div>
                </div>

            </div>

               {/* <RightNav /> */}

        </div>

    );

}
