import EditIcon from '@mui/icons-material/Edit';
import { IconButton, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../Constants";
import { PolicyCheckResult } from "../../Pages/PolicyChecksResult";
import useAuth from "../../context/AuthProvider";
import { CustPolicyDetailsModel, newCustPolicyDetailsModel } from "../../models/CustomerPolicyDataModel";
import { FeecomaprisonModel } from "../../models/FeecomparisonModel";
import { InsuredModel } from "../../models/InsuredsModel";
import { RiskLocationAddress } from "../../models/RiskAddressModel";
import { RuleComparsionmodel } from "../../models/StateRuleMatrixModel";
import { TransactionFeeModel } from "../../models/TransactionModel";
import { AddEditInsuredAddress } from "../AddEdit/AddEditInsuredAddress";
import { AddEditRiskAddress } from "../AddEdit/AddEditRiskAddress";
import { AddEditLateFilingReason } from '../AddEdit/AddEditLateFilingReason';
import { LateFilingReasonModel } from '../../models/LateFilingReasonModel';
import { CheckNIPR } from '../../Pages/CheckNIPR';
import { AddEditPolicyNotes } from '../AddEdit/AddEditPolicyNotes';
import Swal from 'sweetalert2';
import { CustomerPolicyDocumentDataList } from '../Lists/CustomerPolicyDocumentDataList';
import { ButlerResponseModel } from '../../models/ButlerResponseModel';
import { AddEditCustomerPolicyStatusLogs } from '../AddEdit/AddEditCustomerPolicyStatusLogs';
import { CustomerPolicyStatusLogsModel } from '../../models/CustomerPolicyStatusLogsModel';
import InfoIcon from '@mui/icons-material/Info';
import formatPrice from '../../helper/currencyFormatter';
import moment from 'moment';

export const PolicyInfo = () => {

      const initialData: CustPolicyDetailsModel = {
      }
      const newinitialData: newCustPolicyDetailsModel = {
            customerPolicyId: 0
      }

      const [newpolicy, newsetpolicies] = React.useState(newinitialData);

      const [policy, setPolicy] = React.useState<CustPolicyDetailsModel>({});
      const [OpenInsuredAddress, setOpenInsuredAddress] = React.useState(false);
      const [OpenRiskLocation, setOpenOpenRiskLocation] = React.useState(false);
      const [OpenPolicyStatusLogs, setOpenPolicyStatusLogs] = React.useState(false);
      const [OpenLateFilingReason, setOpenLateFilingReason] = React.useState(false);

      const [OpenPolicyNote, setOpenPolicyNote] = React.useState(false);


      const params = useParams();
      const [loading, setloading] = useState(false);




      const initialFeecomaprison: FeecomaprisonModel = { message: "INIT", resp: 0, data: { premium: 0, terrorism: 0, adminFee: 0, stateTax: 0, stampingFee: 0, maintenanceFee: 0, total: 0 } }
      // const [feeComparison, setFeecomparison] = useState(initialFeecomaprison);
      const { user } = useAuth();
      const token = user?.token;

      const initialStateRulePolicyFeeData: RuleComparsionmodel = {
            message: "INIT",
            resp: 0,
            data:
            {
                  customerpolicyId: 0,
                  ruleDescription: "",
                  premium: 0,
                  policyFee: 0,
                  operator: "",
                  calculatedPolicyFee: 0,
                  isRuleMatching: false
            }
      }

      const initialInsuredData: InsuredModel = {
            insuredId: 0,
            insuredName: "",
            insuredAddressLine1: "",
            insuredCity: "",
            stateId: 0,
            insuredZipCode: 0,
            createdDate: new Date
      }

      const [InsuredData, SetInsuredData] = React.useState(initialInsuredData);
      // const [FormdataInsured, setFormDataInsured] = React.useState(initialInsuredData);



      var intialRiskData: RiskLocationAddress = {

      }
      // var intialDataCustPolStatusLogs: CustomerPolicyStatusLogsModel[] = {
      //     //  customerPolicyId: null,
      //     //  modifiedDate: null,
      //     //  customerPolicyStatusLogsId: 0

      // }

      function fetchLocationOfRiskData() {
            fetch(config.url.API_URL + `/RiskLocationAddress/getRiskLocation?policyId=${params.policyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })

                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {

                        setloading(false);
                        // Do something with the response
                        if (res.data != null) {

                              SetRiskLocationData(res.data)

                        }
                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });

      }

      function fetchCustomerPolicyStatusLogs() {
            fetch(config.url.API_URL + `/CustomerPolicy/getCustomerPolicyStatusLogs?CustomerPolicyId=${params.customerpolicyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })
                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {
                        setloading(false);
                        if (res.data != null) {
                              SetCustomerPolicyStatusLogsData(res.data)
                        }
                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });
      }

      function fetchMainData() {
            fetch(config.url.API_URL + `/Policy/getPolicyDetailsById?PolicyID=${params.policyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })

                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {
                        setloading(false);
                        // 
                        // Do something with the response
                        if (res.data != null) {
                              newsetpolicies(res.data);
                              // console.log(res.data);

                        }
                        // fetch(config.url.API_URL + `/CustomerPolicy/checkLateFilingByPolicyId?PolicyId=${params.policyid}`, {
                        //       headers: new Headers({
                        //             'Content-Type': 'application/json',
                        //             'Authorization': `Bearer ${token}`,
                        //       })
                        // }).then((response) => {
                        //       if (response.ok) {
                        //             return response.json();
                        //       }
                        //       throw new Error('Something went wrong');
                        // }).then((isLateFilling) => {
                        //      // 
                        //       if (res.data == null) return
                        //       const policy: CustPolicyDetailsModel = res.data
                        //       policy.isLateFilling = isLateFilling?.data ?? false
                        //       if (!isLateFilling) {
                        //             policy.lateFilingReason = ''
                        //       }
                        //       //setPolicy(res.data);
                        // })
                        setPolicy(res.data);

                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });

      }

      const [RiskLocationData, SetRiskLocationData] = React.useState(intialRiskData);
      const [CustomerPolicyStatusLogsData, SetCustomerPolicyStatusLogsData] = React.useState<any>();
      const initLateFilingData: CustPolicyDetailsModel = {};
      const [LateFilingData, SetLateFilingData] = React.useState<CustPolicyDetailsModel>(initLateFilingData);

      useEffect(() => {
            setloading(true);
            fetchLocationOfRiskData();

            fetchMainData()

            setloading(true);


            setloading(true);

            fetch(config.url.API_URL + `/Insured/getByInsuredId?policyId=${params.policyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })

                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {

                        setloading(false);
                        // Do something with the response
                        if (res.data != null) {
                              res.data.premium?.replace('$', '');
                              res.data.stampingFee?.replace('$', '');
                              res.data.total?.replace('$', '');
                              res.data.stateTax?.replace('$', '');

                              SetInsuredData(res.data)
                        }
                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });


            fetch(config.url.API_URL + `/Policy/GetPolicySystemFees?policyId=${params.policyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })
                  })
                  .then((response) => {

                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {

                        setloading(false)

                        // Do something with the response
                        // console.log("policyfeedata", res.data);
                        if (res.data !== null) {
                              setTransactionFees(res.data);
                        }
                  }).catch((error) => {
                        setloading(false)

                        console.log(error)
                  }).then();
            fetchDataDocument();
            fetchPolicyChecksWithDocumentId();

      }, [])




      const fetchData = () => {

            fetch(config.url.API_URL + `/Policy/getPolicyDetailsById?PolicyID=${params.policyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })

                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {
                        setloading(false);
                        // Do something with the response
                        newsetpolicies(res.data);

                        if (res.data == null) return
                        fetch(config.url.API_URL + `/CustomerPolicy/checkLateFilingByPolicyId?PolicyId=${params.policyid}`, {
                              headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                              })
                        }).then((response) => {
                              if (response.ok) {
                                    return response.json();
                              }
                              throw new Error('Something went wrong');
                        }).then((isLateFilling) => {

                              const policy: CustPolicyDetailsModel = res.data

                              setloading(false);
                              if (!isLateFilling) {
                                    policy.isLateFilling = isLateFilling
                                    policy.lateFilingReason = ''
                              }
                              setPolicy(policy)
                        })

                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });

            setloading(true);

            fetch(config.url.API_URL + `/Insured/getByInsuredId?policyId=${params.policyid}`,
                  {
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })

                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {

                        setloading(false);
                        // Do something with the response
                        if (res.data != null) {
                              SetInsuredData(res.data)

                        }
                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });


      }

      const handleDelete = (id: number) => {
            setOpenPolicyNote(false);
            Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                  showCancelButton: true,
                  confirmButtonColor: '#02A4E3',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Delete',
                  customClass: {
                        confirmButton: 'btn-radius',
                        cancelButton: 'btn-radius',
                        popup: 'card-radius'
                  }
            })
                  .then((result) => {
                        if (result.isConfirmed) {
                              newinitialData.customerPolicyId = id;
                              setOpenPolicyNote(false);
                              fetch(
                                    config.url.API_URL + '/Policy/updateCustomerPolicyData',
                                    {
                                          method: 'POST',

                                          headers: new Headers({
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${token}`,
                                          }),
                                          body: JSON.stringify(newinitialData)

                                    })
                                    .then((response) => {
                                          if (response.ok) {
                                                return response.json();
                                          }
                                          throw new Error('Something went wrong');
                                    })
                                    .then((res) => {
                                          // Do something with the response

                                          handleSubmit()
                                          //setSubmitting(true);
                                    }).catch((error) => {
                                          console.log(error)
                                    });
                        }
                  })

      };


      const handleEdit = (row: InsuredModel) => {
            SetInsuredData(InsuredData);
            setOpenInsuredAddress(true);


      };


      const handleRiskLocationEdit = (row: RiskLocationAddress) => {
            SetRiskLocationData(RiskLocationData);
            setOpenOpenRiskLocation(true);

      };
      const handleCustomerPolicyStatusLogs = () => {
            fetchCustomerPolicyStatusLogs();
            setOpenPolicyStatusLogs(true);

      };
      const handleOpenPolicyNote = (row: RiskLocationAddress) => {
            // SetRiskLocationData(RiskLocationData);
            setOpenPolicyNote(true);

      };
      const handleNotesEdit = (row: newCustPolicyDetailsModel) => {
            newsetpolicies(row);

      }

      const handleLateFilingReason = (row: CustPolicyDetailsModel) => {
            // console.log("onedit ", row);
            setOpenLateFilingReason(true);

      };

      const handleClose = () => {

            setOpenInsuredAddress(false);
            setOpenOpenRiskLocation(false);
            setOpenLateFilingReason(false);
            setOpenPolicyNote(false);
            setOpenPolicyStatusLogs(false)

      };

      const handleSubmit = () => {
            fetchData();
            setOpenInsuredAddress(false);
            setOpenOpenRiskLocation(false);
            setOpenPolicyNote(false);
      };


      const handleClickOpen = () => {

            setOpenInsuredAddress(true);
      };


      const initialButlerData: ButlerResponseModel = {
            hasNext: false, hasPrevious: false, totalCount: 1, items: [{
                  blob_Url: "", documentId: "", documentStatus: "", fileName: "", mimeType: "", documentType: "", confidenceScore: "", formFields: [{ fieldName: "", value: "", confidenceScore: "" }],
                  tables:
                        [
                              {
                                    tableName: "",
                                    confidenceScore: "",
                                    rows: [{
                                          cells: [
                                                {
                                                      columnName: "",
                                                      confidenceScore: "",
                                                      value: "",
                                                }]
                                    }]
                              }]
            }
            ]
      }


      const [PolicyCheckData, setPolicyCheckData] = useState<{ documentId: string, fieldName: string, policyChecklistResultId: number, isMatching: boolean, errorMessage: string }[]>([]);


      const [ResponseData, setResponseData] = React.useState([initialButlerData]);

      const fetchDataDocument = () => {

            setloading(true);
            fetch(config.url.API_URL + `/CustomerPolicyDocument/getCustomerPolicyLogbyCustPolicyid?custPolicyId=${params.customerpolicyid}`,
                  {
                        method: "GET",
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })
                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();

                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {
                        setloading(false);
                        // console.log("document log count", res.data);
                        // Do something with the response
                        if (res.data != null) {
                              setResponseData(res.data)
                              // console.log("Butler response", res.data);

                        }

                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });

      }

      const [customerPolicyDetails, setCustomerPolicyDetails] = React.useState<any>();
      const getCustomerPolicyDetails = () => {
            setloading(true);
            fetch(config.url.API_URL + `/ProcessPolicyChecks/getCustomerPolicyDetails?PolicyId=${params.policyid}`,
                  {
                        method: "GET",
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })
                  }).then((response) => {
                        if (response.ok) {

                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((responseJson) => {

                        setloading(false);
                        setCustomerPolicyDetails(responseJson.data);
                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });
      }

      const fetchPolicyChecksWithDocumentId = () => {

            setloading(true);
            fetch(config.url.API_URL + `/ProcessPolicyChecks/getAllPolicyCheckListByCustpolId?customerpolicyid=${params.customerpolicyid}`,
                  {
                        method: "GET",
                        headers: new Headers({
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`,
                        })
                  })
                  .then((response) => {
                        if (response.ok) {
                              return response.json();
                        }
                        throw new Error('Something went wrong');
                  })
                  .then((res) => {
                        setloading(false);
                        // console.log("Checks", res.data);
                        // Do something with the response
                        if (res.data != null) {
                              setPolicyCheckData(res.data);
                              // console.log("FlagDAta",res.data)
                        }

                  }).catch((error) => {
                        setloading(false);
                        console.log(error)
                  });

      }


      useEffect(() => {
            getCustomerPolicyDetails();
            fetchDataDocument();
            fetchPolicyChecksWithDocumentId();
      }, [])


      const initialTransactionFee: TransactionFeeModel = {
            taxName: "", rate: 0, rateType: "", calculatedFee: 0, premium: 0, policyFee: 0, stampingFee: 0,
            techInterfacefee: 0,
            brokerFee: 0,
            transactionType: 0,
            inspectionFee: '',
            stateTax: ''
      }

      // const [Transaction, settransactions] = React.useState(initialTransaction);
      const [TransactionFees, setTransactionFees] = React.useState([initialTransactionFee]);

      const total = (TransactionFees.reduce((total, currentitem) => total = total + currentitem.calculatedFee!, 0));

      return (
            <React.Fragment>
                  <div style={{ marginTop: "4px" }}>
                        {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                  </div>



                  <div className="row row-cols-1 row-cols-md-3 g-4  ">
                        <div className="col-xl-6 col-lg-6 col-sm-12 ">
                              <div className="card dashboard-table  scroller-none-1" style={{ height: "62vh", marginBottom: "8px", marginTop: "8px" }}>

                                    <div className='d-flex  align-items-center justify-content-between'>
                                          <div><span className="card-title">Policy Details</span></div>
                                          <div>
                                                <IconButton title='Status Logs' onClick={() => { handleCustomerPolicyStatusLogs() }}>
                                                      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 4c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm-1 5h2m-2 3h2" />
                                                      </svg> */}
                                                      <InfoIcon />
                                                </IconButton>
                                          </div></div>

                                    <div className="table-responsive">
                                          {
                                                policy ?
                                                      (
                                                            <table className="table">
                                                                  <tbody>

                                                                        <tr className="align-middle">
                                                                              <th>Policy Number</th>
                                                                              <th>{policy?.policyNumber}</th>
                                                                        </tr>

                                                                        {/* <tr className="align-middle">
                                                                                    <td>Effective Date</td>
                                                                                    <td>{policy.custPData.effectiveDate?.toString()}</td>
                                                                              </tr> */}
                                                                        <tr className="align-middle">
                                                                              <td>Policy Period</td>
                                                                              <td>{policy?.policyPeriod}</td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Insured Name</td>
                                                                              <td>{policy?.namedInsured?.toString()}</td>
                                                                        </tr>

                                                                        <tr className="align-middle">
                                                                              <td>Insured Address </td>
                                                                              <td>{InsuredData?.insuredAddressLine1 ?? "" + InsuredData.insuredCity ?? "" + InsuredData.insuredZipCode}
                                                                                    <IconButton

                                                                                          onClick={() => { handleEdit(initialInsuredData) }}

                                                                                    >
                                                                                          <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clipPath="url(#clip0_83_2377)">
                                                                                                      <path d="M12.75 2.25023C12.947 2.05324 13.1808 1.89699 13.4382 1.79038C13.6956 1.68378 13.9714 1.62891 14.25 1.62891C14.5286 1.62891 14.8044 1.68378 15.0618 1.79038C15.3192 1.89699 15.553 2.05324 15.75 2.25023C15.947 2.44721 16.1032 2.68106 16.2098 2.93843C16.3165 3.1958 16.3713 3.47165 16.3713 3.75023C16.3713 4.0288 16.3165 4.30465 16.2098 4.56202C16.1032 4.81939 15.947 5.05324 15.75 5.25023L5.625 15.3752L1.5 16.5002L2.625 12.3752L12.75 2.25023Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                      <clipPath id="clip0_83_2377">
                                                                                                            <rect width="18" height="18" fill="white" />
                                                                                                      </clipPath>
                                                                                                </defs>
                                                                                          </svg>

                                                                                    </IconButton >

                                                                              </td>
                                                                        </tr>


                                                                        <tr className="align-middle">
                                                                              <td>Description of Risk</td>
                                                                              <td>{policy?.descriptionofRisk}</td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Location of Risk</td>
                                                                              <td>{RiskLocationData?.addressLine1 ?? "" + RiskLocationData?.city ?? "" + RiskLocationData?.zipCode ?? ""}
                                                                                    <IconButton
                                                                                          onClick={() => { handleRiskLocationEdit(intialRiskData) }}

                                                                                    >
                                                                                          <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clipPath="url(#clip0_83_2377)">
                                                                                                      <path d="M12.75 2.25023C12.947 2.05324 13.1808 1.89699 13.4382 1.79038C13.6956 1.68378 13.9714 1.62891 14.25 1.62891C14.5286 1.62891 14.8044 1.68378 15.0618 1.79038C15.3192 1.89699 15.553 2.05324 15.75 2.25023C15.947 2.44721 16.1032 2.68106 16.2098 2.93843C16.3165 3.1958 16.3713 3.47165 16.3713 3.75023C16.3713 4.0288 16.3165 4.30465 16.2098 4.56202C16.1032 4.81939 15.947 5.05324 15.75 5.25023L5.625 15.3752L1.5 16.5002L2.625 12.3752L12.75 2.25023Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                      <clipPath id="clip0_83_2377">
                                                                                                            <rect width="24" height="24" fill="white" />
                                                                                                      </clipPath>
                                                                                                </defs>
                                                                                          </svg>

                                                                                    </IconButton >

                                                                              </td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Insurer(s)</td>
                                                                              <td>{newpolicy?.insurers}</td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Filer Notes:</td>
                                                                              <td>{newpolicy?.notes}</td>
                                                                              <div className='d-flex'>
                                                                                    <IconButton
                                                                                          onClick={() => { handleOpenPolicyNote(intialRiskData) }}

                                                                                    >
                                                                                          <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clipPath="url(#clip0_83_2377)">
                                                                                                      <path d="M12.75 2.25023C12.947 2.05324 13.1808 1.89699 13.4382 1.79038C13.6956 1.68378 13.9714 1.62891 14.25 1.62891C14.5286 1.62891 14.8044 1.68378 15.0618 1.79038C15.3192 1.89699 15.553 2.05324 15.75 2.25023C15.947 2.44721 16.1032 2.68106 16.2098 2.93843C16.3165 3.1958 16.3713 3.47165 16.3713 3.75023C16.3713 4.0288 16.3165 4.30465 16.2098 4.56202C16.1032 4.81939 15.947 5.05324 15.75 5.25023L5.625 15.3752L1.5 16.5002L2.625 12.3752L12.75 2.25023Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                      <clipPath id="clip0_83_2377">
                                                                                                            <rect width="18" height="18" fill="white" />
                                                                                                      </clipPath>
                                                                                                </defs>
                                                                                          </svg>

                                                                                    </IconButton >
                                                                                    <IconButton
                                                                                          color="error"
                                                                                          onClick={() => { handleDelete(policy.customerPolicyId ?? 0) }}

                                                                                    >
                                                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                          </svg>

                                                                                    </IconButton >
                                                                              </div>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>First Request:</td>
                                                                              <td>{moment(newpolicy?.firstRequest).isValid() ? moment(newpolicy?.firstRequest).format("MM/DD/YYYY") : ""}</td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Second Request:</td>
                                                                              <td>{moment(newpolicy?.secondRequest).isValid() ? moment(newpolicy?.secondRequest).format("MM/DD/YYYY") : ""}</td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Third Request:</td>
                                                                              <td>{moment(newpolicy?.thirdRequest).isValid() ? moment(newpolicy?.thirdRequest).format("MM/DD/YYYY") : ""}</td>
                                                                        </tr>
                                                                        <tr className="align-middle">
                                                                              <td>Suspended:</td>
                                                                              <td>{moment(newpolicy?.suspended).isValid() ? moment(newpolicy?.suspended).format("MM/DD/YYYY") : ""}</td>
                                                                        </tr>

                                                                  </tbody>
                                                            </table>
                                                      ) : ""
                                          }
                                    </div>
                              </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-sm-12">
                              <div className="card dashboard-table  scroller-none-1" style={{ height: "62vh", marginBottom: "8px", marginTop: "8px" }}>
                                    <span className="card-title">Fee Calculated by System</span>

                                    <div className="table-responsive">
                                          <table className="table">
                                                <tbody>
                                                      {/* <tr className="align-middle">
                                                            <td>Fee Calculated by System</td>
                                                            
                                                </tr> */}
                                                      <tr>
                                                            <th>Fee Name</th>
                                                            <th>Amount</th>
                                                      </tr>

                                                      {
                                                            TransactionFees?.map((row, index) =>
                                                            (
                                                                  <tr key={index}>
                                                                        <td >
                                                                              {row.taxName}
                                                                        </td>
                                                                        <td>{formatPrice(row.calculatedFee?.toFixed(2) ?? "")}</td>

                                                                  </tr>

                                                            ))}
                                                      {TransactionFees[0]?.transactionType !== 8 ?
                                                            <tr>
                                                                  <td>Premium Amount</td>
                                                                  <td>{formatPrice(TransactionFees[0]?.premium.toFixed(2) ?? "")}</td>
                                                            </tr> :
                                                            <tr>
                                                                  <td>Return Premium</td>
                                                                  <td>{formatPrice(TransactionFees[0]?.premium.toFixed(2) ?? "")}</td>
                                                            </tr>
                                                      }


                                                      {TransactionFees[0]?.policyFee !== null ?
                                                            <tr>
                                                                  <td>Policy Fees</td>
                                                                  <td>{formatPrice(TransactionFees[0]?.policyFee?.toString())}</td>
                                                            </tr>
                                                            : ""}
                                                      {TransactionFees[0]?.inspectionFee !== null ?
                                                            <tr>
                                                                  <td>Inspection Fee</td>
                                                                  <td>{formatPrice(TransactionFees[0]?.inspectionFee ?? "")}</td>
                                                            </tr>
                                                            : ""}
                                                      {InsuredData.stateId === 24 && TransactionFees[0]?.stampingFee !== null ?
                                                            <tr>
                                                                  <td>Stamping Fee</td>
                                                                  <td>{formatPrice(TransactionFees[0]?.stampingFee?.toString())}</td>
                                                            </tr>
                                                            : ""}

                                                      {/* { InsuredData.stateId === 24  && TransactionFees[0]?.stateTax !== null ?
                                                            <tr>
                                                                  <td>state Tax</td>
                                                                  <td>${TransactionFees[0]?.stateTax}</td>
                                                            </tr>
                                                            : ""} */}
                                                      <tr>
                                                            <td>Total Amount</td>
                                                            <td>{formatPrice((total + TransactionFees[0]?.premium
                                                                  + Number(TransactionFees[0]?.policyFee)
                                                                  + Number(TransactionFees[0]?.inspectionFee)
                                                                  + Number(InsuredData.stateId === 24 && TransactionFees[0]?.stampingFee)
                                                            ).toString())}
                                                            </td>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <PolicyCheckResult customerPolicyDetails={customerPolicyDetails} fetchMainData={fetchMainData} customerPolicy={policy} handleClose={handleClose} Open={OpenLateFilingReason} latefilinfCL={LateFilingData} handleSubmit={handleSubmit} initValues={initLateFilingData} fetchLocationOfRiskData={fetchLocationOfRiskData} />
                  <AddEditInsuredAddress handleClose={handleClose} Open={OpenInsuredAddress} InsuredCL={InsuredData} handleSubmit={handleSubmit} initValues={initialInsuredData} fetchData={fetchData} />
                  <AddEditRiskAddress handleClose={handleClose} Open={OpenRiskLocation} riskAddressCL={RiskLocationData} handleSubmit={handleSubmit} initValues={intialRiskData} fetchData={fetchLocationOfRiskData} />
                  {/* <AddEditLateFilingReason
                        isOpen={OpenLateFilingReason}
                        policy={policy}
                        onSubmit={handleSubmit}
                        onClose={handleClose}
                  /> */}
                  <AddEditPolicyNotes handleClose={handleClose} Open={OpenPolicyNote} notesCL={policy} handleSubmit={handleSubmit} initValues={policy} />
                  <AddEditCustomerPolicyStatusLogs CustomerPolicyStatusLogsData={CustomerPolicyStatusLogsData} handleClose={handleClose} Open={OpenPolicyStatusLogs} />
                  <CustomerPolicyDocumentDataList customerPolicyDetails={customerPolicyDetails}
                        ResponseData={ResponseData}
                        loading={loading}
                        setloading={setloading}
                        PolicyCheckData={PolicyCheckData}
                        fetchDataDocument={fetchDataDocument}
                        fetchPolicyChecksWithDocumentId={fetchPolicyChecksWithDocumentId}
                  />

                  {/* <CheckNIPR handleClose={handleClose} handleSubmit={handleSubmit} />                                          */}
            </React.Fragment >
      )
}
