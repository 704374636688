import { Box, Button, Card, CardContent, IconButton, LinearProgress, Link, Menu, MenuItem } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { ButlerResponseModel } from "../../models/ButlerResponseModel";
import { ProcessPolicyChecksModel } from "../../models/PolicyCheckResultModel";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FlagIcon from '@mui/icons-material/Flag';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { CustPolicyDetailsModel } from "../../models/CustomerPolicyDataModel";
import { AddEditLateFilingReason } from "../AddEdit/AddEditLateFilingReason";
import { RiExpandUpDownLine } from "react-icons/ri";
import { CustomerPolicyDocumentDataList } from "./CustomerPolicyDocumentDataList";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
interface Iprops {

    ProcessPolicyChecksCL: ProcessPolicyChecksModel[],
    customerPolicy: CustPolicyDetailsModel,
    fetchData: () => void
    latefilinfCL: CustPolicyDetailsModel,
    // handleClose: () => void,
    // Open: boolean,
    // handleSubmit: () => void
    initValues: CustPolicyDetailsModel
    fetchLocationOfRiskData: () => void
    fetchMainData: () => void
    customerPolicyDetails:any

}

export const PolicyCheckList: React.FC<Iprops> = ({customerPolicyDetails, ProcessPolicyChecksCL, fetchData, customerPolicy, latefilinfCL, initValues, fetchLocationOfRiskData, fetchMainData }) => {
   
    const initValue: ProcessPolicyChecksModel = {

        policyCheckListResultCategoryId: 0,
        categoryName: "",
        isMatching: "",
        custPolicyId: 0
    }
    // console.log("customerPolicy  : ", customerPolicy)
    const { user } = useAuth();
    const token = user?.token;

    // const initialLBData: ProcessPolicyChecksModel = {
    //     policyCheckListResultCategoryId: 0,
    //     categoryName: "",
    //     isMathing: ""
    // }

    function truncateString(str: string, num: number) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }
    const [OpenRiskLocation, setOpenOpenRiskLocation] = React.useState(false);


    const handleClose = () => {

        setOpenLateFilingReason(false);
    };



    const handleSubmit = () => {
        fetchData();
        setOpenLateFilingReason(false);
        // setOpenOpenRiskLocation(false);

    };

    const params = useParams();

    // const [Open, setOpen] = React.useState(false);
    // const [Formdata, setFormData] = React.useState(initialLBData);

    // const handleClose = () => {

    //     setOpen(false);
    // };
    // const handleSubmit = () => {
    //     fetchData();
    //     setOpen(false);
    // };
    // const handleClickOpen = () => {
    //     setFormData(initialLBData);
    //     setOpen(true);
    // };

    // function EditToolbar() {

    //     return (
    //         <div >
    //             <Button
    //                 id="demo-positioned-button"
    //                 aria-controls={open ? 'demo-positioned-menu' : undefined}
    //                 aria-haspopup="true"
    //                 aria-expanded={open ? 'true' : undefined}
    //                 onClick={handleClick}
    //             >
    //                 Dashboard
    //             </Button>
    //             <Menu
    //                 id="demo-positioned-menu"
    //                 aria-labelledby="demo-positioned-button"
    //                 anchorEl={anchorEl}
    //                 open={open}
    //                 onClose={handleClose}
    //                 anchorOrigin={{
    //                     vertical: 'center',
    //                     horizontal: 'right',
    //                 }}
    //                 transformOrigin={{
    //                     vertical: 'center',
    //                     horizontal: 'left',
    //                 }}
    //             >
    //                 <MenuItem onClick={handleClose}>Profile</MenuItem>
    //                 <MenuItem onClick={handleClose}>My account</MenuItem>
    //                 <MenuItem onClick={handleClose}>Logout</MenuItem>
    //             </Menu>
    //         </div >


    //     );

    // }

    // const handleEdit = (ILobData: ProcessPolicyChecksModel) => {
    //     setFormData(ILobData);
    //     setOpen(true);
    // };

    const handleFlag = (policycheckCategoryID: number, custPolicyId: number) => {

        fetch(config.url.API_URL + `/ProcessPolicyChecks/UpdateFlagCategory?PolicyCheckListCategoryResultId=${policycheckCategoryID}&&custPolicyId=${custPolicyId}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {

                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                fetchData();


            }).catch((error) => {

                console.log(error)

            });

    };

    const handleSendEmail = (customerPolicyId: number, policyCheckListResultCategoryId: number) => {

        fetch(config.url.API_URL + `/ProcessPolicyChecks/SendChecksEmail?customerPolicyId=${customerPolicyId}&&categoryId=${policyCheckListResultCategoryId}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {

                    return response;
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                alert("Email Sent");
                fetchData();


            }).catch((error) => {

                console.log(error)

            });

    };
    const [LateFilingData, setReason] = React.useState<string>("");
    const [OpenLateFilingReason, setOpenLateFilingReason] = React.useState(false);


    const handleLateFilingReason = (row: CustPolicyDetailsModel) => {

        setReason(row.lateFilingReason ?? "");
        setOpenLateFilingReason(true);

    };
    const initialData: ButlerResponseModel = {
        hasNext: false, hasPrevious: false, totalCount: 1, items: [{
            blob_Url: "", documentId: "", documentStatus: "", fileName: "", mimeType: "", documentType: "", confidenceScore: "", formFields: [{ fieldName: "", value: "", confidenceScore: "" }],
            tables:
                [
                    {
                        tableName: "",
                        confidenceScore: "",
                        rows: [{
                            cells: [
                                {
                                    columnName: "",
                                    confidenceScore: "",
                                    value: "",
                                }]
                        }]
                    }]
        }
        ]
    }


    const [PolicyCheckData, setPolicyCheckData] = useState<{ documentId: string, fieldName: string, policyChecklistResultId: number, isMatching: boolean, errorMessage: string }[]>([]);


    const [loading, setloading] = useState(false);
    const [ResponseData, setResponseData] = React.useState([initialData]);

    

    


    // i want this in another card other from data grid 


    const columns: GridColDef[] = [

        {
            field: 'policyCheckListResultCategoryId', flex: 0.2, headerName: "Actions", hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "left", headerAlign: "center", renderCell: (rowParams) =>
            (

                <div className="d-flex mx-5 align-items-center scroller-none-1">
                    {
                        // user?.userRoles.includes("Super Admin") || user?.userRoles.includes("Outsource Staff") ?
                        <React.Fragment>
                            <IconButton title="Flag/Un-Flag"
                                color="primary"
                                onClick={() => { rowParams.row.categoryName === 'Is Late Filing' ? handleLateFilingReason(customerPolicy) : handleFlag(rowParams.row.policyCheckListResultCategoryId, rowParams.row.custPolicyId) }}

                            >

                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.66669 13.7502C3.66669 13.7502 4.58335 12.8335 7.33335 12.8335C10.0834 12.8335 11.9167 14.6668 14.6667 14.6668C17.4167 14.6668 18.3334 13.7502 18.3334 13.7502V2.75016C18.3334 2.75016 17.4167 3.66683 14.6667 3.66683C11.9167 3.66683 10.0834 1.8335 7.33335 1.8335C4.58335 1.8335 3.66669 2.75016 3.66669 2.75016V13.7502ZM3.66669 13.7502V20.1668" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </IconButton >
                            {rowParams.row.isMatching === "true" ? "" :
                                <IconButton title="Send Flag Communication"
                                    color="primary"
                                    onClick={() => { handleSendEmail(rowParams.row.custPolicyId, rowParams.row.policyCheckListResultCategoryId) }}

                                >
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.25 5.25C19.25 4.2875 18.4625 3.5 17.5 3.5H3.5C2.5375 3.5 1.75 4.2875 1.75 5.25M19.25 5.25V15.75C19.25 16.7125 18.4625 17.5 17.5 17.5H3.5C2.5375 17.5 1.75 16.7125 1.75 15.75V5.25M19.25 5.25L10.5 11.375L1.75 5.25" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </IconButton >
                            }
                            {/* {rowParams.row.policyCheckListResultCategoryId === 9 || rowParams.row.categoryName === 'Is Late Filing' ? ""
                                //      <Link title="Check document"  
                                //      href= {'/AddStateFlags/' + rowParams.row.custPolicyId?.toString()} underline="none"

                                //    >
                                //        <IconButton >
                                //            <ArrowRightAltIcon />
                                //        </IconButton>
                                //    </Link>
                                : */}
                                <Link title="Check document"
                                href={'#newdivPolicy'}
                                // href={'/CustomerPolicyDocumentData/' + rowParams.row.custPolicyId?.toString() + '#' + rowParams.row.policyCheckListResultCategoryId + '@' + params.policyid} underline="none"

                                >
                                    <IconButton

                                        style={{ color: "#02A4E3" }}
                                    >
                                        <ArrowDownwardIcon/>
                                        {/* <svg width="21" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.58331 11.0002H17.4166M17.4166 11.0002L11 4.5835M17.4166 11.0002L11 17.4168" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg> */}
                                        
                                        
                                    </IconButton>
                                    
                                </Link>
                            {/* } */}
                        </React.Fragment>

                        // : ""
                    }

                </div>
            )


        },
        {
            field: 'categoryName', type: 'text', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerName: 'Category Name', flex: 1, headerAlign: "center", align: "center",
        },


        // { field: 'feedData', type: 'text', headerName: 'Sys Calculated', flex: 0.5 },
        // { field: 'fmData', type: 'text', headerName: 'FM Data', flex: 0.5 },
        {
            field: 'isMatching', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'text', headerName: 'Status', flex: 0.5, headerAlign: "center", align: "center", renderCell: (params) =>
            (
                <div>
                    {
                        customerPolicyDetails?.policyStatus ==="Flagged" || customerPolicyDetails?.policyStatus ==="State Flagged" ?(

                            params.row.categoryName === 'Is Late Filing' ? (
                                customerPolicy.isLateFilling !== true ? (
                                    <div className="text-success">Pass</div>
                                ) : (
                                    <div className="text-danger">Fail</div>
                                )
                            ) : (
                                params.row.isMatching === "true" ? (
                                    <div className="text-success">Pass</div>
                                ) : (
                                    <div className="text-danger">Fail</div>
                                )
                            )
                        ):(
                            <div className="text-success">Pass</div>
                        )
                    }
                    

                </div>

            )
        },
        // {
        //     field: 'notes', type: 'text', headerName: 'Notes', flex: 4,
        //     renderCell: (params) =>
        //     (
        //         <div title={params.row.notes}>
        //             {
        //                 params.row.notes != null || undefined ? truncateString(params.row.notes, 70) : ""
        //             }
        //         </div>

        //     )
        // },

    ];
    return (


        <div style={{ height: "66vh", width: '100%' }} className="action-lefft-1  ">
            {/* {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />} */}
            <DataGrid
                getRowId={(r) => r.policyCheckListResultCategoryId}
                rows={ProcessPolicyChecksCL ?? initValue}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                experimentalFeatures={{ newEditingApi: true }}
                // components={{
                //     Toolbar: EditToolbar,
                // }}
                className=""
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px",
                        width: "100%"



                    }
                }
            />
            {/* <AddEditStateRuleMatrix handleClose={handleClose} Open={Open} StateRuleMatrixCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} /> */}
            <AddEditLateFilingReason
                onClose={handleClose}
                isOpen={OpenLateFilingReason}
                policy={customerPolicy}
                onSubmit={handleSubmit}
                fetchMainData={fetchMainData}
            />
          


        </div>




    )
}