import { InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, linearProgressClasses } from "@mui/material";
import { FieldAttributes, useField } from "formik";
import { useEffect, useState } from "react";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { TaxScheduleByJurisdictionModel, TaxScheduleByJurisdictionModel_VS } from "../../models/TaxScheduleByJurisdiction";
import { FMSelectLineOfBusiness, FMSelectRateTypeField, FMSelectState } from "../Elements/select";
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import React from 'react'
import { FMTextField } from '../Elements/input';
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
interface Iprops {
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    fetchData: () => void
    initvalues: TaxScheduleByJurisdictionModel
    setSelectedOption: React.Dispatch<React.SetStateAction<string | string[]>>
    selectedOptions: string[] | string
    LineOfBusiness: { id: number; businessCode: string; }[]
    setLineOfBusiness: React.Dispatch<React.SetStateAction<{ id: number; businessCode: string; }[]>>
    fetchLOBData: (stateId: string) => void
}

export const AddEdittaxScheduleByJurisdiction: React.FC<Iprops> = ({ Open, handleClose, handleSubmit, initvalues, selectedOptions, setSelectedOption, LineOfBusiness, fetchLOBData, setLineOfBusiness }) => {

    const { user } = useAuth();
    const token = user?.token;

    const [loading, setloading] = React.useState(false);
    const [stateId, setstateId] = useState(0);
    const stateData = React.useRef(0);
    const handleChange = (event: SelectChangeEvent) => {
        setloading(true);
        setstateId(Number(event.target.value));
        stateData.current = Number(event.target.value);
        setloading(false);
        fetchLOBData(event.target.value);
    };

    function resetdata() {
        handleClose();
        setstateId(0);
       fetchLOBData(stateId.toLocaleString() ?? "")
    }

    const CustomFMSelectRateTypeField: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (

            <FormControl error={!!errorText} fullWidth className="main-form-2" >
                <InputLabel id="taxtype-select-error-label" >Rate Type</InputLabel>

                <Select {...field} labelId="Select-Label" value={field.value || ""} IconComponent={ExpandMoreIcon} label="Rate Type">

                    <MenuItem value={0}>Percentage</MenuItem>
                    <MenuItem value={1}>Flat</MenuItem>

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>




        )
    }
    const initialStatedata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialStatedata]);
    // const [selectetate, setSelectedState] = useState<number >(initvalues.stateId ?? 5);


    function fetchData() {
        fetch(config.url.API_URL + "/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson.data != null) {
                    setStates(responseJson.data);
                }

                console.log("statesDAta", responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };



    const FMSelectLineOfBusinessCustom: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();

        useEffect(() => {

        }, []);

        return (

            <React.Fragment>

                <FormControl error={!!errorText} fullWidth  >
                    <InputLabel id="State-Select-Label">Select Line Of Business</InputLabel>
                    <Select {...field}
                        labelId="State-Select-Label"
                        label="Select Line Of Business"
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon}
                        value={field.value || ""}
                    >
                        {/* <MenuItem value={"0"}>Select Line Of Business</MenuItem> */}
                        {LineOfBusiness.map((LineOfBusiness, key) => (
                            <MenuItem key={key} value={LineOfBusiness.id}>
                                {LineOfBusiness.businessCode}{" "}
                            </MenuItem>
                        ))}

                    </Select>

                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>

            </React.Fragment>






        );
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className="card-title" >Create New </DialogTitle>

            <React.Fragment>
                <Formik
                    initialValues={initvalues}
                    validateOnChange={true}
                    validationSchema={TaxScheduleByJurisdictionModel_VS}
                    onSubmit={(data, { setSubmitting }) => {
                     
                        console.log("finalDAta", data)
                        fetch(
                            config.url.API_URL + `/TaxScheduleByJurisdiction/AddEditTaxScheduleByJurisdiction`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: JSON.stringify(data)  // <-- Post parameters

                            })
                            .then((response) => {
                                if (response.ok) {
                                    return response.json();

                                }
                                throw new Error('Something went wrong');
                            })
                            .then((res) => {
                                if (res.resp != 1) {
                                    handleSubmit();
                                    setSubmitting(false);
                                    Swal.fire({
                                        title: "Data Successfully saved",
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: "OK",
                                    });
                                    handleClose();
                                } else {

                                    alert(res.message);
                                    setSubmitting(false);
                                }
                            }).catch((error) => {
                                setSubmitting(false);
                                alert("There was an error Creating the Model");

                                console.log(error)

                            });

                    }} >
                    {({ values, isSubmitting, setFieldValue, errors }) =>
                    (
                        <Form className="main-form" >
                            <DialogContent >
                                <Grid container spacing={2}>

                                    <Grid item xs={6}>
                                    <FormControl error={!!errors.stateId} fullWidth>
                                        {/* <FormControl fullWidth className="main-form"> */}
                                        <InputLabel id="State-Select-Label">  Select State</InputLabel>
                                            <Select
                                                labelId="State-Select-Label"
                                                label="Select State"
                                                value={initvalues.stateId || ""}
                                                id="State-Select-Label"
                                                autoWidth
                                                onChange={(e) => {

                                                    setFieldValue("stateId", e.target.value);
                                                   
                                                 
                                                    initvalues.stateId = Number(e.target.value);
                                                    initvalues.lobid = 0;
                                                    fetchLOBData(String(e.target.value));
                                                }}
                                                name="stateId"
                                                // input={<OutlinedInput label="Multiple Select" />}
                                                className="border-round main-form-2"
                                                IconComponent={ExpandMoreIcon}

                                            >
                                                {/* <MenuItem key={0} value={0}>

                                                    Select State{" "}
                                                </MenuItem>
                                                {/* <MenuItem value={"0"}>State</MenuItem> */}
                                                {States.map((state, key) => (
                                                    <MenuItem key={key} value={state.stateId}>

                                                        {state.stateName}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                            <FormHelperText>{errors.stateId}</FormHelperText>
                                            {/* <FormHelperText>{errors.stateId}</FormHelperText> */}
                                        </FormControl>
                                       
                                        {/* <FMSelectStateCustom autoWidth={true} variant={"standard"} name="stateId" /> */}

                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="taxingJurisdiction" autoFocus margin="dense" label="Enter Tax Name" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="jurisdictionCode" autoFocus margin="dense" label="Enter Jurisdiction Code" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="taxCode" autoFocus margin="dense" label="Enter Tax Code" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="rate" autoFocus margin="dense" label="Enter Rate" type="number" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="minTaxFee" autoFocus margin="dense" label="Min Tax Fee" type="number" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            {/* <CustomFMSelectRateTypeField autoWidth={true} name="rateType"></CustomFMSelectRateTypeField> */}
                                            <FMSelectRateTypeField autoWidth={true} name="rateType"></FMSelectRateTypeField>
                                            
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMSelectLineOfBusinessCustom autoWidth={true} variant={"standard"} name="lobid" ></FMSelectLineOfBusinessCustom>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="zipCode" autoFocus margin="dense" label="Enter ZipCode" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn " type='button' onClick={resetdata}  >Cancel</button>
                                <button className="btn  btn-primary active-btn-blue  search-btn " disabled={isSubmitting} >Submit</button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        </Dialog >
    )
}

