import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { InsuranceComapnyList } from "../components/Lists/InsuranceCompanyList";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { RightNav } from "../components/Nav/RightNav";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";



export const InsuranceComapny = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);


    const [InsuranceComapny, setInsuranceComapny] = useState([])

    const params = useParams();

    const fetchData = () => {
        setloading(true)
        fetch(config.url.API_URL + '/InsuranceCompany/getAllInsuranceCompany',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)

                // Do something with the response
                setInsuranceComapny(res.data)
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });

    }

    useEffect(() => {
        fetchData();
    }, [])

    return (

        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

        //                     <LoggedInUserInfo ButtonText="" Link=""/>


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="card dashboard-table">



        //                                     <div className="table-responsive">
        //                                         {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                <InsuranceComapnyList InsuranceComapnyCL={InsuranceComapny} fetchData={fetchData} />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>

    );

}
