import React, { useEffect, useState } from "react";
import { DataGrid, GridApi, GridColDef, GridEventListener, GridEvents, GridRenderCellParams, GridRowEditStopParams, GridRowId, GridRowModel, GridRowParams, GridToolbar, GridToolbarContainer, GridToolbarFilterButton, GridValueGetterParams, MuiEvent, useGridApiMethod, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button, FormControl, IconButton, Link, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { taxesandfeesModel } from "../../models/TaxesAndFeesModel";
import { config } from "../../Constants";
import { AddEditTaxesAndFees } from "../AddEdit/AddEditTaxesAndFees";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from "../../context/AuthProvider";
import moment from "moment";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { RiExpandUpDownLine } from "react-icons/ri";
import { AddEditLicenseContact } from "../AddEdit/AddEditLicenseContact";
import { partnertaxesandfeesModel } from "../../models/PartnerTaxandFeeModel";
import { FieldAttributes } from "formik";
import { PartnerDetails } from "../../Pages/PartnerTaxandFees";


interface Iprops {
    setPartnerTaxesAndFee: React.Dispatch<React.SetStateAction<partnertaxesandfeesModel[]>>
    PartnerTaxesAndFeeCL: partnertaxesandfeesModel[]
}

export interface lobData {
    id?: number;
    businessCode: string;
    coverageName: string;
}


export interface transData {
    transactionId?: number;
    transactionTypeName: string;

}
export const LicenseMatrix: React.FC<Iprops> = ({ PartnerTaxesAndFeeCL, setPartnerTaxesAndFee }) => {

    const { user } = useAuth();
    const token = user?.token;
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }


    const [LOB, setLob] = React.useState<lobData[] | null>([]);

    const [SelectedLOB, setSelectedLOB] = React.useState<string>('');

    const columns: GridColDef[] = [
        {
            field: 'licensee',
            headerName: 'Licensee',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'licenseState',
            headerName: 'License State',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'licenseNumber',
            headerName: 'License Number',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'mainLicensee',
            headerName: 'Main Licensee',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'entity',
            headerName: 'Entity',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'npn',
            headerName: 'NPN',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'fein',
            headerName: 'FEIN',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {
            field: 'cancellationDate',
            headerName:'Cancellation Date',
            type: 'text',
            flex: 1,
            align: 'center',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            headerAlign: 'center'
        },
        {

            field: 'id',
            flex: 0.7,
            headerName: "Action",
            align: 'center',
            headerAlign: 'center',
            disableColumnMenu: true,
            renderCell: (params) => (<div>

                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}

                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </IconButton >
                {/* {user?.userRoles.split(",").includes("Super Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.partnerTaxesandFeeId) }}

                    >
                        {params.row.isDeleted ? <RestoreFromTrashIcon /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </IconButton >
                    : ""} */}

                {user?.userRoles.split(",").includes("Super Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.partnerTaxesandFeeId); }}

                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </IconButton>
                    : ""}
            </div>)

        },


    ];


    const initialLBData: partnertaxesandfeesModel = {
        partnerTaxesandFeeId: 0,
        stateId: 0,
        lobId: 0,
        rate: 0
    }
    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialLBData);


    const handleClickOpen = () => {
        setFormData(initialLBData);
        setOpen(true);
    };

    const initialTransactiondata = { transactionId: 0, transactionTypeName: "" };
    const [TransactionType, setTransactionType] = useState([initialTransactiondata])
    const fetchTransactionData = () => {
        fetch(config.url.API_URL + '/TransactionType/getAllTransactionType',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson.data != null) {
                    // console.log(responseJson.data)
                    setTransactionType(responseJson.data)

                }
            }).catch((error) => {
                console.log(error)
            });
    }


    const [loading, setLoading] = useState(false);
    const initialdata = { id: 0, businessCode: "", coverageName: "" };
    const [Lineofbusiness, setLineOfBusiness] = useState([initialdata]);

    function fetchLOBData(sateid: string) {
        fetch(config.url.API_URL + `/LineOfBusiness/getLineOBusinessByStateId?stateId=${sateid}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response
                // debugger;
                if (responseJson.data != null) {
                    setLineOfBusiness(responseJson.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedLOB('');
        setLoading(false);

    };
    const handleSubmit = () => {
        setLoading(false);
        setOpen(false);
        handleClose();
    };

    const [SelectedLOBName, setSelectedLOBName] = useState<string>("");
    const handleEdit = (ILobData: partnertaxesandfeesModel) => {
        //debugger;
        fetchLOBData(ILobData.stateId?.toString() ?? "");


        console.log("handleEit", ILobData);
        let a: partnertaxesandfeesModel = {
            partnerTaxesandFeeId: ILobData.partnerTaxesandFeeId,
            stateId: ILobData.stateId,
            partnersId: ILobData.partnersId,
            lobId: ILobData.lobId === null ? 0 : ILobData.lobId ?? 0,
            rate: ILobData.rate,
            rateType: ILobData.rateType,
            rateTypeName: ILobData.rateTypeName,
            notes: ILobData.notes,
            transactionTypeId: ILobData.transactionTypeId ?? 0,
            businessCode: ILobData.businessCode ?? ""

        }
        setSelectedLOB(ILobData.lobId?.toString() ?? '0');
        setSelectedLOBName(ILobData.businessCode ?? "");
        setFormData(a);
        setOpen(true);
    };


    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    // const selectedLineOfBusiness = PartnerTaxesAndFeeCL?.find(
                    //     (val) => val.partnerTaxesandFeeId ===id
                    //   );
                    debugger;

                    PartnerTaxesAndFeeCL = PartnerTaxesAndFeeCL.filter(
                        val => val.partnerTaxesandFeeId !== id
                    );
                    setPartnerTaxesAndFee(PartnerTaxesAndFeeCL);
                    // setFormData(PartnerTaxesAndFeeCL??"");
                    // fetch(config.url.API_URL + "/PartnerTaxandFee/Delete?id=" + `${id}`,
                    fetch(config.url.API_URL + "/PartnerTaxandFee/Delete?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "DELETE",
                        }
                    )
                        .then((response) => {
                            debugger;

                            if (response.ok) {
                                Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                )
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // fetchData();
                            setOpen(false);
                            handleClose();
                        }).catch((error) => {
                            console.log(error)
                        });

                }
            })
    };




    function EditToolbar() {

        return (
            <Box
                sx={{

                    borderBottom: 1,
                    borderColor: 'divider',
                    marginRight: "10px"

                }}
            >
                {/* <div className="d-flex justify-content-between align-items-center mb-3" > */}

                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <span className="sub-card-title p-0" >Licensee(s) Contact Information</span>

                        <div>
                        <button
                            // onClick={handleClickOpen}
                            style={{ marginRight: "10px" }}
                            className=" btn-2 btn d-flex btn-primary surplus-add-btn active-btn-blue "


                            onClick={() => {

                                handleClickOpen()
                            }}


                        >
                            Add New
                        </button>
                        <button
                            // onClick={handleClickOpen}
                            style={{ marginRight: "10px" }}
                            className=" btn-2 btn d-flex btn-primary surplus-add-btn active-btn-blue "

                        >
                            Import
                        </button>
                        </div>


                    </div>
{/* 
                </div> */}
            </Box>

        );

    }

    return (
        <div style={{ height: '40vh', width: '100%' }}>
            <DataGrid

                getRowId={(r) => (r.partnerTaxesandFeeId)}
                rows={PartnerTaxesAndFeeCL}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}

                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px"
                    }
                }

            />
            <AddEditLicenseContact handleClose={handleClose}
                Open={Open}
                partnertaxesandfessCL={Formdata}
                handleSubmit={handleSubmit}
                initValues={initialLBData}
                fetchLOBData={fetchLOBData}
                Lineofbusiness={Lineofbusiness}
                setSelectedLOB={setSelectedLOB} SelectedLOB={SelectedLOB}
                setLineOfBusiness={setLob}
                TransactionType={TransactionType}
                fetchTransactionData={fetchTransactionData}
                setPartnerTaxesAndFee={setPartnerTaxesAndFee}
                PartnerTaxesAndFeeCL={PartnerTaxesAndFeeCL}
                SelectedLOBName={SelectedLOBName}
                setSelectedLOBName={setSelectedLOBName}
            />

        </div>



    )




}