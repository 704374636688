import { Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { config } from "../Constants";
import { FMTextField } from "../components/Elements/input";
import React, { useEffect, useRef, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';
import { maxWidth } from "@mui/system";

export interface resetPassword {
    password?: string,
    UserEmail?: string,
    userhash?: string,
    confirmPassword?: string,
}
export const ResetPassword = () => {
    const ref = useRef<HTMLButtonElement>(null);
    const initialValues: resetPassword = {
        password: "",
        UserEmail: "",
        userhash: "",
        confirmPassword: "",
    }
    const getCharacterValidationError = (str: string) => {
        return `Your password must have at least 1 ${str} character`;
    };

    const [infoOpen, setInfoOpen] = useState(false);
    const [dataVisible, setDataVisible] = useState(false);

    const handleInfoClick = () => {
        setInfoOpen(true);
        setDataVisible(true); // To show content when Info icon is clicked
    };

    const handleClose = () => {
        setInfoOpen(false);
        setDataVisible(false); // To hide content when closing the dialog
    };



    const validationSchema = Yup.object({
        // password: Yup.string()
        //     .required("Please enter a password")
        //     // check minimum characters
        //     // .min(8, "Password must have at least 8 characters")
        //     // different error messages for different requirements
        //     .matches(/[0-9]/, getCharacterValidationError("digit"))
        //     .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        //     .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
        //     .matches(/[@$!%*#?&]/, getCharacterValidationError("Special Character"))
        //     .matches(/^(?:(?!123|234|345|456|567|678|789|012).)*$/, getCharacterValidationError("Consecutive digits not allowed"))
        //     .matches(/^(?:(?!(.)\1{2}).)*$/, getCharacterValidationError("Sequence of characters not allowed"))
        //     .matches(/^(?:(?![a-zA-Z]\1\1).)*$/, getCharacterValidationError("Consecutive characters not allowed")),
        password: Yup.string()
            .required("Please enter a password")
            .matches(/[0-9]/, getCharacterValidationError("digit"))
            .matches(/[a-z]/, getCharacterValidationError("lowercase"))
            .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
            .matches(/[@$!%*#?&]/, getCharacterValidationError("Special Character"))
            .matches(/^(?:(?!123|234|345|456|567|678|789|012).)*$/, getCharacterValidationError("Consecutive digits not allowed"))
            .matches(/^(?!.*(123|234|345|456|567|678|789|890|901|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)).*$/, getCharacterValidationError("Sequence of characters not allowed"))
            .matches(/^(?!.*(AAA|BBB|CCC|DDD|EEE|FFF|GGG|HHH|III|JJJ|KKK|LLL|MMM|NNN|OOO|PPP|QQQ|RRR|SSS|TTT|UUU|VVV|WWW|XXX|YYY|ZZZ|ABCDEFGHIJKLMNOPQRSTUVWXYZ)).*$/, getCharacterValidationError("Sequence of uppercase alphabets not allowed"))
            .matches(/^(?:(?![a-zA-Z]\1\1).)*$/, getCharacterValidationError("Consecutive characters not allowed"))
            .matches(/^(?!.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ)).*$/, getCharacterValidationError("Sequence of uppercase characters not allowed")),



        confirmPassword: Yup.string()
            .required("Please re-type your password")
            // use oneOf to match one of the values inside the array.
            // use "ref" to get the value of passwrod.
            .oneOf([Yup.ref("password")], "Passwords does not match"),
    });

    const params = useParams();
    // const navigate = useNavigate();

    useEffect(() => {

        let abc = ref?.current
        if (abc != null) {
            abc.removeAttribute("disabled");
        }

    })


    const [showpassword, setshowpassword] = React.useState<boolean>(false);
    const [showconfirmpassword, setshowconfirmpassword] = React.useState<boolean>(false);
    return (

        <main className="container-fluid py-3">
            <div className="row">

                <div className="d-flex">
                    <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                        <div className="card-body">
                            <div className="col-lg-12 col col-margin">
                                <div className="card dashboard-table">

                                    <div className="table-responsive">
                                        <div className="">
                                            <div className="row col-lg-12">
                                                <div className="col-lg-4 d-flex" style={{ marginBottom: "0px" }}>
                                                </div>
                                                <div className="col-lg-4  mt-4" style={{ textAlign: "center" }}>
                                                    <span className="card-title " ><b>Create Password</b></span>
                                                    {/* <span className="card-title" ><b>Password Changed Sucessfully</b></span> */}
                                                </div>
                                                <div className="col-lg-4" style={{ textAlign: "right" }}>

                                                </div>
                                            </div>
                                            <React.Fragment>
                                                <Formik
                                                    initialValues={initialValues}
                                                    // validateOnChange={true}

                                                    validationSchema={validationSchema}
                                                    onSubmit={(data, { setSubmitting }) => {
                                                        data.userhash = params.UserHash;
                                                        // data.UserEmail = params.Usermail;


                                                        fetch(
                                                            config.url.API_URL + '/Users/ConfirmPassword',
                                                            {
                                                                method: 'POST',
                                                                headers: new Headers({
                                                                    'Content-Type': 'application/json',
                                                                    // 'Authorization': ""
                                                                }),
                                                                body: JSON.stringify(data)

                                                            })
                                                            .then((response) => {
                                                                if (response.ok) {
                                                                    console.log("res",response);
                                                                    return response.json();
                                                                }
                                                                throw new Error('Something went wrong');
                                                            })
                                                            .then((res) => {

                                                                setSubmitting(true);
                                                                // alert("Data Successfully Saved");
                                                                if (res.resp != 1) {
                                                                    window.location.href = "/ThankYouSuccessMsg";

                                                                }
                                                                if (res.resp === 1) {
                                                                    alert("You have already used that password, try another");
                                                                }


                                                            }).catch((error) => {
                                                                console.log(error)

                                                            });

                                                    }} >
                                                    {({ submitForm, isSubmitting, errors }) => {
                                                        return (
                                                            <Form >

                                                                <div className="m-3 row col">


                                                                    <div className="col-lg-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                                                        <Grid container spacing={1} margin={3} justifyContent="center" alignItems="center" >
                                                                            <Grid item xs={8} marginLeft={5}>

                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                    <FMTextField name="password" autoComplete="new-password" autoFocus margin="dense" label="New Password" type={showpassword ? "text" : "password"} fullWidth variant="outlined" sx={{ m: 2 }} InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton edge="end" color="primary"
                                                                                                    onClick={() => { setshowpassword(!showpassword) }}>

                                                                                                    {
                                                                                                        showpassword === true ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                                                                    }
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }} />
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item xs={0} sx={{ display: "flex" }}>
                                                                                <FormControl fullWidth>
                                                                                    <Tooltip
                                                                                        title=""
                                                                                        open={infoOpen}
                                                                                        onClose={() => handleClose()}
                                                                                        disableFocusListener
                                                                                        disableHoverListener
                                                                                        disableTouchListener
                                                                                        placement="right"
                                                                                    >
                                                                                        <IconButton onClick={handleInfoClick} title="password requirement">
                                                                                            <InfoIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            {/* content to be shown in the dialog */}
                                                                            <Dialog open={dataVisible} onClose={handleClose} >
                                                                                {/* <DialogTitle></DialogTitle> */}
                                                                                <DialogContent >
                                                                                    <span>
                                                                                        <p>Password requirement</p>
                                                                                        <li>1 Digit</li>
                                                                                        <li>1 lowercase letter</li>
                                                                                        <li>1 uppercase letter</li>
                                                                                        <li>1 special character [@ $ ! % * # ? &]</li>
                                                                                        <li>No consecutive digits (e.g., 123)</li>
                                                                                        <li>No sequences of characters (e.g., abc, 123)</li>
                                                                                        <li>No consecutive repeating characters (e.g., aaa, BBB)</li>
                                                                                    </span>
                                                                                </DialogContent>
                                                                            </Dialog>

                                                                            <Grid container spacing={1} margin={3} justifyContent="center" alignItems="center" ></Grid>
                                                                            {/* <Grid item xs={8}>

                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                    <FMTextField name="confirmPassword" autoComplete="new-password" autoFocus margin="dense" label="Confirm Password" type={showpassword ? "text" : "password"} fullWidth variant="outlined" sx={{ m: 2 }} InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton edge="end" color="primary"
                                                                                                    onClick={() => { setshowpassword(!showpassword) }}>

                                                                                                    {
                                                                                                        showpassword === true ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                                                                    }
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }} />
                                                                                </FormControl>
                                                                            </Grid> */}
                                                                            <Grid item xs={8}>

                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                    <FMTextField name="confirmPassword" autoComplete="new-password" autoFocus margin="dense" label="Confirm Password" type={showconfirmpassword ? "text" : "password"} fullWidth variant="outlined" sx={{ m: 2 }} InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton edge="end" color="primary"
                                                                                                    onClick={() => { setshowconfirmpassword(!showconfirmpassword) }}>

                                                                                                    {
                                                                                                        showconfirmpassword === true ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                                                                    }
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }} />
                                                                                </FormControl>
                                                                            </Grid>

                                                                            <div className="row col-lg-12 m-3 ">
                                                                                <div className=" m-auto " style={{ maxWidth: "160px" }}>
                                                                                    <button type="button" onClick={submitForm} ref={ref} className="active-btn-blue">Confirm</button>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>

                                                                    </div>
                                                                </div>

                                                            </Form>

                                                        );
                                                    }}
                                                </Formik>
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </main>



    )

}



