import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import React, { useEffect, useMemo, useState } from "react";
import { FieldAttributes, Form, Formik, useField } from "formik";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  LinearProgress,
  FormHelperText,
  Checkbox,
  FormControl,
  Button,
  Link,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from "@mui/material";
import { FMTextField } from "../components/Elements/input";
import { RightNav } from "../components/Nav/RightNav";
import {
  UserMappingRequestLog,
  UserMappingRequestLog_VS,
} from "../models/UserMappingRequestLogModel";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertToRaw,
  EditorState,
  convertFromHTML,
} from "draft-js";
import { useDropzone } from "react-dropzone";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { ReqDocuments } from "./UploadModelDoocuments";
import {
  FMSelectLineOfBusiness,
  FMSelectState,
} from "../components/Elements/select";
import { fontSize, fontWeight } from "@mui/system";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
var formData = new FormData();
const baseStyle = {
  flex: 1,
  display: "flex",
  // alignItems: "center",
  padding: "6px 34px",
  borderWidth: 1,
  borderRadius: 8,
  borderColor: "#162E3C80",
  borderStyle: "dashed",
  backgroundColor: "rgba(22, 46, 60, 0.10)",
  color: "#162E3C",
  outline: "none",
  transition: "border .24s ease-in-out",
  fontSize: "14px",
  fontWeight: "600"
};
const focusedStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
export const AddUserMappingRequestLog = () => {
  const [loading, setloading] = useState(false);
  const { user } = useAuth();
  const token = user?.token;
  const params = useParams();
  const navigate = useNavigate();
  const [Open, setOpen] = React.useState(false);
  let initialValues: UserMappingRequestLog = {
    userMappingRequestLogId: 0,
    carrierName: "",
    lOB: "",
    examplePolicyDocument: "",
    requestMessage: "",
    stateId: 0,
  };
  const goBack = () => {
    navigate(-1);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Reqdocuments: ReqDocuments = {
    documentName: "Corrected Document",
    modelId: 1,
  };
  const [RequireedDocuments, setRequiredDocuments] = useState([Reqdocuments]);
  const [editorState, seteditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );


  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);
  const [selectetate, setSelectedState] = useState<number>(0);
  const initialdata = { id: 0, businessCode: "" };
  const [LineOfBusiness, setLineOfBusiness] = useState([initialdata]);

  function fetchData() {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }

        console.log("statesDAta", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function fetchLOBData(sateid: string) {
    fetch(config.url.API_URL + `/LineOfBusiness/getLineOBusinessByStateId?stateId=${sateid}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setLineOfBusiness(responseJson.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const FMSelectLineOfBusinessCustom: React.FC<FieldAttributes<SelectProps>> = ({
    variant,
    ...props
  }) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    const { user } = useAuth();

    useEffect(() => {

    }, []);

    return (

      <React.Fragment>

        <FormControl error={!!errorText} fullWidth  >
          <FormHelperText className="formtexthelper-heading"  >Line of Business</FormHelperText>
          {/* <InputLabel id="State-Select-Label">Select Line Of Business</InputLabel> */}
          <Select {...field}
            // labelId="State-Select-Label"
            label={null}
            IconComponent={ExpandMoreIcon}
            className="border-round custom-menu-item"
            input={<OutlinedInput />}
            value={field.value || 0}
          >
            <MenuItem value={0}>Line of Business</MenuItem>
            {LineOfBusiness.map((LineOfBusiness, key) => (
              <MenuItem key={key} value={LineOfBusiness.businessCode}>

                {LineOfBusiness.businessCode}{" "}
              </MenuItem>
            ))}

          </Select>

          <FormHelperText>{errorText}</FormHelperText>
        </FormControl>

      </React.Fragment>

    );
  };

  useEffect(() => {
    fetchData();
  }, [])

  function resetdata() {

    setSelectedState(0);
    formData = new FormData();
  }


  function GenericStyledDropZone(props: any & ReqDocuments) {
    const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: {
        "application/pdf": [".pdf"],
        "image/*": [],
        "application/msg": [".msg"],
      },
      onDrop: (files) => {
        formData.delete(props.doc.modelId + "__" + props.doc.documentName);
        formData.delete(props.doc.modelId + "__" + props.doc.documentName);
        formData.append(
          props.doc.modelId + "__" + props.doc.documentName,
          files[0]
        );
        console.log("Dropped files:", files);
      },
    });
    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );


    const files = acceptedFiles.map((file, index) => (
      <React.Fragment key={index}>
        <span style={{}}>
          {file.name.length > 35 ? file.name.substring(0, 35) + '...' : file.name}
        </span>
        {/* {file.name} - {(file.size / 1024).toFixed(2)} kb */}
      </React.Fragment>
    ));

    // const files = acceptedFiles.map
    //   (
    //     file =>
    //     (
    //       <React.Fragment >
    //         <span style={{}}>
    //           {file.name.length > 35 ? file.name.substring(0, 35) + '...' : file.name}
    //         </span>
    //       </React.Fragment>
    //     )

    //   );
    return (
      <React.Fragment>
        <div className=" col-lg-12">
          <div {...getRootProps({ style })}  >
            <input {...getInputProps()} name={props.doc.documentName} />
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <p>Drop Example Document here </p>
            </div>

          </div>
          <span >
            <p style={{ height: "30px", textAlign: "start", margin: 2, fontSize: "14px" }}>{files}</p>
          </span>


        </div>
      </React.Fragment>
    );
  }


  return (

    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={UserMappingRequestLog_VS}
        onSubmit={(data, { setSubmitting, resetForm }) => {

          console.log("finalData", data);

          let count = 0;
          formData.forEach((element) => {
            count = count + 1;
          });


          if (count < 1) {
            alert("select a document");
            setSubmitting(false);
            return;
          }
          console.log("dataaaaa", data);
          setloading(true);
          formData.append("carrierName", data.carrierName ?? "");
          formData.append("lOB", data.lOB ?? "");
          formData.append(
            "examplePolicyDocument",
            data.examplePolicyDocument ?? ""
          );
          formData.append("state", data.stateId?.toString() ?? "");
          formData.append("requestMessage", data.requestMessage ?? "");



          fetch(config.url.API_URL + `/UserMappingRequest/SaveUserRequest`, {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${token}`,
            }),
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error("Something went wrong");
            })
            .then((res) => {

              setSubmitting(false);
              setloading(false);
              console.log(res);
              if (res.resp === 0) {
                Swal.fire({
                  title: "Request sent sucessfully",
                  text: "",
                  icon: "success",
                  confirmButtonText: "OK",
                }).then(() => {
                  window.location.reload(); // Refresh the page
                });
                resetForm();
                resetdata();
                initialValues = initialValues;
              } else {
                alert("There was an Error sending email");
              }
            })
            .catch((error) => {
              setSubmitting(false);
              alert("There was an error Sending the Request");
              console.log(error);
              setloading(false);
            });
        }
        }
      // }
      >
        {({ submitForm, isSubmitting, errors, setFieldValue }) => {
          return (
            <div className=" main-form-input">

              <Grid
                container
                spacing={3}
                xs={10}
                justifyContent="center"
                alignItems="center"
                margin="auto"
              >
                {/* <Grid item xs={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormHelperText className='formtexthelper-heading'>State</FormHelperText>
                    <FMSelectStateCustom
                      className="border-round "
                      autoWidth={true}
                      variant={"standard"}
                      name="stateId"
                    ></FMSelectStateCustom>
                  </FormControl>
                </Grid> */}
                <Grid item xs={10} >
                  <div className="heading-req">
                    <span className="card-title">Request New Carrier</span>
                  </div></Grid>
                <Grid item xl={3} sm={10}>
                  <FormControl error={!!errors.stateId} fullWidth>
                    <FormHelperText className="formtexthelper-heading"  >Select State</FormHelperText>
                    <Select

                      // labelId="State-Select-Label"
                      // label="State"
                      IconComponent={ExpandMoreIcon}
                      label={null}
                      onChange={(e) => {
                        setSelectedState(Number(e.target.value));
                        setFieldValue("stateId", e.target.value);

                        fetchLOBData(e.target.value.toString())
                      }}
                      value={selectetate}
                      name="stateId"
                      className="border-round custom-menu-item"
                      input={<OutlinedInput label="" />}
                    >
                      <MenuItem key={0} value={0}>

                        Select State{" "}
                      </MenuItem>
                      {States.map((state, key) => (
                        <MenuItem key={key} value={state.stateId}>

                          {state.stateName}{" "}
                        </MenuItem>
                      ))}

                    </Select>
                    <FormHelperText>{errors.stateId}</FormHelperText>
                  </FormControl>
                  {/* <FMSelectStateCustom autoWidth={true} variant={"standard"} name="stateId" /> */}

                </Grid>

                <Grid item xl={3} sm={10} >

                  <FormHelperText className='formtexthelper-heading'>Carrier</FormHelperText>
                  <FormControl className="main-form " sx={{ width: '100%' }}  >
                    <FMTextField name="carrierName" className="select-input-border border-round" label="" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                  </FormControl>
                </Grid>

                <Grid item xl={3} sm={10} >
                  <FormControl sx={{ width: "100%" }}>
                    <FMSelectLineOfBusinessCustom
                      name="lOB"
                      type="text"
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={9} sm={10}>
                  <FormControl sx={{ width: "100%" }} className="requestMessage-textfield" >
                    <FormHelperText className='formtexthelper-heading'  >Request Message</FormHelperText>

                    <FMTextField
                      name="requestMessage"
                      multiline
                      rows={8}
                      autoFocus
                      margin="dense"
                      // label="Request Message"
                      type="text"
                      fullWidth
                      variant="outlined"
                      sx={{}}
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item xs={9}>

                  {RequireedDocuments.map((file, key) => (
                    <div className="card pt-3" key={key}>
                      <div className="card-body card-body-dashboard">
                        <GenericStyledDropZone doc={file} />
                      </div>
                    </div>
                  ))}
                </Grid> */}


                <Grid item xs={9}>
                  <FormControl className="required-box">
                    {RequireedDocuments.map((file, Key) => (
                      <div className="card pt-3">
                        <div className=" card-body-dashboard">
                          <GenericStyledDropZone doc={file} Key={Key} name="" />
                        </div>
                      </div>
                    ))}
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  {/* <div className="row col-lg-9 r-2"> */}
                  <button
                    type="submit"
                    onClick={submitForm}
                    className="active-btn-blue "
                    disabled={isSubmitting}
                  >
                    Request
                  </button>
                  {/* </div> */}
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
    //   </div>
    // </div>
  );
};
