import { LinearProgress, FormHelperText, Checkbox, FormControl, Button, Link, Grid, SelectProps, InputLabel, Select, MenuItem, ListItem, Autocomplete, TextField, SelectChangeEvent, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, InputAdornment } from "@mui/material";
import { Formik, Form } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css";
import { FMTextField } from "../Elements/input";
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';

import Swal from "sweetalert2";
import { CustomerPolicyDocument, CustomerPolicyTaxAndFees } from "../../models/TaxTransactionReportModel";
import { CustomerPolicyDetailsModel } from "../../models/CustomerPolicyDetailsModel";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { compactString } from "../../helper/utils";
import { isValid } from "../Elements/PolicyStatuses";
import { isDateGreaterThanToday } from "./CustomFunctions";


export interface LObList {
    id: number;
    businessCode: string;
    coverageName: string;
    stateId: number;
}

export interface ReqDocuments {
    documentName?: string,
    modelId?: number
    isRequired?: boolean
}

export const AddEditCustomerPolicyDetails = () => {

    const [loading, setloading] = useState(true);

    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();
    const isFileMuleUser = user?.userRoles.split(",").includes("Super Admin")

    const initCustomerPolicyDetails: CustomerPolicyDetailsModel = {}
    const [IsSingleDocument, setIsSingleDocument] = useState(true);
    const [CustomerPolicyDetails, setCustomerPolicyDetails] = useState(initCustomerPolicyDetails)
    const [selectedTransaction, setselectedTransaction] = useState<string>('')
    const [selectedLOB, setSelectedLOB] = useState<string>('')
    const [partnerId, setPartnerId] = useState<string>("")
    const [PolicyDocument, setPolicyDocument] = React.useState<CustomerPolicyDocument[]>();
    const [CustTaxAndFees, setCustTaxAndFees] = useState<CustomerPolicyTaxAndFees[]>([]);
    const [taxAndFeeOptions, setTaxAndFeeOptions] = useState([]);
    const [policyNumber, setPolicyNumber] = useState<string>("");
    const [binderFiledDate, setBinderFiledDate] = useState<string>("");

    const [selectedStateOption, setSelectedStateOption] = useState<any>(1);
    const initialStates = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialStates])

    const Reqdocuments: ReqDocuments = {
        documentName: "",
        modelId: 0,
        isRequired: false,

    }

    const [RequireedDocuments, setRequiredDocuments] = useState([Reqdocuments]);

    let initTaxAndFee: CustomerPolicyTaxAndFees = {
        customerPolicyId: 0,
        amount: "",
        payee: "",
        taxName: "",
        paymentPeriod: ""
    }



    const goBack = () => {
        //<Link href="/FileMuleFlagsQueue"></Link>
        //navigate(-1);
        navigate("/");
    }

    const fetchData = () => {
        setloading(true);
        setSelectedLOB('');
        setInsuranceId('');
        // fetch(config.url.API_URL + `/SLIPSubmission/getJsonData?custPolicyId=${params.CustomerPolicyId}`,
        fetch(config.url.API_URL + `/CustomerPolicy/getCustomerPolicyDetails?custPolicyId=${params.CustomerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {

                console.log('custPolicyData: ', res.data)
                setloading(false);
                if (res.data != null) {
                    var data = res.data;

                    //data.policyEffDate = moment(data.policyEffDate).format("MM/DD/YYYY");
                    // data.policyEffDate = data.policyEffDate !== null && data.policyEffDate !== undefined ? moment(data.policyEffDate).format("MM/DD/YYYY") : data.policyEffDate;
                    // data.policyExpDate = data.policyExpDate !== null && data.policyExpDate !== undefined ? moment(data.policyExpDate).format("MM/DD/YYYY") : data.policyExpDate;

                    // data.firstRequest = data.firstRequest !== null && data.firstRequest !== undefined ? moment(data.firstRequest).format("MM/DD/YYYY") : data.firstRequest;
                    // data.secondRequest = data.secondRequest !== null && data.secondRequest !== undefined ? moment(data.secondRequest).format("MM/DD/YYYY") : data.secondRequest;
                    // data.thirdRequest = data.thirdRequest !== null && data.thirdRequest !== undefined ? moment(data.thirdRequest).format("MM/DD/YYYY") : data.thirdRequest;
                    // data.suspended = data.suspended !== null && data.suspended !== undefined ? moment(data.suspended).format("MM/DD/YYYY") : data.suspended;
                    data.policyFilingDueDate = data.policyFilingDueDate !== null && data.policyFilingDueDate !== undefined ? moment(data.policyFilingDueDate).format("MM/DD/YYYY") : data.policyFilingDueDate;
                    // data.invDate = data.invDate !== null && data.invDate !== undefined ? moment(data.invDate).format("MM/DD/YYYY") : data.invDate;
                    setBinderFiledDate(data.filedBinderDate??'');
                    // if (data.filerNotes && !data.fileName) {
                    //     data.filerName = user?.userFirstName || '' + ' ' + user?.userLastName || '';
                    // }

                    // data.grossPremium = formatPrice(data.grossPremium);
                    // data.nonAdmittedPremium = formatPrice(data.nonAdmittedPremium);
                    // data.fee = formatPrice(data.fee);
                    // data.stampingFee = formatPrice(data.stampingFee);
                    // data.stateTax = formatPrice(data.stateTax);
                    // data.taxBalance = formatPrice(data.taxBalance);
                    // data.taxableFees = formatPrice(data.taxableFees);
                    // data.nonTaxableFees = formatPrice(data.nonTaxableFees);

                    // data.filerName = data.filerNotes !==null || data.filerNotes !== '' ? data.filerName = user?.userFirstName || '' + ' ' + user?.userLastName || '' : ""; 
                    data.dateFiled = data.dateFiled !== null && undefined && "" ? moment(data.dateFiled).format("MM/DD/YYYY") : data.dateFiled;
                    // data.invDate = moment(data.invDate).format("MM/DD/YYYY");
                    //setEffectiveDate(data.policyEffDate ?? "");
                    // setExpDate(data.policyExpDate ?? "");
                    // setFilingDueDate(data.policyFilingDueDate ?? "");
                    //setInvoiceDate(data.invDate ?? "");
                    setInsuranceId(data.insuranceCompanyId);
                    setCustomerPolicyDetails(data);
                    setSelectedStateOption(data.stateId);
                    setselectedTransaction(data.transactionId);
                    setPolicyNumber(data.policyNumber);
                    setPartnerId(data.partnersId);
                    setSelectedLOB(data.lobId);
                    handleStateChange(data.stateId);


                    // setFirstRequest(data.firstRequest ?? "");
                    // setSecondRequest(data.secondRequest ?? "");
                    // setThirdRequest(data.thirdRequest ?? "");
                    //setSusupended(data.suspended ?? "");

                    setPolicyStatus(data.status);
                    //setFirstRequest(data.firstRequest ?? "");
                    //  setSecondRequest(data.secondRequest ?? "");
                    //setThirdRequest(data.thirdRequest ?? "");
                    // setSusupended(data.suspended ?? "");
                    // setDateFiled(data.dateFiled)

                }
            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }

    const getPolicyDocuments = () => {
        // 
        // 
        console.log("params", params.CustomerPolicyId);
        fetch(config.url.API_URL + `/DiligentSearchReport/getDiligentSearchDocument?customerPolicyId=${params.CustomerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setPolicyDocument(res.data);
                console.log("policyDocument", res.data);
            }).catch((error) => {

                console.log(error)

            });
    }
    async function getTaxPayeeByTaxType(customerpolicyid: number, taxName: string) {
        try {
            const response = await fetch(`${config.url.API_URL}/TaxesAndFees/GetTaxandFeePayeeByCustTaxFeesId?customerPolicyId=${customerpolicyid}&taxName=${taxName}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const res: CustomerPolicyTaxAndFees = await response.json();
            return res;
        } catch (error) {
            console.log(error);
            return null; // or handle error as needed
        }
    }
    const fetchCustomerPolicyTaxAndFees = () => {
        fetch(config.url.API_URL + `/CustomerPolicyTaxesAndFees/getByCustomerPolicyId?customerPolicyId=${params.CustomerPolicyId}`, {
            // fetch(config.url.API_URL + `/TaxesAndFees/getAllTaxesAndFeesWithLob`, {
            // fetch(config.url.API_URL + `/TaxesAndFees/GetTaxandFeeNameByStateId?customerPolicyId=${params.CustomerPolicyId}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
            .then((res) => {
                setCustTaxAndFees(res.data);
                console.log("taxdata", res.data);
            }).catch((error) => {
                console.log(error)
            });
    }
    const fetchTaxAndFeesOptions = () => {
        // fetch(config.url.API_URL + `/TaxesAndFees/getAllTaxesAndFeesWithLob`, {
        fetch(config.url.API_URL + `/TaxesAndFees/GetTaxandFeeNameByStateId?customerPolicyId=${params.CustomerPolicyId}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                setTaxAndFeeOptions(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchStates();
        fetchData();
        getTransactionType();
        fetchTransactionTypesData();
        fetchCustomerPolicyTaxAndFees();
        fetchTaxAndFeeData();
        fetchTaxAndFeesOptions();
        getPolicyDocuments();


        // fetchRequiredDocumentName();
    }, [])


    const initialInsuranceCompany = { insuranceCompanyId: 0, insuranceCompanyName: "" };
    const [carriers, setCarriers] = useState([initialInsuranceCompany]);
    const initialLOB = { id: 0, businessCode: "" };
    const [LOB, setLOB] = useState([initialLOB]);
    const initialPartners = { partnersId: 0, customerName: "" };
    const [getPartners, setPartners] = useState([initialPartners]);
    const getTransactionType = () => {
        fetch(config.url.API_URL + '/Partners/getPartners',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                if (responseJson.data != null) {
                    setPartners(responseJson.data)
                }
            }).catch((error) => {
                console.log(error)
            });
    }
    interface transactiontypemodel {
        transactionId: number, transactionTypeName: string
    }
    const fetchTransactionTypesData = () => {
        fetch(config.url.API_URL + "/CustomerPolicy/GetTransactionTypeList", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response

                setTransactionType(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    interface propsDoc {
        open: boolean
        handleDocClose: () => void,
        customerPolicyId: any,
        PolicyDocument: CustomerPolicyDocument[] | undefined
    }
    const handleDocClose = () => {

        setShowDoc(false)
        fetchData();
    };

    // const isAdmin = user?.userRoles.split(",").includes("Super Admin")
    const isAdmin = user?.userRoles.split(",").includes("Super Admin")
    const mISCFiler = user?.userRoles.split(",").includes("MISC Filer")
    const MISCStateReporter = user?.userRoles.split(",").includes("MISC State Reporter")

    const isPolicyTBD = compactString(policyNumber) === compactString("TBD") || compactString(policyNumber) === compactString("TBA")
    const isPolicyNumberDisabled = !(isAdmin || isPolicyTBD)
    console.log({ isPolicyTBD })

    const [TransactionType, setTransactionType] = useState<transactiontypemodel[]>();
    const ShowAllDocument: React.FC<propsDoc> = ({ open, handleDocClose, customerPolicyId, PolicyDocument }) => {

        const { user } = useAuth();
        const token = user?.token;

        useEffect(() => {

        }, [])
        return (

            <Dialog open={open} onClose={handleDocClose} fullWidth maxWidth={'md'}>
                {loading && <LinearProgress style={{ margin: 2 }} />}
                <div > <DialogTitle className='card-title'>All Document</DialogTitle></div>
                <div className="">
                    <React.Fragment>
                        <DialogContent>
                            {PolicyDocument && PolicyDocument?.length > 0 ?
                                <table className="table">
                                    <tbody>

                                        {PolicyDocument?.map((value, key) =>
                                            <Grid gridRow={2} container spacing={2}>
                                                <tr key={key}>
                                                    <td>
                                                        <div style={{ maxWidth: '920px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <Grid>

                                                                <Link href={value.blob_Address} sx={{ textDecoration: "none " }} target="_blank">
                                                                    {value.documentName.substring(0, 99)}
                                                                    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" className="" xmlns="http://www.w3.org/2000/svg" ><path d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75" stroke="#02A4E3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                                                                </Link>
                                                            </Grid>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </Grid>
                                        )}
                                    </tbody>
                                </table>
                                : <span>No supportive document found for this transaction.</span>}

                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn  " type="button" onClick={handleDocClose}  >Cancel</button>

                        </DialogActions>
                    </React.Fragment>
                </div>
            </Dialog>
        );
    }


    const fetchStates = () => {

        fetch(config.url.API_URL + '/FillingsByUser/getUserFillingStates',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                setStates(responseJson.data)

            }).catch((error) => {
                console.log(error)
            });
    }

    const handleStateChange = (event: number) => {

        fetch(config.url.API_URL + `/InsuranceCompany/getPartnerInsuranceCompanyByState?stateId=${event}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);

                setCarriers(responseJson.data)
                getLineOfbusiness(event);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    };
    const getLineOfbusiness = (stateId: number | undefined) => {
        fetch(config.url.API_URL + `/LineOfBusiness/getLineOBusinessByStateId?stateId=${stateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                setloading(false);
                setLOB(responseJson.data)

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }

    const [insuranceId, setInsuranceId] = useState<string>('');
    const baseStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#41729F',
        borderStyle: 'dashed',


        backgroundColor: '#fafafa',
        // color: '#bdbdbd',
        color: '#41729F',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    var formData = new FormData();
    interface CustomProps {
        onChange: (event: { target: { name: string; value: string } }) => void;
        name: string;
    }

    const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
        function NumericFormatCustom(props, ref) {
            const { onChange, ...other } = props;

            return (
                <NumericFormat
                    {...other}
                    getInputRef={ref}
                    onValueChange={(values) => {
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value,
                            },
                        });
                    }}
                    thousandSeparator
                    valueIsNumericString
                    prefix="$"
                />
            );
        },
    );



    function GenericStyledDropZone(props: any & ReqDocuments) {
        const maxLength = 20;
        const {
            getRootProps,
            getInputProps,
            acceptedFiles,
            isFocused,
            isDragAccept,
            isDragReject,
        } = useDropzone({
            maxFiles: 10,
            accept: { 'application/pdf': ['.pdf'], 'image/*': [], 'application/msg': ['.msg'] },
            onDrop: (files) => {
                // formData.delete(props?.doc?.documentName);
                // formData.delete(props?.doc?.documentName);
                // files.map((file) => {
                //     // formData.delete(props?.doc?.modelId + "__" + file.name);
                //     formData.append(props?.doc?.modelId + "__" + file.name, file);
                // })
                // // formData.delete(props?.doc?.documentName);
                // // formData.delete(props?.doc?.documentName);
                // // formData.append(props?.doc?.modelId + "__" + props?.doc?.documentName, files[0]);



                files.map((file) => {

                    console.log(file);
                    formData.append(props?.doc?.modelId + "__" + file.name, file);
                });




            }
        });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }), [
            isFocused,
            isDragAccept,
            isDragReject
        ]);


        const files = acceptedFiles.map(file => (
            <React.Fragment >

                <span style={{ fontSize: "14px" }}>
                    {file?.name?.length > 35 ? file?.name?.substring(0, 35) + '...' : file?.name}
                </span>
                <br />
                {/* {file.name} - {(file.size / 1024).toFixed(2)} kb */}
            </React.Fragment>
        ));

        return (
            <React.Fragment>
                <div className=" box-style col-lg-12">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} name={props?.doc?.documentName} />
                        <p style={{ fontSize: "16px", color: "#162E3C", fontWeight: "600" }}>Upload  {props?.doc?.documentName === '' || props?.doc?.documentName === null || props?.doc?.documentName === undefined ? " documents for storage" : props?.doc?.documentName}
                            <span style={{ color: "red" }}>{props?.doc?.isRequired ? "*" : ""}</span></p>
                    </div>
                    <span >
                        <p style={{ height: "20px", textAlign: "start", margin: 2 }}>{files}</p>
                    </span>
                </div>

            </React.Fragment>
        );
    }
    const [showDoc, setShowDoc] = React.useState(false);
    function OpneShowDocument(id: any) {

        setShowDoc(true);
        // getPolicyDocuments(id);
    }

    const handlePatnerChange = (event: SelectChangeEvent) => {

        setPartnerId(event.target.value);
    };
    const handleStateOptionChange = (event: SelectChangeEvent) => {
        setSelectedLOB('');
        setInsuranceId('')
        setSelectedStateOption(event.target.value);
        handleStateChange(Number(event.target.value));
    };
    const handleInsuranceChange = (event: SelectChangeEvent) => {

        setInsuranceId(event.target.value);
    };
    const handleTransactionChange = (event: SelectChangeEvent) => {

        setselectedTransaction(event.target.value);
    };
    const handleLOBChange = (event: SelectChangeEvent) => {
        setSelectedLOB(event.target.value);
    };

    // function effectiveDateStartChnage(e: string) {
    //     setEffectiveDate(e);
    // }
    // function expDataHandleChange(e: string) {
    //     setExpDate(e);
    // }
    // function handleChangeFilingDueDate(e: string) {
    //     setFilingDueDate(e);
    // }
    // function InvoiceDateHandleChange(e: string) {
    //     setInvoiceDate(e);
    // }

    // function dateFiledHandleChange(e: string) {
    //     setDateFiled(e);
    // }
    // function firstRquestHandleChange(e: string) {
    //     setFirstRequest(e);
    // }

    // function secondRquestHandleChange(e: string) {
    //     setSecondRequest(e);
    // }
    // function thirdRquestHandleChange(e: string) {
    //     setThirdRequest(e);
    // }
    // function suspendedHandleChange(e: string) {
    //     setSusupended(e);
    // }
    const [getPolicyStatus, setPolicyStatus] = React.useState<string>('');
    const policyStatus = [
        'Missing Documents', 'Pending Documents', 'Flagged', 'Ready to File', 'Pending Autofile', 'Pending state review', 'State Flagged', 'Filed', 'IsClosed', 'Not Started'
    ]

    const handlePolicyStatus = (event: SelectChangeEvent) => {
        setPolicyStatus(event.target.value);
    };
    interface TaxandFee {

        taxTypeId: number;
        taxName: string;
    }

    let initlistTaxandFee: TaxandFee = {
        taxTypeId: 0,
        taxName: ""
    }


    const [TaxandFees, setTaxandFees] = useState<TaxandFee[]>([initlistTaxandFee]);
    const fetchTaxAndFeeData = () => {
        // 
        //fetch(config.url.API_URL + `/TaxesAndFees/GetTaxandFeeNameByStateId?customerPolicyId=${params.CustomerPolicyId}`, {
        fetch(config.url.API_URL + `/TaxesAndFees/GetTaxandFeeNameandIdByStateId?customerPolicyId=${params.CustomerPolicyId}`, {
            // fetch(config.url.API_URL + `/TaxesAndFees/GetTaxandFeeNameByStateId`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                if (responseJson.data != null) {
                    setTaxandFees(responseJson.data);
                }

                // console.log("TaxandFees", responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleDeleteRow = (index: number) => {
        //
        const updatedArray = CustTaxAndFees.filter((_, idx) => idx !== index);
        setCustTaxAndFees(updatedArray);

    };
    const handleTaxNameChange = async (e: any, index: any) => {
        const newArr = [...CustTaxAndFees];
        newArr[index].taxName = e.target.value;
        try {
            const res = await getTaxPayeeByTaxType(CustomerPolicyDetails?.customerpolicyid ?? 0, e.target.value);
            if (res) {
                newArr[index].payee = res?.payee ?? "";
                var payee = (document?.getElementById("payee" + index) as HTMLInputElement) ?? ""
                var amount = (document?.getElementById("amount" + index) as HTMLInputElement) ?? ""
                var paymentPeriod = (document?.getElementById("paymentPeriod" + index) as HTMLInputElement) ?? ""

                console.log("Tax  Type data changed: " + res);


                newArr[index].amount = res?.amount ?? "";
                amount.value = newArr[index].amount ?? "";
                newArr[index].paymentPeriod = res?.paymentPeriod ?? "";
                setCustTaxAndFees(newArr);

                payee.value = res?.payee ?? "";

                paymentPeriod.value = res?.paymentPeriod ?? "";
            }
        } catch (error) {
            console.log(error);
        }
    };

    function fetchRequiredDocumentName() {
        setloading(true);
        fetch(config.url.API_URL + `/MasterPolicyType/getRequiredDocuments?custPolicyId=${params.CustomerPolicyId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                // Do something with the response

                setRequiredDocuments(res.data);

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }

    const CheckIsSingleDocument = () => {
        setloading(true);
        fetch(config.url.API_URL + `/MasterPolicyType/checkIsSingleDocument?custPolicyId=${params.CustomerPolicyId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {

                // Do something with the response

                setIsSingleDocument(res.data);

                console.log('11111', res.data)
                if (!res.data) {
                    fetchRequiredDocumentName();
                } else {
                    setRequiredDocuments([{ documentName: "Single Document", modelId: 1 }]);
                }

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }

    useEffect(() => {
        if (!params.CustomerPolicyId) return

        CheckIsSingleDocument();
    }, [params]);

    const updateDocuments = (data: any, setSubmitting: any) => {
        let count = 0
        formData.forEach(element => {
            count = count + 1;
        });
        if (count < 1) {
            alert("select a document");
            setSubmitting(false);
            setloading(false);

            return;

        } else {
            setloading(true);
            formData.append('transactionTypeId', data.transactionTypeId.toString() ?? "");
            let ID = params.customerPolicyId?.toString() ?? "";
            formData.append('CustomerPolicyId', ID);

            if (IsSingleDocument) {
                fetch(
                    config.url.API_URL + `/CustomerPolicy/CorrectedDocumentSubmission/`,
                    {
                        method: 'POST',
                        headers: new Headers({
                            // 'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        body: formData  // <-- Post parameters

                    })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();

                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {

                        // Do something with the response
                        setSubmitting(false);

                        setloading(false);
                        console.log(res);
                        if (res.resp == 200) {
                            setloading(false);
                        } else {
                            alert(res.data);
                        }


                        formData = new FormData();

                    }).catch((error) => {
                        setSubmitting(false);
                        alert("There was an error saving the Document");
                        formData = new FormData();
                        console.log(error)
                        setloading(true);
                        setloading(false);
                    });

            } else {
                fetch(
                    config.url.API_URL + `/CustomerPolicy/CorrectedDocumentSubmission/`,
                    {
                        method: 'POST',
                        headers: new Headers({
                            // 'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        body: formData  // <-- Post parameters

                    })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();

                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {

                        // Do something with the response
                        setSubmitting(false);

                        setloading(false);

                        if (res.resp == 200) {
                            setloading(false);
                        } else {
                            alert(res.data);
                        }

                        formData = new FormData();

                    }).catch((error) => {
                        setSubmitting(false);
                        alert("There was an error saving the Document");
                        formData = new FormData();
                        console.log(error)
                        setloading(true);
                        setloading(false);
                    });
            }
        }
    }

    const handlDeletePolicy = () => {
        setloading(true);
        Swal.fire({
            title: 'Are you sure, you want to delete this filing?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    fetch(config.url.API_URL + "/CustomerPolicy/DeleteCustomerPolicy?id=" + `${params.CustomerPolicyId}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((response) => {
                            setloading(false);
                            return response.json();
                        })
                        .then((res) => {
                            if (res.data == "success") {
                                Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success')
                                    .then(() => {
                                        navigate("/");
                                        //  goBack();
                                        // fetchData();

                                    })
                            }
                            setloading(false);
                            // setOpen(false);
                        }).catch((error) => {
                            setloading(false);
                            console.log(error)
                        });
                }
                setloading(false);
            })
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={CustomerPolicyDetails}

                    onSubmit={(data, { setSubmitting }) => {
                        // updateDocuments(data, setSubmitting)
                        setloading(true);
                        //data.dateFiled = "";
                        data.customerpolicyid = Number(params.CustomerPolicyId);
                        data.insuranceCompanyId = Number(insuranceId);
                        data.stateId = selectedStateOption;
                        data.transactionId = Number(selectedTransaction);
                        data.lobId = Number(selectedLOB);
                        //data.policyEffDate = EffectiveDate.toString();
                        //data.policyExpDate = ExpDate.toString();
                        //data.invDate = InvoiceDate.toString();
                        // data.firstRequest = FirstRequest.toString();
                        // data.secondRequest = SecondRequest.toString();
                        // data.thirdRequest = ThirdRequest.toString();
                        // data.suspended = Susupended.toString();
                        //data.dateFiled = DateFiled == null ? DateFiled : DateFiled.toString();
                        data.partnersId = Number(partnerId);

                        data.status = getPolicyStatus;

                        CustTaxAndFees.forEach((element, index) => {
                            element.payee = (document?.getElementById("payee" + index) as HTMLInputElement)?.value ?? ""
                            element.amount = (document?.getElementById("amount" + index) as HTMLInputElement)?.value ?? ""
                        });
                        data.customerPolicyTaxAnFee = CustTaxAndFees;

                        formData.append("data", JSON.stringify(data));
                        console.log("FinalData", formData)


                        fetch(
                            config.url.API_URL + `/CustomerPolicy/UpdateCustomerPolicyDetails`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    // 'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                // body: JSON.stringify(data)  // <-- Post parameters
                                body: formData  // <-- Post parameters

                            })
                            .then((response) => {
                                setloading(false);
                                setSubmitting(false);
                                if (response.ok) {
                                    return response.json();
                                }
                                throw new Error('Something went wrong');
                            })
                            .then((res) => {

                                setSubmitting(false);
                                setloading(false);
                                if (res.resp == 0) {
                                    Swal.fire({
                                        title: res.message,
                                        text: res.data,
                                        icon: res.data,
                                        confirmButtonColor: '#3085d6',
                                        confirmButtonText: 'Ok'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            //fetchData();
                                            navigate(-1);
                                        }
                                    });
                                    // navigate("/8");
                                }
                            }).catch((error) => {
                                setSubmitting(false);
                                alert("There was an error");

                                console.log(error)
                                setloading(false);
                            });

                    }} >
                    {({ submitForm, values, setFieldValue, isSubmitting }) =>
                    (
                        <Form>
                            {loading && <LinearProgress style={{ margin: 2 }} />}
                            <div className=" col-lg-12 main-form">

                                <Grid xs={12} container spacing={2} >

                                    <Grid item xs={12} >
                                        <Grid item xs={12} display='flex' flexDirection='row'>
                                            <span className="card-title mb-4" >Update Transaction Data</span>
                                        </Grid>
                                        <Grid item xs={12}  >
                                            <Grid item xs={12} sx={{ display: "flex" }}>

                                                <Grid item xs={6}>
                                                    <FormControl sx={{ width: '100%' }} className="main-form-2  field-text" >
                                                        <InputLabel id="State-Select-Label" className="border-round ">Select State</InputLabel>
                                                        <Select
                                                            labelId="State-Select-Label"
                                                            onChange={handleStateOptionChange}
                                                            label={"Select State"}
                                                            value={selectedStateOption}
                                                            disabled={!isAdmin}
                                                            IconComponent={ExpandMoreIcon}

                                                        >
                                                            {States?.map((val, key) =>
                                                                <MenuItem key={key} value={val.stateId}> {val.stateName} </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={.3}></Grid>
                                                <Grid item xs={6}>
                                                    <FormControl sx={{ width: '100%' }} className="main-form-2  field-text"  >
                                                        <InputLabel id="demo-simple-select-carrier" className="border-round">Select Carrier</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-carrier"
                                                            onChange={handleInsuranceChange}
                                                            label={"Select Carrier"}
                                                            value={insuranceId}
                                                            IconComponent={ExpandMoreIcon}
                                                            disabled={!isAdmin}
                                                        >
                                                            {carriers?.map((val, key) =>
                                                                <MenuItem key={key} value={val.insuranceCompanyId}> {val.insuranceCompanyName} </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={.3}>

                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl sx={{ width: '100%' }} className="main-form-2  field-text" >
                                                        <InputLabel id="demo-simple-select-partner" className="border-round">Select Client</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-partner"
                                                            onChange={handlePatnerChange}
                                                            label={"Select Client"}
                                                            disabled={!isAdmin}
                                                            value={partnerId}
                                                            IconComponent={ExpandMoreIcon}
                                                        >
                                                            {getPartners?.map((val, key) =>
                                                                <MenuItem key={key} value={val.partnersId}> {val.customerName} </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={.3}>

                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl sx={{ width: '100%' }} className="main-form-2  field-text" >
                                                        <InputLabel id="demo-simple-select-tran" className="border-round">Select Transaction</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-tran"
                                                            onChange={handleTransactionChange}
                                                            label={"Select Transaction"}
                                                            disabled={!isAdmin}
                                                            value={selectedTransaction}
                                                            IconComponent={ExpandMoreIcon}
                                                        >
                                                            {TransactionType?.map((val, key) =>
                                                                <MenuItem key={key} value={val.transactionId}> {val.transactionTypeName} </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={.3}>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl sx={{ width: '100%' }} className="main-form-2  field-text"  >
                                                        <InputLabel id="demo-simple-select-lob" className="border-round">Select line of business</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-lob"
                                                            onChange={handleLOBChange}
                                                            label={"Select line of business"}
                                                            disabled={!isAdmin}
                                                            value={selectedLOB}
                                                            IconComponent={ExpandMoreIcon}


                                                        >
                                                            {LOB?.map((val, key) =>
                                                                <MenuItem key={key} value={val.id}> {val.businessCode} </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={.3}></Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title " >Policy</span>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl
                                            disabled={!isAdmin}
                                            sx={{ width: '100%' }} >
                                            <FormHelperText
                                                className='formtexthelper-heading' >
                                                Invoice Number
                                            </FormHelperText>
                                            <FMTextField name="invoiceNumber"
                                                disabled={!isAdmin}
                                                InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Policy Number
                                            </FormHelperText>
                                            <FMTextField
                                                name="policyNumber"
                                                disabled={isPolicyNumberDisabled}
                                                InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormHelperText className='formtexthelper-heading' >Policy Effective Date</FormHelperText>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            label={null}
                                            disabled={!isAdmin}
                                            className="select-input-border text-input-box"
                                            value={values.policyEffDate ?? ""}
                                            onChange={(v) =>
                                                setFieldValue("policyEffDate", v)
                                            }
                                            // value={EffectiveDate}
                                            // onChange={(v) => effectiveDateStartChnage(v ?? "")}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    id="effectiveDateStart"
                                                    name="policyEffDate" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormHelperText className='formtexthelper-heading' >Expiration Date</FormHelperText>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            label={null}
                                            className="select-input-border text-input-box"
                                            disabled={!isAdmin}
                                            value={values.policyExpDate ?? ""}
                                            onChange={(v) =>
                                                setFieldValue("policyExpDate", v)
                                            }
                                            // value={ExpDate}
                                            // onChange={(v) => expDataHandleChange(v ?? "")}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    name="policyExpDate" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormHelperText className='formtexthelper-heading' >Invoice Date</FormHelperText>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            label={null}
                                            className="select-input-border text-input-box"
                                            //value={InvoiceDate}
                                            disabled={!isAdmin}
                                            value={values.invDate ?? ""}
                                            onChange={(v) =>
                                                setFieldValue("invDate", v)
                                            }
                                            // onChange={(v) => InvoiceDateHandleChange(v ?? "")}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    name="invDate" />
                                            )}
                                        />
                                    </Grid>



                                    {/* <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormHelperText className='formtexthelper-heading' >Date Filed</FormHelperText>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            label={null}
                                            className="select-input-border text-input-box"
                                            value={DateFiled}
                                            disabled={!isAdmin}
                                            onChange={(v) => dateFiledHandleChange(v ?? "")}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    name="dateFiled" />
                                            )}
                                        />
                                    </Grid> */}

                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                NAIC
                                            </FormHelperText>
                                            <FMTextField
                                                name="naic"
                                                disabled={!isAdmin}
                                                InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Filing Due Date
                                            </FormHelperText>
                                            <FMTextField name="policyFilingDueDate" disabled InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                        </FormControl>
                                        {/* <FormHelperText className='formtexthelper-heading' >Filing Due Date</FormHelperText>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            label={null}
                                            className="select-input-border text-input-box"
                                            value={FilingDueDate}
                                            onChange={(v) => handleChangeFilingDueDate(v ?? "")}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    name="policyFilingDueDate" />
                                            )}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title " >Insured</span>
                                    </Grid>

                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Insured Name
                                            </FormHelperText>
                                            <FMTextField
                                                name="insuredName"
                                                disabled={!isAdmin}
                                                InputLabelProps={{ shrink: true }} label={null} type="text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title " >Premium & Taxes</span>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Premium
                                            </FormHelperText>
                                            <FMTextField
                                                label={null}
                                                // value={values.grossPremium}
                                                type="text"
                                                // onChange={(val : any)=>{
                                                //     console.log(val)
                                                // }}
                                                name="grossPremium"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />

                                            {/* <FMTextField
                                                name="grossPremium"
                                                InputLabelProps={{ shrink: true }}
                                                label={null}
                                                type="text"
                                                fullWidth variant="outlined"
                                                sx={{}}

                                            /> */}

                                        </FormControl>
                                    </Grid>

                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Non Admitted Premium
                                            </FormHelperText>
                                            {/* <FMTextField
                                                name="nonAdmittedPremium"
                                                InputLabelProps={{ shrink: true, }}
                                                label={null} type="Text"
                                                fullWidth variant="outlined"
                                            
                                            /> */}
                                            <FMTextField
                                                label={null}
                                                // value={values.nonAdmittedPremium}
                                                type="text"
                                                // onChange={handleChange}
                                                name="nonAdmittedPremium"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Fee
                                            </FormHelperText>
                                            {/* <FMTextField
                                                name="fee"
                                                InputLabelProps={{ shrink: true }}
                                                label={null}
                                                type="Text"
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            
                                            /> */}
                                            <FMTextField
                                                label={null}
                                                value={values.fee}
                                                type="text"
                                                // onChange={handleChange}
                                                name="fee"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Stamping Fee
                                            </FormHelperText>
                                            <FMTextField
                                                label={null}
                                                // value={values.stampingFee}
                                                type="text"
                                                // onChange={handleChange}
                                                name="stampingFee"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                            {/* <FMTextField
                                                name="stampingFee"
                                                InputLabelProps={{ shrink: true }}
                                                label={null} type="Text"
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            
                                            /> */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                State Tax
                                            </FormHelperText>
                                            <FMTextField
                                                label={null}
                                                // value={values.stateTax}
                                                type="text"
                                                // onChange={handleChange}
                                                name="stateTax"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                            {/* <FMTextField
                                                name="stateTax"
                                                InputLabelProps={{ shrink: true }}
                                                label={null}
                                                type="Text"
                                                fullWidth variant="outlined"
                                            
                                            /> */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Tax Balance
                                            </FormHelperText>
                                            <FMTextField
                                                label={null}
                                                // value={values.taxBalance}
                                                type="text"
                                                // onChange={handleChange}
                                                name="taxBalance"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                            {/* <FMTextField
                                                name="taxBalance"
                                                InputLabelProps={{ shrink: true }}
                                                label={null}
                                                type="Text"
                                                fullWidth variant="outlined"
                                            /> */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Taxable Fees
                                            </FormHelperText>
                                            <FMTextField
                                                label={null}
                                                // value={values.taxableFees}
                                                type="text"
                                                // onChange={handleChange}
                                                name="taxableFees"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                            {/* <FMTextField
                                                name="taxableFees"
                                                InputLabelProps={{ shrink: true }}
                                                label={null}
                                                type="Text"
                                                fullWidth variant="outlined"
                                            
                                            /> */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Non Taxable Fees
                                            </FormHelperText>
                                            <FMTextField
                                                label={null}
                                                // value={values.nonTaxableFees}
                                                type="text"
                                                // onChange={handleChange}
                                                name="nonTaxableFees"
                                                id="formatted-numberformat-input"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                                fullWidth variant="outlined"
                                                sx={{}}
                                            />
                                            {/* <FMTextField
                                                name="nonTaxableFees"
                                                InputLabelProps={{ shrink: true }}
                                                label={null}
                                                type="Text"
                                                fullWidth variant="outlined"
                                                 InputProps={{
                                                    inputComponent: NumericFormatCustom as any,
                                                }}
                                            /> */}
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title" >Tax Type</span>
                                        {CustTaxAndFees?.length >= taxAndFeeOptions?.length ? "" :
                                            <button
                                                className="btn active-btn-blue"
                                                type="button"
                                                style={{ marginLeft: '50px', marginTop: '15px' }}
                                                onClick={() => {
                                                    // if (CustTaxAndFees.length >= taxAndFeeOptions.length) return
                                                    setCustTaxAndFees(filingContactArry => [...filingContactArry, initTaxAndFee])
                                                }}
                                                disabled={!isAdmin}
                                            >
                                                Add More
                                            </button>
                                        }

                                    </Grid> */}
                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title">Tax Type</span>
                                        {
                                            (isAdmin || mISCFiler || MISCStateReporter) ? (
                                                <button
                                                    className="btn active-btn-blue"
                                                    type="button"
                                                    style={{ marginLeft: '50px', marginTop: '15px' }}
                                                    onClick={() => {
                                                        setCustTaxAndFees(filingContactArry => [...filingContactArry, initTaxAndFee])
                                                    }}
                                                    disabled={false} // Button will be enabled if the user is an admin or has the specified roles
                                                >
                                                    Add More
                                                </button>
                                            ) : null
                                        }
                                    </Grid>

                                    {(CustTaxAndFees ?? []).map((taxAndFee, index) => (
                                        <React.Fragment key={index}>

                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >
                                                    Tax Type
                                                </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >
                                                    {/* <FMSelectTaxAndFeesustom autoWidth={true} name="taxName" key={"taxName" + index} defaultValue={taxAndFee.taxName}></FMSelectTaxAndFeesustom> */}
                                                    <Select
                                                        value={taxAndFee.taxName}
                                                        id="test-select-label"
                                                        label={null}
                                                        onChange={(e) => handleTaxNameChange(e, index)}
                                                        // disabled={!isFileMuleUser}
                                                         disabled={
                                                            !(
                                                                user?.userRoles.split(",").includes("Super Admin") ||
                                                                user?.userRoles.split(",").includes("MISC Filer") ||
                                                                user?.userRoles.split(",").includes("MISC State Reporter")
                                                            )
                                                        }
                                                        name="TaxAndFee"
                                                        className="border-round custom-menu-item"
                                                        IconComponent={ExpandMoreIcon}
                                                        displayEmpty
                                                    >
                                                        {(taxAndFeeOptions ?? []).map((fee, key) => (
                                                            <MenuItem key={key} value={fee}  >
                                                                {fee}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >
                                                    Tax Payee
                                                </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <TextField name={"payee" + index}
                                                        // disabled={!(user?.userRoles.split(",").includes("Super Admin"))}
                                                        disabled={
                                                            !(
                                                                user?.userRoles.split(",").includes("Super Admin") ||
                                                                user?.userRoles.split(",").includes("MISC Filer") ||
                                                                user?.userRoles.split(",").includes("MISC State Reporter")
                                                            )
                                                        }
                                                        key={"payee" + index}
                                                        id={"payee" + index}
                                                        defaultValue={taxAndFee.payee} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >
                                                    Amount
                                                </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <TextField
                                                        name={"amount" + index}
                                                        id={"amount" + index}
                                                        key={"amount" + index}
                                                        defaultValue={taxAndFee.amount}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustom as any,
                                                        }}

                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>

                                            </Grid>

                                        </React.Fragment>
                                    ))}



                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title" >Notes</span>
                                    </Grid>


                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Client Notes
                                            </FormHelperText>
                                            <FMTextField name="clientNotes"
                                                InputLabelProps={{ shrink: true }}
                                                label={null} type="Text"
                                                fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Client div
                                            </FormHelperText>
                                            <FMTextField name="clientDiv" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title" >Status</span>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Filer Notes
                                            </FormHelperText>
                                            <FMTextField name="filerNotes"
                                                InputLabelProps={{ shrink: true }}
                                                label={null} type="Text"
                                                fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Filer Name
                                            </FormHelperText>
                                            <FMTextField name="filerName" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormHelperText className='formtexthelper-heading' >Date Filed</FormHelperText>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            label={null}
                                            className="select-input-border text-input-box"
                                            // value={DateFiled}
                                            // onChange={(v) => dateFiledHandleChange(v ?? "")}
                                            value={values.dateFiled ?? ""}
                                            onChange={(v) =>
                                                setFieldValue("dateFiled", v)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    {...params}
                                                    name="dateFiled" />
                                            )}
                                        />
                                    </Grid>
                                    {/* {CustomerPolicyDetails.policySubStatus === 'Filed on Binder' ? */}
                                    {/* {binderFiledDate !== '' && binderFiledDate !== undefined && binderFiledDate !== null  ? */}
                                    {isValid(binderFiledDate)  ?
                                        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                            <FormHelperText className='formtexthelper-heading'>Filed with Binder Date</FormHelperText>
                                            <DatePicker
                                                inputFormat="MM/DD/YYYY"
                                                label={null}
                                                className="select-input-border text-input-box"
                                                value={values.filedBinderDate ?? ""}
                                                onChange={(v) =>
                                                    setFieldValue("filedBinderDate", v)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        {...params}
                                                        name={"filedBinderDate"} />
                                                )}
                                            />
                                        </Grid>
                                        : ""}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >First Request</FormHelperText>
                                                <DatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    label={null}
                                                    // disabled={!isAdmin}
                                                    className="select-input-border text-input-box"
                                                    shouldDisableDate={isDateGreaterThanToday}
                                                    value={values.firstRequest ?? ""}
                                                    onChange={(v) =>
                                                        setFieldValue("firstRequest", v)
                                                    }
                                                    // value={FirstRequest}
                                                    // onChange={(v) => firstRquestHandleChange(v ?? "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            name="firstRequest" />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >Second Request</FormHelperText>
                                                <DatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    label={null}
                                                    //disabled={!isAdmin}
                                                    className="select-input-border text-input-box"
                                                    shouldDisableDate={isDateGreaterThanToday}
                                                    value={values.secondRequest ?? ""}
                                                    onChange={(v) =>
                                                        setFieldValue("secondRequest", v)
                                                    }
                                                    // value={SecondRequest}
                                                    // onChange={(v) => secondRquestHandleChange(v ?? "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            name="secondRequest" />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >Third & Final Request</FormHelperText>
                                                <DatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    label={null}
                                                    //disabled={!isAdmin}
                                                    className="select-input-border text-input-box"
                                                    shouldDisableDate={isDateGreaterThanToday}
                                                    value={values.thirdRequest ?? ""}
                                                    onChange={(v) =>
                                                        setFieldValue("thirdRequest", v)
                                                    }
                                                    // value={ThirdRequest}
                                                    // onChange={(v) => thirdRquestHandleChange(v ?? "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            name="thirdRequest" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                <FormHelperText className='formtexthelper-heading' >Suspended</FormHelperText>
                                                <DatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    label={null}
                                                    //disabled={!isAdmin}
                                                    className="select-input-border text-input-box"
                                                    shouldDisableDate={isDateGreaterThanToday}
                                                    value={values.suspended ?? ""}
                                                    onChange={(v) =>
                                                        setFieldValue("suspended", v)
                                                    }
                                                    // value={Susupended}
                                                    // onChange={(v) => suspendedHandleChange(v ?? "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            name="suspended" />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} display='flex' flexDirection='row'>
                                        <span className="sub-card-title " >Upload Documents </span>
                                    </Grid>
                                    {RequireedDocuments && RequireedDocuments.length > 0 ?
                                        <Grid container gap={5} xs={6}>
                                            {
                                                RequireedDocuments?.map((file, key) => (
                                                    <div className='col-lg-4 col-sm-12 gx-5'>
                                                        <div className="card col-lg-12 m-2">
                                                            <div className=" ">
                                                                <GenericStyledDropZone Key={key} doc={file} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <Grid item xs={8}>
                                                <GenericStyledDropZone Key={1} doc={Reqdocuments} />
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <DialogTitle className="col-log-2">No mapping present for this transaction, you can upload supportive document here.</DialogTitle>
                                                <Grid item xs={3}>
                                                    <GenericStyledDropZone Key={1} doc={Reqdocuments} />
                                                </Grid>
                                            </Grid>
                                            {PolicyDocument && PolicyDocument.length > 0 ?
                                                <Grid item xs={12}>
                                                    <DialogTitle>Documents present for this transaction.</DialogTitle>
                                                </Grid>
                                                : ""}
                                            <Grid item xs={8}>
                                                <div className="card">
                                                    <React.Fragment>
                                                        <DialogContent>
                                                            {PolicyDocument && PolicyDocument.length > 0 ? (
                                                                <table className="table">
                                                                    <tbody>
                                                                        {PolicyDocument.map((value, key) => (
                                                                            <tr key={key}>
                                                                                <td>
                                                                                    <div
                                                                                        style={{
                                                                                            maxWidth: "920px",
                                                                                            overflow: "hidden",
                                                                                            textOverflow: "ellipsis",
                                                                                        }}
                                                                                    >
                                                                                        <Link
                                                                                            href={value.blob_Address} sx={{ textDecoration: "none " }} target="_blank"
                                                                                        >
                                                                                            {value.documentName.substring(0, 99)}
                                                                                            <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75"
                                                                                                    stroke="#02A4E3"
                                                                                                    strokeWidth="2"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                ></path>
                                                                                            </svg>
                                                                                        </Link>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <span>No previously uploaded supportive document found for this transaction.</span>
                                                            )}
                                                        </DialogContent>

                                                    </React.Fragment>
                                                </div>
                                            </Grid>
                                        </Grid>

                                    }

                                    <Grid item xs={12}>
                                        {RequireedDocuments && RequireedDocuments?.length > 0 ?
                                            <Grid item xs={6} display='flex' flexDirection='row'>
                                                <span className="sub-card-title " >Upload Mapped Documents </span>
                                            </Grid>
                                            : ""}
                                        {RequireedDocuments && RequireedDocuments?.length > 0 ?
                                            <Grid container gap={.3} xs={12}>
                                                {
                                                    RequireedDocuments?.map((file, key) => (
                                                        <Grid item xs={2.9}>
                                                            <div className="card  m-0">

                                                                <GenericStyledDropZone Key={key} doc={file} />

                                                            </div>
                                                        </Grid>
                                                    ))

                                                }
                                                <div className="row col-12 mt-4">

                                                    <Grid item xs={6}>
                                                        <span className="sub-card-title ms-3 " >Storage Document</span>
                                                        <div className="card m-0">

                                                            <GenericStyledDropZone Key={1} doc={Reqdocuments} />

                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={6}>

                                                        <span className="sub-card-title ms-3" >Documents present for this transaction.</span>

                                                        <div className="card m-0">
                                                            <React.Fragment>
                                                                <DialogContent style={{ height: "120px", overflow: "scroll", padding: "0px 10px" }}>
                                                                    {PolicyDocument && PolicyDocument?.length > 0 ? (
                                                                        <table className="table">
                                                                            <tbody>
                                                                                {PolicyDocument?.map((value, key) => (
                                                                                    <tr key={key}>
                                                                                        <td>
                                                                                            <div
                                                                                                style={{
                                                                                                    maxWidth: "920px",
                                                                                                    overflow: "hidden",
                                                                                                    textOverflow: "ellipsis",
                                                                                                }}
                                                                                            >
                                                                                                <Link
                                                                                                    href={value.blob_Address} sx={{ textDecoration: "none " }} target="_blank"
                                                                                                >
                                                                                                    {value.documentName.substring(0, 99)}
                                                                                                    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path
                                                                                                            d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75"
                                                                                                            stroke="#02A4E3"
                                                                                                            strokeWidth="2"
                                                                                                            strokeLinecap="round"
                                                                                                            strokeLinejoin="round"
                                                                                                        ></path>
                                                                                                    </svg>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    ) :
                                                                        <Grid style={{ textAlign: 'center', paddingTop: 50 }}>
                                                                            <div className="col-log-10 text-center m-20">
                                                                                No Data found
                                                                            </div>
                                                                        </Grid>

                                                                    }
                                                                </DialogContent>

                                                            </React.Fragment>
                                                        </div>
                                                    </Grid>
                                                </div>

                                            </Grid>



                                            :
                                            <Grid container spacing={2}>


                                                <div className="row col-12 mt-4">

                                                    <Grid item xs={6}>
                                                        <span className="sub-card-title ms-3 " >Storage Document</span>
                                                        <div className="card m-3">

                                                            <GenericStyledDropZone Key={1} doc={Reqdocuments} />

                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={6}>

                                                        <span className="sub-card-title ms-3" >Documents present for this transaction.</span>

                                                        <div className="card m-3">
                                                            <React.Fragment>
                                                                <DialogContent style={{ height: "120px", overflow: "scroll", padding: "0px 10px" }}>
                                                                    {PolicyDocument && PolicyDocument?.length > 0 ? (
                                                                        <table className="table">
                                                                            <tbody>
                                                                                {PolicyDocument?.map((value, key) => (
                                                                                    <tr key={key}>
                                                                                        <td>
                                                                                            <div
                                                                                                style={{
                                                                                                    maxWidth: "920px",
                                                                                                    overflow: "hidden",
                                                                                                    textOverflow: "ellipsis",
                                                                                                }}
                                                                                            >
                                                                                                <Link
                                                                                                    href={value.blob_Address} sx={{ textDecoration: "none " }} target="_blank"
                                                                                                >
                                                                                                    {value.documentName.substring(0, 99)}
                                                                                                    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path
                                                                                                            d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75"
                                                                                                            stroke="#02A4E3"
                                                                                                            strokeWidth="2"
                                                                                                            strokeLinecap="round"
                                                                                                            strokeLinejoin="round"
                                                                                                        ></path>
                                                                                                    </svg>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    ) :
                                                                        <Grid style={{ textAlign: 'center', paddingTop: 50 }}>
                                                                            <div className="col-log-10 text-center m-20">
                                                                                No Data found
                                                                            </div>
                                                                        </Grid>

                                                                    }
                                                                </DialogContent>

                                                            </React.Fragment>
                                                        </div>
                                                    </Grid>
                                                </div>


                                            </Grid>

                                        }
                                    </Grid>


                                    <Grid item xs={12} className="d-flex">
                                        <div className="d-flex">
                                            <div style={{ display: "flex", marginBottom: "20px" }}>
                                                {user?.userRoles.split(",").includes("Super Admin") ||
                                                    user?.userRoles.split(",").includes("MISC Filer") ||
                                                    user?.userRoles.split(",").includes("MISC State Reporter")
                                                    ?
                                                    <button className="active-btn-blue me-2" type='submit' disabled={isSubmitting} >Save</button>
                                                    :
                                                    <button className="active-btn-blue me-2" disabled >Save</button>
                                                }
                                            </div>
                                            <div style={{ display: "flex", marginBottom: "20px" }}>
                                                {user?.userRoles.split(",").includes("Super Admin") ?
                                                    <button className="active-btn-blue" type="button" onClick={handlDeletePolicy}>Remove Policy</button>
                                                    : ""
                                                }
                                                {loading && <CircularProgress style={{ margin: 2 }} />}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    )}
                </Formik>
                <ShowAllDocument open={showDoc} PolicyDocument={PolicyDocument} handleDocClose={handleDocClose} customerPolicyId={CustomerPolicyDetails.customerpolicyid}></ShowAllDocument>
            </React.Fragment>
        </LocalizationProvider >
    )
}