import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { TransactionTypeList } from "../components/Lists/TransactionTypeList";
import { UsersList } from "../components/Lists/UsersList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";


export const TransactionType = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [TansactionType, setTransactionType] = useState([])

    const fetchData = () => {
        setloading(true)

        fetch(config.url.API_URL + '/TransactionType/getAllTransactionType',
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                // Do something with the response
                setTransactionType(res.data)
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });


    }

    useEffect(() => {
        fetchData();
    }, [])


    return (

        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>

        //                         <LoggedInUserInfo ButtonText="Add New" Link="" />


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>

        //                                     <div style={{padding : "1rem" , paddingTop : "2rem", paddingBottom: "4rem"}}>

        //                                         <div className="table-responsive">
        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                    <TransactionTypeList TransactionTypeCL={TansactionType} fetchData={fetchData} />
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>


    )

}