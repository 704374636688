interface ColumnItem {
    id: number
    field: string
    xlsHeader: string[]
    inputType: string
    excelType?: number
    duplicateId?: number | number[]
}

export const excelItemsShort = [
    {
        excelType: 1,
        name: 'FilingQueueData',
        columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    }
]
export const excelItemsDetailed = [
    {
        excelType: 1,
        name: 'TaxTransactionReports',
        //columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],

        // old  // columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
    }
]
export const excelItemsAll = [
    {
        excelType: 1,
        name: 'AllFilingQueueData',
        columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    }
]
export const columnListShort: ColumnItem[] = [
    {
        id: 0,
        field: 'dueDate',
        xlsHeader: ['FilingDueDate'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 1,
        field: 'agency',
        xlsHeader: ['Client'],
        inputType: 'string',
    },

    {
        id: 2,
        field: 'invoiceNumber',
        xlsHeader: ['InvoiceNumber'],
        inputType: 'string',
    },

    {
        id: 3,
        field: 'insuredName',
        xlsHeader: ['InsuredName'],
        inputType: 'string',
    }, {
        id: 4,
        field: 'policyNumber',
        xlsHeader: ['PolicyNumber'],
        inputType: 'string',
    },

    {
        id: 5,
        field: 'homeState',
        xlsHeader: ['HomeState'],
        inputType: 'string',
    },

    {
        id: 6,
        field: 'transactionTypeName',
        xlsHeader: ['TransactionType'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 7,
        field: 'taxType',
        xlsHeader: ['TaxType'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 8,
        field: 'invoiceDate',
        xlsHeader: ['InvoiceDate'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 9,
        field: 'effectiveDate',
        xlsHeader: ['PolicyEffectiveDate'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 10,
        field: 'policyExpDate',
        xlsHeader: ['PolicyExpDate'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 11,
        field: 'grossPremium',
        xlsHeader: ['GrossPremium'],
        inputType: 'number',
        excelType: 1,
    },

    {
        id: 12,
        field: 'status',
        xlsHeader: ['PolicyStatus'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 13,
        field: 'filerNotes',
        xlsHeader: ['FilerNotes'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 14,
        field: 'firstRequest',
        xlsHeader: ['FirstRequest'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 15,
        field: 'secondRequest',
        xlsHeader: ['SecondRequest'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 16,
        field: 'thirdRequest',
        xlsHeader: ['ThirdRequest'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 17,
        field: 'taxAmt',
        xlsHeader: ['TaxAmount'],
        inputType: 'number',
        excelType: 1,
    },
]

// export const columnListDetailed: ColumnItem[] = [

//     {
//         id: 0,
//         field: 'invoiceNumber',
//         xlsHeader: ['InvoiceNumber'],
//         inputType: 'string',
//     },

//     {
//         id: 1,
//         field: 'insuredName',
//         xlsHeader: ['InsuredName'],
//         inputType: 'string',
//     },

//     {
//         id: 2,
//         field: 'policyNumber',
//         xlsHeader: ['PolicyNumber'],
//         inputType: 'string',
//     },


//     {
//         id: 3,
//         field: 'homeState',
//         xlsHeader: ['HomeState'],
//         inputType: 'string',
//     },

//     {
//         id: 4,
//         field: 'taxType',
//         xlsHeader: ['TaxType'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 5,
//         field: 'transactionTypeName',
//         xlsHeader: ['TransactionType'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 6,
//         field: 'coverage',
//         xlsHeader: ['Coverage'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 7,
//         field: 'carrier',
//         xlsHeader: ['Carrier'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 8,
//         field: 'naic',
//         xlsHeader: ['NAIC'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 9,
//         field: 'effectiveDate',
//         xlsHeader: ['TransactionEffDate'],
//         inputType: 'string',
//         excelType: 1,
//     },


//     {
//         id:10,
//         field: 'invoiceDate',
//         xlsHeader: ['InvoiceDate'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 11,
//         field: 'effectiveDate',
//         xlsHeader: ['PolicyEffectiveDate'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 12,
//         field: 'policyExpDate',
//         xlsHeader: ['PolicyExpDate'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 13,
//         field: 'grossPremium',
//         xlsHeader: ['GrossPremium'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 14,
//         field: 'nonAdmittedPremium',
//         xlsHeader: ['NonAdmittedPremium'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 15,
//         field: 'taxableFees',
//         xlsHeader: ['TaxableFees'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 16,
//         field: 'nonTaxableFees',
//         xlsHeader: ['NonTaxableFees'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 17,
//         field: 'taxAmt',
//         xlsHeader: ['TaxAmount'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 18,
//         field: 'taxBalance',
//         xlsHeader: ['TaxBalance'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 19,
//         field: 'agency',
//         xlsHeader: ['Client'],
//         inputType: 'string',
//     },

//     {
//         id: 20,
//         field: 'clientDiv',
//         xlsHeader: ['ClientDiv'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 21,
//         field: 'dateInvoiceAdded',
//         xlsHeader: ['DateInvoiceAdded'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 22,
//         field: 'clientNotes',
//         xlsHeader: ['ClientNotes'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 23,
//         field: 'filerNotes',
//         xlsHeader: ['FilerNotes'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 24,
//         field: 'status',
//         xlsHeader: ['PolicyStatus'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 25,
//         field: 'firstRequest',
//         xlsHeader: ['FirstRequest'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 26,
//         field: 'secondRequest',
//         xlsHeader: ['SecondRequest'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 27,
//         field: 'thirdRequest',
//         xlsHeader: ['ThirdRequest'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 28,
//         field: 'suspended',
//         xlsHeader: ['Suspended'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 29,
//         field: 'dateFiled',
//         xlsHeader: ['DateFiled'],
//         inputType: 'string',
//         excelType: 1,
//     },
//     {
//         id: 30,
//         field: 'filingType',
//         xlsHeader: ['FilingType'],
//         inputType: 'string',
//         excelType: 1,
//     },

//     {
//         id: 31,
//         field: 'filerName',
//         xlsHeader: ['FilerName'],
//         inputType: 'string',
//         excelType: 1,
//     },

// ////////////////////////////////////////////// old columns
//     // {
//     //     id: 0,
//     //     field: 'dueDate',
//     //     xlsHeader: ['Filing Due Date'],
//     //     inputType: 'string',
//     //     excelType: 1,
//     // },

//     // {
//     //     id: 26,
//     //     field: 'paymentPeriod',
//     //     xlsHeader: ['Payment Period'],
//     //     inputType: 'string',
//     //     excelType: 1,
//     // },
//     // {
//     //     id: 27,
//     //     field: 'paymentNoticeDate',
//     //     xlsHeader: ['Payment Notice Date'],
//     //     inputType: 'string',
//     //     excelType: 1,
//     // },
//     // {
//     //     id: 28,
//     //     field: 'feeOnly',
//     //     xlsHeader: ['Fee Only'],
//     //     inputType: 'string',
//     //     excelType: 1,
//     // },
//     // {
//     //     id: 29,
//     //     field: 'bor',
//     //     xlsHeader: ['BOR'],
//     //     inputType: 'string',
//     //     excelType: 1,
//     // },


// ]

export const columnListDetailed: ColumnItem[] = [

    {
        id: 0,
        field: 'invoiceNumber',
        xlsHeader: ['InvoiceNumber'],
        inputType: 'string',
    },

    {
        id: 1,
        field: 'insuredName',
        xlsHeader: ['InsuredName'],
        inputType: 'string',
    },

    {
        id: 2,
        field: 'policyNumber',
        xlsHeader: ['PolicyNumber'],
        inputType: 'string',
    },


    {
        id: 3,
        field: 'homeState',
        xlsHeader: ['HomeState'],
        inputType: 'string',
    },

    {
        id: 4,
        field: 'taxType',
        xlsHeader: ['TaxType'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 5,
        field: 'taxPayee',
        xlsHeader: ['TaxPayee'],
        inputType: 'string',
        excelType: 1,
    },


    {
        id: 6,
        field: 'transactionTypeName',
        xlsHeader: ['TransactionType'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 7,
        field: 'coverage',
        xlsHeader: ['Coverage'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 8,
        field: 'carrier',
        xlsHeader: ['Carrier'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 9,
        field: 'naic',
        xlsHeader: ['NAIC'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 10,
        field: 'effectiveDate',
        xlsHeader: ['TransactionEffDate'],
        inputType: 'custom',
        excelType: 1,
    },


    {
        id: 11,
        field: 'invoiceDate',
        xlsHeader: ['InvDate'],
        inputType: 'custom',
        excelType: 1,
    },

    {
        id: 12,
        field: 'effectiveDate',
        xlsHeader: ['PolicyEffDate'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 13,
        field: 'policyExpDate',
        xlsHeader: ['PolicyExpDate'],
        inputType: 'custom',
        excelType: 1,
    },

    {
        id: 14,
        field: 'grossPremium',
        xlsHeader: ['GrossPremium'],
        inputType: 'number',
        excelType: 1,
    },

    {
        id: 15,
        field: 'nonAdmittedPremium',
        xlsHeader: ['NonAdmittedPremium'],
        inputType: 'number',
        excelType: 1,
    },
    {
        id: 16,
        field: 'taxableFees',
        xlsHeader: ['TaxableFees'],
        inputType: 'number',
        excelType: 1,
    },
    {
        id: 17,
        field: 'nonTaxableFees',
        xlsHeader: ['NonTaxableFees'],
        inputType: 'number',
        excelType: 1,
    },

    {
        id: 18,
        field: 'taxAmt',
        xlsHeader: ['TaxAmt'],
        inputType: 'number',
        excelType: 1,
    },
    {
        id: 19,
        field: 'taxBalance',
        xlsHeader: ['TaxBalance'],
        inputType: 'number',
        excelType: 1,
    },
    {
        id: 20,
        field: 'agency',
        xlsHeader: ['Client'],
        inputType: 'string',
    },

    {
        id: 21,
        field: 'clientDiv',
        xlsHeader: ['ClientDiv'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 22,
        field: 'dateInvoiceAdded',
        xlsHeader: ['DateInvoiceAdded'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 23,
        field: 'clientNotes',
        xlsHeader: ['ClientNotes'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 24,
        field: 'filerNotes',
        xlsHeader: ['FilerNotes'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 25,
        field: 'status',
        xlsHeader: ['Status'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 26,
        field: 'firstRequest',
        xlsHeader: ['FirstRequest'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 27,
        field: 'secondRequest',
        xlsHeader: ['SecondRequest'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 28,
        field: 'thirdRequest',
        xlsHeader: ['ThirdRequest'],
        inputType: 'custom',
        excelType: 1,
    },

    {
        id: 29,
        field: 'suspended',
        xlsHeader: ['Suspended'],
        inputType: 'custom',
        excelType: 1,
    },

    {
        id: 30,
        field: 'dateFiled',
        xlsHeader: ['DateFiled'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 31,
        field: 'filingType',
        xlsHeader: ['FilingType'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 32,
        field: 'filerName',
        xlsHeader: ['FilerName'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 33,
        field: 'bgiaAssignments',
        xlsHeader: ['BGIA Assignments'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 34,
        field: 'removed',
        xlsHeader: ['Removed'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 35,
        field: 'paymentPeriod',
        xlsHeader: ['PaymentPeriod'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 36,
        field: 'paymentNoticeDate',
        xlsHeader: ['PaymentNoticeDate'],
        inputType: 'custom',
        excelType: 1,
    },

    {
        id: 37,
        field: 'feeOnly',
        xlsHeader: ['FeeOnly'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 38,
        field: 'needDiligentSearch',
        xlsHeader: ['NeedDiligentSearch'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 39,
        field: 'diligentSearchNotes',
        xlsHeader: ['DiligentSearchNotes'],
        inputType: 'string',
        excelType: 1,
    },

    {
        id: 40,
        field: 'diligentSearchProcessDate',
        xlsHeader: ['DiligentSearchProcessDate'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 41,
        field: 'sentToVenbrook',
        xlsHeader: ['SentToVenbrook'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 42,
        field: 'receivedFromVenbrook',
        xlsHeader: ['ReceivedFromVenbrook'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 43,
        field: 'diligentSearchCompleted',
        xlsHeader: ['DiligentSearchCompleted'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 44,
        field: 'diligentSearchToGMAC',
        xlsHeader: ['DiligentSearchToGMAC'],
        inputType: 'custom',
        excelType: 1,
    },
    {
        id: 45,
        field: 'bor',
        xlsHeader: ['BOR'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 46,
        field: 'itemType',
        xlsHeader: ['Item Type'],
        inputType: 'string',
        excelType: 1,
    },
    {
        id: 47,
        field: 'path',
        xlsHeader: ['Path'],
        inputType: 'string',
        excelType: 1,
    },





    ////////////////////////////////////////////// old columns
    // {
    //     id: 0,
    //     field: 'dueDate',
    //     xlsHeader: ['Filing Due Date'],
    //     inputType: 'string',
    //     excelType: 1,
    // },

    // {
    //     id: 26,
    //     field: 'paymentPeriod',
    //     xlsHeader: ['Payment Period'],
    //     inputType: 'string',
    //     excelType: 1,
    // },
    // {
    //     id: 27,
    //     field: 'paymentNoticeDate',
    //     xlsHeader: ['Payment Notice Date'],
    //     inputType: 'string',
    //     excelType: 1,
    // },

    // {
    //     id: 29,
    //     field: 'bor',
    //     xlsHeader: ['BOR'],
    //     inputType: 'string',
    //     excelType: 1,
    // },


]