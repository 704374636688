import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import Moment from 'moment';
import { Field, FieldAttributes, Form, Formik, useField } from 'formik';
import { config } from '../../Constants';
import { FMSelectLOBField, FMSelectOperator, FMSelectPartners, FMSelectPayableCategory, FMSelectPayablePaymentType, FMSelectPayablePayout, FMSelectRateTypeField, FMSelectState, FMSelectTaxTypeField, FMSelectTrueFalse } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import { StateRuleMatrixModel, StateRuleMatrixModel_VS } from '../../models/StateRuleMatrixModel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { PayablesModel } from '../../models/PayablesModel';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

interface Iprops {
    PayablesCL: PayablesModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: PayablesModel

}

export const AddEditPayables: React.FC<Iprops> = ({ handleClose, Open, PayablesCL, handleSubmit, initValues }) => {
    const initialValues: PayablesModel = initValues ? PayablesCL : initValues;

    const [selectedPayoutTypeId, setselectedPayoutID] = useState("1");

    const FMSelectPayablePayoutCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { payablePayoutId: 0, payoutName: "" };

        const [PayablePayout, setPayablePayout] = useState([initialdata]);

        const handleChange = (event: SelectChangeEvent) => {

            setselectedPayoutID(event.target.value);
        }

        const fetchData = () => {

            fetch(config.url.API_URL + '/Payables/getPayablePayout',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    setPayablePayout(responseJson.data)

                }).catch((error) => {

                    console.log(error)

                });


        }


        useEffect(() => {
            fetchData();

        }, [])

        return (

            <FormControl error={!!errorText} fullWidth  >
                <InputLabel id="State-Select-Label">Payout</InputLabel>
                <Select
                    {...field}
                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label="Payout"
                    value={selectedPayoutTypeId}


                >

                    {PayablePayout.map((pp, key) =>
                        <MenuItem key={key} value={pp.payablePayoutId}> {pp.payoutName} </MenuItem>
                    )}

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    const { user } = useAuth();
    const token = user?.token;
    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle>Payables</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                // validationSchema={StateRuleMatrixModel_VS}
                onSubmit={(data, { setSubmitting }) => {

                    data.payablePayoutId = Number(selectedPayoutTypeId);
                    fetch(
                        config.url.API_URL + '/payables/AddEdit',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="account" autoFocus margin="dense" label="Account" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="location" label="Location" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>

                                    <FMSelectPayablePayoutCustom autoWidth={true} name="payablePayoutId" />

                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        {

                                            <FMTextField name="amount" label="Amount" type="number" fullWidth variant="outlined" sx={{ m: 2 }} InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }} />


                                        }
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FMSelectPayablePaymentType autoWidth={true} name="payablePaymentTypeId" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FMSelectPayableCategory autoWidth={true} name="payableCategoryId" />
                                </Grid>
                                <Grid item xs={12}>
                                    {user?.userRoles.split(",").includes("Super Admin") ?

                                        <FMSelectPartners autoWidth={true} name="partnersId" />
                                        : ""}
                                </Grid>
                            </Grid>





                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="secondary" onClick={handleClose} type="button" >Cancel</Button>
                            <Button variant="outlined" type='submit' disabled={isSubmitting} >Submit</Button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
