import { useEffect, useState } from "react";
import useAuth from "../../context/AuthProvider";
import { TaxTransactionReportModel_Resp } from "../../models/TaxTransactionReportModel";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import { config } from "../../Constants";
import Swal from "sweetalert2";
import { FMTextField } from "../Elements/input";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


interface Iprops {
    StateRuleMatrixCL: TaxTransactionReportModel_Resp,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: TaxTransactionReportModel_Resp
     fetchDataMain: (
        action: string,
        pageSize: number,
        currentPage: number,
    ) => void;
    selectedButton:number
}

export const AddEditDiligentSearh: React.FC<Iprops> = ({ handleClose, Open, StateRuleMatrixCL, handleSubmit, initValues,fetchDataMain ,selectedButton}) => {
    const initialValues: TaxTransactionReportModel_Resp = initValues ? StateRuleMatrixCL : initValues;



    const { user } = useAuth();
    const token = user?.token;

    const FMSelectCarrierCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { insuranceCompanyId: 0, name: "" };
        const [Carrier, setCarrier] = useState([initialdata])

        const fetchData = () => {
            fetch(config.url.API_URL + '/Carrier/getCarrierSelect',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {
                        // console.log(responseJson.data)
                        setCarrier(responseJson.data)
                    }
                }).catch((error) => {
                    console.log(error)
                });
        }

        useEffect(() => {
            fetchData();
        }, [])


        return (
            <React.Fragment>

                <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} >
                    <InputLabel id="State-Select-Label">Carrier</InputLabel>
                    <Select {...field}
                        labelId="State-Select-Label"
                        label="Carrier"
                        value={field.value || ""}
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon}
                    >
                        {/* <MenuItem value={"0"} >Carrier</MenuItem> */}
                        {Carrier.map((carrier, key) =>
                            <MenuItem key={key} value={carrier.insuranceCompanyId}> {carrier.name} </MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        )
    }

    const FMSelectTransactionType: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { transactionId: 0, transactionTypeName: "" };
        const [TransactionType, setTransactionType] = useState([initialdata])

        const fetchTransactionData = () => {
            fetch(config.url.API_URL + '/TransactionType/getAllTransactionType',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {
                        // console.log(responseJson.data)
                        setTransactionType(responseJson.data)
                        
                    }
                }).catch((error) => {
                    console.log(error)
                });
        }

        useEffect(() => {
            fetchTransactionData();
        }, [])


        return (
            <React.Fragment>

                <FormControl error={!!errorText} fullWidth  >
                    <InputLabel id="State-Select-Label">Transaction Type</InputLabel>
                    <Select {...field}
                        labelId="State-Select-Label"
                        label="TransactionType"
                        value={field.value || ""}
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon}
                    >
                        {/* <MenuItem value={"0"} >Carrier</MenuItem> */}
                        {TransactionType.map((carrier, key) =>
                            <MenuItem key={key} value={carrier.transactionId}> {carrier.transactionTypeName} </MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        )
    }
   
    useEffect(() => {
        // fetchStateData();
    }, [])
    return (

        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className='card-title' >Diligent Search</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {

                    console.log("finaldata", data);
                    fetch(
                        config.url.API_URL + '/DiligentSearchReport/AddEditDiligentSearch',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((response) => {
                            if (response.ok) {

                                return response.json();

                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                            Swal.fire({
                                title: "Data Successfully saved",
                                text: "",
                                icon: "success",
                                confirmButtonText: "OK",
                            });
                            const action =
                            selectedButton === 1
                                ? "getOutstandingFilingsReportData"
                                : selectedButton === 2
                                    ? "getCompletedFilingsReportData"
                                    : selectedButton === 3 ? "getClosedFilingsReportData" : "";
                            fetchDataMain(action,50,1);
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="insuredName" autoFocus margin="dense" label="Insured Name" placeholder="Insured Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="client" autoFocus margin="dense" disabled label="Client" placeholder="client" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyNumber" disabled autoFocus margin="dense" label="Policy Number" placeholder="policyNumber" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="needDiligentSearch" autoFocus margin="dense" label="Need Diligent Search" placeholder="needDiligentSearch" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="diligentSearchNotes" autoFocus margin="dense" label="Diligent Search Notes" placeholder="diligentSearchNotes" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="diligentSearchCompleted" autoFocus margin="dense" label="Diligent Search Completed" placeholder="diligentSearchCompleted" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="suspended" autoFocus margin="dense" label="Suspended" placeholder="Suspended" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                        <FMSelectTransactionType variant={"standard"} name="transactionTypeId" ></FMSelectTransactionType>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                        <FMSelectCarrierCustom variant={"standard"} name="insuranceCompanyId" ></FMSelectCarrierCustom>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="naic" autoFocus margin="dense" disabled label="NAIC" placeholder="NAIC" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyEffDate" autoFocus margin="dense" label="Policy Effective Date" placeholder="policyEffDate" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyExpDate" autoFocus margin="dense" label="Policy Expiration Date" placeholder="policyExpDate" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="grossPremium" autoFocus margin="dense" label="Gross Premium" placeholder="grossPremium" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="taxType"  disabled autoFocus margin="dense" label="Tax Type" placeholder="taxType" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>



                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
