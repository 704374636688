import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { EmailTemplateList } from "../components/Lists/EmailTemplatesList";
import { StateRuleMatrixList } from "../components/Lists/StateRuleMatrixList";
import { StateStampListList } from "../components/Lists/StateStampListList";
import { StateZeroFilingRuleList } from "../components/Lists/StatesZeroFilingRulesList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FillingsByUser } from "./FillingsByUser";

export const EmailTemplate = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [EmailTemplates, setEmailTemplates] = useState([]);

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/EmailTemplate/getAll',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
                if (responseJson.data != null) {
                    setEmailTemplates(responseJson.data)
                }
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (

                                                    < EmailTemplateList EmaiTemplateCL={EmailTemplates} fetchData={fetchData} />


    )

}