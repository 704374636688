import { LinearProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { PolicyCheckList } from "../components/Lists/PolicyChecksResultList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { ProcessPolicyChecksModel } from "../models/PolicyCheckResultModel";
import { CustPolicyDetailsModel } from "../models/CustomerPolicyDataModel";
import { AddEditLateFilingReason } from "../components/AddEdit/AddEditLateFilingReason";

interface Iprops {
    customerPolicy: CustPolicyDetailsModel
    latefilinfCL: CustPolicyDetailsModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: CustPolicyDetailsModel
    fetchLocationOfRiskData: () => void
    fetchMainData:()=>void,
    customerPolicyDetails:any
}

export const PolicyCheckResult : React.FC<Iprops> = ({customerPolicyDetails, customerPolicy, handleClose, Open, latefilinfCL, handleSubmit, initValues, fetchLocationOfRiskData,fetchMainData}) => {
   
   

    const [PolicyCheckData, setPolicyCheckData] = React.useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = React.useState(false);
    const params = useParams();
    
    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/ProcessPolicyChecks/getAllByCustPolicyId?PolicyId=${params.policyid}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
                // console.log(responseJson.data);
                setPolicyCheckData(responseJson.data)
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    React.useEffect(() => {
        fetchData();
        

    }, [])

    return (



        <div className=""> {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <div className="col-lg-12 col "> 
            
                <div className="card dashboard-table  ">
                    <span className="card-title">Flags Category</span>
                    <div className="table-responsive ">
                      
                        {/* <CustomerPolicyList CustomerPolicyModelCL={CustomerPolicy} fetchData={fetchData} /> */}
                        <PolicyCheckList customerPolicyDetails={customerPolicyDetails} ProcessPolicyChecksCL={PolicyCheckData} fetchData={fetchData} customerPolicy={customerPolicy}  latefilinfCL={latefilinfCL} initValues={customerPolicy} fetchLocationOfRiskData={fetchLocationOfRiskData} fetchMainData={fetchMainData} />
                        
                    </div>
                </div>
            </div>

        </div>



    );

}
