import React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';



export const TestComponent = () => {

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'age', headerName: 'Age', width: 100 },
        { field: 'country', headerName: 'Country', width: 150 },
    ];

    const rows = [
        { id: 1, name: 'John', age: 25, country: 'USA' },
        { id: 2, name: 'Mike', age: 30, country: 'Canada' },
        { id: 3, name: 'Sara', age: 22, country: 'Australia' },
    ];

    function NestedDataGrid() {
        function getFullName(params: GridValueGetterParams) {
            return `${params.row.getValue('name') || ''} ${params.row.getValue('age') || ''}`;
        }
    }

        return (
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    checkboxSelection
                    getRowId={(row) => row.id}
                    components={{
                        Toolbar: () => (
                            <div>
                                <h3>Details</h3>
                            </div>
                        ),
                    }}
                    columnBuffer={0}
                    onRowClick={(params, event) => {
                        console.log(params.row);
                    }}
                />
            </div>
        );
    
}