import React, { useEffect, useState } from "react";
import { PolicyList } from "../components/Lists/PolicyList";
import { LinearProgress, } from "@mui/material";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FillinsByUserList } from "../components/Lists/FillingsByUserList";

export const FillingsByUser = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [Fillings, setFillings] = useState([]);
    const [loading, setloading] = useState(false);


    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/FillingsByUser/getFillingsByUser`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                // Do something with the response
                setloading(false);
               
                setFillings(res.data);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });




    }
    useEffect(() => {

        fetchData();

    }, [])

    return (

        <React.Fragment>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <FillinsByUserList FillingsCl={Fillings} fetchData={fetchData} />
        </React.Fragment>



    );

}
