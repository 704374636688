import * as React from 'react';
import { Policies } from './Policies';
import { RightNav } from '../components/Nav/RightNav';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { FillingsByUser } from './FillingsByUser';
import { CustomerPolicyList } from '../components/Lists/CustomerPolicyList';
import useAuth from '../context/AuthProvider';
import { config } from '../Constants';
import { LinearProgress } from '@mui/material';
import { LogsList } from '../components/Lists/LogsList';


export const Logs = () => {
    const [Logs, setLogs] = React.useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = React.useState(false);
    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/Logs/getAllLogs',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                if (responseJson.data != null) {
                    setLogs(responseJson.data)

                }
                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    // const fetchData = (page, pageSize) => {
    //     setloading(true);
    //     const offset = page * pageSize; // Calculate the offset for pagination
    
    //     fetch(`${config.url.API_URL}/Logs/getAllLogs?offset=${offset}&limit=${pageSize}`, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`,
    //         }
    //     })
    //     .then((response) => {
    //         if (response.ok) {
    //             return response.json();
    //         }
    //         throw new Error('Something went wrong');
    //     })
    //     .then((responseJson) => {
    //         if (responseJson.data != null) {
    //             // Update your state or data handling here based on the fetched data
    //             setLogs(responseJson.data);
    //         }
    //         setloading(false);
    //     })
    //     .catch((error) => {
    //         setloading(false);
    //         console.log(error);
    //     });
    // };
    



    React.useEffect(() => {
        fetchData();

    }, [])

    return (
                                                    <LogsList LogsCL={Logs} fetchData={fetchData} />

    );

}








