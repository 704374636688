import * as React from 'react';

import { Field, Form, Formik } from 'formik';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, LinearProgress, Link } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LoadingButton from '@mui/lab/LoadingButton';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { config } from '../../Constants';
import useAuth from '../../context/AuthProvider';
import { CustomerPolicyModel_VS } from '../../models/CustomerPolicyModel';
import * as Yup from 'yup';
import { error } from 'console';
import { forEach } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
var formData = new FormData();
var files: File[];

export interface ReqDocuments {
    documentName?: string,
    modelId?: number
    isRequired?: boolean
}
const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#162E3C80',
    borderStyle: 'dashed',


    backgroundColor: 'rgba(22, 46, 60, 0.10)',
    // color: '#bdbdbd',
    color: '#162E3C',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};



export function GenericStyledDropZone(props: any & ReqDocuments) {
    const maxLength = 20;



    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject,

    } = useDropzone({
        maxFiles: 1,
        accept: { 'application/pdf': ['.pdf'], 'image/*': [], 'application/msg': ['.msg'] },
        onDrop:
            (files) => {
                formData.delete(props.doc.documentName);
                formData.delete(props.doc.documentName);
                formData.append(props.doc.modelId + "__" + props.doc.documentName, files[0]);

            }

    });



    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    const files = acceptedFiles.map
        (
            file =>
            (
                <React.Fragment >
                    <span style={{ fontSize: "14px" }}>
                        {file.name.length > 35 ? file.name.substring(0, 35) + '...' : file.name}
                    </span>
                    {/* {file.name} - {(file.size / 1024).toFixed(2)} kb */}

                </React.Fragment>
            )

        );



    return (
        <React.Fragment>
            <div className=" box-style col-lg-12">
                <div {...getRootProps({ style })}>

                    <input {...getInputProps()} name={props.doc.documentName} />
                    <p style={{ fontSize: "16px", color: "#162E3C", fontWeight: "600" }}>Drop {props.doc.documentName}  <span style={{ color: "red" }}>{props.doc.isRequired ? "*" : ""}</span></p>

                </div>

                <span >
                    <p style={{ height: "20px", textAlign: "start", margin: 2 }}>{files}</p>
                </span>


            </div>

        </React.Fragment>
    );
}

interface props {
    open: boolean
    handleClose: () => void
    custPolicyID: string
}


export const AddEditResubmitSingleDocument: React.FC<props> = ({ open, handleClose, custPolicyID }) => {


    const navigate1 = useNavigate();

    const goBack = () => {
        navigate1(-1);
    }


    const Reqdocuments: ReqDocuments = {
        documentName: "",
        modelId: 0,
        isRequired: false,

    }

    const [RequireedDocuments, setRequiredDocuments] = useState([Reqdocuments]);
    const [IsSingleDocument, setIsSingleDocument] = useState(true);


    function CheckIsSingleDocument() {
        setloading(true);
        fetch(config.url.API_URL + `/MasterPolicyType/checkIsSingleDocument?custPolicyId=${custPolicyID}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {

                // Do something with the response

                setIsSingleDocument(res.data);

                if (!res.data) {
                    fetchRequiredDocumentName();
                } else {
                    setRequiredDocuments([{ documentName: "Single Document", modelId: 1 }]);
                }

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }


    function fetchRequiredDocumentName() {
        setloading(true);
        fetch(config.url.API_URL + `/MasterPolicyType/getRequiredDocuments?custPolicyId=${custPolicyID}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                console.log(res);
                // Do something with the response

                setRequiredDocuments(res.data);

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }


    // let ID: number = 0;
    // const [PolicyTypeId, setPolicyTypeId] = useState(ID);

    const initialValues: { transactionTypeId: number, UserId: number } = { transactionTypeId: 0, UserId: 0 }
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        CheckIsSingleDocument();
        // fetchRequiredDocumentName();
        //  fetchMasterPolicyTypeID();

    }, [])
    return (

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xl'}>
            <div className="card-body"> <DialogTitle className='card-title'>Upload Documents</DialogTitle></div>
            <div className="">

                <React.Fragment>
                    <Formik initialValues={initialValues}
                        validateOnChange={true}
                        validationSchema={CustomerPolicyModel_VS}

                        onSubmit={(data, { setSubmitting }) => {
                            let count = 0
                            formData.forEach(element => {
                                count = count + 1;
                            });
                            if (count < 1) {
                                alert("select a document");
                                setSubmitting(false);
                                return;

                            } else {
                                setloading(true);
                                formData.append('transactionTypeId', data.transactionTypeId.toString() ?? "");
                                let ID = custPolicyID?.toString() ?? "";
                                formData.append('CustomerPolicyId', ID);

                                if (IsSingleDocument) {
                                    fetch(
                                        config.url.API_URL + `/CustomerPolicy/CorrectedDocumentSubmission/`,
                                        {
                                            method: 'POST',
                                            headers: new Headers({
                                                // 'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${token}`,
                                            }),
                                            body: formData  // <-- Post parameters

                                        })
                                        .then((response) => {
                                            if (response.ok) {
                                                return response.json();

                                            }
                                            throw new Error('Something went wrong');
                                        })
                                        .then((res) => {

                                            // Do something with the response
                                            setSubmitting(false);

                                            setloading(false);
                                            console.log(res);
                                            if (res.resp == 200) {
                                                handleClose();
                                            } else {
                                                alert(res.data);
                                            }


                                            formData = new FormData();

                                        }).catch((error) => {
                                            setSubmitting(false);
                                            alert("There was an error saving the Document");
                                            formData = new FormData();
                                            console.log(error)
                                            setloading(true);
                                            handleClose();
                                        });

                                } else {
                                    fetch(
                                        config.url.API_URL + `/CustomerPolicy/CorrectedDocumentSubmission/`,
                                        {
                                            method: 'POST',
                                            headers: new Headers({
                                                // 'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${token}`,
                                            }),
                                            body: formData  // <-- Post parameters

                                        })
                                        .then((response) => {
                                            if (response.ok) {
                                                return response.json();

                                            }
                                            throw new Error('Something went wrong');
                                        })
                                        .then((res) => {

                                            // Do something with the response
                                            setSubmitting(false);

                                            setloading(false);

                                            if (res.resp == 200) {
                                                handleClose();
                                            } else {
                                                alert(res.data);
                                            }

                                            formData = new FormData();

                                        }).catch((error) => {
                                            setSubmitting(false);
                                            alert("There was an error saving the Document");
                                            formData = new FormData();
                                            console.log(error)
                                            setloading(true);
                                            handleClose();
                                        });

                                }


                            }
                        }
                        } >
                        {({ values, isSubmitting }) =>
                        (
                            <Form>
                                <DialogContent>
                                    <div className="card-body">
                                        {RequireedDocuments && RequireedDocuments.length > 0 ?
                                            <div className="row row-cols-1 row-cols-md-3 g-1 " style={{ display: "flex", alignItems: "center" }}>
                                                {
                                                    RequireedDocuments?.map(
                                                        (file, Key) =>
                                                        (
                                                            <div className='col-lg-4 col-sm-12 gx-5'>
                                                                <div className="card col-lg-12 m-2">
                                                                    <div className=" ">
                                                                        <GenericStyledDropZone doc={file} Key={Key} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                            : <span>Mapping not found for this transaction</span>}
                                    </div>



                                    <div className='card-body'>
                                        <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                                            <div className="col-lg-12 m-2 ">

                                                {/* <div className='col-lg-5 '>
                                                            <button type="submit" className="btn btn-primary login-btn  loginpage-samespace" disabled={isSubmitting} >Upload </button>
                                                            <button className='btn btn-secondary' onClick={handleClose}  >Cancel</button>
                                                        </div> */}
                                                <div className="d-flex ">
                                                    {RequireedDocuments && RequireedDocuments.length > 0 ?
                                                        <div className="me-3">
                                                            <button type="submit" className=" active-btn-blue me-2 " disabled={isSubmitting}>Upload</button>
                                                        </div>
                                                        : ""}
                                                    <div className="me-3">
                                                        <button className=" active-btn-white" type='button' onClick={handleClose}>Cancel</button>
                                                    </div>
                                                    <div>   {loading && <CircularProgress />}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </DialogContent>
                                <DialogActions >

                                    {/* <Button variant="outlined" color="secondary" onClick={handleClose}  >Cancel</Button> */}


                                </DialogActions>
                            </Form>

                        )}
                    </Formik>
                </React.Fragment>

            </div>

        </Dialog>

    );
}

