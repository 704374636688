import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { ReconciliationReportsList } from "../components/Lists/ReconciliationReportsList";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { RightNav } from "../components/Nav/RightNav";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";



export const ReconciliationReports = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);


    const [ReconciliationReports, setReconciliationReports] = useState([])

    const params = useParams();

    const fetchData = () => {
        setloading(true)
        fetch(config.url.API_URL + '/ReconciliationReports/getReconciliationReports',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)

                // Do something with the response
                setReconciliationReports(res.data)
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });

    }

    useEffect(() => {
        // fetchData();
    }, [])

    return (


        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                            <LoggedInUserInfo ButtonText="" Link=""/>
                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">
                                            <div className="table-responsive">
                                                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                Reconciliation Reports
                                                {/* <ReconciliationReportsList ReconciliationReportsCL={ReconciliationReports} fetchData={fetchData} />  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>

    );

}

