import React, { useEffect, useRef, useState } from "react";
import { RiExpandUpDownLine } from "react-icons/ri";
import { LinearProgress, } from "@mui/material";
import useAuth from "../../context/AuthProvider";
import { TaxTransactionReportModel, ReconciliationTransactionReportModel_Resp, ReconciliationTransactionReportModel } from "../../models/TaxTransactionReportModel";
import moment from "moment";
import { DataGridPro, GridColDef, GridInputSelectionModel, GridRowId, GridRowModesModel, GridSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import formatPrice from "../../helper/currencyFormatter";
import { getRowsPerPageOptions } from "../AddEdit/CustomFunctions";

interface Iprops {
    ReportModuleCL: ReconciliationTransactionReportModel_Resp;
    // fetchDataMain: (
    //     action: string,
    //     pageSize: number,
    //     currentPage: number,
    // ) => void;

    fetchDataMain: (
        action: string,
        pageSize: number,
        currentPage: number,
        field: string,
        sort?: string
    ) => void;
    selectedButton: number
    setSelectedRows: React.Dispatch<React.SetStateAction<ReconciliationTransactionReportModel[] | null>>
    selectedRows: ReconciliationTransactionReportModel[] | null
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    _currentPage: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    _pageSize: number
}

export const TaxTransactionReportList: React.FC<Iprops> = ({
    ReportModuleCL,
    fetchDataMain,
    selectedButton,
    setSelectedRows,
    selectedRows,
    setCurrentPage,
    _currentPage,
    setPageSize,
    _pageSize
}) => {

   // const [_currentPage, setCurrentPage] = React.useState(1);
   // const [_pageSize, setPageSize] = React.useState(50);
    const { user } = useAuth();
    const [loading, setloading] = React.useState(false);

    const columns: GridColDef[] = [
        {
            field: "client", headerName: "Client", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },
        {
            field: "invoiceNumber", headerName: "InvoiceNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },

        {
            field: "insuredName", headerName: "InsuredName", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },
        {
            field: "policyNumber", headerName: "PolicyNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },

        {
            field: "homeState", headerName: "HomeState", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 2,
        },

        {
            field: "taxType", headerName: "TaxType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },


        {
            field: "transactionType", headerName: "TransactionType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },
        {
            field: "invDate", headerName: "InvoiceDate", type: "date", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), valueGetter: (params) =>
            (
                new Date(params.row.invDate)
            ),
            renderCell: (params) =>
            (
                <div>
                    {(params.row.invDate != null && params.row.invDate != '') ? moment(params.row.invDate).format("MM/DD/YYYY") : ""}
                </div>
            ),
            align: "center", flex: 3,
        },
        {
            field: 'policyEffDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'date', headerName: 'PolicyEffectiveDate', flex: 4, align: 'center', headerAlign: 'center',
            valueGetter: (params) =>
            (
                new Date(params.row.policyEffDate)
            ),
            renderCell: (params) =>
            (
                <div>
                    {(params.row.policyEffDate != null && params.row.policyEffDate != '') ? moment(params.row.policyEffDate).format("MM/DD/YYYY") : ""}
                </div>
            )
        },

        {
            field: "policyExpDate", headerName: "PolicyExpirationDate", type: "date", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
                // <div>
                //     <span className="table-card-title word-wrap-1 p-0"   >{params.colDef.headerName}</span>
                //     <RiExpandUpDownLine />
                // </div>
            ), align: "center", flex: 4,
            valueGetter: (params) =>
            (
                new Date(params.row.policyExpDate)
            ),
            renderCell: (params) =>
            (
                <div>
                    {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
                </div>
            )
        },

        {
            field: "grossPremium", headerName: "GrossPremium", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title word-wrap-1 p-0" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) => (

                formatPrice(params.row.grossPremium)

            ),

            align: "center", flex: 3,
        },
        {
            field: "taxAmt", headerName: "TaxAmount", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) => (

                formatPrice(params.row.taxAmt)

            ), align: "center", flex: 3,
        },

        {
            field: "status", headerName: "Status", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },


    ];

    const sortModelRef = useRef<GridSortModel>([]);
    const onRowsSelectionHandler = (ids: GridSelectionModel | undefined) => {
        const selectedIDs = new Set(ids);

        const uniqueRowsIds = new Set<GridRowId>();
        const selectedRowData = ReportModuleCL.getAllTaxTransactionReportWithExport.filter(
            (row: ReconciliationTransactionReportModel) => {
                if (selectedIDs.has(row?.rowNum ?? "") && !uniqueRowsIds.has(row?.rowNum ?? "")) {
                    uniqueRowsIds.add(row?.rowNum ?? "");
                    return true;
                }
                return false;
            }
        );
        setSelectedRows(selectedRowData); // Store full row data
    };


    const handleNextPreviousPage = (newPage: number) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        setCurrentPage(newPage);
        fetchDataMain("getAllTaxTransactionFilingsData", _pageSize, newPage, field, sort);

    };

    const handlePageSize = (newPageSize: number) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        setPageSize(newPageSize);
        fetchDataMain("getAllTaxTransactionFilingsData", newPageSize, _currentPage, field, sort);
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        sortModelRef.current = sortModel;
        console.log(sortModelRef);
        fetchDataMain("getAllTaxTransactionFilingsData", _pageSize, _currentPage, sortModel[0].field, sortModel[0].sort == undefined || sortModel[0].sort == null ? '' : sortModel[0].sort);
    }, []);

    function EditToolbar() {
        return (
            <React.Fragment>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>

            </React.Fragment>
        )
    }
    // const validRows = ReportModuleCL?.getReconciliationTransactionReport_Respdata?.filter(
    //     (row) => row.rowNum !== undefined
    // );

    return (
        <React.Fragment>
            <div style={{ height: "58vh", overflowX: "scroll" }}>
                <DataGridPro
                    getRowId={(r) => r?.rowNum}
                    rows={ReportModuleCL.getAllTaxTransactionReportWithExport}
                    //rows={validRows} // Only valid rows
                    columns={columns}
                    pageSize={_pageSize}
                    pagination
                    rowCount={ReportModuleCL.totalCount}
                    rowsPerPageOptions={getRowsPerPageOptions(ReportModuleCL?.totalCount, _pageSize)}

                    // rowsPerPageOptions={[25, 50, 100, ReportModuleCL?.totalCount].sort((a, b) => a - b)}
                    paginationMode="server"
                    onSortModelChange={handleSortModelChange}
                    sortModel={sortModelRef.current}

                    sortingOrder={['desc', 'asc']}
                    onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
                    onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
                    style={{ textAlign: "center" }}

                    checkboxSelection={
                        user?.userRoles.split(",").includes("Super Admin") ||
                            user?.userRoles.split(",").includes("MISC State Reporter")
                            ? true : false
                    }
                    onSelectionModelChange={user?.userRoles.split(",").includes("Super Admin") ||
                        user?.userRoles.split(",").includes("MISC State Reporter") ? (newRowSelectionModel) => {
                            onRowsSelectionHandler(newRowSelectionModel);
                        } : undefined}
                    selectionModel={selectedRows?.map((row) => row.rowNum) as GridRowId[]}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    sx={{
                        width: "90vw",
                        "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                        },
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",

                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    columnVisibilityModel={{

                        paymentDate: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                        paymentAmount: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                        lineOfBusiness: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                    }}
                />
            </div>

        </React.Fragment>
    );
};
