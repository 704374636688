import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import { CustomerPolicyModel } from "../../models/CustomerPolicyModel";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { fontFamily, fontWeight, margin } from "@mui/system";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AddEditResubmitSingleDocument } from "../AddEdit/AddEditResubmitSingleDocument";

interface InsuredSubmissionsModel {
    customer?: string,
    customerPolicyId?: number,
    dateCreated?: Date,
    insuredName?: string,
    policyNumber?: string,
    policyPeriod?: string,
    policyStatus?: string
    transactionTypeName?: string
}

interface Iprops {
    CustomerPolicyModelCL: InsuredSubmissionsModel[],
    fetchData: () => void
}

export const InsuredSubmissionsList: React.FC<Iprops> = ({ CustomerPolicyModelCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const [OpenDocResubmission, setDocResubmission] = React.useState(false);
    const handleCheckStatus = (id: number) => {
        setloading(true);
        //call api to save data
        fetch(config.url.API_URL + "/CustomerPolicy/CheckCustomerPolicyDocumentStatus?customerpolicyid=" + `${id}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false);
                // Do something with the response
                fetchData();

                alert(res.data)
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });

    };
    const [custPolId, setcustPolId] = React.useState("0");
    const handleDocumentReubmission = (custPolId: number) => {
        setcustPolId(String(custPolId));

        setDocResubmission(true);
    };

    const handleClose = () => {

        setDocResubmission(false);
 

    };

    const handleGetResponse = (id: number) => {
        setloading(true);
        //call api to save data
        fetch(config.url.API_URL + "/CustomerPolicy/GetCustomerPolicyResponse?customerpolicyid=" + `${id}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false);
                // Do something with the response
                fetchData();

                alert(res.data)
            }).catch((error) => {
                console.log(error)
                setloading(false);

            });

    };

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function EditToolbar() {

        return (
            <Box
                sx={{

                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 0,
                }}
            >
                <div className="row col-lg-12" style={{ textAlign: "center" }} >
                    
                    <div className="col-lg-12" style={{ padding: "5px" }}>
                        <span className="card-title" ></span>
                        <span className="card-title" ><h3>Insured Submission</h3> </span>
                    </div>
                    <div className="col-lg-4"></div>
                </div>


            </Box>

        );

    }


    const columns: GridColDef[] = [

        { field: 'customer', headerName: 'Customer', flex: 1.2, headerAlign: 'center', align: 'center' },
        {
            field: 'dateCreated', type: 'date', headerName: 'Date Submitted', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) =>
            (
                <div>
                    {params.row.dateCreated ? moment(params.row.dateCreated).format("MM-DD-YYYY") : ""}
                    {/* {params.row.dateCreated?.toString().split('T')[0]} */}
                </div>

            )
        },
        { field: 'policyNumber', type: 'string', headerName: 'Policy Number', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'policyPeriod', type: 'string', headerName: 'Policy Period', flex: 1.5 },
        { field: 'insuredName', type: 'string', headerName: ' Insured Name', flex: 1 },
        { field: 'transactionTypeName', type: 'string', headerName: 'Transaction Type', flex: 1 },
        { field: 'policyStatus', type: 'string', headerName: 'Submission Status', flex: 1 },



        
        {
            field: 'uploadodcs', flex: 0.5, headerName: "Re-Submit Documents", align: 'center', renderCell: (params) => (<div>
                {/* <Link href={'/ResubmitCustomerPolicyDocs/' + params.row.customerPolicyId} color="#1976D2">

                    <IconButton
                        className="btn btn-primary  m-1 "
                        style={{ float: 'right' , color:"white"}}
                    >
                        <UploadFileIcon />
                    </IconButton>
                </Link> */}
                <IconButton
                    className="btn btn-primary  m-1 "
                    style={{ float: 'right' , color:"white" , margin: '4px'}}
                 
                    // className="btn btn-primary action-btn"
                    onClick={() => { handleDocumentReubmission(params.row.customerPolicyId) }}
                    title="Re-submit documents"
                >
                    <UploadFileIcon />
                </IconButton >
            </div>)
        }

    ];

    return (

        <div style={{ height: '75vh', width: '100%' }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGrid
                getRowId={(r) => r.customerPolicyId}
                rows={CustomerPolicyModelCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",




                    }
                }
            />
            {OpenDocResubmission ? <AddEditResubmitSingleDocument open={OpenDocResubmission} handleClose={handleClose} custPolicyID={custPolId} /> : ""}
        </div>


    )
}