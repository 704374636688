import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { config } from "../Constants";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { RightNav } from "../components/Nav/RightNav";
import useAuth from "../context/AuthProvider";
import { useParams } from "react-router-dom";
import { SubmissionDataList } from "../components/Lists/SubmissionDataListSD";

export const SubmissionData_SD = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();
    const [SouthDakota, setSouthDakota] = useState([]);

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/Test/getDataSD',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);

                setSouthDakota(responseJson.data)
                console.log("SDDAta",responseJson.data)
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>

                                <LoggedInUserInfo ButtonText="Add New Model" Link="" />


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>

                                            <div style={{padding : "1rem" , paddingTop : "2rem", paddingBottom: "4rem"}}>

                                                <div className="table-responsive">
                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                    <SubmissionDataList SubmissionDataCL={SouthDakota} fetchData={fetchData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>


    )

}