import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, Grid, Link, } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';
import moment from 'moment';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Box, minHeight } from '@mui/system';

export interface getCustomerPolicyDocumentDetails {
    blobAddress?: string,
    documentName?: string,
    documentType?: string,
    dateUploaded?: number
    username?: string,
    fileSizeKB?: number,
    numberOfPages?: number,
    errorMessage?: string,
    policyID?: number
    policyErrorCheckListId: number

}

interface Iprops {
    documentList: getCustomerPolicyDocumentDetails[],
    handleClose: () => void,
    Open: boolean,

}

export const PolicyErrorPopup: React.FC<Iprops> = ({ handleClose, Open, documentList }) => {
    const initialValues: getCustomerPolicyDocumentDetails[] = documentList;
    const [selectedRows, setSelectedRows] = useState<getCustomerPolicyDocumentDetails[]>();
    const { user } = useAuth();
    const token = user?.token;
    const onRowsSelectionHandler = (ids: GridSelectionModel) => {

        const selectedIDs = new Set(ids);

        const selectedRowData = documentList.filter((row) =>
            selectedIDs.has(row?.policyErrorCheckListId ?? "")
        );

        setSelectedRows(selectedRowData);
    };

    const handleSendEmail = () => {

        const MultiplePolicyIds: string = selectedRows?.map(x => x.policyErrorCheckListId).join(",") ?? ""
        fetch(config.url.API_URL + `/ProcessPolicyChecks/SendSelectedChecksEmail?policyId=${documentList[0].policyID}&&PolicyCheckListIdList=${MultiplePolicyIds}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {

                    return response;
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                alert("Email sent");
                // fetchData();


            }).catch((error) => {

                console.log(error)

            });

    };
    function CustomToolbar() {
        return (
            <GridToolbarContainer  >
                <GridToolbarFilterButton className="me-3 sky-blue" />
                <GridToolbarColumnsButton className="me-3 sky-blue" />
                <GridToolbarExport className="me-3 sky-blue" />
            </GridToolbarContainer>

        );
    }

    function EditToolbar() {

        return (
            <Box
                sx={{

                    borderBottom: 1,
                    borderColor: 'divider',
                    marginRight: "10px"

                }}
            >
                <div className="d-flex justify-content-between">
                    <div className=" card-title">
                        Errors

                    </div>
                    <div className="">
                        <div className='d-flex'>
                            <CustomToolbar />
                            {/* {user?.userRoles?.split(",").includes("Super Admin") ? */}
                            <button

                                className=" btn  btn-primary  me-2 active-btn-blue "
                                // style={{ float: 'right' }}
                                onClick={handleSendEmail}
                                title="Send Email"
                            // variant='contained'
                            >
                                Send Email</button>
                            {/* : "" */}
                            {/* } */}

                        </div>


                    </div>
                </div>
            </Box>

        );

    }

    const columns: GridColDef[] = [
        { field: 'errorMessage', type: 'text', headerName: 'Error', flex: 2.5, headerAlign: 'center', align: 'center' },
        {

            field: 'blobAddress', type: 'text',align: 'center', headerName: 'Document Name', flex: 2, headerAlign: 'center', renderCell: (params) => (

                <div>
                    {user?.userRoles.split(",").includes("Super Admin") ?
                        <Link href={"Transaction/" + params.row.policyID?.toString()}>
                            {params.row?.documentName?.split("_")[1]?.split(".")[0]}
                        </Link>
                        :


                        params.row.documentName?.split("_")[1]?.split(".")[0]

                    }
                </div>
            )
        },
        { field: 'dateUploaded', type: 'text', headerName: 'Date Uploaded', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'username', type: 'text', headerName: 'Uploaded By', flex: 1, headerAlign: 'center', align: 'center' },
        {
            field: 'fileSizeKB', type: 'text', headerName: 'File Size', flex: 0.5, headerAlign: 'center', align: 'center', renderCell: (params) => (
                <div>

                    {params?.row?.fileSizeKB} Kb

                </div>
            )
        },
        { field: 'numberOfPages', type: 'text', headerName: 'Pages', flex: 0.5, headerAlign: 'center', align: 'center' },


    ];
    return (

        <Dialog open={Open} onClose={handleClose} fullWidth maxWidth={'xl'}>
            <DialogTitle  >   <Button style={{float:"right"}} onClick={handleClose}  >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 8L8 24M8 8L24 24" stroke="#162E3C" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Button></DialogTitle>

            <DialogContent style={{ minWidth: "80%", overflow: "auto" }}>
                <div style={{ height: "50vh" }}>
                    <DataGrid
                        getRowId={(r) => r.policyErrorCheckListId}
                        rows={documentList}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        disableSelectionOnClick
                        checkboxSelection={user?.userRoles.split(",").includes("Super Admin") ? true : false}
                        onSelectionModelChange={(newRowSelectionModel) => {
                            onRowsSelectionHandler(newRowSelectionModel);

                        }}
                        components={{
                            Toolbar: EditToolbar,
                        }}


                        sx={
                            {
                                fontFamily: "'Proxima Nova', sans-serif",
                                border: "0px",
                                fontSize: "16px",
                                minHeight: "500px"


                            }
                        }
                    />
                    {/* <table className="table">
                        <thead style={{ position: "sticky", top: "0", backgroundColor: "white" }}>
                            <tr >
                                <th style={{ width: "20%" }}> Document Name</th>
                             
                                <th style={{ width: "10%" }}>Date Uploaded</th>
                                <th style={{ width: "10%" }}>Uploaded By</th>
                                <th style={{ width: "5%" }}>File Size</th>
                                <th style={{ width: "5%" }}> Pages</th>
                                <th style={{ width: "25%" }}>Error</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                documentList?.map((row, index) => (
                                    <tr key={index}>
                                        <td >
                                            <Link href= {"Transaction/" + row.policyID?.toString()}>
                                                {row.documentName}
                                            </Link>

                                        </td>
                                      
                                        <td>
                                            {row?.dateUploaded ? moment(row?.dateUploaded).format("MM-DD-YYYY") : ""}
                                          
                                        </td>
                                        <td>
                                            {row.username}
                                        </td>
                                        <td>
                                            {row.fileSizeKB} Kb
                                        </td>
                                        <td>
                                            {row.numberOfPages}
                                        </td>
                                        <td>
                                            {row.errorMessage}
                                        </td>



                                    </tr>
                                ))}
                        </tbody>
                    </table> */}
                </div>


            </DialogContent>
            {/* <DialogActions>


            </DialogActions> */}

        </Dialog >
    )
}
