import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { CustomerInvoiceList } from "../components/Lists/CustomerInvoiceList";
import { TransactionFeeList } from "../components/Lists/TransactionFeeList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";

export const CustomerInvoice = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [CustomerInvoice, setCustomerInvoice] = useState([]);

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/CustomerInvoice/getAll',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
              
                if (responseJson.data != null) {
                    setCustomerInvoice(responseJson.data)

                }
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        <div className='dashboard'>
           

            <div className="col-lg-10 col-md-12 col-sm-12 pl-space" >
                <h1 className="dashboard-form-heading text-center">FileMule<span className="sidenav-icon"><i className='bx bx-menu'></i></span></h1>
                <svg className="MobileWaves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"></use>
                    </g>
                </svg>
                <div className="row heading align-items-center m-0">
                    <div className="col-md-7 col-12">
                        <div className="dashboard-content">
                        <LoggedInUserInfo ButtonText={''} Link={''}/>
                        </div>
                    </div>
                    <div className="col-md-5 col-12">
                        {/* <div className="search">
                            <i className="fa fa-search"></i>
                            <input type="text" className="searchbox" placeholder="Add a report" />
                            <button className="btn btn-primary"> <i className="fa fa-check"></i></button>
                        </div> */}
                    </div>


                </div>
                <div className="card-body">
                    <div className="col-lg-12 col col-margin">
                        <div className="card dashboard-table">

                            <span className="card-title">Invoices</span>

                            <div className="table-responsive">
                                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                <CustomerInvoiceList CustomerInvoiceCL={CustomerInvoice} fetchData={fetchData}/>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <RightNav /> */}

        </div>

    )

}