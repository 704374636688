import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { StateStampListList } from "../components/Lists/StateStampListList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FillingsByUser } from "./FillingsByUser";

export const StateStampList = () => {
  const { user } = useAuth();
  const token = user?.token;
  const [loading, setloading] = useState(false);

  const [stateStampList, setStateStampList] = useState([]);


  const fetchData = () => {
    setloading(true);
    fetch(config.url.API_URL + "/StateStampList/getAllStateStampList", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        setloading(false);
        if (responseJson.data != null) {

          setStateStampList(responseJson.data);
          responseJson.data.statesStampsText = responseJson.data.statesStampsText.replace(/<p>(.*?)<\/p>/g, '$1');
          console.log("StateStampListData", responseJson.data);
        }
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (


    <StateStampListList
      StateStampListCL={stateStampList}
      fetchData={fetchData}
    />

  );
};
