import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridToolbar,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { RiExpandUpDownLine } from "react-icons/ri";
import {
  Box,
  Button,
  Link,
  IconButton,
  SelectChangeEvent,
  SelectProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
} from "@mui/material";
import { config } from "../../Constants";
import { InsuranceCompanyModel } from "../../models/insuranceCompanyModel";
import { AddEditInsuranceCompany } from "../AddEdit/AddEditInsuranceCompany";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Moment from "moment";
import useAuth from "../../context/AuthProvider";
import {
  ReportModuleModel,
  ReportModuleModel_Resp,
} from "../../models/ReportModuleModel";
import { FieldAttributes, useField } from "formik";
import { TIMEOUT } from "dns";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { style } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
interface Iprops {
  ReportModuleCL: ReportModuleModel_Resp;
  fetchDataMain: (
    stateId: string,
    carrierId: string,
    customerId: string,
    outsourcestaffId: string,
    pageSize: number,
    currentPage: number
  ) => void;
}

export const ReportModuleList: React.FC<Iprops> = ({
  ReportModuleCL,
  fetchDataMain,
}) => {
  const initialICData: ReportModuleModel = {
    policyNumber: "",
    insuredName: "",
    state: "",
    effectiveDate: "",
    fileDate: "",
    paymentDate: "",
    paymentAmount: "",
    status: "",
    lineOfBusiness: "",
    customerPolicyId: 0,
    name: "",
    dateCreated: "",
  };
  const [_currentPage, setCurrentPage] = React.useState(1);
  const [_pageSize, setPageSize] = React.useState(50);
  const { user } = useAuth();
  const token = user?.token;
  const [Open, setOpen] = React.useState(false);
  const [Formdata, setFormData] = React.useState(initialICData);
  const [stateFilter, setStateFilter] = React.useState("");
  const [CarrierSelected, setCarrierSelected] = React.useState("");
  const [CustomerSelected, setCustomerSelected] = React.useState("");
  const [OutSourceSelected, setOutSourceSelected] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [selecteRecords, setSelecteRecords] = React.useState([]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const FMSelectStateCustom: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    // const [field, meta] = useField<{}>(props);
    // const errorText = meta.error && meta.touched ? meta.error : "";
    const { user } = useAuth();
    const token = user?.token;
    const initialStatedata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialStatedata]);

    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);
      setStateFilter(event.target.value);

      // fetch(config.url.API_URL + `/InsuranceCompany/getPartnerInsuranceCompanyByState?stateId=${event.target.value}`,
      //     {
      //         method: "GET",
      //         headers: new Headers({
      //             'Content-Type': 'application/json',
      //             'Authorization': `Bearer ${token}`,
      //         })
      //     }).then((response) => {
      //         if (response.ok) {
      //             return response.json();
      //         }
      //         throw new Error('Something went wrong');
      //     })
      //     .then((responseJson) => {
      //         // Do something with the response
      //         setloading(false);

      //         setInsuranceCompany(responseJson.data)

      //     }).catch((error) => {

      //         console.log(error)

      //     });

      fetchDataMain(
        event.target.value,
        CarrierSelected,
        CustomerSelected,
        OutSourceSelected,
        _pageSize,
        _currentPage
      );
    };

    const fetchData = () => {
      fetch(config.url.API_URL + "/States/getStates", {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          // Do something with the response

          setStates(responseJson.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    useEffect(() => {
      fetchData();
    }, []);

    return (
      <FormControl fullWidth>
        <InputLabel id="State-Select-Label">State</InputLabel>
        <Select
          onChange={handleChange}
          labelId="State-Select-Label"
          size="small"
          autoWidth
          label="State"
          value={stateFilter}
          IconComponent={ExpandMoreIcon}
          className="border-round custom-menu-item"
        // displayEmpty
        >
          {/* <MenuItem value="">State</MenuItem>  */}
          {States?.map((state, key) => (
            <MenuItem key={key} value={state.stateId} >
              {state.stateName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    );
  };
  const FMSelectCustomInsuranceCompany: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    const { user } = useAuth();
    const token = user?.token;
    const initialICdata = { insuranceCompanyId: 0, insuranceCompanyName: "" };
    const [InsuranceCompany, setInsuranceCompany] = useState([initialICdata]);
    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);
      setCarrierSelected(event.target.value);
      fetchDataMain(
        stateFilter,
        event.target.value,
        CustomerSelected,
        OutSourceSelected,
        _pageSize,
        _currentPage
      );
    };

    const fetchInsuranceCompany = () => {
      fetch(
        config.url.API_URL + "/InsuranceCompany/getInsuranceCompanyByUserId",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          // Do something with the response
          console.log("datainsurance", responseJson.data);
          if (responseJson.data != null) {
            setInsuranceCompany(responseJson.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      fetchInsuranceCompany();
    }, []);

    return (
      <FormControl fullWidth>
        <InputLabel id="Carrier-Select-Label" >Carrier</InputLabel>
        <Select
          onChange={handleChange}
          labelId="Carrier-Select-Label"
          label={"Carrier"}
          size="small"
          autoWidth
          value={CarrierSelected}
          IconComponent={ExpandMoreIcon}
          className="border-round custom-menu-item"
        >
          {/* <MenuItem className="custom-menu-item" value={""}>Carrier</MenuItem> */}
          {InsuranceCompany.map((insuranceCompany, key) => (
            <MenuItem

              key={key}
              value={insuranceCompany.insuranceCompanyId} className="custom-menu-item"
            >
              {insuranceCompany.insuranceCompanyName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const FMSelectCustomCustomer: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    // const [field, meta] = useField<{}>(props);
    // const errorText = meta.error && meta.touched ? meta.error : "";
    const { user } = useAuth();
    const token = user?.token;
    const initialdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialdata]);
    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);

      setCustomerSelected(event.target.value);
      fetchDataMain(
        stateFilter,
        CarrierSelected,
        event.target.value,
        OutSourceSelected,
        _pageSize,
        _currentPage
      );
    };

    const fetchCustomer = () => {
      fetch(config.url.API_URL + "/Partners/getPartners", {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          // Do something with the response

          setCustomer(responseJson.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      fetchCustomer();
    }, []);

    return (
      <FormControl fullWidth>
        <InputLabel id="State-Select-Label">Customers</InputLabel>
        <Select
          onChange={handleChange}
          labelId="State-Select-Label"
          className="border-round custom-menu-item"
          label="Customers"
          size="small"
          value={CustomerSelected}
          IconComponent={ExpandMoreIcon}
          // displayEmpty
        >
          {/* <MenuItem value={""}> Partners</MenuItem> */}
          {Customer.map((item, key) => (
            <MenuItem key={key} value={item.partnersId}>
              {" "}
              {item.customerName}{" "}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const FMSelectCustomOutSource: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    // const [field, meta] = useField<{}>(props);
    // const errorText = meta.error && meta.touched ? meta.error : "";
    const { user } = useAuth();
    const token = user?.token;
    const initialdata = { userId: 0, userFirstName: "" };
    const [OutSource, setOutSource] = useState([initialdata]);
    const handleChange = (event: SelectChangeEvent) => {
      setOutSourceSelected(event.target.value);
      fetchDataMain(
        stateFilter,
        CarrierSelected,
        CustomerSelected,
        event.target.value,
        _pageSize,
        _currentPage
      );
    };

    const fetchOutSourceList = () => {
      fetch(config.url.API_URL + "/Users/getOutsourceUsers", {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          // Do something with the response
          if (responseJson.data != null) {
            setOutSource(responseJson.data);
          }
          console.log("outsourcedata", responseJson.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      fetchOutSourceList();
    }, []);

    return (
      <FormControl fullWidth>
        <InputLabel id="State-Select-Label">Out Source</InputLabel>
        <Select
          onChange={handleChange}
          labelId="State-Select-Label"
          label="Partners"
          value={OutSourceSelected}
        >
          <MenuItem value={""}> No Filter</MenuItem>
          {OutSource.map((item, key) => (
            <MenuItem key={key} value={item.userId}>
              {" "}
              {item.userFirstName}{" "}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const columns: GridColDef[] = [
    // {
    //     field: 'dateCreated', type: 'text', headerName: 'Date Received', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) =>
    //     (

    //         <div>
    //             {params.row?.dateCreated ? moment(params.row?.dateCreated).format("MM-DD-YYYY") : ""}
    //         </div>

    //     )
    // },
    {
      field: "dateCreated",
      type: "text",
      headerName: "Date Received",
      flex: 0.8,
      headerAlign: "center",
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        return moment(params.row?.dateCreated).format("MM-DD-YYYY");
      },
    },
    {
      field: "fileDate",
      headerName: "Date Filed ",
      type: "date",
      flex: 1,
      align: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          {" "}
          {params.row.fileDate
            ? Moment(params.row.fileDate).format("MM-DD-YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "transactionTypeName",
      type: "text",
      headerName: "Transaction Type",
      flex: 1,
      headerAlign: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      valueGetter: (params) => {
        if (params.value) {
          var substring = params.value.match("Policy(.*)");
          if (substring) return params.value.replace(substring[0], "");
          else if (
            (substring =
              params.value.match("Endorsement(.*)") ||
              (substring = params.value.match("Cancellation(.*)")) ||
              (substring = params.value.match("Reinstatement(.*)")) ||
              (substring = params.value.match("Renewal(.*)")) ||
              (substring = params.value.match("Audit(.*)")))
          )
            return substring[0];
          else return params.value;
        }
      },
    },
    {
      field: "policyNumber",
      headerName: "Policy Number",
      type: "string",
      headerAlign: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      flex: 1,
    },
    // {
    //     field: 'effectiveDate', headerName: 'Effective Date', type: "date", flex: 1, align: 'center', headerAlign: 'center', renderCell: (params) =>
    //     (
    //         <div> {params.row.effectiveDate ? Moment(params.row.effectiveDate).format("MM-DD-YYYY") : ""}</div>
    //     )
    // },
    {
      field: "effectiveDate",
      type: "text",
      headerName: "Effective Date",
      flex: 0.8,
      headerAlign: "center", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        return moment(params.row?.effectiveDate).format("MM-DD-YYYY");
      },
    },

    {
      field: "insuredName",
      headerName: "Insured Name", hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title" >{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    // {
    //   field: "insuranceCompanyName",
    //   type: "string",
    //   headerName: "Carrier",
    //   flex: 1.5,
    //   align: "center",
    //   headerAlign: "center",
    // },

    // {
    //   field: "state",
    //   headerName: "State",
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },

    // {
    //   field: "paymentDate",
    //   headerName: "Payment Date",
    //   type: "date",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => (
    //     <div>
    //       {" "}
    //       {params.row.dateCreated
    //         ? Moment(params.row.dateCreated).format("YYYY-MM-DD")
    //         : ""}
    //     </div>
    //   ),
    // },
    // {
    //   field: "paymentAmount",
    //   headerName: "Payment Amount ",
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
    // {
    //   field: "lineOfBusiness",
    //   headerName: "Line of Business",
    //   type: "text",
    //   align: "center",
    //   headerAlign: "center",
    //   flex: 1,
    // },
  ];

  const [selectedRows, setSelectedRows] = useState<ReportModuleModel[]>();
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = React.useState();

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    const selectedIDs = new Set(ids);

    const selectedRowData = ReportModuleCL?.getData_ReportingModule_data.filter(
      (row) => selectedIDs.has(row?.customerPolicyId ?? "")
    );

    setSelectedRows(selectedRowData);
  };

  // function EditToolbar() {
  //     return (
  //         <Box
  //             sx={{
  //                 borderBottom: 1,
  //                 borderColor: 'divider',
  //                 p: 1,
  //             }}
  //         >
  //             <Button
  //                 onClick={handleClickOpen}
  //                 color="primary"
  //                 variant="outlined"
  //             >
  //                 Add
  //             </Button>
  //         </Box>

  //     );
  // }

  const handleDelete = () => {
    const custid: string =
      selectedRows?.map((x) => x.customerPolicyId).join(",") ?? "";
    if (custid === "") {
      alert("Please Select at least one Record");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          config.url.API_URL +
          "/ReportModule/Delete?multipleIds=" +
          `${custid}`,
          {
            method: "Delete",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong");
          })
          .then((responseJson) => {
            fetchDataMain(
              stateFilter,
              CarrierSelected,
              CustomerSelected,
              OutSourceSelected,
              _pageSize,
              _currentPage
            );
          })
          .catch((error) => {
            console.log(error);
          });
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const handleNextPreviousPage = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataMain(
      stateFilter,
      CarrierSelected,
      CustomerSelected,
      OutSourceSelected,
      _pageSize,
      newPage
    );
  };

  const handlePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    fetchDataMain(
      stateFilter,
      CarrierSelected,
      CustomerSelected,
      OutSourceSelected,
      newPageSize,
      _currentPage
    );
  };


  function EditToolbar() {
    return (
      <React.Fragment>
        <div className="card-title">
          <span className="d-flex">Reports</span>
        </div>

        <Box sx={{}}>
          <Grid container xs={12} display="flex " margin={"10px 0px"} className="align-items-center" justifyContent={"space-between"}>
            <Grid container alignItems={"center"} className="d-flex" xs={9} >
              <Grid item xs={2} sm={6} md={1.2}>
                <FMSelectStateCustom name="state" />
              </Grid>
              <Grid item xs={.2}></Grid>
              <Grid item xs={2} sm={6} md={1.2} >
                <FMSelectCustomInsuranceCompany />
              </Grid>
              <Grid item xs={.2}></Grid>
              <Grid item xs={2} sm={6} md={1.2} >
                <FMSelectCustomCustomer />

              </Grid>   <Grid item xs={.2}></Grid>
              <Grid item xs={.6} sm={6} md={1} alignItems={"center"} className="filter-blue" > <GridToolbarFilterButton /></Grid>
            </Grid>
            <Grid item xs={1.3} sm={6} md={1.3} className=" select-input-border">
              {user?.userRoles.split(",").includes("Super Admin") ? (
                <button
                  onClick={handleDelete}
                  className="active-btn-blue"
                >
                  Remove
                </button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>



    )
  }

  return (
    <React.Fragment>



      {/* <div
        className="row col-lg-12 align-items-center"
        style={{ marginBottom: "10px" }}
      >
        <div className="col-lg-1">
            <Grid xs={1}   className="select-input-border text-input-box" >
          <FMSelectStateCustom /></Grid>
        </div>

        <div className="col-lg-2">
          <FMSelectCustomInsuranceCompany className="select-input-border text-input-box" />
        </div>
        {user?.userRoles.split(",").includes("Super Admin") ? (
          <div className="col-lg-2">
            <FMSelectCustomCustomer />
          </div>
        ) : (
          ""
        )}
        {user?.userRoles.split(",").includes("Super Admin") ? (
          <div className="col-lg-2">
            <span>
              <button
                onClick={handleDelete}
                style={{ marginLeft: "110%", height: "50px" }}
                className="btn btn-secondary"
              >
                Remove
              </button>
            </span>
          </div>
        ) : (
          ""
        )}
      </div> */}

      {/* <div style={{ marginBottom: "-30px" }}>
                <span >
                    <Button
                        onClick={handleDelete}

                        style={{ marginRight: "6px", height: "50px", width: '7%' }}
                        className="btn action-btn m-1"
                    >
                        Remove
                    </Button>


                </span>
            </div> */}

      <div style={{ height: "75vh", width: "100%" }}>

        <DataGrid

          getRowId={(r) => r.customerPolicyId}
          rows={ReportModuleCL?.getData_ReportingModule_data}
          columns={columns}
          pageSize={_pageSize}
          pagination
          rowCount={ReportModuleCL.totalCount}
          // rowsPerPageOptions={[25, 50, 100]}
          paginationMode="server"
          onPageChange={(newPage) => handleNextPreviousPage(newPage)}
          onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
          style={{ textAlign: "center" }}
          checkboxSelection={
            user?.userRoles.split(",").includes("Super Admin") ? true : false
          }
          onSelectionModelChange={
            user?.userRoles.split(",").includes("Super Admin")
              ? (newRowSelectionModel) => {
                onRowsSelectionHandler(newRowSelectionModel);
              }
              : undefined
          }
          components={{
            Toolbar: EditToolbar,
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            fontFamily: "'Proxima Nova', sans-serif;",
            border: "0px",
            fontSize: "16px",
          }}
          // sx={
          //     {
          //         fontFamily: "'Proxima Nova', sans-serif;",
          //         border: "0px",
          //         fontSize: "16px"
          //     }
          // }
          experimentalFeatures={{ newEditingApi: true }}
          // components={{

          //     Toolbar: GridToolbarFilterButton,
          // }}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible

            paymentDate: user?.userRoles.split(",").includes("Super Admin")
              ? true
              : false,
            paymentAmount: user?.userRoles.split(",").includes("Super Admin")
              ? true
              : false,
            // status: user?.userRoles.split(",").includes("Super Admin") ? true : false,
            lineOfBusiness: user?.userRoles.split(",").includes("Super Admin")
              ? true
              : false,
            // dateCreated: user?.userRoles.split(",").includes("Super Admin") ? true : false,
          }}
        />
      </div>

    </React.Fragment>
  );
};
