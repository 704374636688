import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button, IconButton, Link } from "@mui/material";
import { PolicyModel } from '../../models/PolicyModel';
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import { AddEditRejectionNotePolicy } from "../AddEdit/AddEditRejectionPolicy";

interface Iprops {
    policyCL: PolicyModel[],
    fetchData: () => void,

}


export const PolicyList: React.FC<Iprops> = ({ policyCL, fetchData }) => {
    const { user } = useAuth();
    const token = user?.token;
    const [Open, setOpen] = React.useState(false);

    const initialdata: PolicyModel = { batchId: 0, policyId: 0, customPolicyId: "", policyNumber: "", previousPolicyNumber: "", binderNumber: "", effectiveDate: new Date(), policyStatusId: 0, statusNotes: "", insured_Name: "", insured_AddressLine1: "", insured_AddressLine2: "", insured_City: "", insured_StateOrProvince: "", insured_PostalCode: "", insured_Country: "" ,dateCreated: new Date}
    const [Formdata, setFormData] = React.useState(initialdata);

    const handleEdit = (PloicyData: PolicyModel) => {
        setFormData(PloicyData);
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };



    const handleApprove = (id: number) => {
        //call api to save data
        fetch(config.url.API_URL + "/Policy/Approve/?Policyid=" + `${id}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                // Do something with the response
                fetchData();

                setOpen(false);
            }).catch((error) => {
                console.log(error)

            });

    };


    const columns: GridColDef[] = [

       

       
        { field: 'policyNumber', type: 'string', headerName: 'Policy Number', flex: 1.5, },
        { field: 'insured_Name', type: 'string', headerName: 'Insured Name', flex: 1, },
        { 
            field: 'effectiveDate', headerName: 'Effective Date', flex: 1, renderCell:(params)=>
            (
              <div>
                {params.row.effectiveDate?.toString().split('T')[0]}
              </div>
               
            )
         },
        { field: 'statusNotes', type: 'string', headerName: 'Status Notes', flex: 1, },

        { field: 'dateCreated', type: 'date', headerName: 'Date Created', flex: 1, },

        {
            field: 'Status', type: 'string', headerName: 'Current Status', flex: 1, renderCell: (params) => (<div>

                {
                    (params.row.policyStatusId == 2) ? <div>Approved</div> : (params.row.policyStatusId == 3) ? <div>Rejected</div> : <div>Pending</div>}
            </div>
            )
        },

        {
            field: 'id', flex: 1, headerName: "Approve or Reject", renderCell: (params) => (

                user?.userRoles.split(",").includes("Super Admin") ?
                    <div>

                        <IconButton
                            color="primary"
                            onClick={() => { handleApprove(params.row.policyId) }}
                        >
                            <AssignmentTurnedInIcon />
                        </IconButton >

                        <IconButton
                            color="error"
                            onClick={() => { handleEdit(params.row) }}

                        >
                            <CancelIcon />
                            {/* {params.row.isDeleted ? <CancelIcon  /> : ""  />} */}
                        </IconButton >
                    </div>
                    : "")
        },
        {
            field: 'policyId',
            headerName: '',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<number>) => (
                
                <Link href={'/Transaction/' + params.value} sx={{textDecoration : "none "}}>
                   <button type="button" className="btn btn-primary action-btn"><i className="fa-solid fa-right-long"></i></button>

                </Link>

            ),
        },

    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                getRowId={(r) => r.policyId}
                rows={policyCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[10]}
                showCellRightBorder ={false}
                showColumnRightBorder={false}
               
                sx={
                    {
                        fontFamily:"'Proxima Nova', sans-serif;",
                        border:"0px",
                        fontSize: "16px"
                        
                        

                    }
                }

            />

            <AddEditRejectionNotePolicy handleClose={handleClose} Open={Open} PolicyModelCl={Formdata} handleSubmit={handleSubmit} initValues={initialdata} />
        </div>


    )




}