import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, selectClasses } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { LineOfBusinessList } from "../components/Lists/LineOfBusinessList";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { RightNav } from "../components/Nav/RightNav";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { FieldAttributes } from "formik";


export const Lineofbusiness = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);


    const [Lineofbusiness, setLineOfBusiness] = useState([])
    const initialdata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialdata]);
 
    const [SelectedState, setSelectedState] = React.useState<string>('0');
    const fetchLOBByStateId = (stateId: string) => {
        
        fetch(config.url.API_URL + `/LineOfBusiness/getAllLOB?stateId=${stateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                if (responseJson.data != null) {

                    setLineOfBusiness(responseJson.data)
                    console.log("statedata123456", responseJson)
                }

            }).catch((error) => {

                console.log(error)

            });
    }

    function refreshData() {
        fetchLOBByStateId(SelectedState);
    }
    const fetchStateData = () => {

        fetch(config.url.API_URL + `/States/getStates`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {

                if (responseJson.data != null) {

                    setStates(responseJson.data)
                    console.log("listdata", responseJson)
                }

            }).catch((error) => {

                console.log(error)

            });
    }
    useEffect(() => {
        fetchStateData();
        fetchLOBByStateId(SelectedState)


    }, [])
 
   

    return (
        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

        //                         <LoggedInUserInfo ButtonText="Add New" Link="" />


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="card dashboard-table">

        //                                     <div className="row col-lg-12">

        //                                         <div className="col-lg-8" style={{ fontSize: "32px" }}>
        //                                             <span>Line of Business</span>
        //                                         </div>
        //                                         <div className="col-lg-4" >
        //                                             <FMSelectStateCustom name='state' />
        //                                         </div>


        //                                     </div>


        //                                     <div className="table-responsive">
        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                    <LineOfBusinessList LineOfBusinessCL={Lineofbusiness}
                                                      fetchData={refreshData}
                                                       selectedState={SelectedState} 
                                                       setSelectedStates={setSelectedState} 
                                                       selectedStatename={States.find(x => x.stateId == Number(SelectedState))?.stateName} 
                                                       fetchLOBByStateId={fetchLOBByStateId}
                                                        States={States} />
        //                                             /* <LineOfBusinessList LineOfBusinessCL={Lineofbusiness} fetchData={fetchData} /> */
        //                                         </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>


    )

   

}
