import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button';
import { Divider, FormControl, Grid, LinearProgress, Link, } from '@mui/material';
import { Form, Formik, useField } from 'formik';
import { config } from '../../Constants';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { CustomerPaymentLinkModel, CustomerPaymentLinkModel_VS } from '../../models/CustomerPaymentLinkModel';
import {useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { Box, margin } from '@mui/system';
import { RightNav } from '../Nav/RightNav';
import { LoggedInUserInfo } from '../Elements/loggedInUserInfo';
import { FMSelectPartners } from '../Elements/select';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface Iprops {
    abc: string
}

export const FormSpan: React.FC<Iprops> = ({ abc }) => {
    const [field] = useField(abc);
    return (
        <span>{field.value}</span>
    );
};



export const AddEditCustomerPaymentLinks = () => {
    const params = useParams();
    const initValues: CustomerPaymentLinkModel = {
        customerPaymentLinkId: 0,
        payee: '',
        emailAddress: '',
        amount: 0,
        notes: '',
        isExpired: false
    }
    const navigate1 = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const ref = useRef(null);

    const [custPaymentValues, setcustPaymentValues] = useState<CustomerPaymentLinkModel>(initValues);
    const [slide1, setSlide1] = useState<boolean>(true);

    const HnadleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        custPaymentValues.amount = Number(e.target.value);
    }

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/CustomerPaymentLink/getById?customerPaymentId=${params.customerPaymentLinkId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
                if (responseJson.data != null) {

                    setcustPaymentValues(responseJson.data);
                }
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });
    }

    const setAmount = () => {

        //    console.log(ref);
    }

    useEffect(() => {

        fetchData();
    }, []);

    return (

        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText="" Link=""/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card-body-overflow">
                                            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}


                                            <Formik initialValues={custPaymentValues}
                                                enableReinitialize={true}
                                                validateOnChange={true}
                                                validationSchema={CustomerPaymentLinkModel_VS}
                                                onSubmit={(data, { setSubmitting }) => {
                                                    setloading(true);
                                                    // console.log(data);
                                                    fetch(
                                                        config.url.API_URL + '/CustomerPaymentLink/AddEdit',
                                                        {
                                                            method: 'POST',

                                                            headers: new Headers({
                                                                'Content-Type': 'application/json',
                                                                'Authorization': `Bearer ${token}`,
                                                            }),
                                                            body: JSON.stringify(data)  // <-- Post parameters

                                                        })
                                                        .then((response) => {
                                                            if (response.ok) {
                                                                return response.json();
                                                            }
                                                            throw new Error('Something went wrong');
                                                        })
                                                        .then((res) => {
                                                            // Do something with the response

                                                            setloading(false);
                                                            navigate("/paymule/Transactions");


                                                            setSubmitting(true)
                                                        }).catch((error) => {
                                                            setloading(false);
                                                            console.log(error)
                                                        });
                                                }} >
                                                {({ values, isSubmitting, setFieldValue }) => (
                                                    <React.Fragment>

                                                        <div style={{ width: '100%' }}>

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    p: 1,
                                                                    m: 4,
                                                                    bgcolor: 'background.paper',
                                                                    borderRadius: 1,
                                                                }}
                                                            >

                                                                <Form>

                                                                    <Slide direction='up' in={slide1} mountOnEnter unmountOnExit>

                                                                        <Paper elevation={3} sx={{

                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            width: "100vh",
                                                                            padding: "30px",

                                                                        }}>
                                                                            <div className="container">
                                                                                <div className="row col-lg-12">
                                                                                    <div className="col-lg-6 d-flex">
                                                                                        <div className="col-lg-2">
                                                                                            <Link href="/"> 
                                                                                                <Button type="button" title="Home"
                                                                                                    className="btn action-btn" ><HomeIcon />
                                                                                                </Button>
                                                                                            </Link> 
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <Button

                                                                                                className=" btn action-btn"
                                                                                                onClick={goBack}
                                                                                                title="Back"
                                                                                            >
                                                                                                <ArrowBackIosIcon />
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div>
                                                                                    <Grid container spacing={2} >

                                                                                        <Grid item xs={12} display='flex' flexDirection='row'>


                                                                                            <Grid item xs={3}><label>Payer</label></Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                                    <FMTextField name="payee" autoFocus margin="dense" label="Payer" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} display='flex' flexDirection='row' marginBottom="10px">
                                                                                            <Grid item xs={3}><label>Email Address</label></Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                                    <FMTextField name="emailAddress" label="Email Address" type="email" fullWidth variant="outlined" sx={{ m: 2 }} />
                                                                                                </FormControl>
                                                                                            </Grid>

                                                                                        </Grid>

                                                                                        <Grid item xs={12} display='flex' flexDirection='row' >

                                                                                            <Grid item xs={3}><label>Amount</label></Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                                    <FMTextField name="amount" id="tesamount" InputLabelProps={{ shrink: true }} label="Amount" autoFocus margin="dense" type="number" variant="outlined" sx={{ m: 2 }} />
                                                                                                </FormControl >
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} display='flex' flexDirection='row' >

                                                                                            <Grid item xs={3}><label>Payment Date</label></Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                                    <FMTextField name="paymentDate" InputLabelProps={{ shrink: true }} label="Payment Date" autoFocus margin="dense" type="date" variant="outlined" sx={{ m: 2 }} />
                                                                                                </FormControl >
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} display='flex' flexDirection='row'>


                                                                                            <Grid item xs={3}><label>Partner</label></Grid>
                                                                                            <Grid item xs={8}>


                                                                                                <FMSelectPartners autoWidth={true} name="partnersId" />

                                                                                            </Grid>


                                                                                        </Grid>
                                                                                        {/* 
                                                                                            <Grid item xs={12} display='flex' flexDirection='row' justifyContent="center">

                                                                                              <Grid item xs={2} >
                                                                                                  <Button variant="outlined" type='button' onClick={() => { setSlide1(false); setSlide2(true); setSlide3(false); setAmount(); }}  >Next</Button>
                                                                                             </Grid>

                                                                                              </Grid> */}
                                                                                        <Grid item xs={12} display='flex' flexDirection='row'>
                                                                                            <Grid item xs={3}><label>Notes</label></Grid>
                                                                                            <Grid item xs={8}>
                                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                                    <FMTextField name="notes" multiline rows={4} label="Notes" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} display='flex' flexDirection='row' justifyContent="center">

                                                                                            {/* <Grid item xs={2}  >
                                                                                              <Button variant="outlined" type='button' onClick={() => { setSlide1(false); setSlide2(true); setSlide3(false) }}  >Back</Button>


                                                                                             </Grid> */}
                                                                                            <Grid item xs={3} >

                                                                                                <Button variant="outlined" type='submit' disabled={isSubmitting}  >Create Link</Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>

                                                                            </div>


                                                                        </Paper>


                                                                    </Slide>
                                                                    {/* <Slide direction='up' in={slide2} mountOnEnter unmountOnExit>

                                                    <Paper elevation={3} sx={{

                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        width: "100vh",
                                                        padding: "30px",
                                                        height: "60vh"
                                                    }}>
                                                        <Grid container spacing={2} >
                                                         
                                                            <Grid item xs={12} spacing={2} display='flex' flexDirection='row' justifyContent="center">


                                                                <Grid item xs={5} >
                                                                    <div className='border border-success'>
                                                                        <FormControl sx={{ width: '100%' }} >
                                                                            <Paper elevation={3}>



                                                                                <div className='d-flex justify-content-center'>

                                                                                    <span className="logo_name"> <img style={{ width: "87px" }} src="/assets/brand/credit-card.png" className="img-logo" alt="..." /></span>


                                                                                </div>
                                                                                <Divider />
                                                                                <div className="table-responsive " >
                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <th>Fee Name</th>
                                                                                                <th>Amount</th>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>Premium Amount
                                                                                                

                                                                                                </td>
                                                                                                <td>$<FormSpan abc="amount" /></td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>Total Amount</td>
                                                                                                <td>$<FormSpan abc="amount" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                            </Paper>
                                                                        </FormControl>
                                                                    </div>

                                                                </Grid>
                                                                <Grid item xs={0.2}><label></label></Grid>
                                                                <Grid item xs={5} marginBottom="30px" >
                                                                    <div className='border border-success'>
                                                                        <FormControl sx={{ width: '100%' }} >
                                                                            <Paper elevation={3}>

                                                                                <div className='d-flex justify-content-center m-2'>

                                                                                    <span className="logo_name"> <img style={{ width: "70px" }} src="/assets/brand/bank.png" className="img-logo" alt="..." /></span>


                                                                                </div>
                                                                                <Divider />
                                                                                <div className="table-responsive">

                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <th>Fee Name</th>
                                                                                                <th>Amount</th>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>Premium Amount</td>
                                                                                                <td>$<FormSpan abc="amount" /></td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>Total Amount</td>
                                                                                                <td>$<FormSpan abc="amount" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>


                                                                            </Paper>
                                                                        </FormControl>
                                                                    </div>

                                                                </Grid>

                                                            </Grid>


                                                            <Grid item xs={12} display='flex' flexDirection='row' justifyContent="center" >

                                                                <Grid item xs={2} >
                                                                    <Button variant="outlined" type='button' onClick={() => { setSlide1(true); setSlide2(false); setSlide3(false) }}  >Back</Button>

                                                                </Grid>
                                                                <Grid item xs={2} >

                                                                    <Button variant="outlined" type='button' onClick={() => { setSlide1(false); setSlide2(false); setSlide3(true) }}  >Next</Button>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Paper>


                                                </Slide> */}
                                                                    {/* <Slide direction='up' in={slide2} mountOnEnter unmountOnExit>

                                                    <Paper elevation={3} sx={{

                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        width: "100vh",
                                                        padding: "30px",
                                                        height: "60vh"
                                                    }}> */}
                                                                    <Grid container spacing={2} >

                                                                    </Grid>
                                                                    {/* </Paper>


                                                </Slide> */}

                                                                </Form>
                                                            </Box>
                                                        </div>
                                                    </React.Fragment>

                                                )
                                                }


                                            </Formik >

                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>


    )
}
