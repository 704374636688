import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import useAuth from "../../context/AuthProvider";
import { EmailTemplateModel } from "../../models/EmailTemplateModel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { config } from "../../Constants";
import Swal from "sweetalert2";
import { RiExpandUpDownLine } from "react-icons/ri";
interface Iprops {
    EmaiTemplateCL: EmailTemplateModel[],
    fetchData: () => void
}

export const EmailTemplateList: React.FC<Iprops> = ({ EmaiTemplateCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const navigate = useNavigate();

    const [Open, setOpen] = React.useState(false);
    const goBack = () => {
        navigate(-1);
    }

    // const handleDelete = (id: number) => {
    //     fetch(config.url.API_URL + "/EmailTemplate/Delete?id=" + `${id}`,
    //         {
    //             headers: new Headers({
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`,
    //             }),
    //             method: "Delete",
    //         }
    //     )
    //         .then((response) => {
    //             if (response.ok) {
    //                 return response.json();
    //             }
    //             throw new Error('Something went wrong');
    //         })
    //         .then((res) => {
    //            alert("Data Deleted Succesfully")
    //             fetchData();
    //         }).catch((error) => {
    //             console.log(error)
    //         });


    // };


    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    fetch(config.url.API_URL + "/EmailTemplate/Delete?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((response) => {
                            if (response.ok) {
                                Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                )
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            fetchData();
                            setOpen(false);
                        }).catch((error) => {
                            console.log(error)
                        });

                }
            })
    };


    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12" style={{ marginBottom: "10px" }}>
                    {/* <div className="col-lg-4 d-flex">
                        <div style={{ marginRight: "2px" }}>
                            <Link href="/">
                                <Button type="button" title="Home"
                                    className="btn action-btn" ><HomeIcon />
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button

                                className=" btn action-btn"
                                onClick={goBack}
                                title="Back"
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-between">
                        <div className="" style={{ fontSize: "32px" }}>
                            <span className="card-title" >Messaging Configuration</span>
                        </div>
                        {user?.userRoles.split(",").includes("Super Admin") ?
                            <div className="c" style={{ textAlign: "right" }}>
                                <Link href={'/AddEditEmailTemplate/' + 0} color="#1976D2">
                                    <button
                                        className="active-btn-blue"
                                    >
                                        New Template
                                    </button>
                                </Link>
                            </div>
                            : ""}
                    </div>
                </div>
            </Box>

        );

    }


    const columns: GridColDef[] = [

        {
            field: 'templateName', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerName: 'Template Name', flex: 1
        },
        {
            field: 'subject', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'text', headerName: 'subject', flex: 2
        },
        {
            field: 'content', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'text', headerName: 'Body', flex: 2
        },

        {
            field: 'emailTemplateId',
            headerName: 'Actions', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<number>) =>
            (
                <div>
                    <Link href={'/AddEditEmailTemplate/' + params.value} sx={{ textDecoration: "none " }}>
                        <IconButton
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </IconButton>
                    </Link>
                    {user?.userRoles.split(",").includes("Super Admin") ?
                        <IconButton
                            color="error"
                            onClick={() => { handleDelete(params.row.emailTemplateId); }}

                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </IconButton>
                        : ""}
                </div>

            ),
        },

    ];

    return (

        <div style={{ height: '70vh', width: '100%' }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGrid
                getRowId={(r) => r.emailTemplateId}
                rows={EmaiTemplateCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                        padding: "12px 0px"
                    },
                    fontFamily: "'Proxima Nova', sans-serif;",
                    border: "12px",
                    fontSize: "16px",
                }}
            />
        </div>


    )
}