import React, { useEffect, useState } from "react";
import { PolicyList } from "../components/Lists/PolicyList";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { config } from "../Constants";
import { BatchInfo } from "../components/InfoPages/BatchInfo";
import { BatchModel } from "../models/BatchModel";
import useAuth from "../context/AuthProvider";

export const Policies = () => {
    const { user } = useAuth();
    const token = user?.token;
    const initialbatch: BatchModel = { batchId: 0, ipaddress: "", receivedOn: new Date(), muleResponse: "", muleRespondedOn: new Date(), muleErrorCode: 0, muleErrorMessage: "", slaresponse: "", slaerrorCode: 0, slaerrorMessage: "" }
    const [Batchcl, setBatchdetails] = useState(initialbatch);
    const [policies, setpolicies] = useState([]);
    const params = useParams();
    const [loading, setloading] = useState(false);


    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/Policy/getAllPolicies`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                // Do something with the response
                setloading(false);
                setpolicies(res.data)

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });




    }
    useEffect(() => {

        fetchData();

    }, [])

    return (

        <div className="card-body">
            <div className="col-lg-12 col col-margin">
                <div className="card dashboard-table">
                    <span className="card-title">Policies</span>
                    <div className="table-responsive">
                        {loading && <LinearProgress sx={{backgroundColor: "#132D3D"}} />}
                        <PolicyList policyCL={policies} fetchData={fetchData} />
                    </div>
                </div>
            </div>
        </div>



    );

}
