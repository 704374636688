import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid } from "@mui/material";
import { Formik } from "formik";
import { Form } from 'formik';
import { useNavigate } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { PaymentModel } from "../../models/PaymentsModel";
import { FMTextField } from "../Elements/input";

interface Iprops {
  PaymentCL: PaymentModel,
  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void
  initValues: PaymentModel
  PolicyNumber?: string
}

export const AddEditPaymentNotes: React.FC<Iprops> = ({ handleClose, Open, handleSubmit, PaymentCL, initValues, PolicyNumber }) => {
  const initialValues: PaymentModel = initValues ? PaymentCL : initValues;
  const navigate = useNavigate();
  const { user } = useAuth();
  const token = user?.token;

  return (

    <Dialog open={Open} onClose={handleClose}>
      <DialogTitle>Please Add Payment Notes for <b>{PolicyNumber?.toString()}</b>  </DialogTitle>
      <Formik initialValues={initialValues}
        validateOnChange={true}
        //   validationSchema ={PolicyModel_VS}
        onSubmit={(data, { setSubmitting }) => {
          fetch(
            config.url.API_URL + '/PolicyPayments/Save',
            {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }),
              body: JSON.stringify(data)  // <-- Post parameters

            })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error('Something went wrong');
            })
            .then((res) => {
              // Do something with the response
              console.log(res);
              setSubmitting(true)
              handleClose();
              alert(res.message);
              handleSubmit();
              // if(res.resp == 0)
              // {
              //   navigate("/Payment/"+ res.data.toString());

              // }


            }).catch((error) => {
              console.log(error)
            });

        }} >
        {({ values, isSubmitting }) => (
          <Form>

            <DialogContent>
              <Box sx={{ display: 'flex', m: 2 }}>
                <FormControl sx={{ width: '100%' }} >
                  <FMTextField name="notes" autoFocus multiline rows={3} margin="dense" label="Notes" placeholder="Notes" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                </FormControl>
              </Box>

            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={handleClose} type="button" >Cancel</Button>
              <Button variant="outlined" type="submit" disabled={isSubmitting} >Submit</Button>
            </DialogActions>

          </Form>

        )}
      </Formik>
    </Dialog>

  );
}

