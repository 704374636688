import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { FMSelectCustomerPartnerStatus, FMSelectState } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';
import { RiskLocationAddress } from '../../models/RiskAddressModel';
import { useParams } from 'react-router-dom';
import { LateFilingReasonModel } from '../../models/LateFilingReasonModel';
import { CustPolicyDetailsModel } from '../../models/CustomerPolicyDataModel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
interface Iprops {
    isOpen: boolean
    policy: CustPolicyDetailsModel
    onSubmit: (val: CustPolicyDetailsModel) => void
    onClose: () => void
    fetchMainData:()=>void
}


const lateReasons = [
    "Agent delay in returning SL2",
    "UW delay in sending policy documents",
    "Backdating endorsement",
    "No late Filing",
]

export const AddEditLateFilingReason: React.FC<Iprops> = ({
    isOpen, policy, onSubmit, onClose,fetchMainData
}) => {
    const initValue = {
        policyId: policy?.policyId,
        isLateFilling: policy?.isLateFilling,
        lateFilingReason: policy?.lateFilingReason,
    }
    const [loading, setloading] = React.useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const [reason, setReason] = useState<string>('');
    useEffect(() => {
        setReason(lateReasons.includes(policy?.lateFilingReason ?? '') ? policy.lateFilingReason ?? '' : '')
    }, [policy])

    return (

        <Dialog open={isOpen} onClose={onClose}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DialogTitle className='card-title' >Late Filing</DialogTitle>
            <Formik
                initialValues={initValue}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {
                    setloading(true);
                    //data.isLateFilling = true
                   
                    //if (reason !== 'Other') {
                        if (reason !== 'No late Filing') {
                        data.lateFilingReason = reason
                    }
                    data.lateFilingReason = reason
                    setSubmitting(true)
                    fetch(config.url.API_URL + '/CustomerPolicy/setLateFilingReason', {
                        method: 'POST',

                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        body: JSON.stringify(data)  // <-- Post parameters

                    }).then((response) => {
                        setloading(false);
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    }).then(() => {
                        setloading(false);
                        onSubmit(data)
                        onClose()
                        fetchMainData()
                    }).catch((error) => {
                        setloading(false);
                        console.log(error)
                    });
                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form className=' '>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl className='main-form-2' sx={{ width: '100%' }} >
                                        {/* <FormControl fullWidth><FormHelperText className='formtexthelper-heading'>Select Reason</FormHelperText> */}
                                        <InputLabel id="Late-Select-Label">Select Late Filing Reason</InputLabel>
                                            <Select
                                                value={reason}
                                                labelId="Late-Select-Label"
                                                label="Select Late Filing Reason"
                                                onChange={(event: SelectChangeEvent<string>) => {
                                                    const selectedReason = event.target.value;
                                                    setReason(selectedReason);
                                                    if (selectedReason === 'No late Filing') {
                                                        setFieldValue('lateFilingReason', '')
                                                    } else {
                                                        setFieldValue('lateFilingReason', reason)
                                                    }
                                                }}
                                                IconComponent={ExpandMoreIcon}
                                                sx={{ minWidth: "400px" }}
                                            >
                                                {/* <MenuItem value="No late Filing">No Late Filing</MenuItem> */}
                                                {lateReasons.map((item, index) => <MenuItem
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>)}
                                            </Select>
                                        {/* </FormControl> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%', marginTop: '60px' }}>
                                        <FMTextField
                                            name="lateFilingReason"
                                            //hidden={reason === 'No late Filing' ? true: false  }
                                            hidden={ true  }
                                            multiline
                                            rows={4}
                                            InputLabelProps={{ shrink: true }}
                                            label="please type reason"
                                            autoFocus
                                            margin="dense"
                                            type="text"
                                            variant="outlined"
                                            sx={{ m: 2 }}
                                        />
                                    </FormControl >
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <button className=' btn-2 btn d-flex btn-primary search-btn ' type='button' onClick={onClose}  >Cancel</button>
                            <button className='btn  btn-primary active-btn-blue  search-btn ' type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
