import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { UsersList } from "../components/Lists/UsersList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";


export const Users = () => {

    const [users, setUsers] = useState([])
    const [loading, setloading] = useState(false);

    const { user } = useAuth();
    const token = user?.token;
    const fetchData = () => {
        setloading(true);

        fetch(config.url.API_URL + '/Users/getAllUsers',
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false);
                console.log("User",user)
                // Do something with the response
                setUsers(res.data);
                console.log("listData", res.data);
            }).catch((error) => {
                setloading(false);

                console.log(error)
            });


    }


    useEffect(() => {
        fetchData();
    }, [])



    return (
       
    <UsersList UsersCL={users} fetchData={fetchData} />
    
    )

}