import { useEffect, useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, TextField } from "@mui/material"
import useAuth from "../context/AuthProvider"
import { config } from "../Constants"
import { ClientBillingReportModel, ClientBillingReportModel_Resp } from "../models/ClientBillingReportModel"
import { ClientBillingReportMonthlyList } from "../components/Lists/ClientBillingReportMonthlyList"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClientBillingReportAnnualList } from "../components/Lists/ClientBillingReportAnnualList"
import React from "react"
import { padding } from "@mui/system"
import { months } from "moment"
import { ClientBillingReportMonthlyListTest } from "./testExcelPage"

const keyNames: (keyof ClientBillingReportModel)[] = [
  'stateId', 'reportName', 'period', 'license', 'dateFinal', 'isPaymentRequired', 'paymentType', 'isReportRequired', 'licenseMain', 'premiumReport', 'feeDue'
]

export const ClientBillingReport = () => {

  const { user } = useAuth()
  const token = user?.token
  const [loading, setloading] = useState(false)

  const initValue: ClientBillingReportModel_Resp = {
    items: [],
    totalCount: 0,
    pageCount: 0,
    pageSize: 25,
    currentPage: 0,
  }
  const [itemList, setItemList] = useState<ClientBillingReportModel[]>(initValue.items)
  const [currentPage, setCurrentPage] = useState(initValue.currentPage)
  const [pageSize, setPageSize] = useState(initValue.pageSize)

  const fetchCBR = async ({ carrierId, pageSize, currentPage }: { carrierId: string, pageSize: number, currentPage: number }) => {
    try {
      setloading(true)

      const res = await fetch(config.url.API_URL + `/ReportModule/ClientBillingReportView?pageSize=${0}&&currentPage=${0}`, {
        method: "GET",
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }),
      }).then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Something went wrong')
      })

      // Do something with the response
      if (!res.data || !res.data.items) return
      const updates = res.data.items.map((item: ClientBillingReportModel, index: number) => ({
        rowNum: (index + 1).toString(),
        ...item,
      }))

      setItemList(updates)
      console.log('fetch success: ', { updates })
      setloading(false)

    } catch (error) {
      setloading(false)
      console.error(error)
    }
  }

  const [partnerName, setPartnerName] = useState<string>("");
  const fetchData = (action: string,pageSize: number, currentPage: number,period: string,PartnerId: string,month: string, year: string,columnField: string, value: string, field: string ,sort: string, operators: string) => {
    const params = new URLSearchParams();

    params.append('pageSize', pageSize.toString() || '');
    params.append('currentPage', currentPage.toString() || '');
    params.append('period', period || '');
    params.append('PartnerId', PartnerId || '');
    if (period === "Monthly") {
      params.append('month', month || '1');
    } else {
      params.append('month', '0');
    }
    params.append('year', year || '');
    params.append('fieldName', columnField || '');
    params.append('value', value || '');
    params.append('field', field || '');
    params.append('sort', sort || '');
    params.append('operators', operators || '');

    fetch(`${config.url.API_URL}/ClientBilling/${action}?${params.toString()}`, {
        method: "GET",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }),
    })
    .then(response => {
        if (response.ok) {
            return response.json();
        }
        throw new Error("Something went wrong");
    })
    .then(responseJson => {
        if (responseJson.data && responseJson.data.items.length > 0) {
            setItemList(responseJson.data.items);
            if (responseJson.data.items.length !== 0) {
                setPartnerName(responseJson.data.items[0].partnerName);
            }
            setloading(false);
            console.log("alldata", responseJson.data);
        } else {
            setItemList([]);
            setloading(false);
        }
    })
    .catch(error => {
      setItemList([]);
      setloading(false);
    });
};
  const [selectedValue, setSelectedValue] = useState<string>("Monthly");

  const [selectMonthValue, setSelectedMonthValue] = useState<string>("1");
  const [sortModel, setSortModel] = useState([{ field: "", sort: "" }]);
  const [filterModel, setFilterModel] = useState({items: [{columnField: "", value: "", operatorValue: ""}]});
  
  const [yearValue, setYearValue] = useState(new Date().getFullYear().toString());
   
  const deleteOTRData = async (items: ClientBillingReportModel[]) => {
    try {
      for (const item of items) {
        await fetch(config.url.API_URL + `/OpenTaxReport/Delete`, {
          method: "POST",
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }),
          body: JSON.stringify(item),
        }).then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Something went wrong')
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
  const getCBR = async () => {

    fetchData('getClientbillingMonthly', 50, 1, "Monthly", "0", selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'],filterModel['items'][0]['operatorValue']);
  }

  useEffect(() => {
    getCBR()
    fetchCustomer()
  }, [])

  const handlePageChange = (val: number) => {
    setCurrentPage(val)
  }
  const handlePageSizeChange = (val: number) => {
    if (val <= 0) return
    setPageSize(val)
  }

  const handleRemoveItems = async (values: ClientBillingReportModel[]) => {
    console.log({ values })
    await deleteOTRData(values)
    getCBR()
  }

  const handleChange = (event: SelectChangeEvent<string>) => {

    setSelectedValue(event.target.value);
      fetchData("getClientbillingMonthly", 50, 1, event.target.value, SelectedPartner, selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'],filterModel['items'][0]['operatorValue']);
    
  };

  const handleMonthChange = (event: SelectChangeEvent<string>) => {

    setSelectedMonthValue(event.target.value);
      fetchData("getClientbillingMonthly", 50, 1, selectedValue, SelectedPartner, event.target.value, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'],filterModel['items'][0]['operatorValue']);

  };
 

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    setYearValue(event.target.value);
    const currentYear = new Date().getFullYear();
    let value = parseInt(event.target.value, 10);

    if(value > 1900 && value <= currentYear){
        fetchData("getClientbillingMonthly", 50, 1, selectedValue, SelectedPartner, selectMonthValue, event.target.value, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'],filterModel['items'][0]['operatorValue']);
    }
  };

  
  const handleSortChange = (newSortModel: any) => {
    setSortModel(newSortModel)
    console.log('filterModel',filterModel);
    console.log('newSortModel',newSortModel);
      fetchData("getClientbillingMonthly", 50, 1, selectedValue, SelectedPartner, selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], newSortModel[0]['field'], newSortModel[0]['sort'],filterModel['items'][0]['operatorValue']);
  };

  const handleFilterChange = (newFilterModel: any) => {
    setFilterModel(newFilterModel)
      fetchData("getClientbillingMonthly", 50, 1, selectedValue, SelectedPartner, selectMonthValue, yearValue, newFilterModel['items'][0]['columnField'], newFilterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'],filterModel['items'][0]['operatorValue']);
  };

  const [SelectedPartner, setSelectedPartner] = React.useState<string>('0');
  const initialUserdata = { partnersId: 0, customerName: "" };
  const [Customer, setCustomer] = useState([initialUserdata]);
  const fetchCustomer = () => {
    fetch(config.url.API_URL + "/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setCustomer(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const FMSelectCustomCustomer: React.FC<SelectProps> = ({ variant, ...props }) => {
    const handleChangePartner = (event: SelectChangeEvent) => {
        fetchData("getClientbillingMonthly", 50, 1, selectedValue, event.target.value, selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'],filterModel['items'][0]['operatorValue']);
      setSelectedPartner(event.target.value);
    };
    return (
      <div>
        <FormControl fullWidth>
          <Select
            onChange={handleChangePartner}
            value={SelectedPartner}
            id="test-select-label"
            autoWidth
            size="small"
            label={null}
            className="border-round custom-menu-item"
            IconComponent={ExpandMoreIcon}
            displayEmpty
          >
            <MenuItem key={""} value={"0"}>All</MenuItem>
            {Customer?.map((item, key) => (
              <MenuItem
                key={key}
                className="custom-menu-item"
                value={item.partnersId}
              >
                {item.customerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };
  function dateHandle() {

  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className="card-title d-flex col-lg-12">
          <span className="col-lg-5">Client Billing Report</span>
          <div className="col-lg-2">
            {user?.userRoles.split(",").includes("Super Admin") && (<FMSelectCustomCustomer name="customer" />
            )}
          </div>

          <FormControl fullWidth className="main-form-2 ms-3 d-flex">
            <InputLabel className="" id="State-Select-Label">Period</InputLabel>
            <Select
              labelId="Select-Label"
              IconComponent={ExpandMoreIcon}
              label="Period"
              value={selectedValue}
              onChange={handleChange}
            >
              <MenuItem value={"Monthly"}>Monthly</MenuItem>
              <MenuItem value={"Annual"}>Annually</MenuItem>
            </Select>
          </FormControl>
          {selectedValue == "Monthly" ?
            <FormControl fullWidth className="main-form-2 ms-3 d-flex">
              <InputLabel className="" id="month-Label">Select Month</InputLabel>
              <Select
                labelId="month-Label"
                IconComponent={ExpandMoreIcon}
                label="Select Month"
                value={selectMonthValue}
                onChange={handleMonthChange}
              >
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>
            : ""}

            <FormControl fullWidth className="main-form-3 ms-3 d-flex">
              <TextField
                label="Year"
                type="number"
                value={yearValue}
                onChange={handleYearChange}
                defaultValue={new Date().getFullYear()}
                InputProps={{
                  style: { height: '2.8rem' } // Change font size here for input text
                }}
              />
            </FormControl>
          </div>
        <Box
          overflow={"scroll"}
          sx={{
            height: "calc(100% - 60px)",
            width: "100%"
          }}
        >

            <ClientBillingReportMonthlyList
              listData={itemList}
              onRemoveItems={handleRemoveItems}
              setItemList={setItemList}
              selectedMonth={Number(selectMonthValue)}
              handleFilterChange={handleFilterChange}
              handleSortChange={handleSortChange}
            />

        </Box>

      </Box>

    </LocalizationProvider>
  )
}



