import * as React from 'react';
import useAuth from "../context/AuthProvider";
import { Field, Form, Formik } from 'formik';
import { config } from '../Constants';
import { Button, FormHelperText, LinearProgress, Link } from '@mui/material';
import { CustomInputComponent } from '../components/Elements/input';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CustomerPolicyModel_VS } from '../models/CustomerPolicyModel';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { RightNav } from '../components/Nav/RightNav';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { FMSelectState, FMSelectTransactionType } from '../components/Elements/select';
import LoadingButton from '@mui/lab/LoadingButton';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

var formData = new FormData();
var files: File[];

export interface ReqDocuments {
    documentName: string,
    modelId: number
}
const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#41729F',
    borderStyle: 'dashed',


    backgroundColor: '#fafafa',
    // color: '#bdbdbd',
    color: '#41729F',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

// export function StyledDropzoneGLPolicy(props: any) {
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject

//     } = useDropzone({
//         maxFiles: 1,
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop: (files) => {
//             formData.delete('California GL Policy');
//             formData.delete('California Property Policy');
//             formData.append('California GL Policy', files[0]);
//         }
//     });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map(
//         file =>
//         (
//             <React.Fragment>

//                 <FilePresentIcon fontSize='inherit' />

//                 <li key={file.name}>
//                     {file.name} - {(file.size / 1024).toFixed(2)} kb
//                 </li>
//             </React.Fragment>
//         )

//     );

//     return (
//         <React.Fragment>



//             <div className="card-body col-lg-12">
//                 <div {...getRootProps({ style })}>
//                     <input {...getInputProps()} name='CaliforniaGLPolicy' />
//                     <p>Drop GL Policy</p>

//                 </div>

//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>


//         </React.Fragment>
//     );
// }


// export function StyledDropzoneSL1(props: any) {
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject

//     } = useDropzone({
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
//             (files) => {
//                 formData.delete('California SL-1');
//                 formData.append('California SL-1', files[0]);
//             }
//     });


//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map(
//         file =>
//         (
//             <React.Fragment>

//                 <FilePresentIcon fontSize='inherit' />
//                 <li key={file.name}>
//                     {file.name} - {(file.size / 1024).toFixed(2)} kb
//                 </li>

//             </React.Fragment>
//         )

//     );

//     return (
//         <React.Fragment>
//             <div className="card-body col-lg-12">
//                 <div {...getRootProps({ style })}>

//                     <input {...getInputProps()} name='CaliforniaSL1' />
//                     <p>Drop SL1 Document</p>

//                 </div>
//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>

//         </React.Fragment>
//     );
// }

// export function StyledDropzoneSL2(props: any) {
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject

//     } = useDropzone({
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
//             (files) => {
//                 formData.delete('California SL-2');
//                 formData.append('California SL-2', files[0]);
//             }
//     });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map(
//         file =>
//         (
//             <React.Fragment>

//                 <FilePresentIcon fontSize='inherit' />
//                 <li key={file.name}>
//                     {file.name} - {(file.size / 1024).toFixed(2)} kb
//                 </li>
//             </React.Fragment>
//         )

//     );

//     return (
//         <React.Fragment>
//             <div className="card-body col-lg-12 " >
//                 <div {...getRootProps({ style })}>

//                     <input {...getInputProps()} name='CaliforniaSL2' />
//                     <p>Drop California SL2</p>

//                 </div>
//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>

//         </React.Fragment>
//     );
// }


// export function StyledDropzoneSurpluslinesNotifaction(props: any) {
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject
//     } = useDropzone({
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
//             (files) => {
//                 formData.delete('California Surplus Lines Notification');
//                 formData.append('California Surplus Lines Notification', files[0]);
//             }
//     });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map(
//         file =>
//         (
//             <React.Fragment>

//                 <FilePresentIcon fontSize='inherit' />
//                 <li key={file.name}>
//                     {file.name} - {(file.size / 1024).toFixed(2)} kb
//                 </li>
//             </React.Fragment>
//         )

//     );

//     return (
//         <React.Fragment>
//             <div className="card-body col-lg-12">
//                 <div {...getRootProps({ style })}>

//                     <input {...getInputProps()} name='SurpluslinesNotifaction' />
//                     <p>Drop Surplus Lines Notifaction </p>

//                 </div>
//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>

//         </React.Fragment>
//     );
// }

// export function StyledDropzoneCaliforniaD1(props: any) {
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject
//     } = useDropzone({
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
//             (files) => {
//                 formData.delete('California D1');
//                 formData.append('California D1', files[0]);
//             }
//     });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map(
//         file =>
//         (
//             <React.Fragment>

//                 <FilePresentIcon fontSize='inherit' />
//                 <li key={file.name}>
//                     {file.name} - {(file.size / 1024).toFixed(2)} kb
//                 </li>
//             </React.Fragment>
//         )

//     );

//     return (
//         <React.Fragment>
//             <div className="card-body col-lg-12">
//                 <div {...getRootProps({ style })}>

//                     <input {...getInputProps()} name='California D1' />
//                     <p>Drop California D1 </p>

//                 </div>
//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>

//         </React.Fragment>
//     );
// }

// export function StyledDropzoneCaliforniaPropertyPolicy(props: any) {
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject
//     } = useDropzone({
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
//             (files) => {
//                 formData.delete('California Property Policy');
//                 formData.delete('California GL Policy');

//                 formData.append('California Property Policy', files[0]);
//             }
//     });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map(
//         file =>
//         (
//             <React.Fragment>

//                 <FilePresentIcon fontSize='inherit' />
//                 <li key={file.name}>
//                     {file.name} - {(file.size / 1024).toFixed(2)} kb
//                 </li>
//             </React.Fragment>
//         )

//     );

//     return (
//         <React.Fragment>
//             <div className="card-body col-lg-12">
//                 <div {...getRootProps({ style })}>

//                     <input {...getInputProps()} name='California Property Policy' />
//                     <p>Drop California Property Policy</p>

//                 </div>
//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>

//         </React.Fragment>
//     );
// }


export function GenericStyledDropZone(props: any & ReqDocuments) {

    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'application/pdf': ['.pdf'], 'image/*': [], 'application/msg': ['.msg'] }, onDrop:
            (files) => {
                formData.delete(props.doc.modelId + "__" + props.doc.documentName);
                formData.delete(props.doc.modelId + "__" + props.doc.documentName);
                formData.append(props.doc.modelId + "__" + props.doc.documentName, files[0]);
            }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    const files = acceptedFiles.map
        (
            file =>
            (
                <React.Fragment>
                    <FilePresentIcon fontSize='inherit' />
                    <li key={file.name}>
                        {file.name} - {(file.size / 1024).toFixed(2)} kb
                    </li>
                </React.Fragment>
            )

        );

    return (
        <React.Fragment>
            <div className="card-body col-lg-12">
                <div {...getRootProps({ style })}>

                    <input {...getInputProps()} name={props.doc.documentName} />
                    <p>Drop {props.doc.documentName}</p>

                </div>
                <aside>
                    <ul>{files}</ul>
                </aside>
            </div>

        </React.Fragment>
    );
}

interface props {
    fetchData: () => void
}


export const ResubmitCustomerPolicyDocs: React.FC = () => {

    // function fetchMasterPolicyTypeID() {
    //     fetch(config.url.API_URL + `/CustomerPolicy/GetMasterPolicyType?customerpolicyid=${params.CustomerPolicyId}`,
    //         {
    //             headers: new Headers({
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`,
    //             })
    //         }).then((response) => {
    //             if (response.ok) {
    //                 return response.json();
    //             }
    //             throw new Error('Something went wrong');
    //         })
    //         .then((res) => {
    //             //   setloading(false);
    //             // Do something with the response
    //             setPolicyTypeId(res.data)

    //         }).catch((error) => {
    //             setloading(false);
    //             console.log(error)
    //         });
    // }



    const Reqdocuments: ReqDocuments = {
        documentName: "",
        modelId: 0
    }

    const [RequireedDocuments, setRequiredDocuments] = useState([Reqdocuments]);
    const [IsSingleDocument, setIsSingleDocument] = useState(true);


    function CheckIsSingleDocument() {
        setloading(true);
        fetch(config.url.API_URL + `/MasterPolicyType/checkIsSingleDocument?custPolicyId=${params.CustomerPolicyId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {

                // Do something with the response

                setIsSingleDocument(res.data);

                if (!res.data) {
                    fetchRequiredDocumentName();
                } else {
                    setRequiredDocuments([{ documentName: "Single Document", modelId: 1 }]);
                }

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }


    function fetchRequiredDocumentName() {
        setloading(true);
        fetch(config.url.API_URL + `/MasterPolicyType/getRequiredDocuments?custPolicyId=${params.CustomerPolicyId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {

                // Do something with the response

                setRequiredDocuments(res.data);

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }
    const navigate1 = useNavigate();
    const goBack = () => {
        navigate(-1);
    }


    // let ID: number = 0;
    // const [PolicyTypeId, setPolicyTypeId] = useState(ID);

    const initialValues: { transactionTypeId: number, UserId: number } = { transactionTypeId: 0, UserId: 0 }
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        CheckIsSingleDocument();
        // fetchRequiredDocumentName();
        //  fetchMasterPolicyTypeID();

    }, [])
    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>
                                <LoggedInUserInfo ButtonText="" Link="" />
                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>

                                            <div className="table-responsive">
                                                {/* {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />} */}
                                                <h2 style={{textAlign:'center'}}>Re-Submit Customer Policy Documents</h2>

                                                <div className="card-body-overflow">

                                                    <React.Fragment>
                                                        <Formik initialValues={initialValues}
                                                            validateOnChange={true}
                                                            validationSchema={CustomerPolicyModel_VS}
                                                            onSubmit={(data, { setSubmitting }) => {

                                                                setloading(true);
                                                                formData.append('transactionTypeId', data.transactionTypeId.toString() ?? "");
                                                                let ID = params.CustomerPolicyId?.toString() ?? "";
                                                                formData.append('CustomerPolicyId', ID);

                                                                if (IsSingleDocument) {
                                                                    fetch(
                                                                        config.url.API_URL + `/CustomerPolicy/SingleDocumentReSubmission/`,
                                                                        {
                                                                            method: 'POST',
                                                                            headers: new Headers({
                                                                                // 'Content-Type': 'application/json',
                                                                                'Authorization': `Bearer ${token}`,
                                                                            }),
                                                                            body: formData  // <-- Post parameters

                                                                        })
                                                                        .then((response) => {
                                                                            if (response.ok) {
                                                                                return response.json();

                                                                            }
                                                                            throw new Error('Something went wrong');
                                                                        })
                                                                        .then((res) => {

                                                                            // Do something with the response
                                                                            setSubmitting(false);

                                                                            setloading(false);

                                                                            if (res.resp == 200) {
                                                                                navigate("/");
                                                                            } else {
                                                                                alert(res.message);
                                                                            }



                                                                            formData = new FormData();

                                                                        }).catch((error) => {
                                                                            setSubmitting(false);
                                                                            alert("There was an error saving the Document");
                                                                            // formData = new FormData();
                                                                            console.log(error)
                                                                            setloading(true);
                                                                            navigate("/CustomerPolicy");
                                                                        });

                                                                } else {
                                                                    fetch(
                                                                        config.url.API_URL + `/CustomerPolicy/PolicyDocumentResubmission/`,
                                                                        {
                                                                            method: 'POST',
                                                                            headers: new Headers({
                                                                                // 'Content-Type': 'application/json',
                                                                                'Authorization': `Bearer ${token}`,
                                                                            }),
                                                                            body: formData  // <-- Post parameters

                                                                        })
                                                                        .then((response) => {
                                                                            if (response.ok) {
                                                                                return response.json();

                                                                            }
                                                                            throw new Error('Something went wrong');
                                                                        })
                                                                        .then((res) => {

                                                                            // Do something with the response
                                                                            setSubmitting(false);

                                                                            setloading(false);

                                                                            if (res.resp == 200) {
                                                                                navigate("/");
                                                                            }


                                                                            formData = new FormData();

                                                                        }).catch((error) => {
                                                                            setSubmitting(false);
                                                                            alert("There was an error saving the Document");
                                                                            // formData = new FormData();
                                                                            console.log(error)
                                                                            setloading(true);
                                                                            navigate("/CustomerPolicy");
                                                                        });
                                                                }

                                                            }} >
                                                            {({ values, isSubmitting }) =>
                                                            (
                                                                <Form>
                                                                    <div className="card-body" style={{ display: "flex", marginLeft: "12rem" }}>
                                                                        <div className="row row-cols-1 row-cols-md-3 g-1">



                                                                            {
                                                                                RequireedDocuments.map(

                                                                                    (file, Key) =>
                                                                                    (

                                                                                        <div className="card col-lg-5 m-2">
                                                                                            <div className="card-body card-body-dashboard">
                                                                                                <GenericStyledDropZone doc={file} Key={Key} />
                                                                                            </div>
                                                                                        </div>

                                                                                    )


                                                                                )

                                                                            }
                                                                            {/* <div className="col-lg-5 col"> */}
                                                                            <div className="card col-lg-5 m-2">
                                                                                <div className="card-body card-body-dashboard m-1">
                                                                                    <FormHelperText style={{ marginTop: "-10px", marginBottom: "17px" }}>Select Transaction Type</FormHelperText>
                                                                                    <FMSelectTransactionType autoWidth={true} variant={"standard"} name="transactionTypeId" ></FMSelectTransactionType>
                                                                                </div>

                                                                            </div>
                                                                            {/* </div> */}


                                                                        </div>
                                                                    </div>





                                                                    <div className='card-body' style={{ display: "flex", marginLeft: "12rem" }}>
                                                                        
                                                                            <div className="col-lg-10 m-2 ">

                                                                                <div className="card" >
                                                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                                                    <div className="card-body card-body-dashboard">

                                                                                        <div className='col-lg-5 '>


                                                                                            <button type="submit" className="btn btn-primary login-btn  loginpage-samespace" disabled={isSubmitting} >Upload </button>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                    </div>
                                                                    {/* <div className="col-lg-6 col">

                                            </div> */}



                                                                </Form>

                                                            )}
                                                        </Formik>
                                                    </React.Fragment>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>



    );
}

