import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import { Button, FormControl, Grid, InputLabel, LinearProgress, Link, MenuItem, Select, SelectChangeEvent, SelectProps, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { config } from "../Constants";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { CarrierManagementList } from "../components/Lists/CarrierManagementList";
import { RightNav } from "../components/Nav/RightNav";
import useAuth from "../context/AuthProvider";
import Swal from "sweetalert2";


export const CarrierManagement = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const [InsuranceComapny, setInsuranceComapny] = useState([])

    const [carrier, setCarrier] = useState<string>("");
    const [naic, setNaic] = useState<string>("");
    const [stateId, setStateId] = useState("");


    // const params = useParams();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function RunSearch() {
        fetchData(false);
    }

    function ClearSearch() {
        setloading(true);
        setStateId("");
        setNaic('');
        setCarrier("");
        fetchData(true);

    }
    // function handlePushToLive() {
    //     handleMappingsUpdate();
    // }


    const handleMappingsUpdate = () => {
        Swal.fire({
            title: 'Do you want to push mappings to live?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
           
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update mappings!',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })  .then((result) => {
            if (result.isConfirmed) {
                setloading(true);
                fetch(config.url.API_URL + "/InsuranceCompany/UpdateTables",
                    {
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        method: "Get",
                    }
                )
                    .then((response) => {
                        // 
                        setloading(false);
                        if (response.ok) {
                            Swal.fire({
                                title: "Data updated",
                                icon: 'success',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'ok'
                            })
                            return;
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        

                    }).catch((error) => {
                        setloading(false);
                        console.log(error)
                    });

            }
        });

    }

    const fetchData = (isClear: boolean) => {
        setloading(true)
        fetch(config.url.API_URL + `/InsuranceCompany/getAllInsuranceCompany?carrier=${isClear ? "" : carrier}&&naic=${isClear ? "" : naic}&&stateid=${isClear ? "" : stateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)

                // Do something with the response
                setInsuranceComapny(res.data)
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });
    }

    // const initialStatedata = { stateId: 0, stateName: "" };




    const FMSelectStateCustom: React.FC<SelectProps> = ({ variant, ...props }) => {

        const { user } = useAuth();
        const token = user?.token;
        const initialStatedata = { stateId: 0, stateName: "" };
        const [States, setStates] = useState([initialStatedata])


        const handleChange = (event: SelectChangeEvent) => {
            setloading(true);
            setStateId(event.target.value);
            setloading(false);

        }


        const fetchData = () => {

            fetch(config.url.API_URL + '/States/getStates',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    setStates(responseJson.data)

                }).catch((error) => {

                    console.log(error)

                });


        }
        useEffect(() => {

            fetchData()

        }, [])

        return (

            <FormControl fullWidth >

                <InputLabel id="State-Select-Label">State</InputLabel>

                <Select


                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label="State"
                    value={stateId}


                >
                    <MenuItem value={""}> No Filter</MenuItem>

                    {States.map((state, key) =>
                        <MenuItem key={key} value={state.stateId}> {state.stateName} </MenuItem>

                    )}

                </Select>


            </FormControl>

        )
    }


    function carriernameChange(e: React.ChangeEvent<HTMLInputElement>) {
        console.log(e.target.value);
        setCarrier(e.target.value);

    }


    function naicChange(e: React.ChangeEvent<HTMLInputElement>) {
        console.log(e.target.value);
        setNaic(e.target.value);

    }

    useEffect(() => {
        fetchData(true);
    }, [])

    return (

        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>

                                // <LoggedInUserInfo ButtonText="Add New" Link="" />


                                // <div className="card-body">
                                //     <div className="col-lg-12 col col-margin">
                                //         <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>
                                //             <div style={{  padding: "1rem" }}>
                                //                 <div className="row col-lg-12">
                                //                     <div className="col-lg-3" style={{fontSize:"32px"}}>
                                //                         <span className="d-flex">Carriers</span>
                                //                     </div>
                                //                     <div className= "col-lg-8" style={{marginLeft:"10px"}}>
                                //                         <Grid container spacing={2} >


                                //                             <Grid item xs={18} display='flex' flexDirection='row' className="m-1">

                                //                                 <Grid item xs={7} >
                                //                                     <TextField name="carrierName" autoFocus value={carrier} label="Carrier Name" onChange={carriernameChange} type="text" fullWidth variant="outlined" />
                                //                                 </Grid>
                                //                                 <Grid item xs={0.5}>
                                //                                 </Grid>

                                //                                 <Grid item xs={6} >
                                //                                     <TextField name="naicNumber" autoFocus value={naic} label="NAIC Number" onChange={naicChange} type="text" fullWidth variant="outlined" />
                                //                                 </Grid>
                                //                                 <Grid item xs={0.5}>
                                //                                 </Grid>
                                //                                 <Grid item xs={6} >
                                //                                     <FMSelectStateCustom name="state" />
                                //                                 </Grid>
                                //                                 <Grid item xs={0.5}>
                                //                                 </Grid>

                                //                                 <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
                                //                                     <Grid item xs={4} >
                                //                                         <button type="button" style={{ height: "50px" , marginLeft: "30px"}} onClick={() => { RunSearch() }} className="btn btn-primary d-flex">Search</button>
                                //                                     </Grid>
                                //                                     <Grid item xs={0.2}>
                                //                                     </Grid>
                                //                                     <Grid item xs={4} >
                                //                                         <button type="button" style={{ height: "50px", marginLeft: "50px" }} onClick={() => { ClearSearch() }} className="btn btn-secondary d-flex">Clear</button>

                                //                                     </Grid>

                                //                                 </Grid>



                                //                             </Grid>
                                //                         </Grid>

                                //                     </div>
                                //                 </div>
                                //                 <div className="table-responsive">
                                //                     {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}


                                                    <CarrierManagementList InsuranceComapnyCL={InsuranceComapny} fetchData={() => fetchData(false)} handleMappingsUpdate={() => handleMappingsUpdate()}  />
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>

    );

}
