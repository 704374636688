import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { FMSelectCustomerPartnerStatus, FMSelectState } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';

interface Iprops {
    InsuredCL: InsuredModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: InsuredModel
    fetchData:()=>void
}

export const AddEditInsuredAddress: React.FC<Iprops> = ({ handleClose, Open, InsuredCL, handleSubmit, initValues ,fetchData}) => {
    const initialValues: InsuredModel = initValues ? InsuredCL : initValues;
    const { user } = useAuth();
    const token = user?.token;



    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle className='card-title'  >Insured Address</DialogTitle>
            <Formik

                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                // validationSchema={PartnersModelVS}
                onSubmit={(data, { setSubmitting }) => {
                    console.log(data);
                    fetch(
                        config.url.API_URL + '/Insured/InsuredAddressUpdate',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                            fetchData();
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} ><FormHelperText className='formtexthelper-heading'>Address</FormHelperText>
                                        <FMTextField name="insuredAddressLine1" placeholder='Address' label="" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }}><FormHelperText className='formtexthelper-heading'>City</FormHelperText>
                                        <FMTextField name="insuredCity" InputLabelProps={{ shrink: true }} label="" autoFocus margin="dense" type="text" variant="outlined" sx={{ m: 2 }} />

                                    </FormControl >
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} ><FormHelperText className='formtexthelper-heading'>State</FormHelperText>
                                        <FMSelectState name="stateId" placeholder='State' label="" />
                                    </FormControl>
                                </Grid>                                
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                    <FormHelperText className='formtexthelper-heading'>Zip</FormHelperText>
                                        <FMTextField name="insuredZipCode" placeholder='Zip' label={null} type="number" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <button  className=' btn-2 btn d-flex btn-primary search-btn ' onClick={handleClose} type="button" >Cancel</button>
                            <button className='btn  btn-primary active-btn-blue  search-btn '  disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
