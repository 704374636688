import React, { useState } from "react"
import {
  DataGrid, GridColDef, GridSelectionModel, GridToolbar,
} from "@mui/x-data-grid"
import { RiExpandUpDownLine } from "react-icons/ri"
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, } from "@mui/material"
import useAuth from "../../context/AuthProvider"
import moment from "moment"
import { ClientBillingReportModel } from "../../models/ClientBillingReportModel"
// import * as XLSX from 'xlsx'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2"
// import * as XLSX from 'xlsx-js-style';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { style } from "@mui/system"
import formatPrice from "../../helper/currencyFormatter"

interface Iprops {
  listData: ClientBillingReportModel[]
  onRemoveItems: (val: ClientBillingReportModel[]) => void
  setItemList: React.Dispatch<React.SetStateAction<ClientBillingReportModel[]>>
  selectedMonth: number
  handleFilterChange: any
  handleSortChange: any

}

export const ClientBillingReportMonthlyList: React.FC<Iprops> = ({
  listData,
  onRemoveItems,
  setItemList,
  selectedMonth,
  handleFilterChange,
  handleSortChange


}) => {
  const { user } = useAuth()
  const token = user?.token
  const [selectedRows, setSelectedRows] = useState<ClientBillingReportModel[]>()

  const onRowsSelectionHandler = (ids: GridSelectionModel) => {
    const selectedIDs = new Set(ids)
    const selectedRowData = listData.filter(
      (row) => selectedIDs.has(row?.rowNum ?? "")
    )
    setSelectedRows(selectedRowData)
  }

  const handleDelete = () => {
    if (!selectedRows || selectedRows.length < 1) return
    onRemoveItems(selectedRows)
    setSelectedRows([])
  }



  // const printDataExcelwithoutLogo = () => {
  //   const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
  //   const groupedByClient = dataToPrint.reduce((acc, row) => {
  //     const client = row.partnerName;
  //     if (client) {
  //       if (!acc[client]) {
  //         acc[client] = [];
  //       }
  //       acc[client].push(row);
  //     }
  //     return acc;
  //   }, {} as Record<string, ClientBillingReportModel[]>);

  //   for (const [client, rows] of Object.entries(groupedByClient)) {
  //     const groupedData = rows.reduce((acc, row) => {
  //       const state = row.state;
  //       if (state) {
  //         if (!acc[state]) {
  //           acc[state] = [];
  //         }
  //         acc[state].push(row);
  //       }
  //       return acc;
  //     }, {} as Record<string, ClientBillingReportModel[]>);

  //     let grandTotalFeeDue = 0;
  //     const clientName = client || "Unknown Client";

  //     const wb = XLSX.utils.book_new();

  //     for (const [state, rows] of Object.entries(groupedData)) {
  //       const wsData: any[][] = [];
  //       const headerStyle = {
  //         font: { bold: true },
  //         fill: { fgColor: { rgb: 'CCCCCC' } } // Grey color for headers
  //       };
  //       const titleStyle = {
  //         font: { sz: 14, bold: true }, // Bigger font for "Filed Transactions"
  //         alignment: { horizontal: "center" }
  //       };

  //       // Adding custom header fields starting from the 6th row
  //       wsData[1] = [{ v: "Filed Transactions", s: titleStyle }];
  //       wsData[2] = ["Client Name:", clientName];
  //       wsData[3] = ["Start Date:", moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")];
  //       wsData[4] = ["End Date:", moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")];
  //       wsData[5] = []; // Empty row for spacing

  //       // Adding data headers
  //       const headers = Columns.filter(col => !col.hide).map(col => col.headerName);
  //       wsData[6] = headers.map(header => ({ v: header, s: headerStyle }));

  //       rows.forEach((row, rowIndex) => {
  //         const rowData: any[] = [];
  //         Columns.forEach((col) => {
  //           if (!col.hide) {
  //             let value = (row as any)[col.field];
  //             if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
  //               rowData.push({ v: value ? "✔" : "" });
  //             } else if (col.field === 'period') {
  //               const formattedMonth = value ? moment(value).format("MMMM") : '';
  //               rowData.push({ v: formattedMonth });
  //             } else {
  //               rowData.push({ v: value !== (undefined || null) ? value : "" });
  //             }

  //             if (col.field === 'feeDue') {
  //               grandTotalFeeDue += parseFloat(value) || 0;
  //             }
  //           }
  //         });
  //         wsData[7 + rowIndex] = rowData;
  //       });

  //       wsData.push(['', '', '', '', '', '', `Grand Total Fee Due`, grandTotalFeeDue.toFixed(2)]);

  //       const ws = XLSX.utils.aoa_to_sheet(wsData);

  //       // Apply striped table formatting
  //       const range = XLSX.utils.decode_range(ws['!ref'] || '');
  //       const evenRowStyle = { fill: { patternType: "solid", fgColor: { rgb: "FFDDDDDD" } } };
  //       const oddRowStyle = { fill: { patternType: "solid", fgColor: { rgb: "FFFFFFFF" } } };

  //       for (let R = range.s.r + 6; R <= range.e.r; ++R) {
  //         for (let C = range.s.c; C <= range.e.c; ++C) {
  //           const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
  //           if (!ws[cellRef]) continue; // Skip empty cells

  //           // Apply even or odd row style
  //           const isEvenRow = (R % 2) === 0;
  //           if (!ws[cellRef].s) ws[cellRef].s = {};
  //           ws[cellRef].s.fill = isEvenRow ? evenRowStyle.fill : oddRowStyle.fill;
  //         }
  //       }

  //       // Auto-adjust column widths
  //       const colWidths = headers.map((_, colIndex) => {
  //         let maxWidth = 10; // Minimum column width
  //         wsData.forEach(row => {
  //           const cell = row[colIndex];
  //           if (cell && cell.v !== undefined && cell.v !== null) {
  //             const cellValue = cell.v.toString();
  //             if (cellValue.length > maxWidth) {
  //               maxWidth = cellValue.length;
  //             }
  //           }
  //         });
  //         return { wch: maxWidth };
  //       });
  //       ws['!cols'] = colWidths;

  //       XLSX.utils.book_append_sheet(wb, ws, state);
  //     }

  //     XLSX.writeFile(wb, 'Report.xlsx');
  //   }
  // };

  // Function to convert an image to a base64 string
  const getBase64ImageFromUrl = (url: string) => {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
      img.src = url;
    });
  };

  const printDataExcel33 = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, ClientBillingReportModel[]>);

      let grandTotalFeeDue = 0;
      const clientName = client || "Unknown Client";

      const workbook = new ExcelJS.Workbook();

      for (const [state, rows] of Object.entries(groupedData)) {
        const worksheet = workbook.addWorksheet(state);



        // Add custom header fields starting from the 6th row
        worksheet.mergeCells('B2:C2');
        worksheet.getCell('B2').value = "Filed Transactions";
        worksheet.getCell('B2').font = { size: 20 };

        // worksheet.getCell('B2').alignment = { horizontal: 'center' };

        // worksheet.getCell('A7').value = "Client Name:";
        worksheet.getCell('B3').value = clientName;
        worksheet.getCell('B3').font = { size: 15 };
        worksheet.getCell('B4').value = `Start Date:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`;

        worksheet.getCell('B4').value = `End Date:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`;


        // Adding data headers
        const headers = Columns.filter(col => !col.hide).map(col => col.headerName);
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'CCCCCC' }
          };
        });

        headers.forEach((header, index) => {
          worksheet.getColumn(index + 1).width = Math.max(header?.length ?? 10, 15); // Initialize column widths
        });

        rows.forEach((row) => {
          const rowData: any[] = [];
          Columns.forEach((col, colIndex) => {
            if (!col.hide) {
              let value = (row as any)[col.field];
              if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                value = value ? "✔" : "";
              } else if (col.field === 'period') {
                value = value ? moment(value).format("MMMM") : '';
              } else {
                value = value !== (undefined || null) ? value : "";
              }

              rowData.push(value);

              // Update column width
              if (value && value.toString().length > (worksheet.getColumn(colIndex + 1).width ?? 15)) {
                worksheet.getColumn(colIndex + 1).width = value.toString().length;
              }

              if (col.field === 'feeDue') {
                grandTotalFeeDue += parseFloat(value) || 0;
              }
            }
          });
          worksheet.addRow(rowData);
        });
        worksheet.getCell(`G` + (rows.length + 6)).value = `Fee Due: `;
        worksheet.getCell(`G` + (rows.length + 6)).font = { size: 15, color: { argb: "FF0000" } };
        worksheet.getCell(`H` + (rows.length + 6)).value = grandTotalFeeDue.toFixed(2);
        worksheet.getCell(`H` + (rows.length + 6)).font = { size: 15, color: { argb: "FF0000" } };
        // Apply striped table formatting
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 10) { // Apply styles to data rows
            row.eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: rowNumber % 2 === 0 ? 'FFDDDDDD' : 'FFFFFFFF' }
              };
            });
          }
        });


        // Add image to worksheet
        const base64Image = await getBase64ImageFromUrl('/assets/brand/ExcelLogo.png'); // Replace with your image path
        const imageId = workbook.addImage({
          base64: base64Image.split(',')[1], // Extract base64 data
          extension: 'png',
        });
        worksheet.addImage(imageId, 'A1:A2');


      }

      // Convert the workbook to a Blob
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Use FileSaver.js to save the file
      saveAs(blob, 'Report.xlsx');
    }
  };

  const printDataExcelOld = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);

    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, ClientBillingReportModel[]>);

      let grandTotalFeeDue = 0;
      const clientName = client || "Unknown Client";

      for (const [state, rows] of Object.entries(groupedData)) {
        // Load the existing template

        const workbook = new ExcelJS.Workbook();
        const response = rows.length > 19 ? (await fetch('/assets/brand/ClientBillingTemplateNew1.xlsx')) : await fetch('/assets/brand/ClientBillingTemplateNew.xlsx'); // Replace with your template path
        const arrayBuffer = await response.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.getWorksheet(1);

        if (worksheet != null) {
          worksheet.getCell('B3').value = clientName;
          worksheet.getCell('B4').value = `Star tDate:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`
          worksheet.getCell('B5').value = `EndDate:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`



          // add a table to a sheet

          rows.forEach((row, rowIndex) => {


            Columns.forEach((col, colIndex) => {

              if (!col.hide) {
                let value = (row as any)[col.field];
                if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                  value = value ? "✔" : "";
                } else if (col.field === 'period') {
                  value = value ? moment(value).format("MMMM") : '';
                }
                else {
                  value = value !== (undefined || null) ? value : "";
                }
                if (col.field === 'feeDue') {
                  grandTotalFeeDue += parseFloat(value) || 0;
                }

                worksheet.getRow(rowIndex + 7).getCell(colIndex).value = value;

                // worksheet.getRow(rowIndex + 7).getCell(colIndex). style =  {fill: {type: 'pattern', pattern: 'solid', bgColor: {argb: '#ffa5a5a5'}}}

              }

            });

            // worksheet.addRow(rowData);
          });
          if (rows.length > 19) {
            worksheet.getCell(`G` + (rows.length + 8)).value = 'Fee Due';
            worksheet.getCell(`G` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
            worksheet.getCell(`H` + (rows.length + 8)).value = grandTotalFeeDue.toFixed(2);
            worksheet.getCell(`H` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
            // worksheet.getCell(`H` + (rows.length + 6)).fill = { bgColor:{ argb: "#ffffff" } , pattern : 'solid'  , type: 'pattern', };
          }



        } else {
          alert("Something went wrong");
          return;
        }

        // Convert the workbook to a Blob
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Use FileSaver.js to save the file
        saveAs(blob, 'Report.xlsx');
      }
    }
  };

  const printDataExcel = async () => {

    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);

    //for (const [client, rows] of Object.entries(groupedByClient)) {
      // const groupedData = rows.reduce((acc, row) => {
      //   const state = row.state;
      //   if (state) {
      //     if (!acc[state]) {
      //       acc[state] = [];
      //     }
      //     acc[state].push(row);
      //   }
      //   return acc;
      // }, {} as Record<string, ClientBillingReportModel[]>);

      let grandTotalFeeDue = 0;
      

      for (const [clients, rows] of Object.entries(groupedByClient)) {
        // Load the existing template
        const clientName = clients || "Unknown Client";
        const workbook = new ExcelJS.Workbook();
        const response = await fetch('/assets/brand/ClientBillingTemplateNewUpdated.xlsx'); // Replace with your template path
        const arrayBuffer = await response.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.getWorksheet(1);

        if (worksheet != null) {
          worksheet.getCell('B3').value = clientName;
          worksheet.getCell('B4').value = `Start Date:  ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}`
          worksheet.getCell('B5').value = `End Date:  ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}`



          // add a table to a sheet

          rows.forEach((row, rowIndex) => {


            Columns.forEach((col, colIndex) => {

              if (!col.hide) {
                let value = (row as any)[col.field];
                if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                  value = value ? "✔" : "";
                } else if (col.field === 'period') {
                  value = value ? moment(value).format("MMMM") : '';
                }
                else {
                  value = value !== (undefined || null) ? value : "";
                }
                if (col.field === 'feeDue') {
                  grandTotalFeeDue += parseFloat(value) || 0;
                }

                const cell = worksheet.getRow(rowIndex + 7).getCell(colIndex);
                cell.value = col.field === 'feeDue' ? Number(value) : value;
                if (col.field === 'feeDue') {

                  cell.numFmt = '"$"#,##0.00';
                }
                const fillColors = ['d9d9d9', 'FFFFFF', 'ededed'];
                const fillColor = fillColors[(rowIndex) % 3];

                if (fillColor !== 'FFFFFF') {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: fillColor },
                  }
                }

                // if (fillColor === 'FFFFFF') {
                //     cell.border = {
                //         top: { style: 'thin' },
                //         left: { style: 'thin' },
                //         bottom: { style: 'thin' },
                //         right: { style: 'thin' },
                //     };
                // }





              }
            });

            // worksheet.addRow(rowData);
          });

          worksheet.getCell(`G` + (rows.length + 8)).value = 'Fee Due';
          worksheet.getCell(`G` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
          worksheet.getCell(`H` + (rows.length + 8)).value = {
            formula: `SUM(H7:H${rows.length + 7})`,
            result: 0,
          };
          worksheet.getCell(`H` + (rows.length + 8)).numFmt = '"$"#,##0.00';
          worksheet.getCell(`H` + (rows.length + 8)).font = { size: 15, color: { argb: "FF0000" } };
          // worksheet.getCell(`H` + (rows.length + 6)).fill = { bgColor:{ argb: "#ffffff" } , pattern : 'solid'  , type: 'pattern', };




        } else {
          alert("Something went wrong");
          return;
        }

        // Convert the workbook to a Blob
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Use FileSaver.js to save the file
        saveAs(blob, 'Report.xlsx');
      }
   // }
  };




  const printDataPdf = () => {
    const dataToPrint = selectedRows && selectedRows.length > 0 ? selectedRows : listData;
    const groupedByClient = dataToPrint.reduce((acc, row) => {
      const client = row.partnerName;
      if (client) {
        if (!acc[client]) {
          acc[client] = [];
        }
        acc[client].push(row);
      }
      return acc;
    }, {} as Record<string, ClientBillingReportModel[]>);
    for (const [client, rows] of Object.entries(groupedByClient)) {
      const groupedData = rows.reduce((acc, row) => {
        const state = row.state;
        if (state) {
          if (!acc[state]) {
            acc[state] = [];
          }
          acc[state].push(row);
        }
        return acc;
      }, {} as Record<string, ClientBillingReportModel[]>);
      let grandTotalFeeDue = 0;
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        const clientName = client; // Replace this with the actual client name dynamically
        printWindow.document.write(`
        <html>
          <head>
            <title>Print Report</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
              }
              .header {
                text-align: left;
                margin-bottom: 20px;
              }
              .header h1 {
                margin: 0;
                font-size: 24px;
              }
              .header p {
                margin: 0;
                font-size: 14px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
              }
              th, td {
                border: 1px solid black;
                text-align: center;
                padding: 8px;
              }
              th {
                background-color: grey;
                color: white;
              }
              .total-row {
                font-weight: bold;
              }
              @media print {
                th {
                  background-color: grey !important;
                  -webkit-print-color-adjust: exact;
                }
              }
            </style>
          </head>
          <body>
            <div class="header">
              <h1>Filed Transactions</h1>
              <h3>${clientName}</h3>
              <p>Start Date: ${moment(new Date(2024, selectedMonth - 1, 1)).format("MM/DD/YYYY")}</p>
              <p>End Date : ${moment(new Date(2024, selectedMonth, 0)).format("MM/DD/YYYY")}</p>
            </div>
      `);
        for (const [state, rows] of Object.entries(groupedData)) {
          printWindow.document.write(`
          <table>
            <thead>
             
              <tr>
        `);
          Columns.forEach((col) => {
            if (!col.hide) {
              printWindow.document.write(`<th>${col.headerName}</th>`);
            }
          });
          printWindow.document.write(`
              </tr>
            </thead>
            <tbody>
        `);
          rows.forEach((row) => {
            printWindow.document.write("<tr>");
            Columns.forEach((col) => {
              if (!col.hide) {
                const value = (row as any)[col.field];
                if (col.field === "isPaymentRequired" || col.field === "isReportRequired") {
                  printWindow.document.write(`<td>${value ? ":heavy_check_mark:" : ""}</td>`);
                } else if (col.field === 'period') {
                  const formattedMonth = value ? moment(value).format("MMMM") : '';
                  printWindow.document.write(`<td>${formattedMonth}</td>`);
                } else if (col.field === 'state') {
                  printWindow.document.write(`<td style="width: 4%;">${value}</td>`);
                } else if (col.field === 'invoiceNumber') {
                  printWindow.document.write(`<td style="width: 8%;">${value}</td>`);
                }  else if (col.field === 'policyNumber') {
                  printWindow.document.write(`<td style="width: 12%;">${value}</td>`);
                } else if (col.field === 'insuredName') {
                  printWindow.document.write(`<td style="width: 31%;">${value}</td>`);
                }  
                else {
                  printWindow.document.write(`<td>${value !== (undefined || null) ? value : ""}</td>`);
                }
                if (col.field === 'feeDue') {
                  grandTotalFeeDue += parseFloat(value) || 0;
                }
              }
            });
            printWindow.document.write("</tr>");
          });
          printWindow.document.write(`
            </tbody>
          </table>
        `);
        }
        printWindow.document.write(`
        <table>
          <tfoot>
            <tr class="total-row">
              <td colspan='${Columns.length - 1}' style='text-align: right; color:red;'>Fee Due</td>
              <td>${grandTotalFeeDue.toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </body>
    </html>
      `);
        printWindow.document.close();
        printWindow.print();
      }
    }
  };
  const [selectedOption, setSelectedOption] = useState<string>('Print');
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
    printData(event.target.value);

  };

  const printData = (option: string) => {
    if (!selectedRows || selectedRows.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'No rows selected',
        text: 'Please select at least one row to proceed.',
      });
      setSelectedOption('0');
      return;
    }

    if (option === 'excel') {
      printDataExcel();
    } else if (option === 'pdf') {
      printDataPdf();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No option selected',
        text: 'Please select an option to proceed.',
      });
    }
    setSelectedOption('0');
  };
  function EditToolbar() {
    return (
      <React.Fragment>
        <Box display="flex" sx={{}}>
          <Grid
            container
            display="flex"
            margin={"10px 0px"}
            justifyContent={"space-between"}
          >

            <Grid item xs={12} md={6}>
              <GridToolbar />
            </Grid>
            <Grid item xs={12} md={6}>

              <Grid container display='flex' justifyContent={'flex-end'}>

                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className=" select-input-border">
                  {user?.userRoles.split(",").includes("Super Admin") ? (
                    <button
                      onClick={handleDelete}
                      className="active-btn-blue"
                    >
                      Remove
                    </button>
                  ) : (
                    ""
                  )}

                </Grid>
                <Grid item xs={4} sm={4} md={1.5} minWidth={130} className=" select-input-border">

                  {user?.userRoles.split(",").includes("Super Admin") ? (

                    <FormControl fullWidth className="">
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="active-btn-print"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === '0') {
                            return 'Print';
                          }
                          return selected;
                        }}
                      >
                        {/* <MenuItem key={0} value={0}> {'Print'} </MenuItem> */}
                        <MenuItem value={'excel'}>DownloadExcel</MenuItem>
                        <MenuItem value={'pdf'}>DownloadPDF</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Grid>


              </Grid>

            </Grid>
          </Grid>
        </Box>

      </React.Fragment>
    );
  }
  const Columns: GridColDef[] = [
    {
      field: 'rowNum',
      type: 'number',
      headerName: 'No',
      hide: true,
      hideable: false,
      disableExport: true,
    },

    {
      field: "state",
      type: "text",
      headerName: "State",
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "invoiceNumber",
      type: "text",
      headerName: "Invoice Number",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "insuredName",
      type: "text",
      headerName: "Insured Name",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "policyNumber",
      type: "text",
      headerName: "Policy Number",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "filed",
      type: "text",
      headerName: "Status",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
    },
    {
      field: "dateFinal",
      type: "text",
      headerName: "Date Filed",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "transactionTypeName",
      type: "text",
      headerName: "Transaction Type",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: "feeDue",
      type: "text",
      headerName: "Fee Due",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      hideSortIcons: true,
      renderHeader: (params) => (
        <div>
          <span className="table-card-title">{params.colDef.headerName}</span>
          <RiExpandUpDownLine />
        </div>
      ),
      valueGetter: (params) => formatPrice(params.value),
      editable: true, // Make the feeDue column editable
    },
  ];



  const handleRowEditCommit = (row: any) => {

    let updatedRow = row.row
    let AllRec = [...listData];

    let exsistinfRec = AllRec.find(x => x.rowNum === updatedRow.rowNum);

    if (exsistinfRec != undefined) {
      exsistinfRec.feeDue = updatedRow.feeDue;
      setItemList(AllRec);
    }

    return updatedRow;
  };

  

  return (

    <DataGrid
      getRowId={(r) => r.rowNum}
      rows={listData}
      columns={Columns}
      pageSize={25}
      pagination
      rowsPerPageOptions={[10, 25, 50]}
      editMode="row"
      onRowEditStop={handleRowEditCommit}
      checkboxSelection={
        user?.userRoles.split(",").includes("Super Admin") ? true : false
      }
      filterMode="server"
      onFilterModelChange={handleFilterChange} // For filtering
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
      onSortModelChange={handleSortChange} // Add this line for sorting
      onSelectionModelChange={
        user?.userRoles.split(",").includes("Super Admin")
          ? (newRowSelectionModel) => {
            onRowsSelectionHandler(newRowSelectionModel)
          }
          : undefined
      }
      components={{
        Toolbar: EditToolbar,
      }}
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
        },
        fontFamily: "'Proxima Nova', sans-serif",
        border: "0px",
        fontSize: "16px",
      }}
    // experimentalFeatures={{ newEditingApi: true }}
    />
  )
}
