import { FormHelperText, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { StateStampListModel, StateStampListModel_VS } from "../../models/StateStampListModel";
import { FMSelectTrueFalse } from "../Elements/select";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css";
import { FMTextField } from "../Elements/input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { boolean, number } from "yup";
import { error } from "console";
import Swal from "sweetalert2";



export const AddEditStateStamps = () => {

    const [loading, setloading] = useState(true);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();

    const initValues: StateStampListModel = {
        statesStampStateId: 0,
        statesStampsIsStateStamp: false,
        statesStampsText: "",
        statesStampContingentUpon: false,
        statesStampsFontSize: "",
        statesStampsFontWeight: "",
        statesStampsIsCaps: false,
        statesStampsColor: "",
        statesStampsStampAfterApproval: false,
        statesStampsListId: 0,
        effectiveDate: "",
        stateName: "",
        stateId: 0,
    }

    const [StateStampData, setStateStampData] = useState(initValues)
    const [editorState, seteditorState] = useState<EditorState>(EditorState.createEmpty());
    //const initialdata = { stateId: 0, stateName: "" };
    const [states, setStates] = useState<StateStampListModel[]>([initValues])

    function fetchStateData() {
        fetch(config.url.API_URL + "/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson.data != null) {
                    setStates(responseJson.data);
                }

                console.log("statesDAta", responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const goBack = () => {
        navigate(-1);
    }
    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/StateStampList/getStateStampData?statesStampsListsId=${params.statesStampsListsId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                responseJson.data.effectiveDate = responseJson.data.effectiveDate?.split("T")[0];



                setStateStampData(responseJson.data);


                const contentBlock = convertFromHTML(responseJson.data.statesStampsText == null ? "" : responseJson.data.statesStampsText);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const _editorState = EditorState.createWithContent(contentState);
                seteditorState(_editorState);
                setloading(false);

                StateStampData.effectiveDate = StateStampData.effectiveDate?.split("T")[0];
                console.log("statestampata", responseJson.data);

            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();
        fetchStateData();

    }, [])


    return (


        <React.Fragment>

            <Formik
                enableReinitialize={true}
                initialValues={StateStampData}
                validateOnChange={true}
                validationSchema={StateStampListModel_VS}
                onSubmit={(data, { setSubmitting }) => {

                    // data.statesStampsListId = parseInt(params.statesStampsListsId ?? "0");
                    //  console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                    data.statesStampsText = draftToHtml(convertToRaw(editorState.getCurrentContent())).toString();
                    // data.statesStampStateId = parseInt(params.statesStampStateId ?? "0");
                    data.statesStampStateId = data.statesStampStateId;

                    data.statesStampsIsStateStamp = data.statesStampsIsStateStamp !== undefined
                        ? JSON.parse(String(data.statesStampsIsStateStamp))
                        : false;

                    data.statesStampContingentUpon = data.statesStampContingentUpon !== undefined
                        ? JSON.parse(String(data.statesStampContingentUpon))
                        : false;

                    data.statesStampsStampAfterApproval = data.statesStampsStampAfterApproval !== undefined
                        ? JSON.parse(String(data.statesStampsStampAfterApproval))
                        : false;



                    console.log("final data", data);
                    fetch(
                        config.url.API_URL + `/StateStampList/AddEditStateStamp`,
                        {
                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();

                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            if (res.resp != 1) {

                                setSubmitting(false);
                                Swal.fire({
                                    title: "Data Successfully saved",
                                    text: "",
                                    icon: "success",
                                    confirmButtonText: "OK",
                                });
                                navigate("/Admin/StateStampList");

                            } else {

                                alert(res.message);
                                setSubmitting(false);
                            }
                        }).catch((error) => {
                            setSubmitting(false);
                            alert("There was an error Creating the Model");

                            console.log(error)

                        });



                }} >
                {({ submitForm, values, setFieldValue, isSubmitting, errors }) =>
                (
                    <Form>
                        <div className="card-body  m-2">
                            <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="card-body card-body-dashboard   ">
                                        <FormControl sx={{ width: '100%' }} className="m-1"  >
                                            <FormHelperText className="formtexthelper-heading">State </FormHelperText>
                                            <FormControl error={!!errors.statesStampStateId} fullWidth>
                                                <Select

                                                    value={values.statesStampStateId}
                                                    id="test-select-label"
                                                    // autoWidth
                                                    label={null}
                                                    onChange={(event) => {

                                                        setFieldValue("statesStampStateId", event.target.value);
                                                        // initValues.stateId = Number(event.target.value);

                                                    }}
                                                    name="statesStampStateId"

                                                    className="border-round custom-menu-item"
                                                    IconComponent={ExpandMoreIcon}
                                                    displayEmpty

                                                >
                                                    <MenuItem key={0} value={0}>

                                                        Select State{" "}
                                                    </MenuItem>
                                                    {/* <MenuItem value={"0"}>State</MenuItem> */}
                                                    {states.map((state, key) => (
                                                        <MenuItem key={key} value={state.stateId}>

                                                            {state.stateName}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                                <FormHelperText>{errors.statesStampStateId}</FormHelperText>
                                                {/* <FormHelperText>{errors.stateId}</FormHelperText> */}
                                            </FormControl>
                                        </FormControl>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="card-body card-body-dashboard">
                                        <FormControl sx={{ width: '100%' }} className="m-1" >
                                            <FormHelperText className="formtexthelper-heading">Is State Stamp</FormHelperText>
                                            <FMSelectTrueFalse autoWidth={true} name="statesStampsIsStateStamp" />
                                            {/* <FormHelperText>{errors.statesStampsIsStateStamp}</FormHelperText> */}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="card-body card-body-dashboard ">
                                        <FormControl sx={{ width: '100%' }} className="m-1" >
                                            <FormHelperText className="formtexthelper-heading">Contingent Upon</FormHelperText>
                                            <FMSelectTrueFalse autoWidth={true} name="statesStampContingentUpon" />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="card-body card-body-dashboard ">
                                        <FormControl sx={{ width: '100%' }} className="m-1" >
                                            <FormHelperText className="formtexthelper-heading">Stamp After Approval</FormHelperText>
                                            <FMSelectTrueFalse autoWidth={true} name="statesStampsStampAfterApproval" /></FormControl>

                                    </div>
                                </div>
                            </div>
                            <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                            <div className="col-lg-12 col-sm-12">
                                    <div className="card-body  card-body-dashboard m-0 main-form">
                                        <FormControl sx={{ width: '100%' }} className="m-1" >
                                            <FormHelperText className="formtexthelper-heading">Effective Date</FormHelperText>
                                            <FMTextField name="effectiveDate" InputLabelProps={{ shrink: true }} label={null} autoFocus margin="dense" type="date" variant="outlined" />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-10 offset-md-1">
                                <Editor
                                    wrapperClassName="card card-noborder"
                                    editorClassName="editor-class"
                                    editorState={
                                        editorState
                                    }
                                    onEditorStateChange={newState => {
                                        seteditorState(newState);
                                        console.log("newstate", draftToHtml(convertToRaw(newState.getCurrentContent())));


                                    }}
                                />
                            </div>

                            <div className='card-body card-body-dashboard'>


                                <button className="btn  btn-primary active-btn-blue  search-btn " type="submit" onClick={submitForm} disabled={isSubmitting}  >Save</button>

                            </div>

                        </div>


                    </Form>


                )}
            </Formik>
        </React.Fragment>
    )
}

