import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, Box, FormHelperText, FormControl, Autocomplete, TextField, SelectProps, InputLabel, Select, MenuItem, Button, LinearProgress } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import { FMSelectDocumentType, FMSelectTransactionType, } from "../Elements/select";
import useAuth from "../../context/AuthProvider";
import { SurplusLineFormMapping, SurplusLineFormMapping_VS } from "../../models/SurplusLineFormMappingModel";
import { butlerList } from "../Lists/ButlerMappingList";
import { Stack } from "@mui/system";
import { Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { number } from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";


interface Iprops {
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initvalues: SurplusLineFormMapping
    fetchData: () => void,
    setSselectedOption: React.Dispatch<React.SetStateAction<butlerList | null>>
    selectedOptionValue: butlerList | null
    setSelectedTrans: React.Dispatch<React.SetStateAction<string | string[]>>
    SelectedTrans: string[] | string
    isEditMode: boolean
    value: number


}

export const AddEditSurplusLineFormMapping: React.FC<Iprops> = ({ value, Open, handleClose, handleSubmit, initvalues, fetchData, selectedOptionValue, setSselectedOption, setSelectedTrans, SelectedTrans, isEditMode }) => {

    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();
    interface butlerList {
        modelId: number, name: string
    }

    const initialdata: butlerList = {
        modelId: 180,
        name: "AL-SL Notification"
    };
    const handleOptionChange = (event: React.ChangeEvent<{}>, newValue: butlerList | null) => {
        setSselectedOption(newValue);

    };
    const [iniButlerData, setiniButlerData] = useState<butlerList | null>(initialdata);



    const [loadingModels, setLoadingModels] = useState(true);
    const [ButlerModelData, setButlerModelData] = useState([initialdata])
    const fetchModelList = () => {

        fetch(config.url.API_URL + '/ButlerModel/getAllButlerModels',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setLoadingModels(false);
                setButlerModelData(responseJson.data)
                //console.log("butlerdata", responseJson)

            }).then((responseJson) => {
                console.log("inside our then");
                var initModl = ButlerModelData.find(x => x.modelId === initvalues.modelId);
                let ini: butlerList = {
                    modelId: initModl?.modelId ?? 0,
                    name: initModl?.name ?? ""
                };
                console.log("initmodleddata", ini);
                if (ini != null || undefined) {
                    setiniButlerData(ini);
                }
            })
            .catch((error) => {

                console.log(error)

            });
    }
    const FMSelectTrueFalseCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error ? meta.error : "";

        return (
            <React.Fragment>
                <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} className="main-form-2" >
                    <InputLabel >Is Required</InputLabel>
                    <Select {...field} labelId="Select-Label" IconComponent={ExpandMoreIcon} name="isRequired" label="Is Required"   >

                        <MenuItem value={true as any}>True</MenuItem>
                        <MenuItem value={false as any}>False</MenuItem>

                    </Select>

                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>

        )
    }



    // const initialdata = { modelId: 0, name: "" };
    //     const [ButlerModelData, setButlerModelData] = useState([initialdata])

    const fetchButlerData = () => {

        fetch(config.url.API_URL + '/ButlerModel/getAllButlerModels',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                setButlerModelData(responseJson.data)
                console.log("butlerdata", responseJson)

            }).catch((error) => {

                console.log(error)

            });


    }


    useEffect(() => {
        fetchButlerData();

    }, [])


    const FMSelectCustomButlerModel: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        // const initialdata = { modelId: 0, name: "" };
        // const [ButlerModelData, setButlerModelData] = useState([initialdata])

        // const fetchData = () => {

        //     fetch(config.url.API_URL + '/ButlerModel/getAllButlerModels',
        //         {
        //             method: "GET",
        //             headers: new Headers({
        //                 'Content-Type': 'application/json',
        //                 'Authorization': `Bearer ${token}`,
        //             })
        //         }).then((response) => {
        //             if (response.ok) {
        //                 return response.json();
        //             }
        //             throw new Error('Something went wrong');
        //         })
        //         .then((responseJson) => {
        //             // Do something with the response

        //             setButlerModelData(responseJson.data)
        //             console.log("butlerdata", responseJson)

        //         }).catch((error) => {

        //             console.log(error)

        //         });


        // }


        // useEffect(() => {
        //     fetchData();

        // }, [])

        return (

            <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}   >

                {/* <InputLabel ></InputLabel> */}
                {/* <Select {...field} label="Butler Model">
                    {ButlerModelData.map((ButlerModelData, key) =>
                        <MenuItem key={key} value={ButlerModelData.modelId}> {ButlerModelData.name} </MenuItem>
                    )}
                </Select> */}
                <Autocomplete
                    className="main-form-autocomplete"
                    options={ButlerModelData}
                    getOptionLabel={(option) => option.name}
                    value={selectedOptionValue}
                    onChange={handleOptionChange}
                    popupIcon={<ExpandMoreIcon />}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Model" name="modelId" variant="outlined" />
                    )}
                />
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        )
    }
    const [selectedTransactionList, setselectedTransactionList] = React.useState<string[] | string>([]);

    const initialTrandata = { transactionId: 0, transactionTypeName: "" };
    const [TransactionType, setTransactionType] = useState([initialTrandata])
    const fetchAllTransData = () => {

        fetch(config.url.API_URL + '/CustomerPolicy/GetTransactionTypeList',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                setTransactionType(responseJson.data)

            }).catch((error) => {

                console.log(error)

            });


    }


    const FMSelectTransTypeCustomcopy: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error ? meta.error : "";
        return (

            <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} >
                {/* {(user?.userRoles.split(",").includes("Super Admin")) ?  <InputLabel id="State-Select-Label">Roles</InputLabel> :""} */}
                <InputLabel id="Transaction type">Transaction type</InputLabel>
                <Select
                    {...field}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    // hidden={!(user?.userRoles.split(",").includes("Super Admin"))}
                    label="Transaction type"
                    multiple
                    labelId="Roles"
                    value={SelectedTrans}
                    onChange={(e) => {




                    }}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.toLocaleString().split(',').map((value) => (
                                <Chip
                                    key={value}
                                    label={TransactionType.find(x => x.transactionId.toString() === value)?.transactionTypeName}
                                    onDelete={() =>
                                        setSelectedTrans(
                                            SelectedTrans.toLocaleString().split(',').filter((item) => item !== value)
                                        )
                                    }
                                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                />
                            ))}
                        </Stack>
                    )}

                    input={<OutlinedInput label="Multiple Select" />}
                >

                    {TransactionType.map((Role, key) =>
                        <MenuItem key={key} value={Role.transactionId}> {Role.transactionTypeName} </MenuItem>
                    )}

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    useEffect(() => {
        fetchAllTransData();

    }, [])
    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className="card-title" >Create Mappings for {initvalues.state} </DialogTitle>

            <React.Fragment>
                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                <Formik

                    initialValues={initvalues}
                    validateOnChange={true}
                    validationSchema={SurplusLineFormMapping_VS}

                    onSubmit={(data, { setSubmitting, setErrors }) => {
                        var oldDocumentTypeId = initvalues.documentTypeId !== null && initvalues.documentTypeId !== undefined && initvalues.documentTypeId > 0 ? initvalues.documentTypeId : data.documentTypeId;
                        var oldButlerModelId = initvalues.modelId !== null && initvalues.modelId !== undefined && initvalues.modelId > 0 ? initvalues.modelId : selectedOptionValue?.modelId;

                        data.modelId = selectedOptionValue?.modelId;
                        console.log("finalDatttaaa", data);
                        
                        if (data.isRequired === null) {
                            data.isRequired = false;
                        }

                        data.commaSeparatedTransactionIds = SelectedTrans.toString();
                        if (data.commaSeparatedTransactionIds.length < 1) {
                            setErrors({ transactionId: 'Please enter transaction' });
                            setSubmitting(false);
                            return;
                        }

                        if (selectedOptionValue?.modelId == undefined || selectedOptionValue?.modelId < 1) {
                            //alert("Butler Model Required");
                            setErrors({ modelId: 'Select Model' });
                            setSubmitting(false);
                            setloading(false);
                            return;
                        }
                        setloading(true);
                        fetch(
                            config.url.API_URL + `/ButlerMappings/AddEditSurplusLineFormMapping?oldDocumentTypeId=${oldDocumentTypeId}&&oldButlerModelId=${oldButlerModelId}&&value=${value}`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: JSON.stringify(data)
                            })
                            .then((response) => {
                                if (response.ok) {
                                    return response.json();

                                }

                                throw new Error('Something went wrong');
                            })
                            .then((res) => {
                                debugger
                                if (res.data === 1) {
                                    Swal.fire({
                                        title: res.message,
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: "OK",
                                    });
                                }
                                fetchData();
                                setSubmitting(false);
                                setloading(false);
                                handleClose();

                            }).catch((error) => {
                                setSubmitting(false);
                                alert("There was an error Creating the Mapping");
                                console.log(error)
                                setloading(false);
                            });

                    }} >
                    {({ values, isSubmitting, setFieldValue, errors }) =>
                    (
                        <Form className=""  >
                            <DialogContent>
                                <Grid container spacing={2}>



                                    <Grid item xs={6}>

                                        <FormControl error={!!errors.transactionId} fullWidth sx={{ m: 1 }} className="main-form-2" >
                                            {/* {(user?.userRoles.split(",").includes("Super Admin")) ?  <InputLabel id="State-Select-Label">Roles</InputLabel> :""} */}
                                            <InputLabel id="Transaction type">Transactions type</InputLabel>
                                            <Select
                                                disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                                // hidden={!(user?.userRoles.split(",").includes("Super Admin"))}
                                                label={null}
                                                name="transactionId"
                                                IconComponent={ExpandMoreIcon}
                                                multiple
                                                labelId="Roles"
                                                value={SelectedTrans}
                                                onChange={(e) => {
                                                    setSelectedTrans(e.target.value);
                                                    setFieldValue("transactionId", e.target.value);
                                                }}
                                                renderValue={(selected) => (
                                                    <Stack gap={1} direction="row" flexWrap="wrap">
                                                        {selected.toLocaleString().split(',').map((value) => (
                                                            <Chip
                                                                key={value}
                                                                label={TransactionType.find(x => x.transactionId.toString() === value)?.transactionTypeName}
                                                                onDelete={() =>
                                                                    setSelectedTrans(
                                                                        SelectedTrans.toLocaleString().split(',').filter((item) => item !== value)
                                                                    )
                                                                }
                                                                disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                                                deleteIcon={
                                                                    <CancelIcon
                                                                        onMouseDown={(event) => event.stopPropagation()}
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                    </Stack>
                                                )}

                                                input={<OutlinedInput label="Multiple Select" />}
                                            >

                                                {TransactionType.map((Role, key) =>
                                                    <MenuItem key={key} value={Role.transactionId}> {Role.transactionTypeName} </MenuItem>
                                                )}

                                            </Select>

                                            <FormHelperText>{errors.transactionId}</FormHelperText>
                                        </FormControl>


                                        {/* <FMSelectTransTypeCustomcopy variant={"standard"} name="transactionId" ></FMSelectTransTypeCustomcopy> */}
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectDocumentType autoWidth={true} variant={"standard"} name="documentTypeId" ></FMSelectDocumentType>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>

                                        <FormControl error={!!errors.modelId} fullWidth sx={{ m: 1 }}>
                                            <Autocomplete
                                                className="main-form-autocomplete"
                                                options={ButlerModelData}
                                                getOptionLabel={(option) => option.name}
                                                value={ButlerModelData.find(option => option.modelId === values.modelId) || null}
                                                onChange={(event, newValue) => {

                                                    setSselectedOption(newValue);
                                                    setFieldValue('modelId', newValue ? newValue.modelId : '');
                                                }}
                                                popupIcon={<ExpandMoreIcon />}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Model"
                                                        name="modelId"
                                                        variant="outlined"
                                                        error={!!errors.modelId}
                                                    />
                                                )}
                                            />
                                            {errors.modelId && (
                                                <FormHelperText>{errors.modelId}</FormHelperText>
                                            )}
                                        </FormControl>
                                        {/* <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectCustomButlerModel variant={"standard"} name="modelId"  ></FMSelectCustomButlerModel>
                                        </FormControl> */}
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectTrueFalseCustom autoWidth={true} variant={"standard"} name="isRequired" ></FMSelectTrueFalseCustom>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                            </DialogContent>



                            <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn " type="button" onClick={handleClose}  >Cancel</button>
                                <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                            </DialogActions>
                        </Form>

                    )}
                </Formik>
            </React.Fragment >
        </Dialog >




    );
}