import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAuth from "../context/AuthProvider";
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import { config } from '../Constants';
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from '@mui/material';
import { FMTextField } from '../components/Elements/input';
import { FMSelectModelField } from '../components/Elements/select';
import { UploadDocumentModel, UploadDocumentModel_VS } from '../models/UploadDcumentModel';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

var formData = new FormData();


const baseStyle = {
    flex: 1,
    display: 'flex',

    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function StyledDropzone(props: any) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: { 'image/*': [] ,'application/pdf':['.pdf']   }, onDrop: files => formData.append('file',files[0]) }
   
    );

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    function onDrop(acceptedFiles: File[]) {
        acceptedFiles.forEach(file => {
            formData.append('file', file);
        })

    }



    const files = acceptedFiles.map(
        file => 
        (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
        )
   
    );

    return (

        <div {...getRootProps({ style })}>
            <input {...getInputProps()} name='file' />
            <p>Drag 'n' drop Document here or click to select document</p>
            <aside>
                <ul>{files}</ul>
            </aside>
        </div>

    );
}

interface props {
    fetchData: () => void
}
export const UploadDocuments: React.FC<props> = ( {fetchData}  ) => {
    const initialValues: UploadDocumentModel = { Transactionid: 0, ModelId: 0 }
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'

        }}      >
            <Box
                sx={{
                    m: 1,
                    width: '100%',
                    height: 'auto',

                }}
            >
                <h1>Upload Documents for this Transaction</h1>
                <Paper
                    elevation={3}
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',


                    }}
                >
                    {loading && <LinearProgress sx={{backgroundColor: "#132D3D"}} />}



                    <Formik initialValues={initialValues}
                        validateOnChange={true}
                        validationSchema={UploadDocumentModel_VS}
                        onSubmit={(data, { setSubmitting }) => {
                            setloading(true);
                            data.Transactionid = params.transactionID;
                            formData.append('Transactionid' , data.Transactionid ?? "0")
                            formData.append('ModelId',data.ModelId.toString() ?? "0")
                            

                          
                            fetch(
                                config.url.API_URL + `/UploadDocument/UploadFilesFromWeb/`,
                                {
                                    method: 'POST',
                                    headers: new Headers({
                                        // 'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    }),
                                    body: formData  // <-- Post parameters

                                })
                                .then((response) => {
                                    if (response.ok) {
                                        setloading(false);
                                        return response.json();

                                    }
                                    throw new Error('Something went wrong');
                                })
                                .then((res) => {
                                    alert(res.data);
                                    // Do something with the response
                                    setSubmitting(false);
                                    fetchData();
                                    setloading(false);

                                    formData = new FormData();

                                }).catch((error) => {
                                    setloading(false);
                                    setSubmitting(false);
                                    alert("There was an error saving the Document");
                                    formData = new FormData();
                                    console.log(error)
                                });

                        }} >
                        {({ values, isSubmitting }) =>
                        (
                            <Form>

                                <DialogContent>
                                    <Box sx={{ display: 'flex', m: 2 }}>

                                        <Box sx={{ display: 'flex', width: '30%', m: 2 }}>
                                            <FMSelectModelField autoWidth={true} name="ModelId" ></FMSelectModelField>

                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', m: 1 }}>
                                        <StyledDropzone />
                                    </Box>
                                </DialogContent>
                                <DialogActions>

                                    <Button variant="outlined" type="submit" disabled={isSubmitting} >Upload Documents</Button>
                                </DialogActions>
                            </Form>
                        )
                        }
                    </Formik >
                </Paper>

            </Box>
        </Box>

    );
}

