import { config } from "../Constants"

export const Thankyou = () => {
    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-8 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">

                                            <div className="table-responsive">
                                                <div className="">
                                                    <div className="row col-lg-12">
                                                        <div className="col-lg-4 d-flex" style={{ marginBottom: "0px" }}>
                                                        </div>
                                                        <div className="col-lg-4" style={{ textAlign: "center" }}>


                                                        </div>
                                                        <div className="col-lg-4" style={{ textAlign: "right" }}>

                                                        </div>
                                                    </div>
                                                    <div className="m-3 row col" style={{ height: "50vh" }}>


                                                        <div className="col-lg-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      
                                                            <div className="text-center">
                                                                <div className="m-3">
                                                                <span className="logo_name " >  <img src="/assets/brand/checked.png" height='100vh' width='100vh' className="img-logo" alt="..." /></span>

                                                                </div>
                                                                <h1>Thank You !</h1>
                                                                <p>     <span className="card-title" ><b>Password Changed Sucessfully</b></span></p>
                                                                {/* <button className="btn btn-primary" >Back Home</button> */}

                                                                <h3><a className="btn btn-success"  href= {config.url.API_URL === "https://app.filemule.io/" ? "https://app.filemule.io/": "https://app.filemule.io/"}  >Click here to login to FileMule</a></h3>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        </section >
    )
}