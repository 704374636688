
import { IconButton, LinearProgress, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { TransactionFeeModel } from "../../models/TransactionModel";
import { EmailReportModuleModel } from '../../models/EmailReportModuleModel';
import { PolicyCheckResult } from "../../Pages/PolicyChecksResult";


const initValue: EmailReportModuleModel = {
    emailReportId: 0,
    customerName: "",
    templateName: "",
    emailSent: false,
    stateName: "",
    emailSendDate: "",
    blob_Address: "",
    content: ""
}
const emailReports: EmailReportModuleModel[] = [
    { ...initValue },
];


export const EmailReportsDetails = () => {
    const [EmailReportModuleDetails, setEmailReportModule] = useState(emailReports);

    // const [feeComparison, setFeecomparison] = useState(initialFeecomaprison);
    const { user } = useAuth();
    const token = user?.token;




    useEffect(() => {
        setloading(true);

        fetchData();

    }, [])

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/EmailReports/GetEmailReportsDetails?EmailReportId=${params.emailReportId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);
                
                setEmailReportModule(responseJson.data)
                console.log("butlerdata", responseJson.data)
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }



    const initialTransactionFee: TransactionFeeModel = {
        taxName: "", rate: 0, rateType: "", calculatedFee: 0, premium: 0, policyFee: 0, stampingFee: 0,
        techInterfacefee: 0,
        brokerFee: 0,
        transactionType: 0,
        inspectionFee: '',
        stateTax: ''
    }
    const [loading, setloading] = useState(false);

    // const [Transaction, settransactions] = React.useState(initialTransaction);
    const [TransactionFees, setTransactionFees] = React.useState([initialTransactionFee]);

    const params = useParams();

    return (
        <React.Fragment>

            <div style={{ marginTop: "4px" }}>
                {/* <span className="text-center"><h3>Email Report Details</h3></span> */}
                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            </div>



            <div className="row row-cols-1 row-cols-md-3 g-4 m-0 ">

                <div className="col-lg-6 col-sm-12">


                    <div className="card dashboard-table" style={{ height: "460px" }}>

                        <span className="card-title">Email Details</span>
                        <div className="table-responsive">
                            {
                                <table className="table">
                                    <tbody>

                                        <tr className="align-middle " >
                                            <td>Template Name</td>
                                            <td>{EmailReportModuleDetails[0]?.templateName}</td>
                                        </tr>
                                        <tr className="align-middle">
                                            <td>Email Subject</td>
                                            <td>{EmailReportModuleDetails[0]?.subject?.toString()}</td>
                                        </tr>
                                        <tr className="align-middle">
                                            <td>CC</td>
                                            <td>{EmailReportModuleDetails[0]?.cc?.toString()}</td>
                                        </tr>
                                        <tr className="align-middle">
                                            <td>TO</td>
                                            <td>{EmailReportModuleDetails[0]?.mailSentTo?.toString()}</td>
                                        </tr>
                                        <tr className="align-middle">
                                            <td>Customer</td>
                                            <td>{EmailReportModuleDetails[0]?.customerName?.toString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-12">
                    <div className="card dashboard-table" style={{ height: "460px" }}>
                        <span className="card-title">Attachments</span>
                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                    {EmailReportModuleDetails.map((email, key) =>
                                        <tr key={key}>
                                            <td>
                                                <div style={{ maxWidth: '920px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    <Link href={'#'} sx={{ textDecoration: "none " }} target="_blank">
                                                        {email.blob_Address?.replace('https://filemuleblobstorage.blob.core.windows.net/filemuleblobstorage/', '')}
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4 m-0 ">
                <div className="col-lg-12 col-sm-12">

                    <div className="card dashboard-table" style={{ height: "300px" }}>

                        <div className="table-responsive">
                            {
                                <table className="table">
                                    <tbody>
                                        <tr className="align-middle">
                                            <td className="col-lg-2">Content</td>
                                            <td className="col-12">
                                                {typeof EmailReportModuleDetails[0]?.content === 'string' ? EmailReportModuleDetails[0]?.content.replace(/<[^>]+>/g, '') : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}
