import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { InsuranceComapnyList } from "../components/Lists/InsuranceCompanyList";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { RightNav } from "../components/Nav/RightNav";
import { ReportModuleList } from "../components/Lists/ReportModuleList";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { ReportModuleModel_Resp } from "../models/ReportModuleModel";
import { EmailReportModuleList } from "../components/Lists/EmailReportModuleList";
import { EmailReportModuleModel } from "../models/EmailReportModuleModel";




export const EmailReportModule = () => {
    const [_currentPage, setCurrentPage] = React.useState(1);
    const [_pageSize, setPageSize] = React.useState(50);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: EmailReportModuleModel = {
        emailReportId: 0,
        customerName: "",
        templateName: "",
        emailSent: false,
        stateName: "",
        emailSendDate: ""
    }

    const [EmailReportModule, setEmailReportModule] = useState([]);

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/ReportModule/getAllEmailReports',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);

                setEmailReportModule(responseJson.data)
                console.log("butlerdata", responseJson.data)
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();
    }, [])

    return (


        <EmailReportModuleList ReportModuleCL={EmailReportModule} fetchData={fetchData} />

    );

}
