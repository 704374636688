import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, Stack } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import { FieldAttributes, Form, Formik, useField } from 'formik';
import { config } from '../../Constants';
import { FMSelectLOBField, FMSelectRateTypeField, FMSelectStateField, FMSelectTaxAndFees, FMSelectTaxTypeField } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from 'react-router-dom';
import { error } from 'console';
import Swal from 'sweetalert2';
import CancelIcon from "@mui/icons-material/Cancel";
import { PartnersModel_VS, partnertaxesandfeesModel } from '../../models/PartnerTaxandFeeModel';
import { lobData, transData } from '../Lists/PartnerTaxandFeeList';
import { NumbersRounded } from '@mui/icons-material';

interface Iprops {
  partnertaxesandfessCL: partnertaxesandfeesModel,
  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void
  initValues: partnertaxesandfeesModel
  fetchLOBData: (stateId: string) => void
  fetchTransactionData: () => void
  Lineofbusiness: lobData[] | null
  TransactionType: transData[] | null
  setLineOfBusiness: React.Dispatch<React.SetStateAction<lobData[] | null>>
  setSelectedLOB: React.Dispatch<React.SetStateAction<string>>
  SelectedLOB: string
  setPartnerTaxesAndFee: React.Dispatch<React.SetStateAction<partnertaxesandfeesModel[]>>
  PartnerTaxesAndFeeCL: partnertaxesandfeesModel[]
  SelectedLOBName: string
  setSelectedLOBName: React.Dispatch<React.SetStateAction<string>>

}

export const AddEditLicenseContact: React.FC<Iprops> = ({
  handleClose, Open, PartnerTaxesAndFeeCL, setPartnerTaxesAndFee,
  partnertaxesandfessCL, handleSubmit, initValues, Lineofbusiness, fetchLOBData, setSelectedLOB,
  SelectedLOB, TransactionType, fetchTransactionData,
  SelectedLOBName,
  setSelectedLOBName
}) => {
  const initialValues: partnertaxesandfeesModel = initValues ? partnertaxesandfessCL : initValues;
  const { user } = useAuth();
  const token = user?.token;

  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);

  function fetchStateData() {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }

        console.log("statesDAta", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event: SelectChangeEvent) => {
    debugger;
    setSelectedLOB(event.target.value);
    const selectedLineOfBusiness = Lineofbusiness?.find(
      (lob) => lob.id === Number(event.target.value)
    );
    setSelectedLOBName(selectedLineOfBusiness?.businessCode ?? '');

  };

  const FMSelectCustomLineOfBusiness: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error ;
    const { user } = useAuth();
    
 

    return (

      <FormControl  error={!!errorText} fullWidth sx={{ m: 1 }}  >
        <InputLabel id="State-Select-Label"> Select LOB</InputLabel>
        <Select
          onChange={handleChange}
          labelId="State-Select-Label"
          label=" Select LOB"
          value={SelectedLOB || ""}
          className="border-round main-form-2"
          IconComponent={ExpandMoreIcon}
          name="lobId"
        >
          {/* <MenuItem key={0} value={0}> Select LOB </MenuItem> */}
          {Lineofbusiness?.map((LineOfBusiness, key) =>
            <MenuItem key={key} value={LineOfBusiness.id}> {LineOfBusiness.businessCode} - {LineOfBusiness.coverageName} </MenuItem>

          )}
          
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    )
  }


  const FMSelectTransactionType: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
    const [field, meta] = useField<{}>(props);
     const errorText = meta.error;

    return (
      <React.Fragment>
        <FormControl error={!!errorText} fullWidth>
          <InputLabel id="State-Select-Label">Transaction Type</InputLabel>
          <Select {...field}
            labelId="State-Select-Label"
            label="TransactionType"
            className="border-round main-form-2"
            IconComponent={ExpandMoreIcon}
            name="transactionTypeId"

          >
            {TransactionType?.map((carrier, key) =>
              <MenuItem key={key} value={carrier.transactionId}> {carrier.transactionTypeName} </MenuItem>
            )}
          </Select>
          
          <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
      </React.Fragment>
    );
  }



  useEffect(() => {
    fetchTransactionData();
    fetchStateData();
  }, []);



  const [loading, setloading] = useState(false);

  return (
    <Dialog open={Open} onClose={handleClose}>
      <DialogTitle className='card-title'>Licensee Contact</DialogTitle>

      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={PartnersModel_VS}
        onSubmit={(data, { setSubmitting, setErrors }) => {
          debugger;
          setloading(true);
          // data.rateType = data.rateType
          data.rate = Number(data.rate)
          data.lobId = Number(SelectedLOB);
          data.businessCode = SelectedLOBName;
          data.rateTypeName = data.rateType === '0' ? 'Percentage' : 'Dollar';

if(data.lobId===null || undefined)
  {
    setErrors({lobId:'Please select a line of business'})
  }

  if(data.transactionTypeId===0 || data.transactionTypeId===null || data.transactionTypeId==undefined)
    {
      setErrors({transactionTypeId:'Please select a transaction type'})
    }
  

          if (data.partnerTaxesandFeeId === 0) {
            data.partnerTaxesandFeeId = PartnerTaxesAndFeeCL.length + 1
            setPartnerTaxesAndFee(oldArray => [...oldArray, data]);
          } else {
            debugger;
            let AllRec = PartnerTaxesAndFeeCL
            AllRec.forEach((x, index) => {
              debugger;
              if (x.partnerTaxesandFeeId === data.partnerTaxesandFeeId) {
                AllRec[index].transactionTypeId = data.transactionTypeId;
                AllRec[index].lobId = data.lobId;
                AllRec[index].stateId = data.stateId;
                AllRec[index].rate = data.rate;
                AllRec[index].rateType = data.rateType;
                AllRec[index].notes = data.notes;
                AllRec[index].businessCode = data.businessCode;
                AllRec[index].rateTypeName = data.rateTypeName;
              }
            });
            setPartnerTaxesAndFee(AllRec)

          }

          handleSubmit();
          console.log(PartnerTaxesAndFeeCL);
        }
        }
      >
        {({ values, isSubmitting, errors, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl error={!!errors.stateId} sx={{ width: '100%' }}>
                    <Select
                      value={initialValues.stateId}
                      id="test-select-label"
                      label={null}
                      onChange={(e) => {
                        setFieldValue("stateId", e.target.value);
                        initialValues.stateId = Number(e.target.value);
                        initialValues.lobId = 0;
                        fetchLOBData(String(e.target.value));
                      }}
                      name="stateId"
                      className="border-round custom-menu-item"
                      IconComponent={ExpandMoreIcon}
                      displayEmpty
                    >
                      <MenuItem key={0} value={0}>
                        Select State{" "}
                      </MenuItem>
                      {/* <MenuItem value={"0"}>State</MenuItem> */}
                      {States.map((state, key) => (
                        <MenuItem key={key} value={state.stateId}>
                          {state.stateName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.stateId}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <FMSelectTransactionType autoWidth={true} name="transactionTypeId"></FMSelectTransactionType>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <FMSelectRateTypeField autoWidth={true} name="rateType"></FMSelectRateTypeField>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }} className='main-form'>
                    <FMTextField name="rate" autoFocus margin="dense" label="Amount" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>

                <FormControl error={!!errors.lobId} fullWidth sx={{ m: 1 }}  >
                    <InputLabel id="State-Select-Label"> Select LOB</InputLabel>
                    <Select
                      onChange={(e) => {
                        setFieldValue("lobId", e.target.value);
                        initialValues.lobId = Number(e.target.value);
                        // initialValues.lobId = 0;
                         handleChange(e);
                      }}
                      labelId="State-Select-Label"
                      label=" Select LOB"
                      value={SelectedLOB}
                      className="border-round main-form-2"
                      IconComponent={ExpandMoreIcon}
                    >
                      {/* <MenuItem key={0} value={0}> Select LOB </MenuItem> */}
                      {Lineofbusiness?.map((LineOfBusiness, key) =>
                        <MenuItem key={key} value={LineOfBusiness.id}> {LineOfBusiness.businessCode} - {LineOfBusiness.coverageName} </MenuItem>
                      )}
                    </Select>
                    <FormHelperText>{errors.lobId}</FormHelperText>
                  </FormControl>



                  {/* <FMSelectCustomLineOfBusiness autoWidth={true} name="lobId"></FMSelectCustomLineOfBusiness> */}
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormHelperText></FormHelperText>
                    <FMTextField name="notes" autoFocus margin="dense" multiline rows={3} label="Notes" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <button className="btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}>Cancel</button>
              <button className="btn btn-primary active-btn-blue search-btn" type='submit' disabled={isSubmitting}>Submit</button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

}
