import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAuth from "../../context/AuthProvider";
import DialogTitle from '@mui/material/DialogTitle';
import { InsuranceCompanyModel, InsuranceCompanyModel_VS } from '../../models/insuranceCompanyModel';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { Box } from '@mui/material';
import { FMTextField } from '../Elements/input';
import { FMSelectState } from '../Elements/select';

interface Iprops {
  InsuranceCompanyCL: InsuranceCompanyModel,

  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void,
  initValues: InsuranceCompanyModel

}


export const AddEditInsuranceCompany: React.FC<Iprops> = ({ handleClose, Open, InsuranceCompanyCL, handleSubmit, initValues }) => {
  const initialValues: InsuranceCompanyModel = initValues ? InsuranceCompanyCL : initValues;

  const { user } = useAuth();
  const token = user?.token;


  return (

    <Dialog open={Open} onClose={handleClose}>
      <DialogTitle>Insurance Company</DialogTitle>
      <Formik initialValues={initialValues}
        validateOnChange={true}
        validationSchema={InsuranceCompanyModel_VS}
        onSubmit={(data, { setSubmitting }) => {
          fetch(
            config.url.API_URL + '/InsuranceCompany/put',
            {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }),
              body: JSON.stringify(data)  // <-- Post parameters

            })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error('Something went wrong');
            })
            .then((res) => {
              // Do something with the response
              handleSubmit()
              setSubmitting(true)

            }).catch((error) => {
              console.log(error)
            });

        }} >
        {({ values, isSubmitting }) => (
          <Form>

            <DialogContent>
              <Box sx={{ display: 'flex', m: 2 }}>
                <FMTextField name="naicNumber" autoFocus margin="dense" placeholder="Naic Number" label="Naic Number" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
              </Box>
              <Box sx={{ display: 'flex', m: 2 }}>
                <FMTextField name="name" autoFocus margin="dense" placeholder="Name" label="Name" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
              </Box>
              <Box sx={{ display: 'flex', m: 2, }}>

                <FMSelectState autoWidth={true} name="stateId"  ></FMSelectState>

              </Box>

            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={handleClose} type="button" >Cancel</Button>
              <Button variant="outlined" type="submit" disabled={isSubmitting} >Submit</Button>
            </DialogActions>

          </Form>

        )}


      </Formik>
    </Dialog>




  );
}

