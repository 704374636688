import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { FMSelectCustomerPartnerStatus, FMSelectState } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';
import { RiskLocationAddress } from '../../models/RiskAddressModel';
import { useParams } from 'react-router-dom';
import { LateFilingReasonModel } from '../../models/LateFilingReasonModel';
import { CustPolicyDetailsModel, newCustPolicyDetailsModel } from '../../models/CustomerPolicyDataModel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from 'moment';

interface Iprops {
    notesCL: newCustPolicyDetailsModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: CustPolicyDetailsModel

}
export const AddEditPolicyNotes: React.FC<Iprops> = ({ handleClose, Open, notesCL, handleSubmit, initValues }) => {
    const initialValues: newCustPolicyDetailsModel = initValues ? notesCL : initValues;
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();

    interface formData {
        customerPolicyId: number,
        notes?: string,
        dateModified?: string,
        notesDate?: Date,
        firstRequest?: string,
        secondRequest?: string,
        thirdRequest?: string,
        suspended?: string,
        filerName?: string
    }

    const options = [
        'Select One',
        'Missing Documents',
        'Corrections Required',
        // 'Filed on Binder',
        // 'First request',
        // 'Second request',
        // 'Third&Final request',
        // 'Escalation',
        // 'Other',
    ]
    const [noteOption, setNoteOption] = useState(options[0]);
    const [noteDetail, setNoteDetail] = useState<string>('')
    //const [noteDate, setNoteDate] = useState<Date >(new Date());
    const [noteDate, setNoteDate] = useState<Date>();
    const [firstRequestDate, setFirstRequestDate] = useState('')
    const [secondRequestDate, setSecondRequestDate] = useState('')
    const [thirdRequestDate, setThirdRequestDate] = useState('')
    const [suspendedDate, setSuspendedDate] = useState('')
    const [showNoteDateField, setShowNoteDateField] = useState(false);
    const handleFirstRequestChange = (val: string | undefined) => {

        if (val != null) {
            setFirstRequestDate(moment(val)?.format("MM/DD/YYYY"));

        }
    }
    const handleSecondRequestChange = (val: string | undefined) => {
        if (val != null) { setSecondRequestDate(moment(val)?.format("MM/DD/YYYY")) }
    }
    const handleThirdRequestChange = (val: string | undefined) => {
        if (val != null) { setThirdRequestDate(moment(val)?.format("MM/DD/YYYY")) }

    }
    const handleSuspendedRequestChange = (val: string | undefined) => {
        if (val != null) { setSuspendedDate(moment(val)?.format("MM/DD/YYYY")) }

    }


    const shouldDisableDate = (date: Date) => {
        const minDate = new Date('1900-01-01')
        const maxDate = new Date()
        return date < minDate || date > maxDate;
    };

    const handleNoteDetailChange = (event: any) => {

        const update = event.target.value
        console.log("noteDetail",update);
        setNoteDetail(update)
        setShowNoteDateField(update !== '');
        setNoteDate(update === '' ? new Date() : noteDate);
    }


    const handleNoteOptionChange = (event: SelectChangeEvent) => {
        const update = event.target.value
        setNoteOption(update)
        if (update === 'Filed on Binder') {

            setShowNoteDateField(true);
            setNoteDate(new Date());
        }
    }

    const handleNoteDate = (event: any) => {
        setNoteDate(event)
    }

    const fetchCustomerPolicyByCustPolicyId = (customerPolicyId: string | undefined) => {
        //setloading(true);
        debugger
        fetch(config.url.API_URL + `/CustomerPolicy/getCustomerPolicyDetails?custPolicyId=${customerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                // setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                //  setloading(false);
                if (res.data != null) {
                    debugger
                    var data = res.data;
                    //  setCustomerPolicyDetails(data);
                    setFirstRequestDate(data.firstRequest ?? '');
                    setSecondRequestDate(data.secondRequest ?? '');
                    setThirdRequestDate(data.thirdRequest ?? '');
                    setSuspendedDate(data.suspended ?? '');
                    setNoteDate(data.notesDate ? data.notesDate.toLocaleDateString() : new Date().toLocaleDateString());
                    const filerNotes = data.filerNotes
                    setNoteDetail(filerNotes)
                    setNoteOption(options[0])
                }
            }).catch((error) => {
                //  setloading(false);
                console.log(error)
            });
    }

    function clearDataAndClose() {
        setNoteDate(undefined)
        setFirstRequestDate('');
        setSecondRequestDate('');
        setThirdRequestDate('');
        setSuspendedDate('');
        setShowNoteDateField(false);
        
        setNoteDetail('')

        setNoteOption(noteOption[0]);
        handleClose();
    }

    useEffect(() => {
        if (Open) {
            fetchCustomerPolicyByCustPolicyId(params.customerpolicyid)
        }
    }, [Open])

    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'} >
            <DialogTitle className='card-title'  >Filer Notes</DialogTitle>
            <Formik

                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {
                    let custPolicyData: formData = {
                        customerPolicyId: 0
                    }
                    custPolicyData.customerPolicyId = Number(params.customerpolicyid ?? 0);
                    custPolicyData.customerPolicyId = Number(params.customerpolicyid ?? 0);
                    if (noteDetail == null || noteDetail == "" || noteDetail == undefined) {
                        custPolicyData.notes = noteOption
                    } else {
                        custPolicyData.notes = noteDetail
                    }
                    // custPolicyData.notes = noteDetail === '' ? noteOption : noteDetail;
                    custPolicyData.dateModified = (new Date).toString();
                    custPolicyData.notesDate = noteDate;
                    custPolicyData.firstRequest = firstRequestDate;
                    custPolicyData.secondRequest = secondRequestDate;
                    custPolicyData.thirdRequest = thirdRequestDate;
                    custPolicyData.suspended = suspendedDate;
                    custPolicyData.filerName = user?.userFirstName ?? '';


                    fetch(
                        config.url.API_URL + '/Policy/updateCustomerPolicyData',
                        {
                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(custPolicyData)

                        })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response
                            setFirstRequestDate('');
                            setSecondRequestDate('');
                            setThirdRequestDate('');
                            setSuspendedDate('');
                            setNoteDate(undefined);
                            setNoteDetail('');

                            handleSubmit()
                            setSubmitting(true);
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue , setErrors}) => (
                    <Form className='main-form'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DialogContent>

                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }} className="main-form-2 " >
                                            <InputLabel id="State-Select-Label" className="border-round ">Select Note</InputLabel>
                                            <Select
                                                // name={'noteOption'}
                                                value={noteOption}

                                                onChange={handleNoteOptionChange}
                                                labelId="State-Select-Label"
                                                label={"Select State"}
                                                IconComponent={ExpandMoreIcon}
                                            >
                                                {options.map((val, key) => (
                                                    val === "Select One" ?
                                                        <MenuItem key={key} value={val}><span style={{ color: 'red' }}>{val}</span></MenuItem>
                                                        : <MenuItem key={key} value={val}>{val}</MenuItem>
                                                )
                                                )}
                                            </Select>
                                           

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%', minWidth: '500px' }}>
                                            <FormHelperText className='formtexthelper-heading'>
                                                Note
                                            </FormHelperText>
                                            <TextField
                                                // name="noteDetail"
                                                label={null}
                                                rows={3}
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ marginTop: 2 }}
                                                multiline
                                                value={noteDetail}
                                                onChange={handleNoteDetailChange}
                                                required={noteOption === options[2]}
                                            // disabled={noteOption !== options[1]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    shouldDisableDate={shouldDisableDate}
                                                    inputFormat="MM/DD/YYYY"
                                                    label="First Request"
                                                    className="select-input-border text-input-box"
                                                    value={firstRequestDate ?? ''}
                                                    onChange={(v) => handleFirstRequestChange(v?.toString())}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            id="firstRequestDate"
                                                            name="firstRequestDate"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    shouldDisableDate={shouldDisableDate}
                                                    inputFormat="MM/DD/YYYY"
                                                    label="Second Request"
                                                    className="select-input-border text-input-box"
                                                    value={secondRequestDate ?? ''}
                                                    onChange={(v) => handleSecondRequestChange(v?.toString())}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            id="secondRequestDate"
                                                            name="secondRequestDate"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    shouldDisableDate={shouldDisableDate}
                                                    inputFormat="MM/DD/YYYY"
                                                    label="Third & Final Request"
                                                    className="select-input-border text-input-box"
                                                    value={thirdRequestDate ?? ''}
                                                    onChange={(v) => handleThirdRequestChange(v?.toString())}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            id="thirdRequestDate"
                                                            name="thirdRequestDate"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    shouldDisableDate={shouldDisableDate}
                                                    inputFormat="MM/DD/YYYY"
                                                    label="Suspended"
                                                    className="select-input-border text-input-box"
                                                    value={suspendedDate ?? ''}
                                                    onChange={(v) => handleSuspendedRequestChange(v?.toString())}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            id="suspendedDate"
                                                            name="suspendedDate"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={.3}></Grid>
                                        {(showNoteDateField) && (
                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12} marginTop={3}>
                                                <FormHelperText className='formtexthelper-heading' >Note Date</FormHelperText>
                                                <DatePicker
                                                    inputFormat="MM/DD/YYYY"
                                                    label={null}
                                                    className="select-input-border text-input-box"
                                                    shouldDisableDate={shouldDisableDate}
                                                    value={noteDate}
                                                    onChange={handleNoteDate}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            {...params}
                                                            id="effectiveDateStart"
                                                            name="policyEffDate" />
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <button className=' btn-2 btn d-flex btn-primary search-btn ' type='button' onClick={clearDataAndClose}  >Cancel</button>
                                <button className='btn  btn-primary active-btn-blue  search-btn ' disabled={isSubmitting || noteOption === options[0]} >Submit</button>
                            </DialogActions>
                        </LocalizationProvider>
                    </Form>
                )}
            </Formik>
        </Dialog >

    )
}